import React from "react";
import { Col, Row } from "react-bootstrap";
import { CustomModal } from "./common/ui/Modals/CustomModal";

interface SecurityModalProps {
    openModal: boolean;
}

export default class SecurityModal extends React.Component<SecurityModalProps, any> {
    constructor(props: any) {
        super(props);

    }

    private onCloseModal = () => {
        location.reload();
    };

    render() {
        const { openModal } = this.props;
        return (
            <CustomModal
                show={openModal}
                onHide={this.onCloseModal}
                isConfirmModal={true}
                className="session-expired-modal"
                onSubmit={this.onCloseModal}
                confirmButtonName="Login"
                title="Security"
            >
                <div>
                    <Row>
                        <Col lg={9}>
                            <h2 style={{ color: "#337ab7", margin: "0" }}>Session Expired</h2>
                            <Row className="margin-top-10">
                                Your session has expired. Please login again.
                            </Row>
                        </Col>
                    </Row>
                </div>
            </CustomModal>
        );
    }
}

