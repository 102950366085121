

import axios from "axios";
import {
  completedSentItemsHistoryMetrics,
  completeSentItemsHistory, completeSentItemsHistoryDownload, receiveSentItemsHistory,
  receiveSentItemsHistoryDownload, receiveSentItemsHistoryMetrics, requestSentItemsHistory,
  requestSentItemsHistoryDownload, requestSentItemsHistoryMetrics
} from "../actions/sentItemsHistoryActions";
import { AppThunkAction } from "../store";
import { actionTypes as sentItemsHistoryActions } from "../types/sentItemsHistoryTypes";
import { actionTypes as notificationActions } from "../types/notificationTypes";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import { getMailboxUserEmailId } from "../helper/HelperFunctions";
import { IReportItemsHistoryRequestModel } from "../core/viewModels/report/SentItemsHistoryModel";
import fileDownload from "js-file-download";
import { ReportConstants, ReportDownloadContansts } from "../helper/Constants";
import moment from "moment";
import { IMetricsCardModel } from "../core/viewModels/report/reportViewModel";
import { ToasterMessages } from "../helper/ToasterMessages";

export type DispatchActions = requestSentItemsHistory
  | receiveSentItemsHistory
  | completeSentItemsHistory
  | requestSentItemsHistoryDownload
  | receiveSentItemsHistoryDownload
  | completeSentItemsHistoryDownload
  | requestSentItemsHistoryMetrics
  | receiveSentItemsHistoryMetrics
  | completedSentItemsHistoryMetrics

export type KnownActions = DispatchActions | NotificationAction;
export const getSentItemsHistory =
  (
    payload: IReportItemsHistoryRequestModel,
    callback?: () => void,
    mailboxUserEmailId = getMailboxUserEmailId()
  ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: sentItemsHistoryActions.REQUEST_SENT_ITEMS_HISTORY,
      });
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/SentItemsHistory/GetSentItemsHistory?mailboxUserEmailId=${mailboxUserEmailId}`,
          payload
        )
        .then((response) => {
          const data = response.data;

          dispatch({
            type: sentItemsHistoryActions.RECEIVE_SENT_ITEMS_HISTORY,
            payload: data
          });
          if (callback) {
            callback();
          }

        })
        .catch((error: Error) => {
          dispatch({
            type: notificationActions.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        })
        .finally(() => {
          dispatch({
            type: sentItemsHistoryActions.COMPLETED_SENT_ITEMS_HISTORY,
          });
        });
    };

export const getSentItemsHistoryDownload =
  (
    payload: IReportItemsHistoryRequestModel,
    count: number
  ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      const mailboxUserEmailId = getMailboxUserEmailId();
      dispatch({
        type: sentItemsHistoryActions.REQUEST_SENT_ITEMS_HISTORY_DOWNLOAD,
      });
      dispatch({
        type: notificationActions.NOTIFICATION,
        statusMessage: ToasterMessages.WARNING.DOWNLOAD_PROGRESS,
        statusType: StatusType.Warning,
      });
      const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/SentItemsHistory/DownloadSentItemsHistory?mailboxUserEmailId=${mailboxUserEmailId}&timezoneOffsetInMinutes=${timezoneOffsetInMinutes}`,
          { ...payload, pageSize: count }
        )
        .then((response) => {
          const data = response.data;
          const date = moment().format(ReportDownloadContansts.DATE_FORMAT_FILE_NAME);
          const filename = `${ReportDownloadContansts.DEFAULT_FILE_NAME_SIH}${date}`;
          const fileExtension = ReportDownloadContansts.REPORT_FILE_FORMAT;
          fileDownload(data, `${filename}.${fileExtension}`);
          dispatch({
            type: sentItemsHistoryActions.RECEIVE_SENT_ITEMS_HISTORY_DOWNLOAD,
          });
        })
        .catch((error: Error) => {
          dispatch({
            type: notificationActions.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        })
        .finally(() => {
          dispatch({
            type: sentItemsHistoryActions.COMPLETED_SENT_ITEMS_HISTORY_DOWNLOAD,
          });
        });
    };

export const getSentItemsHistoryMetrics =
  (
    callback?: () => void,
  ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: sentItemsHistoryActions.REQUEST_SENT_ITEMS_HISTORY_METRICS,
      });
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/SentItemsHistory/GetSIHMetrics`,
        )
        .then((response) => {
          const data = response.data;
          const metricsData: IMetricsCardModel[] = [];
          data && Object.keys(data).map(id => {
            let title = "";
            switch (id) {
              case ReportConstants.SIH_METRICS_IDS[0]:
                title = ReportConstants.SIH_METRICS_TITLES[0];
                break;
              case ReportConstants.SIH_METRICS_IDS[1]:
                title = ReportConstants.SIH_METRICS_TITLES[1];
                break;
              case ReportConstants.SIH_METRICS_IDS[2]:
                title = ReportConstants.SIH_METRICS_TITLES[2];
                break;
            }
            metricsData.push({
              title,
              count: data[id]
            });
          });
          dispatch({
            type: sentItemsHistoryActions.RECEIVE_SENT_ITEMS_HISTORY_METRICS,
            payload: metricsData
          });
          if (callback) {
            callback();
          }

        })
        .catch((error: Error) => {
          dispatch({
            type: notificationActions.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        })
        .finally(() => {
          dispatch({
            type: sentItemsHistoryActions.COMPLETED_SENT_ITEMS_HISTORY_METRICS,
          });
        });
    };