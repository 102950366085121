import React, { FC } from "react";
import { IProfileDropdownModalProps } from "../../../core/viewModels/layout/HeaderModel";
import { MyDownloadsModalConstants } from "../../../helper/Constants";
import MyDownloadFileTable from "../parts/MyDownloadFileTable";
import { CustomModal } from "../../common/ui/Modals/CustomModal";

const MyDownloadsModal: FC<IProfileDropdownModalProps> = ({ show, onHide }) => {
    return (
        <CustomModal
            className="my-downloads-modal"
            show={show}
            cancelButtonName="Close"
            title={MyDownloadsModalConstants.TITLE}
            isConfirmModal={true}
            onSubmit={() => onHide()}
            onHide={() => onHide()}>
          
            
                <div className="description margin-bottom-20-px">
                    <i className="fas fa-info-circle margin-right-5-px" />
                    <span>{MyDownloadsModalConstants.DESCRIPTION}</span>
                </div>
                <MyDownloadFileTable />
        </CustomModal>
    );
};

export default MyDownloadsModal;
