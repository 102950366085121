import React, { FC, useEffect, useRef, useState } from "react";
import { Button, Form, Modal, Row, Col, FormControl } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { fetchUploadReportAttachmentSasLink, submitProblem } from "../../actionCreators/commonActionCreators";
import { IFileToUpload, uploadFile } from "../../core/common/commonUpload";
import { IProfileDropdownModalProps } from "../../core/viewModels/layout/HeaderModel";
import { IReportProblemModel } from "../../core/viewModels/user/UserViewModel";
import { appInsightsData } from "../../helper/AppInsightsData";
import { AppNotifier } from "../../helper/AppNotifier";
import { reportAttachmentFormats, reportProblemConstants } from "../../helper/Constants";
import { fileTypeValidation, simplePhoneValidation, validateFileSize } from "../../helper/Validations";
import { logger } from "../../oidcClient/authProvider";
import { ApplicationState } from "../../store";
import AttachmentList from "../common/compose/parts/AttachmentList";
import { IFileUploadModel } from "../common/compose/parts/AttachmentUploader";
import FileAttachment from "./parts/FileAttachment";
import { AutomationIdConstants } from "../../helper/AutomationConstants";
import { ToasterMessages } from "../../helper/ToasterMessages";

const formLabelWidth = 4;
const initialFormDetail = {
    cpaEmail: "",
    description: "",
    isAttachmentPresent: false,
};
const ReportModal: FC<IProfileDropdownModalProps> = ({ show, onHide }) => {
    const companyName = useSelector((state: ApplicationState) => state.userAuth.user.profile.company_name);
    const profile = useSelector((state: ApplicationState) => state.userProfile);

    const [attachment, setAttachment] = useState<IFileUploadModel | undefined>();
    const [email, setEmail] = useState<string>("");
    const [phoneNumber, setPhoneNumber] = useState<string>();
    const [formDetail, setFormDetail] = useState<IReportProblemModel>(initialFormDetail);
    const [validated, setValidated] = useState<boolean>(false);
    const ref = useRef<HTMLFormElement>(null);
    const dispatch = useDispatch();

    const onFormChange: React.FormEventHandler<HTMLFormElement> = e => {
        const element = e.target as HTMLInputElement;
        const data = { ...formDetail, [element.id]: element.value };
        setFormDetail(data);
    };
    const handleAttachmentUpdate: React.ChangeEventHandler<HTMLInputElement> = e => {
        e.preventDefault();
        e.stopPropagation();
        const target = e.target as HTMLInputElement;
        const files: FileList | null = target.files;

        if (
            files?.length &&
            validateFileSize(
                files[0],
                reportProblemConstants.MAXIMUM_FILE_SIZE,
                reportProblemConstants.FILE_SIZE_ERROR_MESSAGE
            ) &&
            fileTypeValidation(files[0].name, reportAttachmentFormats, ToasterMessages.ERROR.FILE_TYPE_UNSUPPORTED)
        ) {
            logger.trackEvent({
                name: `${appInsightsData.ReportProblem.PageTitle} - ${appInsightsData.ReportProblem.Operation.AttachmentAdded}`,
            });
            setAttachment({
                file: files[0],
                uploadProgress: 0,
                guid: uuidv4(),
                isUploaded: false,
            });
        } else {
            e.target.value = "";
        }
    };
    const handleOnRemoveAttachment = () => {
        logger.trackEvent({
            name: `${appInsightsData.ReportProblem.PageTitle} - ${appInsightsData.ReportProblem.Operation.AttachmentRemoved}`,
        });
        setAttachment(undefined);
    };
    const resetForm = () => {
        setEmail(profile.emailAddress);
        setPhoneNumber(profile.phone);
        setAttachment(undefined);
    };
    const handleCancel = () => {
        resetForm();
        setValidated(false);
        onHide();
    };

    const checkIsAttachmentUploaded = () => {
        if (attachment) {
            if (attachment.uploadProgress === 1 || attachment.isUploaded) {
                return true;
            } else {
                AppNotifier.Warning(ToasterMessages.WARNING.UPLOAD_IN_PROGRESS);
                return false;
            }
        } else {
            return true;
        }
    };
    const handleSubmit: React.MouseEventHandler<HTMLButtonElement> = e => {
        e.preventDefault();
        e.stopPropagation();

        const form = ref.current;
        if (!!form && form.checkValidity()) {
            const data = !attachment
                ? { ...formDetail, isAttachmentPresent: false }
                : { ...formDetail, isAttachmentPresent: true, attachmentName: attachment.file.name };

            if (checkIsAttachmentUploaded()) {
                dispatch(submitProblem(data));
                resetForm();
                form.reset();
                onHide();
                setValidated(false);
            }
        } else {
            setValidated(true);
        }
    };
    const onUploadFile = (documents: IFileUploadModel) => {
        if (!documents.isUploaded) {
            dispatch(
                fetchUploadReportAttachmentSasLink(documents.file.name, (data: { sas: string }) => {
                    uploadFile(
                        documents.file,
                        data,
                        documents.file.name,
                        uploadProgressCallback,
                        uploadCompleteCallback
                    );
                })
            );
        }
    };

    const uploadProgressCallback = (percent: number) => {
        if (attachment) {
            setAttachment({
                ...attachment,
                uploadProgress: (percent == 100 ? percent - 10 : percent) / 100,
            });
        }
    };

    const uploadCompleteCallback = (fileToUpload: IFileToUpload, errorMessage?: string) => {
        if (fileToUpload.uploadFailed) {
            if (errorMessage) {
                AppNotifier.Warning(errorMessage);
            }
            setAttachment(undefined);
            return;
        }

        if (attachment && !fileToUpload.uploadFailed) {
            setAttachment({
                ...attachment,
                isUploaded: true,
                uploadProgress: 1,
            });
        }
    };

    useEffect(() => {
        setEmail(profile.emailAddress);
        setPhoneNumber(profile.phone);
    }, [profile]);

    useEffect(() => {
        setFormDetail({ ...formDetail, cpaEmail: email, cpaPhoneNumber: phoneNumber });
    }, [email, phoneNumber]);

    useEffect(() => {
        attachment && onUploadFile(attachment);
    }, [!!attachment]);

    return (
        <Modal show={show} onHide={handleCancel} dialogClassName="custom-bootstrap-modal">
            <Modal.Header closeButton closeVariant="white">
                <div data-test-auto={AutomationIdConstants.reportProblem.ReportModalHeader}>
                    {reportProblemConstants.TITLE}
                </div>
            </Modal.Header>
            <Modal.Body>
                <Form
                    ref={ref}
                    id="report-form"
                    noValidate
                    validated={validated}
                    className="custom-form padding-4"
                    onChange={onFormChange}
                >
                    <Row>
                        <Col xs={6}>
                            <Form.Group as={Row} className="width-100-percent" controlId="product_type">
                                <Form.Label as={Col} xs={formLabelWidth}>
                                    {reportProblemConstants.PRODUCT_TYPE_LABEL}
                                </Form.Label>
                                <Col>
                                    <FormControl
                                        type="text"
                                        readOnly
                                        value={reportProblemConstants.PRODUCT_TYPE_PLACEHOLDER}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group as={Row} className="width-100-percent" controlId="cpa_firm">
                                <Form.Label as={Col} xs={formLabelWidth}>
                                    {reportProblemConstants.CPA_FIRM_LABEL}
                                </Form.Label>
                                <Col>
                                    <FormControl type="text" readOnly value={companyName} />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group as={Row} className="width-100-percent" controlId="logged_in_user">
                                <Form.Label as={Col} xs={formLabelWidth}>
                                    {reportProblemConstants.USER_LABEL}
                                </Form.Label>
                                <Col>
                                    <FormControl value={`${profile.firstName} ${profile.lastName}`} readOnly />
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Form.Group as={Row} className="width-100-percent" controlId="cpaPhoneNumber">
                                <Form.Label as={Col} xs={formLabelWidth}>
                                    {reportProblemConstants.PHONE_LABEL}
                                </Form.Label>
                                <Col>
                                    <FormControl
                                        type="text"
                                        value={phoneNumber}
                                        onChange={e => simplePhoneValidation(e.target.value, setPhoneNumber)}
                                    />
                                </Col>
                            </Form.Group>
                        </Col>
                        <Col xs={6}>
                            <Form.Group as={Row} className="width-100-percent" controlId="cpaEmail">
                                <Form.Label as={Col} xs={formLabelWidth} data-test-auto={AutomationIdConstants.reportProblem.ReportModalEmailLabel}>
                                    {reportProblemConstants.EMAIL_LABEL}
                                </Form.Label>
                                <Col>
                                    <FormControl
                                        type="email"
                                        value={email}
                                        onChange={e => setEmail(e.target.value)}
                                        required
                                    />
                                    <Form.Control.Feedback type="invalid" data-test-auto={AutomationIdConstants.reportProblem.ReportModalEmailInvalid}>
                                        {!email
                                            ? reportProblemConstants.EMPTY_ERROR_MESSAGE
                                            : reportProblemConstants.INVALID_EMAIL_ERROR_MESSAGE}
                                    </Form.Control.Feedback>
                                </Col>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Form.Group controlId="description">
                        <Form.Label className="margin-bottom-3" data-test-auto={AutomationIdConstants.reportProblem.ReportModalDescription}>{reportProblemConstants.DESCRIPTION_LABEL}</Form.Label>
                        <Form.Control as="textarea" required />
                        <Form.Control.Feedback type="invalid" data-test-auto={AutomationIdConstants.reportProblem.ReportModalNoDescription}>
                            {reportProblemConstants.EMPTY_ERROR_MESSAGE}
                        </Form.Control.Feedback>
                    </Form.Group>
                    {!attachment ? (
                        <FileAttachment
                            onChange={handleAttachmentUpdate}
                            label={reportProblemConstants.ATTACHMENT_LABEL}
                            fileFormat={reportProblemConstants.SUPPOERTED_FILE_FORMATE}
                            infoText={reportProblemConstants.ATTACHMENT_INFO_TEXT}
                        />
                    ) : (
                        <AttachmentList
                            attachments={attachment ? [attachment] : []}
                            onRemoveAttachment={handleOnRemoveAttachment}
                            isRemovingAttachment={false}
                        />
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button size="lg" className="margin-right-4" variant="outline-secondary" onClick={handleCancel} data-test-auto={AutomationIdConstants.reportProblem.ReportModalCancleBtnText}>
                    Cancel
                </Button>
                <Button size="lg" type="submit" form="report-form" onClick={handleSubmit} data-test-auto={AutomationIdConstants.reportProblem.ReportModalSubmitBtnText}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ReportModal;
