

import axios from "axios";
import {
  completedReceivedItemsHistoryMetrics,
  completeReceivedItemsHistory, completeReceivedItemsHistoryDownload,
  receiveReceivedItemsHistory, receiveReceivedItemsHistoryDownload,
  receiveReceivedItemsHistoryMetrics, requestReceivedItemsHistory, requestReceivedItemsHistoryDownload, requestReceivedItemsHistoryMetrics
} from "../actions/receivedItemsHistoryActions";
import { AppThunkAction } from "../store";
import { actionTypes as receivedItemsHistoryActions } from "../types/receivedItemsHistoryTypes";
import { actionTypes as notificationActions } from "../types/notificationTypes";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import { getMailboxUserEmailId } from "../helper/HelperFunctions";
import { IReportItemsHistoryRequestModel } from "../core/viewModels/report/SentItemsHistoryModel";
import fileDownload from "js-file-download";
import { ReportConstants, ReportDownloadContansts } from "../helper/Constants";
import moment from "moment";
import { IMetricsCardModel } from "../core/viewModels/report/reportViewModel";
import { ToasterMessages } from "../helper/ToasterMessages";

export type DispatchActions = requestReceivedItemsHistory
  | receiveReceivedItemsHistory
  | completeReceivedItemsHistory
  | requestReceivedItemsHistoryDownload
  | receiveReceivedItemsHistoryDownload
  | completeReceivedItemsHistoryDownload
  | requestReceivedItemsHistoryMetrics
  | receiveReceivedItemsHistoryMetrics
  | completedReceivedItemsHistoryMetrics

export type KnownActions = DispatchActions | NotificationAction;
export const getReceivedItemsHistory =
  (
    payload: IReportItemsHistoryRequestModel,
    callback?: () => void,
    mailboxUserEmailId = getMailboxUserEmailId()
  ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: receivedItemsHistoryActions.REQUEST_RECEIVED_ITEMS_HISTORY,
      });
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/ReceivedItemsHistory/GetReceivedItemsHistory?mailboxUserEmailId=${mailboxUserEmailId}`,
          payload
        )
        .then((response) => {
          const data = response.data;

          dispatch({
            type: receivedItemsHistoryActions.RECEIVE_RECEIVED_ITEMS_HISTORY,
            payload: data
          });
          if (callback) {
            callback();
          }

        })
        .catch((error: Error) => {
          dispatch({
            type: notificationActions.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        })
        .finally(() => {
          dispatch({
            type: receivedItemsHistoryActions.COMPLETED_RECEIVED_ITEMS_HISTORY,
          });
        });
    };

export const getReceivedItemsHistoryDownload =
  (
    payload: IReportItemsHistoryRequestModel,
    count: number
  ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      const mailboxUserEmailId = getMailboxUserEmailId();
      dispatch({
        type: receivedItemsHistoryActions.REQUEST_RECEIVED_ITEMS_HISTORY_DOWNLOAD,
      });
      dispatch({
        type: notificationActions.NOTIFICATION,
        statusMessage: ToasterMessages.WARNING.DOWNLOAD_PROGRESS,
        statusType: StatusType.Warning,
      });
      const timezoneOffsetInMinutes = new Date().getTimezoneOffset();
      axios
        .post(
          `${process.env.REACT_APP_API_URL}/api/ReceivedItemsHistory/DownloadReceivedItemsHistory?mailboxUserEmailId=${mailboxUserEmailId}&timezoneOffsetInMinutes=${timezoneOffsetInMinutes}`,
          { ...payload, pageSize: count }
        )
        .then((response) => {
          const data = response.data;
          const date = moment().format(ReportDownloadContansts.DATE_FORMAT_FILE_NAME);
          const filename = `${ReportDownloadContansts.DEFAULT_FILE_NAME_RIH}${date}`;
          const fileExtension = ReportDownloadContansts.REPORT_FILE_FORMAT;
          fileDownload(data, `${filename}.${fileExtension}`);
          dispatch({
            type: receivedItemsHistoryActions.RECEIVE_RECEIVED_ITEMS_HISTORY_DOWNLOAD,
          });
        })
        .catch((error: Error) => {
          dispatch({
            type: notificationActions.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        })
        .finally(() => {
          dispatch({
            type: receivedItemsHistoryActions.COMPLETED_RECEIVED_ITEMS_HISTORY_DOWNLOAD,
          });
        });
    };

export const getReceivedItemsHistoryMetrics =
  (
    callback?: () => void,
  ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: receivedItemsHistoryActions.REQUEST_RECEIVED_ITEMS_HISTORY_METRICS,
      });
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/ReceivedItemsHistory/GetRIHMetrics`,
        )
        .then((response) => {
          const data = response.data;
          const metricsData: IMetricsCardModel[] = [];
          data && Object.keys(data).map(id => {
            let title = "";
            switch (id) {
              case ReportConstants.RIH_METRICS_IDS[0]:
                title = ReportConstants.RIH_METRICS_TITLES[0];
                break;
              case ReportConstants.RIH_METRICS_IDS[1]:
                title = ReportConstants.RIH_METRICS_TITLES[1];
                break;
              case ReportConstants.RIH_METRICS_IDS[2]:
                title = ReportConstants.RIH_METRICS_TITLES[2];
                break;  
            }

            metricsData.push({
              title,
              count: data[id]
            });
          });
          dispatch({
            type: receivedItemsHistoryActions.RECEIVE_RECEIVED_ITEMS_HISTORY_METRICS,
            payload: metricsData
          });
          if (callback) {
            callback();
          }

        })
        .catch((error: Error) => {
          dispatch({
            type: notificationActions.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        })
        .finally(() => {
          dispatch({
            type: receivedItemsHistoryActions.COMPLETED_RECEIVED_ITEMS_HISTORY_METRICS,
          });
        });
    };