import axios, { AxiosResponse } from "axios";

import { AppThunkAction } from "../store";
import { actionTypes as userSettingsActions } from "../types/userSettingsTypes";
import { actionTypes as notifactionAction } from "../types/notificationTypes";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import { IDelegateeUser, IDropOffSettings, IUserSettingsModel } from "../core/viewModels/user/UserViewModel";
import { AppNotifier } from "../helper/AppNotifier";

import {
    CompletedDropOffSettings,
    CompleteDelegateeList,
    CompleteLastLoginEnable,
    CompleteUpdateDropoffSettings,
    CompleteUpdateLastLoginEnable,
    CompleteUpdateUserSettings,
    CompleteUserSettings,
    ReceivedDropOffSettings,
    ReceiveDelegateeList,
    ReceiveLastLoginEnable,
    ReceiveUpateLastLoginEnable,
    ReceiveUpdateDropoffSettings,
    ReceiveUpdateUserSettings,
    ReceiveUserSettings,
    RequestDelegateeList,
    RequestDropOffSettings,
    RequestLastLoginEnable,
    RequestUpdateDropoffSettings,
    RequestUpdateLastLoginEnable,
    RequestUpdateUserSettings,
    RequestUserSettings,
    UpdateUserSettings,
} from "../actions/userSettingsActions";
import { ToasterMessages } from "../helper/ToasterMessages";

export type DispatchActions =
    | RequestUserSettings
    | ReceiveUserSettings
    | CompleteUserSettings
    | RequestDelegateeList
    | ReceiveDelegateeList
    | CompleteDelegateeList
    | RequestUpdateUserSettings
    | ReceiveUpdateUserSettings
    | CompleteUpdateUserSettings
    | UpdateUserSettings
    | RequestLastLoginEnable
    | ReceiveLastLoginEnable
    | CompleteLastLoginEnable
    | RequestUpdateLastLoginEnable
    | ReceiveUpateLastLoginEnable
    | CompleteUpdateLastLoginEnable
    | RequestDropOffSettings
    | ReceivedDropOffSettings
    | CompletedDropOffSettings
    | RequestUpdateDropoffSettings
    | ReceiveUpdateDropoffSettings
    | CompleteUpdateDropoffSettings;

export type KnownActions = DispatchActions | NotificationAction;

export const fetchUsersList = (): AppThunkAction<KnownActions> => (dispatch, getState) => {
    dispatch({
        type: userSettingsActions.REQUEST_DELEGATEE_LIST,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Contact/GetSupportContactList`)
        .then((response: AxiosResponse<IDelegateeUser[]>) => {
            const { data } = response;
            dispatch({
                type: userSettingsActions.RECEIVE_DELEGATEE_LIST,
                payload: data.filter(user => user.userId != getState().userProfile.userId),
            });
        })
        .catch(function (error: Error) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
            dispatch({
                type: userSettingsActions.COMPLETE_DELEGATEE_LIST,
            });
        });
};

export const fetchUserSettings =
    (callback?: (data: IUserSettingsModel) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: userSettingsActions.REQUEST_USER_SETTINGS });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/User/GetUserSettingsModel`)
            .then((response: AxiosResponse<IUserSettingsModel>) => {
                const { data } = response;
                dispatch({
                    type: userSettingsActions.RECEIVE_USER_SETTINGS,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(function (error: Error) {
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                dispatch({
                    type: userSettingsActions.COMPLETE_USER_SETTINGS,
                });
            });
    };

export const updateUserSettings =
    (payload: IUserSettingsModel, callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: userSettingsActions.REQUEST_UPDATE_USER_SETTINGS });
        axios
            .put(`${process.env.REACT_APP_API_URL}/api/User/UpdateExchangeUserSettings`, payload)
            .then(() => {
                dispatch({
                    type: userSettingsActions.RECEIVE_UPDATE_USER_SETTINGS,
                });

                dispatch({
                    type: userSettingsActions.UPDATE_USER_SETTINGS,
                    payload: payload,
                });
                if (callback) {
                    callback();
                }
                AppNotifier.Success(ToasterMessages.SUCCESS.APPLY_USER_SETTINGS);
            })
            .catch(function (error: Error) {
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                dispatch({
                    type: userSettingsActions.COMPLETE_UPDATE_USER_SETTINGS,
                });
            });
    };

export const fetchLastLoginEnable = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({ type: userSettingsActions.REQUEST_LAST_LOGIN_ENABLE });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/User/DefaultSettings`)
        .then((response: AxiosResponse<{ enableLastLogin: boolean }>) => {
            const { data } = response;
            dispatch({
                type: userSettingsActions.RECEIVE_LAST_LOGIN_ENABLE,
                payload: data,
            });
        })
        .catch(function (error: Error) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
            dispatch({
                type: userSettingsActions.COMPLETE_LAST_LOGIN_ENABLE,
            });
        });
};

export const updateLastLoginEnable =
    (payload: boolean): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: userSettingsActions.REQUEST_UPDATE_LAST_LOGIN_ENABLE });
        const value = { enableLastLogin: payload };
        axios
            .put(`${process.env.REACT_APP_API_URL}/api/User/DefaultSettings`, value)
            .then(() => {
                dispatch({ type: userSettingsActions.RECEIVE_UPDATE_LAST_LOGIN_ENABLE, payload: value });
                AppNotifier.Success(ToasterMessages.SUCCESS.APPLY_USER_SETTINGS);
            })
            .catch(function (error: Error) {
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                dispatch({
                    type: userSettingsActions.COMPLETE_UPDATE_LAST_LOGIN_ENABLE,
                });
            });
    };

export const requestDropOffSettings = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: userSettingsActions.REQUEST_DROPOFF_SETTINGS,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/User/DropOffSettings`)
        .then((response: any) => {
            const data = response.data;
            dispatch({
                type: userSettingsActions.RECEIVED_DROPOFF_SETTINGS,
                payload: data,
            });
        })
        .catch(function (error: any) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
        })
        .finally(() => {
            dispatch({
                type: userSettingsActions.COMPLETED_DROPOFF_SETTINGS,
            });
        });
};

export const updateDropOffSettings =
    (payload: IDropOffSettings, callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: userSettingsActions.REQUEST_UPDATE_DROPOFF_SETTINGS });
        axios
            .put(`${process.env.REACT_APP_API_URL}/api/User/DropOffSettings`, payload)
            .then(() => {
                dispatch({
                    type: userSettingsActions.RECEIVED_UPDATE_DROPOFF_SETTINGS,
                });

                dispatch({
                    type: userSettingsActions.RECEIVED_DROPOFF_SETTINGS,
                    payload: payload,
                });

                if (callback) {
                    callback();
                }
                AppNotifier.Success(ToasterMessages.SUCCESS.APPLY_USER_SETTINGS);
            })
            .catch(function (error: Error) {
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                dispatch({
                    type: userSettingsActions.COMPLETED_UPDATE_DROPOFF_SETTINGS,
                });
            });
    };

export const renewSession = () => {
    axios.get(`${process.env.REACT_APP_API_URL}/api/MyAccount/StayAlive`);
};
