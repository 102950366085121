import "isomorphic-fetch";
import * as React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Forethought, openWidgetOnClick } from "@sssuite-component-ui/forethought-ai-chatbot";
import { SessionTimeout } from "@sssuite-component-ui/session-timeout";

import { Notification } from "../../notification/Notification";
import ComposeModal from "../../common/compose/ComposeModal";
import { AuthenticationMethod, MessageActions } from "../../../core/common/enums";
import { getAccessToken, signoutRedirect } from "../../../oidcClient/userService";
import { requestSessionIdleMinutes } from "../../../actions/authActions";
import LastLoginModal from "../LastLoginModal";
import { fetchLastLoginEnable, renewSession } from "../../../actionCreators/userSettingsActionCreators";
import { fetchAuthRetentionPeriods } from "../../../actionCreators/composeActionCreators";
import SignalRWebSocket from "../../signalR/SignalRWebSocketContainer";
import Layout from "@sssuite-component-ui/ss-suite-layout";
import { ApplicationState } from "../../../store";
import { ILayoutModals } from "../../../core/viewModels/layout/HeaderModel";
import { getSideMenuData, headerConfig } from "./LayoutConfig";
import MyAccountModal from "../profile/MyAccountModal";
import MyDownloadsModal from "../profile/MyDownloadsModal";
import MySettings from "../userSettings/MySettings";
import ReportModal from "../ReportModal";
import { DropoffMenuModal } from "../profile/DropOffMenuModal";
import LoginHistory from "../LoginHistory";
import ViewInboxDropDown from "../ViewInboxDropDown";
import { reportProblemConstants } from "../../../helper/Constants";
import "./style.scss";
import { requestHelpCenterUrl, saveMailboxUserToStore } from "../../../actionCreators/commonActionCreators";
import { requestMFAOTPLength } from "../../../actions/otpActions";
import { requestCompanySettings } from "../../../actions/companyActions";
import {
    fetchReceivedMessageHistorySettings,
    fetchRequestHistorySettings,
    fetchSentMessageHistorySettings,
} from "../../../actionCreators/adminSettingsActionCreators";
import { getWidgetData, getLeftSideMenuData, getSuiteUrl } from "../../../actionCreators/layoutActionCreators";
import { ReportProblemIcon } from "../../svg/HeaderMenuIcons";
import UserAutoLogoutModal from "../UserAutoLogoutModal";
import { requestCompanyMfaSettings } from "../../../actionCreators/companyMfaSettingActionCreators";
import { requestPasswordPolicySettings } from "../../../actionCreators/passwordPolicySettingActionCreator";
import { addWalkMeScript } from "../../../common/WalkMe";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";
import Logout from "../profile/Logout";
import { actionCreators as PostAuthStore } from "../../../store/PostAuth/PostAuthStore";
import SecurityModal from "../../SecurityModal";
import { createForethoughtCookie } from "../../../helper/HelperFunctions";
import { ForeThoughtConstants } from "../../../helper/Constants";
import { fetchUserOwnedFolders } from "../../../actionCreators/folderActionCreator";
import { getDocumentCategoriesDropdown } from "../../../actionCreators/documentCategoryActionCreator";
import { injectPendoScript } from "../../common/pendo/Pendo";

declare global {
    interface Window {
        Variables: any;
        Forethought: any;
        pendo: any;
    }
}
export interface ILayoutProps {
    children?: React.ReactNode;
}

const LayoutContainer: React.FunctionComponent<ILayoutProps> = props => {
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState<ILayoutModals>({
        lastLogin: false,
        composeModal: false,
        myAccount: false,
        loginHistory: false,
        dropOff: false,
        myDownloads: false,
        mySettings: false,
        reportProblem: false,
        logOut: false,
    });
    const { enableLastLogin } = useSelector((state: ApplicationState) => state.userSettingsState.defaultSettings);
    const { exchangeEnabled,isTestCompany } = useSelector((state: ApplicationState) => state.postAuthState);
    const sessionIdleMinutes = useSelector((appState: ApplicationState) => appState.userAuth.sessionIdleMinutes);
    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);
    const { userAutoLogoutCause, showAutoLogoutModal } = useSelector((state: ApplicationState) => state.signalRState);
    const userAuth = useSelector((state: ApplicationState) => state.userAuth.user);
    const dispatch = useDispatch();

    const { device_id } = useSelector((state: ApplicationState) => state.userAuth.user.profile);
    const loggedOut = useSelector((state: ApplicationState) => state.postAuthDataStore.loggedOut);
    const warningVisibleMinutes = 1;
    const [sessionExpiredModal, setSessionExpiredModal] = useState<boolean>(false);
    let sessionExpired: any;
    let sessionRenew: any;

    useEffect(() => {
        sessionRenew = setInterval(() => {
            renewSession();
        }, 1000 * 60 * 20);

        setIntervalSessionExpiredCheck();
        if (exchangeEnabled) {
            dispatch(requestSessionIdleMinutes());
            dispatch(fetchLastLoginEnable());
            dispatch(fetchAuthRetentionPeriods(AuthenticationMethod.ACCESSCODE));
            dispatch(requestCompanyMfaSettings());
            dispatch(requestPasswordPolicySettings());
            dispatch(saveMailboxUserToStore());
            dispatch(requestMFAOTPLength());
            dispatch(requestCompanySettings());
            dispatch(requestHelpCenterUrl());
            dispatch(fetchSentMessageHistorySettings());
            dispatch(fetchReceivedMessageHistorySettings());
            dispatch(fetchRequestHistorySettings());
            dispatch(fetchUserOwnedFolders());
            dispatch(getDocumentCategoriesDropdown());
        }

        dispatch(getWidgetData());
        addWalkMeScript();
        dispatch(getSuiteUrl());
        dispatch(getLeftSideMenuData());
    }, [exchangeEnabled]);

    useEffect(()=>{
        if(userAuth?.profile){
            injectPendoScript(userAuth,isTestCompany);
            }
    },[userAuth?.profile]);

    const setIntervalSessionExpiredCheck = () => {
        sessionExpired = setInterval(() => {
            if (!getAccessToken()) {
                setSessionExpiredModal(true);
            }
        }, 1000);
    };

    const headerChildren = () => (
        <div className="header-actions-wrapper">
            {!mailboxUserData && exchangeEnabled && (
                <>
                    <span
                        onClick={openWidgetOnClick}
                        title={reportProblemConstants.TITLE}
                        data-test-auto={AutomationIdConstants.reportProblem.ReportProblemBtn}
                        className={showModal.reportProblem ? "report-problem-active" : ""}
                    >
                        <ReportProblemIcon />
                    </span>

                    <ViewInboxDropDown />
                </>
            )}
        </div>
    );
    useEffect(() => {
        setShowModal({ ...showModal, lastLogin: enableLastLogin });
    }, [enableLastLogin]);

    useEffect(() => {
        () => {
            clearInterval(sessionRenew);
            clearInterval(sessionExpired);
        };
    }, []);

    return (
        <div>
            <Layout
                headerProps={headerConfig(showModal, setShowModal, headerChildren(), navigate)}
                sideMenuProps={getSideMenuData(showModal, setShowModal, navigate)}
            >
                <div className="container-fluid">
                    <Notification />
                    {props.children}
                </div>
            </Layout>

            {exchangeEnabled && !showAutoLogoutModal && (
                <>
                    {showModal.composeModal && (
                        <ComposeModal
                            show={showModal.composeModal}
                            title="New Message"
                            onHide={() => setShowModal({ ...showModal, composeModal: false })}
                            messageAction={MessageActions.New}
                            attachments={[]}
                        />
                    )}
                    <LastLoginModal
                        show={showModal.lastLogin}
                        onHide={() => setShowModal({ ...showModal, lastLogin: false })}
                    />
                </>
            )}
            <SignalRWebSocket />
            <MyAccountModal
                show={showModal.myAccount}
                onHide={() => setShowModal({ ...showModal, myAccount: false })}
            />

            <MySettings show={showModal.mySettings} onHide={() => setShowModal({ ...showModal, mySettings: false })} />

            {showModal.dropOff && (
                <DropoffMenuModal
                    show={showModal.dropOff}
                    onHide={() => setShowModal({ ...showModal, dropOff: false })}
                />
            )}

            <LoginHistory
                show={showModal.loginHistory}
                onHide={() => setShowModal({ ...showModal, loginHistory: false })}
            />
            <MyDownloadsModal
                show={showModal.myDownloads}
                onHide={() => setShowModal({ ...showModal, myDownloads: false })}
            />
            <ReportModal
                show={showModal.reportProblem}
                onHide={() => setShowModal({ ...showModal, reportProblem: false })}
            />
            <UserAutoLogoutModal logoutCause={userAutoLogoutCause} show={showAutoLogoutModal} />
            <SecurityModal openModal={sessionExpiredModal} />
            <SessionTimeout
                deviceId={device_id}
                logout={signoutRedirect}
                onExternalLogout={() =>
                    createForethoughtCookie(ForeThoughtConstants.FORETHOGHT_WIDGET_VISIBLE, "false")
                }
                sessionIdleMinutes={sessionIdleMinutes}
                warningVisibleMinutes={warningVisibleMinutes}
                currentTabLoggedOutShow={loggedOut}
                setCurrentTabLoggedOutShow={() => {
                    dispatch(PostAuthStore.loggedOut());
                }}
            />
            <Logout showState={showModal.logOut} onHide={() => setShowModal({ ...showModal, logOut: false })} />
            <Forethought offsetY="40px" positionX="right" positionY="bottom" hideWidgetIframeOnLoad="true" />
        </div>
    );
};

export default LayoutContainer;
