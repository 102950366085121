import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    requestDropOffRecipientsList,
    updateDropOffRecipient,
} from "../../../../../../../actionCreators/dropOffActionCreators";
import { IDropOffRecipient } from "../../../../../../../core/viewModels/company/settings/DropOffSettings";
import { ApplicationState } from "../../../../../../../store";
import { RecipientsSearch } from "./RecipientsSearch";
import { AppNotifier } from "../../../../../../../helper/AppNotifier";
import { DropOffUsersConstants } from "../../../../../../../helper/Constants";
import { RecipientsTable } from "./RecipeintsTable";
import { UpdateDropOffRecipientType } from "../../../../../../../core/common/enums";
import { ResourceIdLocators } from "../../../../../../../helper/ResourceIdLocators";
import { ToasterMessages } from "../../../../../../../helper/ToasterMessages";
export const DropOffRecipients: React.FC = () => {
    const [selectedRecipients, setSelectedRecipients] = useState<IDropOffRecipient[]>([]);
    const [recipientsTableData, setRecipientsTableData] = useState<IDropOffRecipient[]>([]);

    const [reload, setReload] = useState(false);

    const dispatch = useDispatch();
    const dropOffRecipients = useSelector(
        (state: ApplicationState) => state.dropOffState.dropOffRecipientsData.dropOffRecipients
    );

    useEffect(() => {
        dispatch(requestDropOffRecipientsList());
        setSelectedRecipients([]);
    }, [reload]);

    useEffect(() => {
        setRecipientsTableData(dropOffRecipients);
    }, [dropOffRecipients]);

    const handleEnableDropOffRecipients = (selectedRecipients: IDropOffRecipient[]) => {
        const recipientsPayload = [];
        if (selectedRecipients.length > 1) {
            for (let i = 0; i < selectedRecipients.length; i++) {
                recipientsPayload.push({ ...selectedRecipients[i], [DropOffUsersConstants.IS_DROPOFF_ENABLED]: true });
            }
        } else {
            const individualRecipient = { ...selectedRecipients[0], [DropOffUsersConstants.IS_DROPOFF_ENABLED]: true };
            recipientsPayload.push(individualRecipient);
        }
        dispatch(
            updateDropOffRecipient(recipientsPayload, UpdateDropOffRecipientType.EnableRecipients,ResourceIdLocators.AdminSetting.DropOff, (result: boolean) => {
                if (result) {
                    AppNotifier.Success(
                        recipientsPayload.length > 1
                            ? ToasterMessages.SUCCESS.ENABLE_DROP_OFF_RECIPIENTS
                            : ToasterMessages.SUCCESS.ENABLE_DROP_OFF_RECIPIENT
                    );
                    setReload(reload => !reload);
                }
            })
        );
    };

    const handleDisableDropOffRecipients = (selectedRecipients: IDropOffRecipient[]) => {
        const recipientsPayload = [];
        let errorMessage = "";
        if (selectedRecipients.length > 1) {
            for (let i = 0; i < selectedRecipients.length; i++) {
                recipientsPayload.push({ ...selectedRecipients[i], [DropOffUsersConstants.IS_DROPOFF_ENABLED]: false });
            }
        } else {
            const individualRecipient = { ...selectedRecipients[0], [DropOffUsersConstants.IS_DROPOFF_ENABLED]: false };
            recipientsPayload.push(individualRecipient);
        }
        dispatch(
            updateDropOffRecipient(recipientsPayload, UpdateDropOffRecipientType.DisableRecipients ,ResourceIdLocators.AdminSetting.DropOff, (result: boolean) => {
                if (result) {
                    AppNotifier.Success(
                        recipientsPayload.length > 1
                            ? ToasterMessages.SUCCESS.DISABLE_DROP_OFF_RECIPIENTS
                            : ToasterMessages.SUCCESS.DISABLE_DROP_OFF_RECIPIENT
                    );
                    setReload(reload => !reload);
                }
                else{
                        errorMessage = selectedRecipients.length === 1 ?
                            ToasterMessages.ERROR.DROP_OFF_RECIPIENT_REMOVED_UNSUCCESSFUL_ONE_RECIPIENT :
                            ToasterMessages.ERROR.DROP_OFF_RECIPIENT_REMOVED_UNSUCCESSFUL;
                        AppNotifier.Error(errorMessage);
                }
            })
        );
    };
    
    const handleSetAsDefualtRecipient = (selectedRecipients: IDropOffRecipient[]) => {
        const recipientsPayload = [];
        const individualRecipient = { ...selectedRecipients[0], [DropOffUsersConstants.IS_DEFAULT]: true };
        recipientsPayload.push(individualRecipient);

        dispatch(
            updateDropOffRecipient(recipientsPayload, UpdateDropOffRecipientType.SetAsDefault,ResourceIdLocators.AdminSetting.DropOff, (result: boolean) => {
                if (result) {
                    AppNotifier.Success(ToasterMessages.SUCCESS.SET_AS_DEFAULT_DROP_OFF_RECIPIENT);
                    setReload(reload => !reload);
                }
            })
        );
    };

    const handleRemoveAsDefaultRecipient = (selectedRecipients: IDropOffRecipient[]) => {
        const recipientsPayload = [];
        const individualRecipient = { ...selectedRecipients[0], [DropOffUsersConstants.IS_DEFAULT]: false };
        recipientsPayload.push(individualRecipient);

        dispatch(
            updateDropOffRecipient(recipientsPayload, UpdateDropOffRecipientType.RemoveAsDefault,ResourceIdLocators.AdminSetting.DropOff, (result: boolean) => {
                if (result) {
                    AppNotifier.Success(ToasterMessages.SUCCESS.REMOVE_AS_DEFAULT_DROP_OFF_RECIPIENT);
                    setReload(reload => !reload);
                }
            })
        );
    };

    return (
        <div className="DropUser-wrapper">
            <div className="search-button-wrapper">
                <RecipientsSearch reload={reload} setRecipientsTableData={setRecipientsTableData} />
            </div>
            <div className="dropOff-table-container">
                <RecipientsTable
                    selectedRecipient={selectedRecipients}
                    updateSelectedRecipient={setSelectedRecipients}
                    recipientTableData={recipientsTableData}
                    handleEnableDropOffRecipients={handleEnableDropOffRecipients}
                    handleDisableDropOffRecipients={handleDisableDropOffRecipients}
                    handleSetAsDefaultRecipient={handleSetAsDefualtRecipient}
                    handleRemoveAsDefaultRecipient={handleRemoveAsDefaultRecipient}
                />
            </div>
        </div>
    );
};
