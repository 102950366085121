import React, { Dispatch, SetStateAction, useCallback, useEffect, useReducer, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, FormControl, Row } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { debounce } from "throttle-debounce";

import SentMessageListItem from "../../sentMessage/parts/SentMessageListItem";
import { FolderType } from "./../../../../core/common/enums";
import {
    initialSentMessage,
    IPagination,
    ISentMessageModel,
} from "../../../../core/viewModels/message/SentMessageModel";
import { sentMessageReducer } from "../../../../reducers/sentMessageReducers";
import MessageToolbar from "../../receivedMessage/parts/MessageToolbar";
import DefaultPagination from "../../../common/ui/DefaultPagination";
import {
    ArchiveConstants,
    DEFAULT_PAGE_SIZE,
    initialPagination,
    MessageListConstants,
    SortAndFilterConstants,
    SortAndFilterIconType,
} from "../../../../helper/Constants";
import { getFolderMessageSortOptions, getMessageListHeight } from "../../../../helper/HelperFunctions";

import { ApplicationState } from "../../../../store";
import { sentPageChanged } from "../../../../actionCreators/signalRActionCreator";
import { ResourceIdLocators } from "../../../../helper/ResourceIdLocators";
import { RefreshIcon, SearchIcon } from "../../../svg/IconCollection";
import { FiltersCustomComponent } from "../../../common/ui/Filters/FiltersCustomComponent";

interface ISentMessageListProps {
    messages: ISentMessageModel[];
    totalRecords: number;
    isLoading: boolean;
    pagination: IPagination;
    reload?: boolean;
    deleteMessages: (messages: ISentMessageModel[], callback: any) => void;
    fetchMessages: (pageNo: number, pageSize: number, sortColumn?: string, sortOrder?: string, search?: string ,messageListReload?:boolean ,selectedMessage?:any) => void;
    print: (messages: ISentMessageModel[]) => void;
    cancelTransfer: (messageIds: number[], callback: any) => void;
    checkFolderExpiry: (folderId: number) => boolean;
    moveToFolder: (messages: ISentMessageModel[], folderId: number, callback?: any) => void;
    resendAccessLink: (messages: ISentMessageModel[]) => void;
    getMessageDetail: (message: ISentMessageModel) => void;
    hideMessageDetailView(hide: boolean): void;
    setPagination: Dispatch<SetStateAction<IPagination>>;
}

const sortingData = [
    {
        title: SortAndFilterConstants.SORT_BY_TEXT,
        menus: [SortAndFilterConstants.NEWEST_FIRST_ORDER, SortAndFilterConstants.OLDEST_FIRST_ORDER],
    },
];

const SentMessageList: React.FC<ISentMessageListProps> = props => {
    const dispatch = useDispatch();
    const { pagination, setPagination, fetchMessages } = props;
    const [selectedMessage, setSelectedMessage] = useState(initialSentMessage);
    const [selectedSentMessage, dispatchSelectedMessageActions] = useReducer(sentMessageReducer, {
        messages: [],
        isAllSelected: false,
    });
    const [searchTextBox, setSearchTextBox] = useState("");
    const [selectedSortOption, setSelectedSortOption] = useState<string>(SortAndFilterConstants.NEWEST_FIRST_ORDER);

    useEffect(() => {
        const sort = getFolderMessageSortOptions(selectedSortOption);
        setPagination(initialPagination);
        fetchMessages(initialPagination.currentPage, initialPagination.pageSize, sort.sortColumn, sort.sortOrder, searchTextBox);
    }, [selectedSortOption]);

    useEffect(() => {
        handleResetSearchAndSort();
    }, [props.reload]);

    useEffect(() => {
        if (searchTextBox === "") {
            dispatchSelectedMessageActions({
                type: "removeAll",
                payload: null,
            });
        }
    }, [searchTextBox]);

    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);
    const handleOnPageChange = (pageNo: number, pageSize: number = DEFAULT_PAGE_SIZE) => {
        setPagination({
            pageSize,
            currentPage: pageNo,
            totalRecords: props.totalRecords,
        });
        const sort = getFolderMessageSortOptions(selectedSortOption);
        fetchMessages(pageNo, pageSize, sort.sortColumn, sort.sortOrder, searchTextBox);
        dispatch(sentPageChanged(pageNo));
    };

    const handleMessageReload = () => {
        fetchMessages(pagination.currentPage, pagination.pageSize, "");
        handleResetSearchAndSort();
        dispatchSelectedMessageActions({
            type: "removeAll",
            payload: null,
        });     
    };

    const handleMessageDetailView = () => props.hideMessageDetailView(true);

    const handleOnSelected = (message: ISentMessageModel, e: any) => {
        e.stopPropagation();
        if (e.target.checked) {
            dispatchSelectedMessageActions({
                type: "add",
                payload: message,
                messageCount: props.messages.length,
            });
        } else {
            dispatchSelectedMessageActions({ type: "remove", payload: message });
        }
    };

    const handleOnAllSelected = (e: any) => {
        if (e.target.checked) {
            dispatchSelectedMessageActions({
                type: "addAll",
                payload: props.messages,
            });
        } else {
            dispatchSelectedMessageActions({
                type: "removeAll",
                payload: null,
            });
        }
    };

    const handleOnMessageItemClick = (message: ISentMessageModel, e: any) => {
        e.stopPropagation();
        setSelectedMessage(message);
        props.getMessageDetail(message);
    };

    const handleDeleteMessages = (callback: any) => {
        props.deleteMessages(selectedSentMessage.messages, callback);
        dispatchSelectedMessageActions({
            type: "removeAll",
            payload: null,
        });
        setSearchTextBox("");
    };

    const handleCancelTransfer = (callback: any) => {
        props.cancelTransfer(
            selectedSentMessage.messages.map(x => x.sentMessageId),
            callback
        );
        dispatchSelectedMessageActions({
            type: "removeAll",
            payload: null,
        });
        setSearchTextBox("");
    };

    const handleMoveToFolder = (folderId: number, callback?: any) => {
        props.moveToFolder(selectedSentMessage.messages, folderId, callback);
        dispatchSelectedMessageActions({
            type: "removeAll",
            payload: null,
        });
        setSearchTextBox("");
    };

    const handleResendAccessLink = () => {
        props.resendAccessLink(selectedSentMessage.messages);
        dispatchSelectedMessageActions({
            type: "removeAll",
            payload: null,
        });
    };
    const onFilter: React.ChangeEventHandler<HTMLInputElement> = e => {
        const value = (e.target as HTMLInputElement).value.toLocaleLowerCase();
        //Set only search box
        setSearchTextBox(value);
        debounceFn(value);
    };

    const getData = (value: string) => {
        const sort = getFolderMessageSortOptions(selectedSortOption);
        setPagination(initialPagination);
        fetchMessages(pagination.currentPage, pagination.pageSize, sort.sortColumn, sort.sortOrder, value);
    };

    const debounceFn = useCallback(debounce(1000, getData), [selectedSortOption]);

    const handleResetSearchAndSort = () => {
        setSelectedSortOption(SortAndFilterConstants.NEWEST_FIRST_ORDER);
        setSearchTextBox("");
    };

    const handleMessageListReload = () => {
        fetchMessages(pagination.currentPage, pagination.pageSize,"",SortAndFilterConstants.NEWEST_FIRST_ORDER,"",true,selectedMessage);
        handleResetSearchAndSort();
        dispatchSelectedMessageActions({
            type: "removeAll",
            payload: null,
        });     
    };
    return (
        <>
            <Row className="search-header-wrapper nopadding-left">
                <Col sm={10} md={8} className="search-input-wrapper-col padding-left" style={{flexGrow:1}}>
                    <div className="search-input-wrapper">
                        <FormControl
                            placeholder={ArchiveConstants.ShareFolder.PLACEHOLDER_SEARCH_TEXT}
                            value={searchTextBox}
                            onChange={onFilter}
                            className="search-input search-textbox"
                        />
                        <div className="search-icon">
                            <SearchIcon/>
                        </div>
                    </div>
                </Col>
                <Col sm={2} md={2} className="filter-icon-container">
                    <div>
                        <FiltersCustomComponent
                            groups={sortingData}
                            selectedSortOption={selectedSortOption}
                            iconType={SortAndFilterIconType.Sort}
                            setSelectedSortOption={setSelectedSortOption}
                        />
                    </div>
                </Col>
                <Col sm={2} md={2} className="list-refresh-icon-container">
                    <div className="list-refresh-button" onClick={handleMessageListReload}  title={ArchiveConstants.REFRESH_TITLE}>
                        <RefreshIcon/>
                    </div>
                </Col>
            </Row>
            <div className="message-list-wrapper">
                <Row>
                    <Col>
                        <MessageToolbar
                            folderType={FolderType.SENT}
                            folderId={FolderType.SENT}
                            selectedMessages={selectedSentMessage.messages}
                            isAllSelected={selectedSentMessage.isAllSelected}
                            isAnySelected={selectedSentMessage.messages.length > 0}
                            selectAll={handleOnAllSelected}
                            deleteMessages={handleDeleteMessages}
                            cancelTransfer={handleCancelTransfer}
                            printDocument={() => {
                                props.print(selectedSentMessage.messages);
                            }}
                            checkFolderExpiry={props.checkFolderExpiry}
                            moveToFolder={handleMoveToFolder}
                            resendAccessLink={handleResendAccessLink}
                            reloadMessages={handleMessageReload}
                            showSelectAllCheckbox={props.messages && props.messages.length > 0}
                            hideMessageDetailView={handleMessageDetailView}
                            resourceLocators={ResourceIdLocators.Sent}
                            resetSearchAndSort={handleResetSearchAndSort}
                        />
                    </Col>
                    <Col>
                        {props.messages && props.messages.length > 0 && (
                            <DefaultPagination
                                pageSize={pagination.pageSize}
                                currentPage={pagination.currentPage}
                                totalRecords={props.totalRecords}
                                onPageChange={handleOnPageChange}
                            />
                        )}
                    </Col>
                </Row>
                <LoadingOverlay style={{ innerHeight: "400px", outerHeight: "500px" }}>
                    <div
                        style={{
                            height: getMessageListHeight(mailboxUserData, FolderType.SENT),
                        }}
                        className="message-list"
                    >
                        {props.messages && props.messages.length > 0 ? (
                            props.messages.map((item: any, index: number) => {
                                const isChecked =
                                    selectedSentMessage.messages.includes(item) || selectedSentMessage.isAllSelected;
                                const selectedMessageId =
                                    selectedMessage.sentMessageId === 0
                                        ? props.messages[0].sentMessageId
                                        : selectedMessage.sentMessageId;
                                if (selectedMessage.sentMessageId === 0) {
                                    setSelectedMessage(props.messages[0]);
                                }
                                return (
                                    <SentMessageListItem
                                        message={item}
                                        key={index}
                                        index={index}
                                        isChecked={isChecked}
                                        selectedMessageId={selectedMessageId}
                                        onSelected={handleOnSelected}
                                        onClick={(message, e) => handleOnMessageItemClick(message, e)}
                                    />
                                );
                            })
                        ) : (
                            <div className="vertical-middle">{MessageListConstants.NO_MESSAGE_TEXT}</div>
                        )}
                    </div>
                    <Loader loading={props.isLoading} />
                </LoadingOverlay>
            </div>
        </>
    );
};

export default SentMessageList;
