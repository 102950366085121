import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import Dropdown from "react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { AuthMethodLabels, MessageOptionsConstants } from "../../../../../helper/Constants";
import {
    fetchAuthenticationMethodSettings,
    fetchRetentionSettings,
    requestAllRetentionPeriods,
    updateAdminSettings,
} from "../../../../../actionCreators/adminSettingsActionCreators";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ApplicationState } from "../../../../../store";
import { AuthenticationMethod, CompanySettingsName } from "../../../../../core/common/enums";
import AuthMethodCard from "../../../../common/ui/AuthMethodCard";
import CustomToggleSwitch from "../../../../common/ui/switch/CustomToggleSwitch";
import AccessCodeIcon from "../../../../../assets/icons/svg/Access_code.svg";
import QnAIcon from "../../../../../assets/icons/svg/Qna_mode.svg";
import {
    IAuthenticationMethodSettings,
    initialCompanyAuthenticationSettings,
} from "../../../../../core/viewModels/company/settings/AuthenticationSettings";
import { CustomModal } from "../../../../common/ui/Modals/CustomModal";
import { getCompanyAuthMethods } from "../../../../../helper/HelperFunctions";
import { IRetentionPeriod } from "../../../../../core/viewModels/company/settings/RetentionSettings";
import { appInsightsData } from "../../../../../helper/AppInsightsData";
import { logger } from "../../../../../oidcClient/authProvider";

type OptionType = { value: string; label: string } | string | undefined;

export const MessageOptions: React.FC = () => {
    const dispatch = useDispatch();

    const { messageOptions: messageOptionsSettings, isLoading } = useSelector(
        (appState: ApplicationState) => appState.adminSettingsState
    );

    const retentionOptions = messageOptionsSettings.retentionPeriodList.map((rp: IRetentionPeriod) => {
        return { value: rp.retentionId.toString(), label: rp.description };
    });

    const getSelectedOption = (value?: number) => {
        if (value) {
            const val = value.toString();
            return retentionOptions.find(rp => rp.value === val);
        }
        return "";
    };

    const [authMethodState, setAuthMethodState] = useState<IAuthenticationMethodSettings>(
        initialCompanyAuthenticationSettings
    );
    const [showAuthMethodWarningModal, setAuthMethodWarningModal] = useState(false);
    const [qnARetentionPeriod, setQnARetentionPeriod] = useState<OptionType>("");

    const [accessCodeRetentionPeriod, setAccessCodeRetentionPeriod] = useState<OptionType>("");

    // const [loginRetentionPeriod, setLoginRetentionPeriod] = useState<OptionType>("");

    const [dropOffRetentionPeriod, setDropOffRetentionPeriod] = useState<OptionType>("");

    useEffect(() => {
        dispatch(requestAllRetentionPeriods(true));
        dispatch(fetchRetentionSettings());
        dispatch(fetchAuthenticationMethodSettings());
        // Track Page view event
        logger.trackPageView(appInsightsData.AdminSettings.MessageOptions.PageTitle);
    }, []);

    useEffect(() => {
        if (messageOptionsSettings.retentionSettings?.defaultRetentionPeriods) {
            setQnARetentionPeriod(
                getSelectedOption(messageOptionsSettings.retentionSettings.defaultRetentionPeriods.questionAndAnswer)
            );

            setAccessCodeRetentionPeriod(
                getSelectedOption(messageOptionsSettings.retentionSettings.defaultRetentionPeriods.accessCode)
            );

            // setLoginRetentionPeriod(
            //     getSelectedOption(messageOptionsSettings.retentionSettings.defaultRetentionPeriods.loginTransfer)
            // );

            setDropOffRetentionPeriod(
                getSelectedOption(messageOptionsSettings.retentionSettings.defaultRetentionPeriods.dropOffTransfer)
            );
        }

        if (messageOptionsSettings.authenticationSettings) {
            setAuthMethodState({
                ...authMethodState,
                ...messageOptionsSettings.authenticationSettings,
            });
        }
    }, [messageOptionsSettings]);

    const updateRetentionSettings = (name: string, option: any) => {
        if (messageOptionsSettings.retentionSettings) {
            const settings = {
                ...messageOptionsSettings.retentionSettings,
                defaultRetentionPeriods: {
                    // loginTransfer: Number.parseInt((loginRetentionPeriod as any).value),
                    questionAndAnswer: Number.parseInt((qnARetentionPeriod as any).value),
                    accessCode: Number.parseInt((accessCodeRetentionPeriod as any).value),
                    dropOffTransfer: Number.parseInt((dropOffRetentionPeriod as any).value),
                    [name]: Number.parseInt(option.value),
                },
            };

            messageOptionsSettings && dispatch(updateAdminSettings(CompanySettingsName.RetentionSettings, settings));
        }
    };

    const handleAuthMethodClick = (authMethod: AuthenticationMethod, type: string) => {
        let authMethods: AuthenticationMethod[] = [...authMethodState.authenticationMethods];

        if (authMethodState.defaultAuthenticationMethod != authMethod) {
            if (type === "isEnabled") {
                if (authMethods.includes(authMethod)) {
                    authMethods = authMethods.filter(function (auth) {
                        return auth != authMethod;
                    });
                } else {
                    authMethods = authMethods.concat(authMethod);
                }
                setAuthMethodState({
                    ...authMethodState,
                    authenticationMethods: authMethods,
                });

                const updateAdminSettingsRequestPayload: IAuthenticationMethodSettings = {
                    ...authMethodState,
                    authenticationMethods: authMethods,
                };

                dispatch(
                    updateAdminSettings(
                        CompanySettingsName.AuthenticationMethodSettings,
                        updateAdminSettingsRequestPayload
                    )
                );
            }

            if (type === "isDefault") {
                setAuthMethodState({
                    ...authMethodState,
                    authenticationMethods: [...authMethods],
                    defaultAuthenticationMethod: authMethod,
                });

                const updateAdminSettingsRequestPayload: IAuthenticationMethodSettings = {
                    ...authMethodState,
                    authenticationMethods: authMethods,
                    defaultAuthenticationMethod: authMethod,
                };
                dispatch(
                    updateAdminSettings(
                        CompanySettingsName.AuthenticationMethodSettings,
                        updateAdminSettingsRequestPayload
                    )
                );
            }
        }
    };

    const updateToggleSettings = (name: string, value: boolean) => {
        setAuthMethodState({ ...authMethodState, [name]: value });
        const updateAdminSettingsRequestPayload: IAuthenticationMethodSettings = {
            ...authMethodState,
            [name]: value,
        };
        dispatch(
            updateAdminSettings(CompanySettingsName.AuthenticationMethodSettings, updateAdminSettingsRequestPayload)
        );
    };

    const handleAuthenticationToggleSwitch = (e: any, name: string) => {
        const value = e.target.checked;
        if (name === MessageOptionsConstants.ALLOW_EMPLOYESS_TO_CHANGE_DEFAULT_METHOD && !value) {
            setAuthMethodWarningModal(true);
        } else {
            updateToggleSettings(name, value);
        }
    };

    const getAuthMethodMetaData = (authMethod: AuthenticationMethod, type: string) => {
        switch (authMethod) {
            case AuthenticationMethod.QUESTIONANSWER:
                return type === "label" ? AuthMethodLabels.QUESTIONANSWER : QnAIcon;
            case AuthenticationMethod.ACCESSCODE:
                return type === "label" ? AuthMethodLabels.ACCESSCODE : AccessCodeIcon;
            // case AuthenticationMethod.LOGINPASSWORD:
            //     return type === "label" ? AuthMethodLabels.LOGINPASSWORD : LoginIcon;
            default:
                return "";
        }
    };

    const handleAuthMethodWarningModalSubmission = () => {
        setAuthMethodWarningModal(false);
        updateToggleSettings(MessageOptionsConstants.ALLOW_EMPLOYESS_TO_CHANGE_DEFAULT_METHOD, false);
    };

    return (
        <LoadingOverlay>
            <>
                <header>
                    <span>{MessageOptionsConstants.MESSAGE_OPTIONS_HELPER_TEXT}</span>
                </header>
                <section>
                    <div className="settings-accordian-container">
                        <Accordion flush className="settings-accordian">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    <div className="accordion-title-info-container">
                                        <h4>{MessageOptionsConstants.AUTHENTICATION_METHOD}</h4>
                                        <span>{MessageOptionsConstants.AUTHENTICATION_METHOD_HELPER_TEXT}</span>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <h5 className="heading-black-md">
                                        {MessageOptionsConstants.AUTHENTICATION_METHOD_BODY_HELPER_TEXT}
                                    </h5>

                                    <div className="auth-method-dialog-body flex-row-space-around">
                                        {authMethodState?.authenticationMethods &&
                                            getCompanyAuthMethods().map(authMethod => (
                                                <AuthMethodCard
                                                    key={authMethod.value}
                                                    cardTitle={getAuthMethodMetaData(authMethod.key, "label")}
                                                    cardImage={getAuthMethodMetaData(authMethod.key, "icon")}
                                                    isDefault={
                                                        authMethodState.defaultAuthenticationMethod == authMethod.key
                                                    }
                                                    onMethodClick={type => handleAuthMethodClick(authMethod.key, type)}
                                                    isActive={authMethodState.authenticationMethods.includes(
                                                        authMethod.key
                                                    )}
                                                    allowEmployeesToChangeDefaultMethod={
                                                        authMethodState.allowEmployeesToChangeDefaultMethod
                                                    }
                                                    allowEmployeesToChangeAuthMethod={
                                                        authMethodState.allowEmployeesToChangeAuthMethod
                                                    }
                                                />
                                            ))}
                                    </div>
                                    <div className="margin-top-5">
                                        <div className="settings-label-toggle-wrapper">
                                            <CustomToggleSwitch
                                                label={MessageOptionsConstants.CHANGE_AUTHENTICATION_METHOD_TEXT}
                                                name={MessageOptionsConstants.ALLOW_EMPLOYESS_TO_CHANGE_AUTH_METHOD}
                                                handleChange={handleAuthenticationToggleSwitch}
                                                switched={authMethodState.allowEmployeesToChangeAuthMethod}
                                            />
                                        </div>

                                        <div className="settings-label-toggle-wrapper">
                                            <CustomToggleSwitch
                                                label={MessageOptionsConstants.CHANGE_DEFAULT_METHOD_TEXT}
                                                name={MessageOptionsConstants.ALLOW_EMPLOYESS_TO_CHANGE_DEFAULT_METHOD}
                                                handleChange={handleAuthenticationToggleSwitch}
                                                switched={authMethodState.allowEmployeesToChangeDefaultMethod}
                                            />
                                        </div>
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className="second-accordian-item" eventKey="1">
                                <Accordion.Header>
                                    <div className="accordion-title-info-container">
                                        <h4>{MessageOptionsConstants.RETENTION_PERIOD}</h4>
                                        <span>{MessageOptionsConstants.RETENTION_PERIOD_HELPER_TEXT}</span>
                                    </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                    <div className="settings-label-dropdown-wrapper">
                                        <h5>{MessageOptionsConstants.RETENTION_PERIOD_QNA}</h5>

                                        <Dropdown
                                            className="setting-dropdown"
                                            onChange={option => {
                                                setQnARetentionPeriod(option as any);
                                                updateRetentionSettings("questionAndAnswer", option);
                                            }}
                                            placeholder="Select Authentication Method"
                                            options={retentionOptions}
                                            value={qnARetentionPeriod}
                                        />
                                    </div>
                                    <div className="settings-label-dropdown-wrapper">
                                        <h5>{MessageOptionsConstants.RETENTION_PERIOD_ACCESS_CODE}</h5>

                                        <Dropdown
                                            className="setting-dropdown"
                                            onChange={option => {
                                                setAccessCodeRetentionPeriod(option as any);
                                                updateRetentionSettings("accessCode", option);
                                            }}
                                            placeholder="Select Authentication Method"
                                            options={retentionOptions}
                                            value={accessCodeRetentionPeriod}
                                        />
                                    </div>
                                    {/* <div className="settings-label-dropdown-wrapper">
                                    <h5>{MessageOptionsConstants.RETENTION_PERIOD_LOGIN}</h5>

                                    <Dropdown
                                        className="setting-dropdown"
                                        onChange={option => {
                                            setLoginRetentionPeriod(option as any);
                                            updateRetentionSettings("loginTransfer", option);
                                        }}
                                        placeholder="Select Authentication Method"
                                        options={retentionOptions}
                                        value={loginRetentionPeriod}
                                    />
                                </div> */}
                                    <div className="settings-label-dropdown-wrapper">
                                        <h5>{MessageOptionsConstants.RETENTION_PERIOD_DROP_OFF}</h5>

                                        <Dropdown
                                            className="setting-dropdown drop-up"
                                            onChange={option => {
                                                setDropOffRetentionPeriod(option as any);
                                                updateRetentionSettings("dropOffTransfer", option);
                                            }}
                                            placeholder="Select Authentication Method"
                                            options={retentionOptions}
                                            value={dropOffRetentionPeriod}
                                        />
                                    </div>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                </section>
            </>
            {showAuthMethodWarningModal && (
                <CustomModal
                    show={showAuthMethodWarningModal}
                    cancelButtonName="No"
                    confirmButtonName="Yes"
                    title={"Confirm Selection"}
                    isConfirmModal={true}
                    className="message-options-auth-method-warning-modal"
                    onSubmit={handleAuthMethodWarningModalSubmission}
                    onHide={() => setAuthMethodWarningModal(false)}
                >
                    {MessageOptionsConstants.CHANGE_AUTHENTICATION_METHOD_WARNING_TEXT}
                </CustomModal>
            )}
            <Loader loading={isLoading} />
        </LoadingOverlay>
    );
};
