import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Button } from "react-bootstrap";

import { FolderDropOffModalConstants } from "../../../../../helper/Constants";
import { CustomModal } from "../CustomModal";
import { ApplicationState } from "../../../../../store";
import { fetchFolderDropOffLink } from "../../../../../actionCreators/folderActionCreator";
import { copyToClipboard } from "../../../../../helper/HelperFunctions";
import { CopyIcon } from "../../../../svg/IconCollection";

interface IFolderDropOffModal {
    show: boolean;
    folderId: number;
    onHide: () => void;
}

export const FolderDropOffModal: React.FC<IFolderDropOffModal> = ({ show, folderId, onHide }) => {
    const dispatch = useDispatch();
    const folderDropOffState = useSelector((state: ApplicationState) => state.folderState.folderDropOff);

    useEffect(() => {
        dispatch(fetchFolderDropOffLink(folderId));
    }, []);

    const handleCopyToClipboard = () => {
        copyToClipboard(folderDropOffState.dropOffUrl);
    };

    return (
        <CustomModal
            className="dropoff-modal-container"
            show={show}
            hideFooter={true}
            onHide={() => {
                onHide();
            }}
            onSubmit={() => {
                //No submit action
            }}
            isConfirmModal={true}
            title={FolderDropOffModalConstants.MODAL_TITLE}
        >
            <LoadingOverlay>
                <div className="dropoff-modal-links-container">
                    <a href={folderDropOffState?.dropOffUrl} target="_blank" rel="noreferrer">
                        {folderDropOffState?.dropOffUrl}
                    </a>
                    <Button
                        className="btn-copy"
                        title={FolderDropOffModalConstants.MODEL_TITLE_COPY}
                        variant="link"
                        onClick={handleCopyToClipboard}
                    >
                        <CopyIcon />
                    </Button>
                </div>
                <Loader loading={folderDropOffState.isLoading} />
            </LoadingOverlay>
        </CustomModal>
    );
};

export default FolderDropOffModal;
