import { actionTypes } from "../types/mfaTypes";
import { ICompanyMfaSettings } from "../core/viewModels/company/CompanySettingsViewModel";
import { AppThunkAction } from "../store";
import { CompanySettingsConstants } from "../helper/Constants";
import axios from "axios";
import { actionTypes as notificationTypes } from "../types/notificationTypes";
import {
	NotificationAction,
	StatusType,
} from "../reducers/notificationReducer";
import { ToasterMessages } from "../helper/ToasterMessages";

export interface RequestCompanyMfaSettingsAction {
	type: actionTypes.REQUEST_COMPANY_MFA_SETTINGS;
	message: string;
}

export interface ReceiveCompanyMfaSettingsAction {
	type: actionTypes.RECEIVE_COMPANY_MFA_SETTINGS;
	settings: ICompanyMfaSettings;
}

export type DispatchActions =
	| RequestCompanyMfaSettingsAction
	| ReceiveCompanyMfaSettingsAction;

export type KnownActions = DispatchActions | NotificationAction;

export const requestCompanyMfaSettings =
    (): AppThunkAction<KnownActions> => (dispatch) => {
        dispatch({
            type: actionTypes.REQUEST_COMPANY_MFA_SETTINGS,
            message: CompanySettingsConstants.OverlayMessage.ApplicationLoading,
        });
        axios
            .get(
                `${process.env.REACT_APP_API_URL_SUITE}/api/mfa/mfa-setting`
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_COMPANY_MFA_SETTINGS,
                    settings: data,
                });
            })
            .catch(function () {
                dispatch({
                    type: notificationTypes.NOTIFICATION,
                    statusMessage: ToasterMessages.ERROR.COMPANY_SETTINGS_ERROR,
                    statusType: StatusType.Error,
                });
            });
    };