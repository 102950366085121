import React, { useState } from "react";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch, useSelector } from "react-redux";
import {
    addUpdateTemplate,
    deleteTemplate,
    fetchTemplateTableData,
    getTemplateDetail,
} from "../../../../../actionCreators/templateActionCreators";
import { SortOrder, TemplateColumnName } from "../../../../../core/common/enums";
import {
    IDocumentRequestTemplate,
    IDocumentRequestTemplateModel,
    initialTemplateFormState,
    initialTemplateTableRequestObject,
    ITemplateTableDataRequestModel,
} from "../../../../../core/viewModels/template/TemplateModel";
import { TemplateListingConstants, TemplateModalConstants } from "../../../../../helper/Constants";
import { ApplicationState } from "../../../../../store";
import { CustomModal } from "../../../../common/ui/Modals/CustomModal";
import { AddTemplateModal } from "../../../../common/ui/Modals/TemplateModal/AddTemplateModal";
import TemplateListingTable from "./TemplateListingTable";
import "./styles.scss";
import { AppNotifier } from "../../../../../helper/AppNotifier";
import { ToasterMessages } from "../../../../../helper/ToasterMessages";

export const TemplateListing: React.FC = () => {
    const [selectedTemplate, setSelectedTemplate] = useState<IDocumentRequestTemplate[]>([]);
    const [deleteTemplatePayload, setDeleteTemplatePayload] = useState<IDocumentRequestTemplate[]>([]);
    const [showAddEditTemplateModal, setShowAddEditTemplateModal] = useState(false);
    const [isEditModal, setIsEditModal] = useState(false);
    const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);
    const [currentPageNumber, setCurrentPageNumber] = useState(1);
    const { templateList, isLoading, isModalLoading } = useSelector((state: ApplicationState) => state.templateState);
    const [intialModalData, setIntialModalData] = useState(initialTemplateFormState);
    const { userId } = useSelector((state: ApplicationState) => state.userProfile);
    const [sortOrder, setSortOrder] = useState<SortOrder>(SortOrder.DESC);
    const [sortColumn, setSortColumn] = useState<TemplateColumnName>(TemplateColumnName.CREATEDDATE);
    const dispatch = useDispatch();

    const reloadTemplateData = () => {
        let pageNumber = currentPageNumber;
        if (currentPageNumber > 1 && selectedTemplate.length === templateList.length) {
            pageNumber = currentPageNumber - 1;
            setCurrentPageNumber(currentPageNumber - 1);
        }
        const payload = {
            ...initialTemplateTableRequestObject,
            sortColumn: sortColumn,
            sortOrder: sortOrder,
            pageNumber: pageNumber,
        };
        fetchTemplateData(payload);
        setSelectedTemplate([]);
    };
    const clearModalState = () => {
        setSelectedTemplate([]);
        setShowAddEditTemplateModal(false);
        setShowDeleteTemplateModal(false);
        setIntialModalData(initialTemplateFormState);
    };
    const handleTableSorting = (sortColumn: TemplateColumnName, sortOrder: SortOrder, pageNumber: number) => {
        const payload = {
            ...initialTemplateTableRequestObject,
            pageNumber: pageNumber,
            sortColumn: sortColumn,
            sortOrder: sortOrder,
        };
        dispatch(fetchTemplateTableData(payload));
    };
    const fetchTemplateData = (payload: ITemplateTableDataRequestModel) => {
        dispatch(fetchTemplateTableData(payload));
    };

    const handleOnDelete = (selectedTemplate: IDocumentRequestTemplate[]) => {
        if (selectedTemplate.length > 0) {
            setDeleteTemplatePayload(selectedTemplate);
            setShowDeleteTemplateModal(true);
        }
    };
    const handleDeleteTemplateSubmission = () => {
        const folderIds = deleteTemplatePayload.map(f => f.documentRequestTemplateId);
        dispatch(deleteTemplate(folderIds, handleDeleteSuccessCallback));
    };
    const handleDeleteSuccessCallback = () => {
        setShowDeleteTemplateModal(false);
        reloadTemplateData();
        setDeleteTemplatePayload([]);
    };
    const onClickAddTemplate = () => {
        setIsEditModal(false);
        setShowAddEditTemplateModal(true);
        setIntialModalData(initialTemplateFormState);
    };
    const onClickEditTemplate = (selectedTemplate: IDocumentRequestTemplate) => {
        if (selectedTemplate) {
            dispatch(getTemplateDetail(selectedTemplate.documentRequestTemplateId, intitialiseEditModal));
        }
    };

    const intitialiseEditModal = (data: IDocumentRequestTemplateModel) => {
        setIntialModalData(data);
        setIsEditModal(true);
        setShowAddEditTemplateModal(true);
    };
    const handleOnDuplicateTemplate = (selectedTemplate: IDocumentRequestTemplate) => {
        dispatch(getTemplateDetail(selectedTemplate.documentRequestTemplateId, createDuplicateTemplate));
    };
    const createDuplicateTemplate = (data: IDocumentRequestTemplateModel) => {
        if (data.documentRequestTemplate.name.length > TemplateListingConstants.TEMPLATE_DUPLICATE_NAME_CONDITION) {
            AppNotifier.Error(ToasterMessages.ERROR.UNABLE_TO_DUPLICATE_TEMPLATE);
            setSelectedTemplate([]);
        } else {
            const payload = {
                documentRequestTemplate: {
                    ...data.documentRequestTemplate,
                    createdBy: userId,
                    name: `${data.documentRequestTemplate.name}-copy`,
                },
                files: data.files,
            };
            dispatch(addUpdateTemplate(payload, reloadTemplateData));
        }
    };

    return (
        <LoadingOverlay>
            <>
                <header>
                    <span>{TemplateListingConstants.TITLE_HELPER_TEXT}</span>
                </header>

                <div className="margin-top-20-px template-listing-table">
                    <TemplateListingTable
                        isContentLoading={isLoading}
                        data={templateList}
                        count={templateList && templateList[0]?.count}
                        selectedTemplate={selectedTemplate}
                        updateSelectedTemplate={setSelectedTemplate}
                        handleOnAddTemplate={onClickAddTemplate}
                        handleOnEditTemplate={onClickEditTemplate}
                        handleOnDuplicateTemplate={handleOnDuplicateTemplate}
                        handleOnDelete={handleOnDelete}
                        currentPageNumber={currentPageNumber}
                        setCurrentPageNumber={setCurrentPageNumber}
                        handleTableSorting={handleTableSorting}
                        fetchTemplateData={fetchTemplateData}
                        sortOrder={sortOrder}
                        sortColumn={sortColumn}
                        setSortOrder={setSortOrder}
                        setSortColumn={setSortColumn}
                    />
                </div>

                {showAddEditTemplateModal && (
                    <AddTemplateModal
                        initialModalData={intialModalData}
                        isEditModal={isEditModal}
                        showModal={showAddEditTemplateModal}
                        hideModal={clearModalState}
                        reloadTemplateData={reloadTemplateData}
                        isLoading={isModalLoading}
                    />
                )}
                {showDeleteTemplateModal && (
                    <CustomModal
                        show={showDeleteTemplateModal}
                        onHide={() => {
                            clearModalState();
                        }}
                        onSubmit={handleDeleteTemplateSubmission}
                        cancelButtonName="Cancel"
                        confirmButtonName="Ok"
                        isConfirmModal={true}
                        className="delete-request-template-modal"
                        title={
                            selectedTemplate.length > 1
                                ? TemplateModalConstants.DELETE_TEMPLATES_MODAL_TITLE
                                : TemplateModalConstants.DELETE_TEMPLATE_MODAL_TITLE
                        }
                    >
                        {selectedTemplate.length > 1
                            ? TemplateModalConstants.DELETE_TEMPLATES_MODAL_BODY
                            : TemplateModalConstants.DELETE_TEMPLATE_MODAL_BODY}
                    </CustomModal>
                )}
            </>

            <Loader loading={isLoading} />
        </LoadingOverlay>
    );
};
