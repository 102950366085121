import React from "react";
import { IDropOffSender } from "../../../../../../../core/viewModels/company/settings/DropOffSettings";
import { DropOffUsersConstants } from "../../../../../../../helper/Constants";
import { DeleteIcon } from "../../../../../../svg/IconCollection";

interface ISenderInLineActions {
    handleEditClick: (selectedSender: IDropOffSender) => void;
    selectedSender: IDropOffSender;
    handleDeleteSenderClick: (selectedSender: IDropOffSender[]) => void;
    handleWhitelistSenderClick: (selectedSender: IDropOffSender[]) => void;
    handleBlacklistSenderClick: (selectedSender: IDropOffSender[]) => void;
    updateSelectedSender: (selectedSender: IDropOffSender[]) => void;
}

const SenderInLineActions: React.FC<ISenderInLineActions> = ({
    handleEditClick,
    selectedSender,
    handleDeleteSenderClick,
    handleWhitelistSenderClick,
    handleBlacklistSenderClick,
    updateSelectedSender,
}) => {
    return (
        <div onClick={() => updateSelectedSender([selectedSender])} className="inline-actions-bar-container">
            <div className="folder-toolbar__action">
                <div onClick={() => handleEditClick(selectedSender)} className="pointer button-hide" title={"Edit"}>
                    <i className="fas fa-pen"></i>
                </div>
            </div>

            <div className="folder-toolbar__action" style={{ paddingRight: "20px" }}>
                <span onClick={() => handleDeleteSenderClick([selectedSender])} title={"Delete"} className="pointer">
                    <DeleteIcon width={15} height={15} disabled={false} />
                </span>
            </div>
            <button
                onClick={
                    selectedSender.isBlacklisted
                        ? () => handleWhitelistSenderClick([selectedSender])
                        : () => handleBlacklistSenderClick([selectedSender])
                }
                className={
                    selectedSender.isBlacklisted ? "button-secondary-blue-outlined" : "button-secondary-grey-outlined"
                }
            >
                {selectedSender.isBlacklisted
                    ? DropOffUsersConstants.SENDER_WHITELIST_USERS_BUTTON
                    : DropOffUsersConstants.SENDER_BLACKLIST_USERS_BUTTON}
            </button>
        </div>
    );
};

export default SenderInLineActions;
