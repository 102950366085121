import { AdminSettingsConstants, TabConstants } from "../../../helper/Constants";
import { TabType } from "../../common/enums";

export interface ITabModel {
    title: string;
    type: TabType;
    isActive: boolean;
}

export interface ISettingsInfo {
    title: string;
    items: ITabModel[];
}

export const adminSettingsRoute: string[] = [
    "/drop-off",
    "/history-report-settings",
    "/notifications",
    "/document-request-settings",
    "/general",
    "/email-templates"
];

const initialSettings: ISettingsInfo[] = [
    {
        title: AdminSettingsConstants.GENERAL_SETTING_TITLE,
        items: [
            {
                title: TabConstants.GENERAL_MESSAGE_OPTIONS,
                type: TabType.MessageOption,
                isActive: true,
            },
            {
                title: TabConstants.GENERAL_AUTHENTICATION_QUESTIONS,
                type: TabType.AuthenticationQuestions,
                isActive: false,
            },
            {
                title: TabConstants.GENERAL_SUPPORT_CONTACT,
                type: TabType.SupportContact,
                isActive: false,
            },
        ],
    },
    {
        title: AdminSettingsConstants.DROPOFF_SETTING_TITLE,
        items: [
            {
                title: TabConstants.DROPOFF_CONFIGURATION,
                type: TabType.Configuration,
                isActive: true,
            },
            {
                title: TabConstants.DROPOFF_USERS,
                type: TabType.User,
                isActive: false,
            },
        ],
    },
    {
        title: AdminSettingsConstants.REPORT_SETTING_TITLE,
        items: [
            {
                title: TabConstants.REPORT_MESSAGE_HISTORY,
                type: TabType.MessageHistory,
                isActive: true,
            },
            {
                title: TabConstants.REPORT_REQUEST_HISTORY,
                type: TabType.RequestHistory,
                isActive: false,
            },
        ],
    },
    {
        title: AdminSettingsConstants.NOTIFICATION_SETTING_TITLE,
        items: [
            {
                title: TabConstants.NOTIFICATION_MESSAGE,
                type: TabType.MessageNotification,
                isActive: true,
            },
            {
                title: TabConstants.NOTIFICATION_REQUEST,
                type: TabType.RequestNotification,
                isActive: false,
            },
        ],
    },
    {
        title: AdminSettingsConstants.DOCUMENT_REQUEST_SETTING_TITLE,
        items: [
            {
                title: TabConstants.DOCUMENT_REQUEST_CATEGORY,
                type: TabType.DocumentCategory,
                isActive: true,
            },
            {
                title: TabConstants.DOCUMENT_REQUEST_TEMPLATE,
                type: TabType.RequestTemplate,
                isActive: false,
            },
        ],
    },
    {
        title: AdminSettingsConstants.EMAIL_TEMPLATE_TITLE,
        items: [
            {
                title: TabConstants.EMAIL_TEMPLATE_SECURE_MESSAGE,
                type: TabType.MessageEmailTemplate,
                isActive: true,
            },
            {
                title: TabConstants.EMAIL_TEMPLATE_REQUESTS,
                type: TabType.RequestsEmailTemplate,
                isActive: false,
            },
        ],
    }
];

const settingsMap: { [key: string]: string } = {
    "history-report-settings": AdminSettingsConstants.REPORT_SETTING_TITLE,
    "drop-off": AdminSettingsConstants.DROPOFF_SETTING_TITLE,
    notifications: AdminSettingsConstants.NOTIFICATION_SETTING_TITLE,
    "document-request-settings": AdminSettingsConstants.DOCUMENT_REQUEST_SETTING_TITLE,
    general: AdminSettingsConstants.GENERAL_SETTING_TITLE,
    "email-templates": AdminSettingsConstants.EMAIL_TEMPLATE_TITLE,
};

export const getSettingsTab = (path: string): ISettingsInfo => {
    return initialSettings.filter(s => s.title == settingsMap[path])[0];
};
