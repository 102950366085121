import React, { useState } from "react";
import { Loader } from "react-overlay-loader";
import Dropdown, { Option } from "react-dropdown";
import { IFolderModel } from "../../../../../core/viewModels/folder/FolderModel";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { updateFoldersRetentionPeriod } from "../../../../../actionCreators/folderActionCreator";
import { AppNotifier } from "../../../../../helper/AppNotifier";
import { RetentionPeriodModal } from "../../../../../helper/Constants";
import { updateRetentionPeriod } from "../../../../../actionCreators/documentRequestActionCreators";
import { IDocumentRequestModel } from "../../../../../core/viewModels/documentRequest/DocumentRequestModel";
import { ToasterMessages } from "../../../../../helper/ToasterMessages";
import { CustomModal } from "../CustomModal";
import { AutomationIdConstants } from "../../../../../helper/AutomationConstants";
interface IRetentionModalProps {
    showState: boolean;
    retentionOptions: Option[];
    selectedFolders?: IFolderModel[];
    changeRtnResourceId?: string;
    document?: IDocumentRequestModel;
    onHide: () => void;
    onSubmit: (updatedRetention?: number) => void;
}

export const ChangeRetentionPeriodModal: React.FC<IRetentionModalProps> = props => {
    const dispatch = useDispatch();
    const [selectedRetention, setRetention] = useState<string>("");

    const retentionData = useSelector((appState: ApplicationState) => appState.folderState.retentionPeriodsData);

    const handleFormSubmit = () => {
        const retentionString = selectedRetention === "" ? getGridDefaultRetention() : selectedRetention;
        if (props.selectedFolders && props.selectedFolders.length >= 1) {
            /*Change retentiion dispatch call*/
            dispatch(
                updateFoldersRetentionPeriod(
                    props.selectedFolders.map(f => f.folderId),
                    +retentionString,
                    result => {
                        if (result) {
                            AppNotifier.Success(
                                props.selectedFolders && props.selectedFolders.length > 1
                                    ? ToasterMessages.SUCCESS.CHANGE_RETENTIONS
                                    : ToasterMessages.SUCCESS.CHANGE_RETENTION
                            );
                            props.onSubmit(+retentionString);
                        }
                    },
                    props?.changeRtnResourceId
                )
            );
        } else if (props.document) {
            dispatch(
                updateRetentionPeriod(
                    props.document.documentRequestId,
                    +retentionString,
                    props?.changeRtnResourceId,
                    result => {
                        if (result) {
                            props.onSubmit(+retentionString);
                        }
                    }
                )
            );
        }
        props.onSubmit();
    };

    const onRetentionDropdownChange = (option: Option) => {
        setRetention(option.value);
    };

    const getGridDefaultRetention = (): string => {
        let retention = 0;
        if (props.selectedFolders && props.selectedFolders.length >= 1) {
            retention = props.selectedFolders[0].expiresDays;
            const isCommon = props.selectedFolders.every(f => f.expiresDays === retention);
            retention = isCommon ? retention : 0;
        } else if (props.document) {
            retention = props.document.expiryDays;
        }
        return retention === 0 ? "" : retention.toString();
    };

    return (
        <CustomModal
            show={props.showState}
            onHide={props.onHide}
            onSubmit={handleFormSubmit}
            title={RetentionPeriodModal.CHANGE_RETENTION_PERIOD_TITLE}
            cancelButtonName={"Cancel"}
            confirmButtonName={"Ok"}
            className="change-retention-period-modal"
            isConfirmModal={true}
            cancelButtonAutomationId={AutomationIdConstants.retentionPeriodModal.CancelBtn}
            conformButtonAutomationId={AutomationIdConstants.retentionPeriodModal.OkBtn}
        >
            <div>
                <Dropdown
                    options={props.retentionOptions}
                    onChange={onRetentionDropdownChange}
                    placeholder="Select Retention Period"
                    value={getGridDefaultRetention()}
                />
                <Loader classNamePrefix="sm" text="" loading={retentionData.isLoading} />
            </div>
        </CustomModal>
    );
};
