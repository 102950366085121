import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/esm/Button";
import ButtonGroup from "react-bootstrap/esm/ButtonGroup";
import { useDispatch, useSelector } from "react-redux";

import {
    FolderType,
    MessageActions,
    MessageSource,
    MessageType,
    RecipientType,
    ShareFolderTypes,
} from "../../../core/common/enums";
import { IRecipientModel } from "../../../core/viewModels/message/RecipientModel";
import {
    deleteMessage as deleteRecipientMessage,
    deleteSharedMessage,
} from "../../../actionCreators/receivedMessageActionCreators";
import { deleteMessage as deleteSpamMessage } from "../../../actionCreators/spamMessageActionCreators";
import {
    deleteMessages as deleteSentMessage,
    forwardSentMessage,
    fetchSentMessageDetailsHistory,
} from "../../../actionCreators/sentMessageActionCreators";
import { IReceivedMailMessage } from "../../../core/viewModels/message/ReceivedMailMessageModel";
import { IReceivedMessageModel } from "../../../core/viewModels/message/ReceivedMessageModel";
import { IReceivedMessageRecipientModel } from "../../../core/viewModels/message/ReceivedMessageRecipientModel";
import { ISentMailMessage } from "../../../core/viewModels/message/SentMailMessageModel";
import { IPagination, ISentMessageModel } from "../../../core/viewModels/message/SentMessageModel";
import { ISentMessageRecipientModel } from "../../../core/viewModels/message/SentMessageRecipientModel";
import { AppNotifier } from "../../../helper/AppNotifier";
import {
    DraftMessageConstants,
    MessageToolbarConstants,
    MyDownloadsModalConstants,
} from "../../../helper/Constants";
import {
    getCurrentDate,
    getExpiryDateFromDays,
    getFormattedSentDateForDetailedView,
} from "../../../helper/DateHelperFunctions";
import ComposeModal from "../compose/ComposeModal";
import {
    convertTextToHtml,
    formatBytes,
    getDynamicMaxHeightForAttachments,
    getDynamicMaxHeightForMessage,
    getImageInitials,
    getMailboxUserEmailText,
    getMessageTitleFromFolderType,
    getPageTitleFromFolderType,
    isFolderOwner,
} from "../../../helper/HelperFunctions";
import { FileFormatIcon } from "../../svg/FileIconCollection";
import { getFileExtension } from "../../../helper/Validations";
import { IFileModel } from "../../../core/viewModels/file/FileModel";
import { ApplicationState } from "../../../store";
import { IFileUploadModel } from "../compose/parts/AttachmentUploader";
import { Col, Overlay, OverlayTrigger, Popover, Row } from "react-bootstrap";
import CustomDatePicker from "../ui/CustomDatePicker";
import moment from "moment";
import { IDraftMailMessage } from "../../../core/viewModels/message/DraftMailMessage";
import { deleteDraftMessage } from "../../../actionCreators/draftMessageActionCreator";
import { IResourceLocator } from "../../../helper/ResourceIdLocators";
import { IMyAccountUserProfile } from "../../navigation/parts/Profile";
import { dataFormatter } from "../../pages/reports/parts/Formatter";
import { IFolderModel } from "../../../core/viewModels/folder/FolderModel";
import { ColorConstants } from "../../../assets/custom/colors";
import { PrintComponent } from "../print/PrintComponent";
import { MessagePrint } from "./MessagePrint";
import { DeleteIcon, DownloadIcon, EditIcon, ForwardIcon, ReplyAllIcon, ReplyIcon } from "../../svg/IconCollection";
import { ToasterMessages } from "../../../helper/ToasterMessages";
import { CustomModal } from "../ui/Modals/CustomModal";

interface IMessageDetailProps {
    message: IReceivedMailMessage | ISentMailMessage | any;
    messageId: number;
    folderType: FolderType;
    resourceLocators: IResourceLocator;
    pagination: IPagination;
    currentfolder?: IFolderModel;
    downloadSingleAttachment?: (
        fileId: number,
        physicalPath: string,
        messageId: number,
        fileName: string,
        senderEmail: string,
        receivedMessageGuid: string,
        messageSource: MessageSource
    ) => void;
    downloadAllAttachments?: (
        files: IFileModel[],
        messageId: number,
        senderEmail: string,
        receivedMessageGuid: string,
        messageSource: MessageSource
    ) => void;
    cancelTransfer?: (messageId: number, callback: any) => void;
    hideMessageDetailView?: (hide: boolean) => void;
    getMessageDetail?: (message: IReceivedMessageModel | ISentMessageModel | any) => void;
    changeExpiry?: (expiresDate: Date, message: IReceivedMessageModel | ISentMessageModel, callback?: any) => void;
    fetchFolderMessages?: (pageNo: number, pageSize: number, search: string, message?: any) => void;
    onSuccessCallback?: (pageNo: number, pageSize: number, search: string, message?: any) => void;
    resetSearchAndSort?: () => void;
}

const convertRecipientToContact = (messageModel: IReceivedMailMessage | ISentMailMessage) => {
    const recipientQA = messageModel.recipientSecurityQuestions.find(
        x => x.recipientEmail.toLowerCase() === messageModel.messageDetail.senderEmail.toLowerCase()
    );
    return [
        {
            firstName: messageModel.messageDetail.senderFirstName,
            lastName: messageModel.messageDetail.senderLastName,
            emailAddress: messageModel.messageDetail.senderEmail,
            recipientType: RecipientType.ToRecipient,
            userId: messageModel.messageDetail.senderId,
            questionId: recipientQA?.securityQuestionId,
            question: recipientQA?.question,
            answer: recipientQA?.answer,
        },
    ];
};

const convertRecipientToContactForDraft = (messageModel: IDraftMailMessage) => {
    const toRecipients: IRecipientModel[] = [];

    messageModel.recipients.map(recipient => {
        toRecipients.push({
            firstName: recipient.firstName,
            lastName: recipient.lastName,
            emailAddress: recipient.email,
            recipientType: RecipientType.ToRecipient,
            userId: recipient.recipientId,
            questionId: recipient.securityQuestionId,
            question: recipient.securityQuestion,
            answer: recipient.securityAnswer,
        });
    });

    return toRecipients;
};

const convertRecipientCcContact = (messageModel: IReceivedMailMessage | ISentMailMessage, userProfile: any) => {
    const tempRecipients: any[] = messageModel.recipients;
    const ccRecipients = tempRecipients.filter(
        (recipient: IReceivedMessageRecipientModel | ISentMessageRecipientModel) => {
            return recipient.email !== userProfile.emailAddress;
        }
    );
    return ccRecipients.map((v: IReceivedMessageRecipientModel | ISentMessageRecipientModel) => ({
        firstName: v.firstName,
        lastName: v.lastName,
        emailAddress: v.email,
        recipientType: v.recipientType,
        userId: v.recipientId,
        questionId: messageModel.recipientSecurityQuestions.find(
            x => x.recipientEmail.toLowerCase() === v.email.toLowerCase()
        )?.securityQuestionId,
        question: messageModel.recipientSecurityQuestions.find(
            x => x.recipientEmail.toLowerCase() === v.email.toLowerCase()
        )?.question,
        answer: messageModel.recipientSecurityQuestions.find(
            x => x.recipientEmail.toLowerCase() === v.email.toLowerCase()
        )?.answer,
    }));
};

const convertToAttachments = (messageAttachments: IFileModel[]) => {
    const newFiles: IFileUploadModel[] = [];
    Array.from(messageAttachments).forEach((item: IFileModel) => {
        newFiles.push({
            file: new File([item.name], item.name),
            guid: item.fileGuid,
            isUploaded: true,
            uploadProgress: 0,
            fileSize: item.size,
            fileId: item.fileId,
        });
    });

    return newFiles;
};

const MessageDetail: React.FC<IMessageDetailProps> = props => {
    const [showComposeModal, setComposeModalVisibility] = useState(false);
    const [subject, setSubject] = useState("");
    const [ccRecipients, setCcRecipients] = useState<IRecipientModel[]>([]);
    const [toRecipients, setToRecipients] = useState<IRecipientModel[]>([]);
    const [title, setTitle] = useState("");
    const [attachments, setAttachments] = useState<IFileUploadModel[]>([]);
    const [showDelete, toggleShowDelete] = useState(false);
    const [showSentMessageDelete, toggleShowSentMessageDelete] = useState(false);
    const [messageAction, setMessageAction] = useState(MessageActions.Reply);
    const [showExpiry, toggleShowExpiry] = useState(false);
    const [showDateValidation, toggleDateValidation] = useState(false);
    const [expiryDate, setExpiryDate] = useState(getCurrentDate());
    const [isComponentMounted, setIsComponentMounted] = useState(false);
    const [reloadAction, setReloadAction] = useState("");
    const clearDatePicker = React.useRef();
    const dispatch = useDispatch();
    const { resourceLocators } = props;
    const userProfileState: IMyAccountUserProfile = useSelector((appState: ApplicationState) => appState.userProfile);
    const [showMessageActivityPopover, setShowMessageActivityPopover] = useState(false);
    const activityLogToggle = useRef(null);

    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);
    const showReplyForward =
        !mailboxUserData && props.folderType !== FolderType.SPAM && props.folderType !== FolderType.DRAFT;
    const showDownloadAttachmentButton = props.message.attachments && props.message.attachments.length > 0;
    const firstName =
        props.folderType === FolderType.DRAFT
            ? props.message.messageDetail.firstName
            : props.message.messageDetail?.senderFirstName;
    const lastName =
        props.folderType === FolderType.DRAFT
            ? props.message.messageDetail.lastName
            : props.message.messageDetail?.senderLastName;
    const emailAddress =
        props.folderType === FolderType.DRAFT
            ? props.message.messageDetail.emailAddress
            : props.message.messageDetail?.senderEmail;
    const displayDate =
        props.folderType === FolderType.DRAFT
            ? props.message.messageDetail.createdDate
            : props.message.messageDetail?.sentDate;
    const showExpiryOption = props.folderType !== FolderType.DRAFT;
    const { pagination, fetchFolderMessages } = props;
    const user = useSelector((state: ApplicationState) => state.userProfile);

    const mailboxUserEmailId = getMailboxUserEmailText();
    const recipientEmailId = mailboxUserEmailId ? mailboxUserEmailId : userProfileState.emailAddress;
    const recipientExpiryDays =
        props.folderType === FolderType.SENT
            ? props.message?.messageDetail?.expiresDays
            : props.message?.recipients?.find((x: any) => x.email === recipientEmailId)?.expiresDays;

    useEffect(() => {
        setIsComponentMounted(true);
    }, []);

    useEffect(() => {
        dispatch(fetchSentMessageDetailsHistory(props.messageId, MessageType.SENT));
    }, [props.messageId]);
    const sentDetails = useSelector((appState: ApplicationState) => appState.sentMessageState.mailData.activityLogData);
    useEffect(() => {
        if (showComposeModal) {
            switch (messageAction) {
                case MessageActions.Reply:
                    setSubject("RE: " + props.message.messageDetail?.subject);
                    setToRecipients(convertRecipientToContact(props.message));
                    setCcRecipients([]);
                    setTitle("Reply Message");
                    setAttachments([]);
                    break;
                case MessageActions.ReplyAll:
                    setSubject("RE: " + props.message.messageDetail?.subject);
                    setToRecipients(convertRecipientToContact(props.message));
                    setCcRecipients(convertRecipientCcContact(props.message, userProfileState));
                    setTitle("Reply Message");
                    setAttachments([]);
                    break;
                case MessageActions.Forward:
                    setSubject("FW: " + props.message.messageDetail?.subject);
                    setToRecipients([]);
                    setCcRecipients([]);
                    setTitle("Forward Message");
                    setAttachments(convertToAttachments(props.message.attachments));
                    break;
                case MessageActions.Draft:
                    setSubject(props.message.messageDetail?.subject);
                    setToRecipients(convertRecipientToContactForDraft(props.message));
                    setCcRecipients([]);
                    setTitle("Draft Message");
                    setAttachments(convertToAttachments(props.message.attachments));
                    break;
                default:
                    break;
            }
        }
    }, [showComposeModal]);

    useEffect(() => {
        if (isComponentMounted) {
            switch (reloadAction) {
                case DraftMessageConstants.SEND_MESSAGE:
                    setReloadAction("");
                    props.onSuccessCallback && props.onSuccessCallback(1, pagination.pageSize, "");
                    break;
                case DraftMessageConstants.SAVE_TO_DRAFT:
                    fetchFolderMessages && fetchFolderMessages(1, pagination.pageSize, "", props.message);
                    setReloadAction("");
                    break;
            }
            props.resetSearchAndSort && props.resetSearchAndSort();
        }
    }, [reloadAction]);

    const handleReplyForwardClick = (action: MessageActions) => {
        setMessageAction(action);
        setComposeModalVisibility(!showComposeModal);
    };

    const handleAttachmentClick = (
        item: IFileModel,
        senderEmail: string,
        receivedMessageGuid: string,
        messageSource: MessageSource
    ) => {
        props.downloadSingleAttachment &&
            props.downloadSingleAttachment(
                item.fileId!,
                item.fileGuid,
                props.messageId,
                item.name,
                senderEmail,
                receivedMessageGuid,
                messageSource
            );
    };

    const handleDownloadAllClick = () => {
        props.folderType !== FolderType.SPAM &&
            props.downloadAllAttachments &&
            props.downloadAllAttachments(
                props.message.attachments,
                props.messageId,
                props.message.messageDetail.senderEmail,
                props.message.messageDetail?.receivedMessageGuid,
                props.message.messageDetail?.messageSource
            );
    };

    const handleOnClickDelete = (e: any) => {
        e.stopPropagation();
        toggleShowDelete(true);
    };

    const handleOnClickEdit = () => {
        setMessageAction(MessageActions.Draft);
        setComposeModalVisibility(!showComposeModal);
    };

    const handleOnClickSentMessageDelete = (e: any) => {
        e.stopPropagation();
        if (mailboxUserData) {
            toggleShowDelete(true);
        } else {
            toggleShowSentMessageDelete(true);
        }
    };

    const handleOnHideDelete = () => {
        toggleShowDelete(false);
    };

    const handleOnHideSentMessageDelete = () => {
        toggleShowSentMessageDelete(false);
    };

    const handleOnSubmitDelete = () => {
        const callback = (result: any) => {
            if (result) {
                AppNotifier.Success(ToasterMessages.SUCCESS.DELETE_MESSAGES);
                toggleShowDelete(false);
                toggleShowSentMessageDelete(false);
                fetchFolderMessages && fetchFolderMessages(pagination.currentPage, pagination.pageSize, "");
                props.hideMessageDetailView && props.hideMessageDetailView(true);
                props.resetSearchAndSort && props.resetSearchAndSort();
            }
        };
        switch (props.folderType) {
            case FolderType.ARCHIVE:
                dispatch(
                    deleteSharedMessage(
                        [props.message.messageDetail as IReceivedMessageModel],
                        callback,
                        getPageTitleFromFolderType(props.folderType),
                        resourceLocators.MsgDtlBtnDelete
                    )
                );
                break;
            case FolderType.INBOX:
                dispatch(
                    deleteRecipientMessage(
                        [props.message.messageDetail as IReceivedMessageModel],
                        callback,
                        getPageTitleFromFolderType(props.folderType),
                        resourceLocators.MsgDtlBtnDelete
                    )
                );
                break;
            case FolderType.SPAM:
                dispatch(
                    deleteSpamMessage(
                        [props.message.messageDetail as IReceivedMessageModel],
                        callback,
                        resourceLocators.MsgDtlBtnDelete
                    )
                );
                break;
            case FolderType.SENT:
                dispatch(
                    deleteSentMessage(
                        [props.message.messageDetail as ISentMessageModel],
                        callback,
                        resourceLocators.MsgDtlBtnDelete
                    )
                );
                break;
            case FolderType.DRAFT:
                dispatch(deleteDraftMessage([props.messageId], callback, resourceLocators.MsgDtlBtnDelete));
                break;
        }
    };
    const changeExpiryDisabled =
        props.folderType === FolderType.ARCHIVE ||
        props.folderType === FolderType.SPAM ||
        props.folderType === FolderType.SENT;

    const handleOnClickChangeExpiry = (e: any) => {
        if (changeExpiryDisabled) return;

        e.stopPropagation();
        toggleShowExpiry(true);
    };

    const hidePopup = () => {
        setExpiryDate(getCurrentDate());
        toggleShowExpiry(false);
        toggleDateValidation(false);
    };

    const handleOnChangeExpiry = (e: any) => {
        const date = moment(e).toDate();
        const maxDate = new Date(
            getExpiryDateFromDays(props.message.messageDetail?.sentDate, props.message.messageDetail?.expiresDays)
        );
        if (date < getCurrentDate() || date > maxDate || date.toDateString() === "Invalid Date") {
            toggleDateValidation(true);
            setExpiryDate(getCurrentDate());
            clearDatePicker && clearDatePicker.current;
        } else {
            setExpiryDate(date);
            toggleDateValidation(false);
        }
    };

    const handleOnSubmitChangeExpiry = () => {
        if (showDateValidation) return;

        if (expiryDate.getTime() === getCurrentDate().getTime()) {
            AppNotifier.Warning(ToasterMessages.WARNING.REQUIRE_EXPIRY_DATE);
            return;
        }

        if (props.changeExpiry) {
            props.changeExpiry(expiryDate, props.message.messageDetail, (result: any) => {
                if (result) {
                    AppNotifier.Success(ToasterMessages.SUCCESS.CHANGE_EXPIRY);
                    toggleShowExpiry(false);
                    setExpiryDate(getCurrentDate());
                    props.getMessageDetail && props.getMessageDetail(props.message.messageDetail);
                    props.resetSearchAndSort && props.resetSearchAndSort();
                }
            });
        }
    };

    /**Change Expiry Popover */
    const popover = (
        <Popover id="changeExpiry">
            <Popover.Header as="h3">Change Expiry</Popover.Header>
            <Popover.Body>
                <CustomDatePicker
                    minDate={getCurrentDate()}
                    maxDate={
                        new Date(
                            getExpiryDateFromDays(
                                props.message.messageDetail?.sentDate,
                                props.message.messageDetail?.expiresDays
                            )
                        )
                    }
                    className="mb-3"
                    onChange={handleOnChangeExpiry}
                    clearDatePicker={clearDatePicker}
                    onSubmit={handleOnSubmitChangeExpiry}
                />
                {showDateValidation && <p className="text-danger">Please enter a valid date</p>}
                <div className="" >
                    <button className="button-grey" style={{marginRight: "8px"}}onClick={hidePopup}>
                        Cancel
                    </button>
                    <button className="button-primary-blue-2" onClick={handleOnSubmitChangeExpiry}>
                        Ok
                    </button>
                </div>
            </Popover.Body>
        </Popover>
    );
    const DetailsPopover = (
        <Popover id="popover-activity">
            <Popover.Body className="message-detail-activity-popover">
                {sentDetails &&
                    Array.isArray(sentDetails) &&
                    sentDetails?.map(activityLogData => (
                        <>
                            <div key={activityLogData.email} className="activity">
                                <div className="main">
                                    <div className="email" title={activityLogData.email}>
                                        {activityLogData.email || "-"}
                                    </div>
                                    <div className="date">{dataFormatter(activityLogData.accessDate) || "-"}</div>
                                </div>
                                <div className="files">
                                    {activityLogData.filesDownloaded} / {activityLogData.filesUploaded}
                                </div>
                            </div>
                        </>
                    ))}
            </Popover.Body>
        </Popover>
    );

    const enableEditPermission = (folder?: IFolderModel) => {
        if (!folder || isFolderOwner(folder, user) || folder.permissionGroupId == ShareFolderTypes.EDIT) {
            return true;
        } else if (folder.permissionGroupId == ShareFolderTypes.VIEW) {
            return false;
        }
    };
    const openPopover = () => {
        if (sentDetails.length !== 0) {
            setShowMessageActivityPopover(true);
        }
    };
    return (
        <>
            <div className="message-pane">
                <div className="message-pane-top">
                    <div className="message-pane-subject">{props.message.messageDetail?.subject || "(no subject)"}</div>

                    <ButtonGroup className="message-pane-action" aria-label="Message pane actions">

                        {
                            props.folderType !== FolderType.DRAFT && <Button
                                variant="link"
                                className="icon-wrapper"
                                title="Print Message"
                            >
                                <PrintComponent
                                    showPrintIcon
                                >
                                    <MessagePrint
                                        message={props.message}
                                        messageTypeText={getMessageTitleFromFolderType(props.folderType)}
                                    />
                                </PrintComponent>
                            </Button>
                        }
                        {!mailboxUserData && props.folderType === FolderType.DRAFT && (
                            <Button
                                variant="link"
                                className="icon-wrapper edit-button"
                                title="Edit"
                                onClick={handleOnClickEdit}
                            >
                                <EditIcon/>
                            </Button>
                        )}

                        {enableEditPermission(props.currentfolder) ? (
                            <span
                                className="icon-wrapper delete-icon-wrapper"
                                data-resource-id={resourceLocators.MsgDtlBtnDelete}
                                title="Delete"
                                onClick={
                                    props.folderType !== FolderType.SENT
                                        ? handleOnClickDelete
                                        : handleOnClickSentMessageDelete
                                }
                            >
                                <DeleteIcon />
                            </span>
                        ) : (
                            <span
                                className="icon-wrapper delete-icon-wrapper toolbar-icon-disabled "
                                data-resource-id={resourceLocators.MsgDtlBtnDelete}
                                title="Delete"
                            >
                                <DeleteIcon />
                            </span>
                        )}

                        {showReplyForward && (
                            <>
                                {enableEditPermission(props.currentfolder) ? (
                                    <Button
                                        variant="link"
                                        className="icon-wrapper"
                                        title="Reply"
                                        data-resource-id={resourceLocators.MsgDtlBtnReply}
                                        onClick={() => handleReplyForwardClick(MessageActions.Reply)}
                                    >
                                        <ReplyIcon/>
                                    </Button>
                                ) : (
                                    <Button
                                        variant="link"
                                        className="icon-wrapper"
                                        title="Reply"
                                        data-resource-id={resourceLocators.MsgDtlBtnReply}
                                        disabled={true}
                                    >
                                       <ReplyIcon/>
                                    </Button>
                                )}

                                {enableEditPermission(props.currentfolder) ? (
                                    <Button
                                        variant="link"
                                        className="icon-wrapper"
                                        title="Reply All"
                                        data-resource-id={resourceLocators.MsgDtlBtnReplyAll}
                                        onClick={() => handleReplyForwardClick(MessageActions.ReplyAll)}
                                    >
                                        <ReplyAllIcon/>
                                    </Button>
                                ) : (
                                    <Button
                                        variant="link"
                                        className="icon-wrapper"
                                        title="Reply All"
                                        data-resource-id={resourceLocators.MsgDtlBtnReplyAll}
                                        disabled={true}
                                    >
                                         <ReplyAllIcon/>
                                    </Button>
                                )}

                                {enableEditPermission(props.currentfolder) ? (
                                    <Button
                                        variant="link"
                                        className="icon-wrapper"
                                        title="Forward"
                                        data-resource-id={resourceLocators.MsgDtlBtnForward}
                                        onClick={() => handleReplyForwardClick(MessageActions.Forward)}
                                    >
                                        <ForwardIcon/>
                                    </Button>
                                ) : (
                                    <Button
                                        variant="link"
                                        className="icon-wrapper"
                                        title="Forward"
                                        data-resource-id={resourceLocators.MsgDtlBtnForward}
                                        disabled={true}
                                    >
                                         <ForwardIcon/>
                                    </Button>
                                )}
                            </>
                        )}

                        {showDownloadAttachmentButton && (
                            <span
                                className={`icon-wrapper download-button ${props.folderType === FolderType.SPAM ? "attachment-disabled toolbar-icon-disabled" : "pointer"
                                    }`}
                                data-resource-id={resourceLocators.MsgDtlBtnDownloadFiles}
                                onClick={() => props.folderType !== FolderType.SPAM && handleDownloadAllClick()}
                                title={MessageToolbarConstants.TITLE_DOWNLOAD_ALL}
                            >
                                <DownloadIcon  />
                                <span
                                    className={`attachment-count ${props.folderType === FolderType.SPAM ? "attachment-count-disabled" : ""
                                        }`}
                                >
                                    {props.message.attachments.length}
                                </span>
                            </span>
                        )}
                    </ButtonGroup>
                </div>
                <div className="message-pane-header">
                    <div className="row pos-relative">
                        <div>
                            <div className="message-pane-leftheader">
                                <div className="avatar-wrapper">
                                    <div className="avatar">{getImageInitials(firstName, lastName)}</div>
                                </div>
                                <div className="message-pane-metadata">
                                    <div
                                        className="message-pane-sender"
                                        title={`${firstName} ${lastName} <${emailAddress}>`}
                                    >
                                        {`${firstName} ${lastName}`} <span>&lt;{emailAddress}&gt;</span>
                                    </div>
                                    <div className="message-pane-sentdate">
                                        {getFormattedSentDateForDetailedView(displayDate)}
                                    </div>
                                    <div className="message-pane-recipient">
                                        <b>
                                            To
                                            <Overlay
                                                target={activityLogToggle.current}
                                                placement="bottom-start"
                                                show={showMessageActivityPopover}
                                                rootClose
                                                onHide={() => setShowMessageActivityPopover(false)}
                                            >
                                                {DetailsPopover}
                                            </Overlay>
                                            {props.folderType == FolderType.SENT ? (
                                                <span
                                                    className="fa fa-caret-down"
                                                    style={{ color: ColorConstants.grey, paddingLeft: "2px" }}
                                                    title="Download Info"
                                                    ref={activityLogToggle}
                                                    onClick={openPopover}
                                                ></span>
                                            ) : null}
                                            :
                                        </b>
                                        &nbsp;
                                        {props.message.recipients.map((item: any, i: number) => {
                                            return (
                                                <React.Fragment key={i}>
                                                    {`${item.firstName ?? ""} ${item.lastName ?? ""}`.trim()}
                                                    {(item.firstName || item.lastName) && ` <${item.email}>`}
                                                    {!(item.firstName || item.lastName) && ` ${item.email}`}
                                                    {i !== props.message.recipients.length - 1 && <span>, </span>}
                                                </React.Fragment>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showExpiryOption && (
                            <>
                                <div className="col-sm-3 text-end expiry-text-container">
                                    <div className="message-pane-expiredate">
                                        <OverlayTrigger
                                            show={showExpiry}
                                            trigger="click"
                                            placement="left"
                                            overlay={popover}
                                        >
                                            <span
                                                className={changeExpiryDisabled ? "cursor-default" : "cursor-pointer"}
                                                data-resource-id={resourceLocators.MsgDtlBtnChangeExpiry}
                                                title={
                                                    changeExpiryDisabled
                                                        ? ""
                                                        : MessageToolbarConstants.TITLE_CHANGE_EXPIRY
                                                }
                                                onClick={e => handleOnClickChangeExpiry(e)}
                                            >
                                                <span className="fas fa-exclamation-circle"></span>
                                                &nbsp; Expires on{" "}
                                                {getExpiryDateFromDays(
                                                    props.message.messageDetail?.sentDate,
                                                    recipientExpiryDays
                                                )}
                                            </span>
                                        </OverlayTrigger>
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    {props.message.messageDetail?.message && (
                        <div
                            className="message-pane-body"
                            style={{
                                borderBottomStyle: `${props.message.messageDetail.message.length > 250 &&
                                        props.message.attachments.length !== 0
                                        ? "solid"
                                        : "none"
                                    }`,
                                maxHeight: `${getDynamicMaxHeightForMessage(
                                    props.message.attachments.length,
                                    props.message.messageDetail.message.length,
                                    props.folderType
                                )}`,
                            }}
                        >
                            <div
                                dangerouslySetInnerHTML={{
                                    __html: convertTextToHtml(props.message.messageDetail?.message),
                                }}
                            ></div>
                        </div>
                    )}
                    <Row>
                        <Col sm={12}>
                            {props.message.attachments && props.message.attachments.length > 0 && (
                                <div
                                    className={`message-pane-attachment  ${props.folderType === FolderType.SPAM && "spam-disabled"
                                        }`}
                                >
                                    <div
                                        className="attachments"
                                        style={{
                                            maxHeight: `${getDynamicMaxHeightForAttachments(
                                                props.message.attachments.length,
                                                props.message.messageDetail.message.length
                                            )}`,
                                        }}
                                    >
                                        {props.message.attachments.map((item: IFileModel, i: number) => {
                                            return (
                                                <div
                                                    className={`attachment-card  ${props.folderType === FolderType.SPAM && "attachment-disabled"
                                                        }`}
                                                    data-resource-id={resourceLocators.MsgDtlBtnDownloadFile}
                                                    key={`file${i}`}
                                                    onClick={() =>
                                                        props.folderType !== FolderType.SPAM &&
                                                        handleAttachmentClick(
                                                            item,
                                                            props.message?.messageDetail?.senderEmail,
                                                            props.message?.messageDetail?.receivedMessageGuid,
                                                            props.message?.messageDetail?.messageSource
                                                        )
                                                    }
                                                    style={{ width: "24%" }}
                                                >
                                                    <div className="attachment-icon">
                                                        <FileFormatIcon
                                                            disabled={props.folderType === FolderType.SPAM}
                                                            extension={getFileExtension(item.name)}
                                                        ></FileFormatIcon>
                                                    </div>
                                                    <div
                                                        className="attachment-detail ellipsis"
                                                        title={item.name}
                                                        style={{ width: "80%" }}
                                                    >
                                                        {item.name}
                                                        <div>{formatBytes(item.size)}</div>
                                                    </div>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            )}
                        </Col>
                    </Row>


                </div>
            </div>

            <ComposeModal
                messageBody={props.message.messageDetail?.message}
                resourceLocators={resourceLocators}
                draftMessage={props.message}
                setReloadAction={setReloadAction}
                subject={subject}
                toRecipients={toRecipients}
                ccRecipients={ccRecipients}
                show={showComposeModal}
                title={title}
                onHide={() => setComposeModalVisibility(!showComposeModal)}
                messageAction={messageAction}
                attachments={attachments}
                onMessageForward={(message, handleSuccess, handleForwardMessageError) =>
                    dispatch(
                        forwardSentMessage(
                            message,
                            props.messageId,
                            props.folderType,
                            handleSuccess,
                            handleForwardMessageError,
                            resourceLocators?.MsgDtlBtnForward
                        )
                    )
                }
            />

            <CustomModal
                show={showDelete}
                title={MessageToolbarConstants.TITLE_DELETE_POPUP}
                cancelButtonName="Cancel"
                confirmButtonName={MessageToolbarConstants.TITLE_DELETE}
                onSubmit={handleOnSubmitDelete}
                onHide={handleOnHideDelete}
                className="delete-message-modal"
                isConfirmModal={true}
            >
                <span>{MessageToolbarConstants.DELETE_SINGLE_MESSAGE}</span>
                {props.folderType === FolderType.ARCHIVE && (
                    <div className="delete-warning">
                        <i className="fas fa-exclamation-triangle margin-right-10-px" />
                        <span>{MyDownloadsModalConstants.DELETE_WARNING}</span>
                    </div>
                )}
            </CustomModal>

            <CustomModal
                show={showSentMessageDelete}
                title={MessageToolbarConstants.TITLE_DELETE_POPUP}
                cancelButtonName="Cancel"
                confirmButtonName={MessageToolbarConstants.TITLE_DELETE}
                onSubmit={handleOnSubmitDelete}
                onHide={handleOnHideSentMessageDelete}
                className="delete-message-modal"
                isConfirmModal={true}
            >
                <span>{MessageToolbarConstants.DELETE_SINGLE_MESSAGE}</span>
            </CustomModal>
        </>
    );
};

export default MessageDetail;
