import React, { FC } from "react";
import { AuthenticationQuestions } from "./AuthenticationQuestions";
import MailboxDelegation from "./MailboxDelegation";
import MessageOptions from "./MessageOptions";
import MessageNotification from "./MessageNotifications";
import EmailMessageTemplate from "./EmailMessageTemplate";

interface IUserSettingsContentProps {
    eventKey: string;
}
const UserSettingsContent: FC<IUserSettingsContentProps> = ({ eventKey }) => {
    let content;
    switch (eventKey) {
        case "authentication_questions":
            content = <AuthenticationQuestions />;
            break;
        case "message_options":
            content = <MessageOptions />;
            break;
        case "mailbox_delegation":
            content = <MailboxDelegation />;
            break;
        case "notifications":
            content = <MessageNotification />;
            break;
        case "emailTemplates":
            content = <EmailMessageTemplate />;
                break;
        default:
            content = <></>;
    }
    return content;
};

export default UserSettingsContent;
