import React from "react";
import { IDocumentRequestListModel } from "../../../core/viewModels/documentRequest/DocumentRequestModel";
import { getFormattedSentDate } from "../../../helper/DateHelperFunctions";
import { enableEditPermission, getDocumentStatusButtonCaption, getDocumentStatusButtonClass } from "../../../helper/HelperFunctions";
import Checkbox, { CheckBoxSize } from "../../common/ui/Checkbox";
import { FileEarMarkArrowUp } from "../../svg/IconCollection";
import { IFolderModel } from "../../../core/viewModels/folder/FolderModel";
import { ApplicationState } from "../../../store";
import { useSelector } from "react-redux";

interface IDocumentRequestListItemProps {
    document: IDocumentRequestListModel;
    index: number;
    isChecked: boolean;
    folder?: IFolderModel;
    onClickDocumentItem: (document: IDocumentRequestListModel) => void;
    selectedDocumentId: number;
    onClickCheckBox: (document: IDocumentRequestListModel, isSelect: boolean) => void;
}

export const DocumentRequestListItem: React.FC<IDocumentRequestListItemProps> = ({
    document,
    index,
    isChecked,
    folder,
    onClickDocumentItem,
    onClickCheckBox,
    selectedDocumentId,
}) => {
    const user = useSelector((state: ApplicationState) => state.userProfile);
    const onClickDocumentItemHandler = (e: any) => {
        e.stopPropagation();
        onClickDocumentItem(document);
    };

    const onClickCheckBoxHandler = (e: any) => {
        e.stopPropagation();
        onClickCheckBox(document, e);
    };

    return (
        <div
            className={`message-item document-list-item ${
                selectedDocumentId === document.documentRequestId && "message-item-selected"
            }`}
            onClick={e => selectedDocumentId !== document.documentRequestId && onClickDocumentItemHandler(e)}
            key={index}
        >
            <div className="message-item-body">
                <div className="message-item-checkbox">
                    <Checkbox
                        id={`chkMessage_${document.documentRequestId}`}
                        text=""
                        size={CheckBoxSize.sm}
                        checked={isChecked}
                        onChange={onClickCheckBoxHandler}
                        onClick={e => e.stopPropagation()}
                        disabled={!enableEditPermission(user, folder)}
                    />
                </div>
                <div className="message-item-group">
                    <div className="message-item-header">
                        <span 
                        title={document.recipientEmail}
                        className="message-item-title overflowText">{document.recipientEmail}</span>
                        <span className="message-item-datetime">{getFormattedSentDate(document.sentDate)}</span>
                    </div>
                    <div className="message-item-footer">
                        <div>
                            <FileEarMarkArrowUp />
                            <span>{`${document.uploadedDocs}/${document.totalDocs}`}</span>
                        </div>
                        <button
                            className={`document-status-button ${getDocumentStatusButtonClass(
                                document.ListingStatus
                            )}`}
                        >
                            {getDocumentStatusButtonCaption(document.ListingStatus)}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};
