import React from "react";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { IModalProps } from "../../../core/viewModels/profile/ProfileViewModel";
import { WithLogout, WithLogoutProps } from "../../../oidcClient/withLogout";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";
import { UserManagementConstants } from "../../../helper/Constants";
import { CustomModal } from "../../common/ui/Modals/CustomModal";


interface LogoutState {
    saving: boolean;
}

export class Logout extends React.Component<IModalProps & WithLogoutProps, LogoutState> {
    constructor(props: any) {
        super(props);
        this.state = {
            saving: false
        };
    }
    private onLogout = (e: React.SyntheticEvent<EventTarget>) => {
        e.preventDefault();
        this.setState({
            saving: true
        });

        this.props.onLogout();
    };

    public render() {
        return <CustomModal
            show={this.props.showState}
            onHide={this.props.onHide}
            className="logout-modal"
            cancelButtonName="No, Discard"
            confirmButtonName="Yes, Log Out"
            isConfirmModal={true}
            title="Log Out"
            onSubmit={this.onLogout}
            isConfirmButtonDisabled={this.state.saving}
            cancelButtonAutomationId={AutomationIdConstants.logoutModal.LogoutCancelBtn}
            conformButtonAutomationId={AutomationIdConstants.logoutModal.LogoutSubmitBtn}>
            <LoadingOverlay className='full-height'>
                {UserManagementConstants.UserLogoutWarnigMessage}
                <Loader loading={this.state.saving} text="Log Out in progress..." />
            </LoadingOverlay>

        </CustomModal>;
    }
}
export default WithLogout(Logout);