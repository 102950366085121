import React from "react";
import { FolderType } from "../../../../core/common/enums";
import { IFolderModel } from "../../../../core/viewModels/folder/FolderModel";
import { IReceivedMessageModel } from "../../../../core/viewModels/message/ReceivedMessageModel";
import { ISentMessageModel } from "../../../../core/viewModels/message/SentMessageModel";
import TreeNode from "../TreeNode/TreeNode";

interface TreeProps {
  data: IFolderModel[],
  destinationFolder: IFolderModel | undefined,
  folderType: FolderType,
  selectedMessages?: IReceivedMessageModel[] | ISentMessageModel[],
  selectedFolders?: IFolderModel[],
  isMovingFolder: boolean,
  isDR : boolean,
  isExpanded: boolean,
  handleFolderSelection: (folder: IFolderModel) => void,
}

const Tree: React.FC<TreeProps> = (props) => {
  const { data = [], handleFolderSelection, destinationFolder, isExpanded, selectedMessages,
    folderType, selectedFolders } = props;


  return (
    <div className="d-tree">
      <ul className="d-flex d-tree-container flex-column">
        {data.map((tree: IFolderModel, index: number) => (
          <TreeNode
            key={tree.folderId}
            index={index}
            node={tree}
            isDR={props.isDR}
            folderType={folderType}
            isExpanded={isExpanded}
            destinationFolder={destinationFolder}
            selectedMessages={selectedMessages}
            selectedFolders={selectedFolders}
            handleFolderSelection={handleFolderSelection}
          />
        ))}
      </ul>
    </div>
  );
};

export default Tree;
