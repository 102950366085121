import { Action, Reducer } from "redux";
import produce from "immer";
import * as UnlockActions from "../actionCreators/unlockActionCreator";
import { actionTypes } from "../types/unlockTypes";
import { IOnehubLockedTPViewModel, ILockLinkModel } from "../core/viewModels/unlock/UnlockModel";


export interface IUnlockStore {
    isLoading: boolean;
    unlockLinks: ILockLinkModel[] | IOnehubLockedTPViewModel[];
}

const initialState: IUnlockStore = {
  isLoading: false,
  unlockLinks: []
};

export const reducer: Reducer<IUnlockStore> = produce(
  (draft: IUnlockStore = initialState, incomingAction: Action) => {
    const action = incomingAction as UnlockActions.DispatchActions;
    switch (action.type) {
      case actionTypes.REQUEST_UNLOCK_LINKS:
        draft.isLoading = true;
        return draft;
      case actionTypes.RECEIVE_UNLOCK_LINKS:
        draft.isLoading = false;
        draft.unlockLinks = action.payload;
        return draft;
      case actionTypes.COMPLETE_UNLOCK_LINKS:
        draft.isLoading = false;
        return draft;
      case actionTypes.UPDATE_LINK_LOCK:
        draft.isLoading = true;
        return draft;
      default:
        return draft;
    }
  }
);
