import React, { useEffect, useState } from "react";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ApplicationState } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { CompanySettingsName } from "../../../../../core/common/enums";
import CustomToggleSwitch from "../../../../common/ui/switch/CustomToggleSwitch";
import {
    addMessageMailTemplate,
    deleteMessageMailTemplate,
    getSavedMessageTemplates,
    updateMessageMailTemplate,
} from "../../../../../actionCreators/SavedMessageActionCreator";
import MessageListBox from "./parts/SavedMessageTemplateList";
import MessageBox from "./parts/SavedMessageDetailBox";
import {
    initialSavedMessageSettings,
    initialSavedMessageTemplate,
    ISavedMessageTemplateModel,
    ISavedMessageTemplateSettings,
} from "../../../../../core/viewModels/settings/SavedMessageTemplate";
import { TemplateSource, TemplateType } from "../../../../../core/common/enums";
import { AddSavedeMessagesModal } from "./AddSavedMessageModal";
import DeleteSavedMessageTemplate from "./parts/DeleteSavedMessageModal";
import {
    fetchSavedMessageMailTemplateSettings,
    updateAdminSettings,
} from "../../../../../actionCreators/adminSettingsActionCreators";
import { SavedMessageEmailTemplate } from "../../../../../helper/Constants";
import { CustomModal } from "../../../../common/ui/Modals/CustomModal";

export const MessageEmailTemplate: React.FC = () => {
    const adminSettingsStore = useSelector((state: ApplicationState) => state.adminSettingsState);
    const { savedMessageTemplateSettings, isLoading: isAdminLoading } = adminSettingsStore;
    const [savedMessageSettingSettingState, setSavedMessageSettingState] =
        useState<ISavedMessageTemplateSettings>(initialSavedMessageSettings);
    const [isUpdateCompanySettings, setIsUpdateCompanySettings] = useState(false);
    const { data, isLoading } = useSelector((state: ApplicationState) => state.SavedMessageTemplateState);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState<ISavedMessageTemplateModel>(initialSavedMessageTemplate);
    const [filteredData, setFilteredData] = useState<ISavedMessageTemplateModel[]>([]);
    const [selectedTemplateId, setSelectedTemplateId] = useState<number>();
    const [defaultMessage ,setDefaultMessage] = useState<ISavedMessageTemplateModel>(initialSavedMessageTemplate);
    const [isTemplateEdited , setTemplateEdited] = useState(false);
    const [showDefaultMethodWarningModal, setDefaultMethodWarningModal] = useState(false);
    const dispatch = useDispatch();
    const isBaseTemplateEditable = filteredData.find(x => x.templateName === "BaseTemplate")?.savedMessageTemplateId!==defaultMessage.savedMessageTemplateId;
    useEffect(() => {
        dispatch(fetchSavedMessageMailTemplateSettings());
        dispatch(getSavedMessageTemplates(TemplateSource.AdminCreated));
        setSelectedMessage(defaultMessage);
    }, []);
   
    useEffect(() => {
        const filtered = data.filter(
            item => item.templateSource === TemplateSource.AdminCreated || item.isSystemDefault
        );
        const defMessage =
            data.find(x => x.savedMessageTemplateId === savedMessageSettingSettingState.savedMessageMailTemplateId) ||
            initialSavedMessageTemplate;
        const topItemIndex = filtered.findIndex(
            item => item.savedMessageTemplateId === defMessage.savedMessageTemplateId
        );
        if (topItemIndex > 0) {
            const [topItem] = filtered.splice(topItemIndex, 1);
            filtered.unshift(topItem);
        }
        setFilteredData(filtered);
    }, [data]);

    useEffect(() => {
        if (selectedMessage.templateName == "" && filteredData.length > 0) {
            setSelectedMessage(filteredData[0]);
        }
        if (isTemplateEdited) {
            const editedData =
                filteredData.find(x => x.savedMessageTemplateId === selectedMessage.savedMessageTemplateId) ||
                initialSavedMessageTemplate;
            setTemplateEdited(false);
            setSelectedMessage(editedData);
        } else {
            const filtered =
                filteredData.find(
                    x => x.savedMessageTemplateId === savedMessageSettingSettingState.savedMessageMailTemplateId
                ) || initialSavedMessageTemplate;
            setDefaultMessage(filtered);
        }
    }, [filteredData]);

    useEffect(() => {
        setSelectedMessage(defaultMessage);
    },[defaultMessage]);

    useEffect(() => {
        if (savedMessageTemplateSettings) {
            setSavedMessageSettingState({
                ...savedMessageSettingSettingState,
                savedMessageMailTemplateId: savedMessageTemplateSettings.savedMessageMailTemplateId,
                isUserAllowedToAddSecureMailTemplate: savedMessageTemplateSettings.isUserAllowedToAddSecureMailTemplate,
                isUserAllowedToChangeSecureMailTemplate:
                    savedMessageTemplateSettings.isUserAllowedToChangeSecureMailTemplate,
            });
        }
    }, [savedMessageTemplateSettings]);

    const handleToggleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        const value = e.target.checked;
        if(name === SavedMessageEmailTemplate.ALLOW_TO_SET_DEFAULT_Template && !value){
            setDefaultMethodWarningModal(true);
            return;
        }
        setSavedMessageSettingState(prevPermissions => ({
            ...prevPermissions,
            [name]: value,
        }));
        setIsUpdateCompanySettings(true);
    };
     
    const updateSavedMessageSettingState = () => {
        setSavedMessageSettingState(prevPermissions => ({
            ...prevPermissions,
            isUserAllowedToChangeSecureMailTemplate: false,
            isUserAllowedToAddSecureMailTemplate: false,
        }));
        setDefaultMethodWarningModal(false);
        setIsUpdateCompanySettings(true);
    };

    useEffect(() => {
        if (isUpdateCompanySettings) {
            const updateSettingsPayload = {
                savedMessageMailTemplateId: savedMessageSettingSettingState.savedMessageMailTemplateId,
                isUserAllowedToAddSecureMailTemplate:
                    savedMessageSettingSettingState.isUserAllowedToAddSecureMailTemplate,
                isUserAllowedToChangeSecureMailTemplate:
                    savedMessageSettingSettingState.isUserAllowedToChangeSecureMailTemplate,
            };
            dispatch(
                updateAdminSettings(
                    CompanySettingsName.SavedMessageTemplateSettings,
                    {
                        ...updateSettingsPayload,
                    },
                    () => {
                        dispatch(fetchSavedMessageMailTemplateSettings());
                        dispatch(getSavedMessageTemplates(TemplateSource.AdminCreated));
                        setIsUpdateCompanySettings(false);
                    }
                )
            );
        }
    }, [isUpdateCompanySettings]);

    const OnSuccessfullTemplateAction = (data?: number, isDefault?: boolean, defaultTemplateReverted?: boolean) => {
        if (data && isDefault) {
            setSavedMessageSettingState({
                ...savedMessageSettingSettingState,
                savedMessageMailTemplateId: data,
            });
            setIsUpdateCompanySettings(true);
        } 
        else if (data && defaultTemplateReverted) {
            const baseTemplateId = filteredData.find(x => x.templateName === "BaseTemplate")?.savedMessageTemplateId;
            const updateId = defaultTemplateReverted === true ? baseTemplateId : data;
            setSavedMessageSettingState({
                ...savedMessageSettingSettingState,
                savedMessageMailTemplateId: updateId || data,
            });
            setIsUpdateCompanySettings(true);
        }
        setTemplateEdited(true);
        dispatch(getSavedMessageTemplates(TemplateSource.AdminCreated));  
        setShowEditModal(false);
    };

    const OnSuccessfullTemplateCreateAction = (data?: number, isDefault?: boolean) => {
        if (data && isDefault) {
            setSavedMessageSettingState({
                ...savedMessageSettingSettingState,
                savedMessageMailTemplateId: data,
            });
            setIsUpdateCompanySettings(true);
        }
        dispatch(getSavedMessageTemplates(TemplateSource.AdminCreated));
        setShowAddModal(false);
    };

   const OnSuccessfullTemplateDeleteion =()=>{
    dispatch(getSavedMessageTemplates(TemplateSource.AdminCreated));
   };
    const handleDataSubmit = (
        templateData: ISavedMessageTemplateModel,
        isUpdate: boolean,
        isDefault?: boolean,
        defaultTemplateReverted?: boolean
    ) => {
        const CompleteTemplateData: ISavedMessageTemplateModel = {
            ...templateData,
            templateType: TemplateType.SecureMail,
            templateSource: TemplateSource.AdminCreated,
        };
        if (isUpdate) {
            dispatch(
                updateMessageMailTemplate(templateData, OnSuccessfullTemplateAction, isDefault, defaultTemplateReverted)
            );
        } else {
            dispatch(addMessageMailTemplate(CompleteTemplateData, OnSuccessfullTemplateCreateAction, isDefault));
        }
    };

    const handleTemplateDeleteClick = (id: number) => {
        setShowDeleteModal(true);
        setSelectedTemplateId(id);
    };
    const handleOnDeleteSubmit = () => {
        selectedTemplateId && dispatch(deleteMessageMailTemplate(selectedTemplateId, OnSuccessfullTemplateDeleteion));
        setShowDeleteModal(false);
        setSelectedMessage(initialSavedMessageTemplate);
    };
    const onSelectMessage = (index: number, data: ISavedMessageTemplateModel) => {
        setSelectedMessage(data);
    };

    return (
        <LoadingOverlay>
            <div className="savedMessage sm-admin">
                <header>
                    <span>{SavedMessageEmailTemplate.MESSAGE_EMAILTEMPLATE_HELPER_TEXT}</span>
                </header>
                <h3 className="settings-title">{SavedMessageEmailTemplate.MESSAGE_EMAILTEMPLATE_HEADER}</h3>
                <section className="margin-top-10-px">
                    <div className="switch-wrapper">
                        <CustomToggleSwitch
                            name={SavedMessageEmailTemplate.ALLOW_TO_ADD_NEW_Template}
                            handleChange={handleToggleSwitchChange}
                            switched={savedMessageSettingSettingState.isUserAllowedToAddSecureMailTemplate}
                            label={SavedMessageEmailTemplate.ALLOW_TO_ADD_NEW_Template_TEXT}
                        />
                    </div>

                    <div className="switch-wrapper">
                        <CustomToggleSwitch
                            name={SavedMessageEmailTemplate.ALLOW_TO_SET_DEFAULT_Template}
                            handleChange={handleToggleSwitchChange}
                            switched={savedMessageSettingSettingState.isUserAllowedToChangeSecureMailTemplate}
                            label={SavedMessageEmailTemplate.ALLOW_TO_CHANGE_DEFAULT_Template_TEXT}
                        />
                    </div>
                </section>

                <div className="saved-message-container">
                    <MessageListBox
                        SavedMessageTemplatedata={filteredData}
                        selectedMessage={selectedMessage}
                        onSelectMessage={onSelectMessage}
                        onAddMessageTemplate={() => setShowAddModal(true)}
                        defaultTemplate={defaultMessage}
                    />
                    <MessageBox
                        selectedMessage={selectedMessage || defaultMessage}
                        onDelete={handleTemplateDeleteClick}
                        onEdit={() => {
                            setShowEditModal(true);
                        }}
                        SavedMessageTemplatedata={filteredData}
                        defaultTemplate={defaultMessage}
                        templateSouceValue={TemplateSource.AdminCreated}
                        isBaseTemplateEditable={isBaseTemplateEditable}
                    />
                </div>
                {showAddModal && (
                    <AddSavedeMessagesModal
                        templateTypeValue={TemplateType.SecureMail}
                        show={showAddModal}
                        onHide={() => setShowAddModal(false)}
                        isEdit={false}
                        handleSubmit={handleDataSubmit}
                        defaultTemplate={defaultMessage}
                    />
                )}

                {showEditModal && (
                    <AddSavedeMessagesModal
                        templateTypeValue={TemplateType.SecureMail}
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        isEdit={true}
                        handleSubmit={handleDataSubmit}
                        data={selectedMessage}
                        defaultTemplate={defaultMessage}
                    />
                )}
                {showDeleteModal && selectedMessage && (
                    <DeleteSavedMessageTemplate
                        show={showDeleteModal}
                        onClose={() => {
                            setShowDeleteModal(false);
                        }}
                        handleSubmit={handleOnDeleteSubmit}
                    />
                )}
                {showDefaultMethodWarningModal && (
                    <CustomModal
                        show={showDefaultMethodWarningModal}
                        cancelButtonName="No"
                        confirmButtonName="Yes"
                        title={"Confirm Selection"}
                        isConfirmModal={true}
                        className="confirmation-modal"
                        onSubmit={updateSavedMessageSettingState}
                        onHide={() => setDefaultMethodWarningModal(false)}
                    >
                        {SavedMessageEmailTemplate.CHANGE_DEFAULT_TEMPLATE_METHOD_WARNING_TEXT}
                    </CustomModal>
                )}
                <Loader loading={isLoading || isAdminLoading} />
            </div>
        </LoadingOverlay>
    );
};
