import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch, useSelector } from "react-redux";
const _ = require("lodash");

import { addUpdateTemplate, editTemplate } from "../../../../../actionCreators/templateActionCreators";
import { getDocumentCategoriesDropdown } from "../../../../../actionCreators/documentCategoryActionCreator";
import { ColorConstants } from "../../../../../assets/custom/colors";
import {
    IDocumentRequestTemplate,
    IDocumentRequestTemplateFile,
    IDocumentRequestTemplateModel,
    initialTemplateFormState,
} from "../../../../../core/viewModels/template/TemplateModel";
import { AppNotifier } from "../../../../../helper/AppNotifier";
import {
    DateFormatConstants,
    NewDRConstants,
    TemplateListingConstants,
    TemplateValidationConstants,
} from "../../../../../helper/Constants";
import { getTaxYears, isDateEqual } from "../../../../../helper/DateHelperFunctions";
import { ApplicationState } from "../../../../../store";
import { PlusCircleIcon } from "../../../../svg/IconCollection";
import CustomDatePicker from "../../CustomDatePicker";
import TinyMCEComponent from "../../tinymce/TinyMCE";

import { CustomModal } from "../CustomModal";
import { AddFilesRow } from "./AddFilesRow";
import { ToasterMessages } from "../../../../../helper/ToasterMessages";

interface IAddTemplateModalProps {
    isEditModal: boolean;
    showModal: boolean;
    isLoading: boolean;
    hideModal: () => void;
    initialModalData: IDocumentRequestTemplateModel;
    reloadTemplateData: () => void;
}

export const AddTemplateModal: React.FC<IAddTemplateModalProps> = ({
    isEditModal,
    showModal,
    hideModal,
    initialModalData,
    reloadTemplateData,
    isLoading,
}) => {
    const { documentRequestTemplate: intialTemplateData, files: intialFilesData } = initialModalData;
    const [templateData, setTemplateData] = useState<IDocumentRequestTemplate>(intialTemplateData);
    const [filesData, setFilesData] = useState<IDocumentRequestTemplateFile[]>(intialFilesData);
    const [validated, setValidated] = useState<boolean>(false);
    const [isDueDateInvalid, setIsDueDateInvalid] = useState<boolean>(false);
    const { userId } = useSelector((state: ApplicationState) => state.userProfile);
    const clearDatePicker = useRef(null);
    const ref = useRef<HTMLFormElement>(null);
    const dispatch = useDispatch();

    useEffect(() => {
        setTemplateData(intialTemplateData);
        if (!intialFilesData) setFilesData(initialTemplateFormState.files);
        else setFilesData(intialFilesData);
        if (intialTemplateData.documentRequestTemplateId !== 0) {
            if (intialTemplateData.dueDate) {
                if (!checkIsDueDateValid(intialTemplateData.dueDate)) {
                    setIsDueDateInvalid(true);
                    clearDatePicker && clearDatePicker.current;
                }
            }
        }
        dispatch(getDocumentCategoriesDropdown());
    }, [intialTemplateData.name, intialFilesData]);

    const onFormChange: React.ChangeEventHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        const templateDataCopy = { ...templateData, [id]: value };
        setTemplateData(templateDataCopy);
    };

    const handleFilesFormChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { id, value } = e.target;

        const filesDataCopy: IDocumentRequestTemplateFile[] = _.cloneDeep(filesData);
        filesDataCopy[index][id as keyof IDocumentRequestTemplateFile] = value as any;
        setFilesData(filesDataCopy);
    };

    const handleCategoryChange = (value: any, index: number) => {
        const filesDataCopy: IDocumentRequestTemplateFile[] = _.cloneDeep(filesData);
        filesDataCopy[index].documentCategory = value as any;
        setFilesData(filesDataCopy);
    };

    const checkIsDueDateValid = (date: Date | undefined) => {
        date = moment(date).toDate();
        const maxDate = moment().add(NewDRConstants.MAX_DUE_DATE, "year").toDate();
        const currentDate = new Date();
        return (date < maxDate && date > currentDate) || isDateEqual(date, currentDate);
    };

    const handleDueDateChange = (date: Date | undefined) => {
        date = moment(date).toDate();

        if (!checkIsDueDateValid(date)) {
            setIsDueDateInvalid(true);
            clearDatePicker && clearDatePicker.current;
        } else {
            setIsDueDateInvalid(false);
            const data = { ...templateData, dueDate: date };
            setTemplateData(data);
        }
    };

    const handleOnSubmit = () => {
        const form = ref.current;
        const filespayload = filesData.map(data => {
            return {
                name: data.name.trim(),
                description: data.description.trim(),
                documentCategory: data.documentCategory,
            };
        });
        if (!!form && form.checkValidity() && !isDueDateInvalid) {
            const payload = {
                documentRequestTemplate: { ...templateData, createdBy: userId, name: templateData.name.trim() },
                files: filespayload,
            };
            setValidated(false);
            if (!isEditModal) addModalOnSubmit(payload);
            else editModalOnSubmit(payload);
        }

        setValidated(true);
    };

    const onClickRemoveRow = (index: number) => {
        const filesDataCopy = [...filesData];
        filesDataCopy.splice(index, 1);
        setFilesData(filesDataCopy);
    };

    const onClickAddFiles = () => {
        const filesDataCopy = [...filesData];
        filesDataCopy.push({
            name: "",
            description: "",
        });
        setFilesData(filesDataCopy);
    };

    const hideModalHandler = () => {
        hideModal();
        setTemplateData(initialTemplateFormState.documentRequestTemplate);
        setFilesData(initialTemplateFormState.files);
        setValidated(false);
        setIsDueDateInvalid(false);
    };

    const addModalOnSubmit = (data: IDocumentRequestTemplateModel) => {
        dispatch(addUpdateTemplate(data, handleAddSuccessCallback));
    };

    const editModalOnSubmit = (data: IDocumentRequestTemplateModel) => {
        dispatch(editTemplate(data, handleEditSuccessCallback));
    };

    const handleDescriptionChange = (description: string): void => {
        setTemplateData({ ...templateData, description });
    };

    const handleEditSuccessCallback = () => {
        AppNotifier.Success(ToasterMessages.SUCCESS.EDIT_TEMPLATE);
        hideModalHandler();
        reloadTemplateData();
    };

    const handleAddSuccessCallback = () => {
        hideModalHandler();
        reloadTemplateData();
    };

    const onClearDueDate = () => {
        clearDatePicker && clearDatePicker.current;
        setTemplateData({ ...templateData, dueDate: null });
        setIsDueDateInvalid(false);
    };

    const getDisplayDueDate = (): string => {
        let displayDate = moment(templateData.dueDate).utc().format(DateFormatConstants.DEFAULT_DATE_FORMAT);
        if (!(templateData.dueDate instanceof Date)) {
            displayDate = moment(templateData.dueDate!.toString()).format(DateFormatConstants.DEFAULT_DATE_FORMAT);
        }
        return displayDate;
    };

    return (
        <CustomModal
            show={showModal}
            onHide={hideModalHandler}
            cancelButtonName="Cancel"
            confirmButtonName={isEditModal ? "Save" : "Create"}
            onSubmit={handleOnSubmit}
            title={
                !isEditModal
                    ? TemplateListingConstants.ADD_TEMPLATE_MODAL_TITLE
                    : TemplateListingConstants.EDIT_TEMPLATE_MODAL_TITLE
            }
            className="settings-modal request-template-modal"
        >
            <LoadingOverlay>
                <Form
                    ref={ref}
                    className="settings-form template-form"
                    noValidate
                    validated={validated}
                    onSubmit={e => {
                        e.preventDefault();
                    }}
                >
                    <Row>
                        <Form.Group controlId="name">
                            <Form.Label>{TemplateListingConstants.MODAL_NAME_INPUT_LABEL}</Form.Label>
                            <Form.Control
                                onChange={onFormChange}
                                value={templateData.name}
                                className="add-question-input"
                                type="text"
                                required
                                placeholder={TemplateListingConstants.MODAL_NAME_INPUT_PLACEHOLDER}
                                maxLength={TemplateListingConstants.TEMPLATE_NAME_MAX_LENGTH}
                            />
                            <Form.Control.Feedback type="invalid">
                                {TemplateValidationConstants.NAME_REQUIRED_TEXT}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group className="input-with-editor">
                            <Form.Label>{TemplateListingConstants.MODAL_DESCRIPTION_INPUT_LABEL}</Form.Label>
                            <div className="editor-wrapper">
                                <TinyMCEComponent
                                    messageBody={templateData.description}
                                    changeStateTinymceBody={e => handleDescriptionChange(e)}
                                    height={120}
                                />
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} xs={3} controlId="year">
                            <Form.Label>{TemplateListingConstants.MODAL_YEAR_INPUT_LABEL}</Form.Label>
                            <Form.Select
                                onChange={onFormChange}
                                value={templateData.year}
                                placeholder={TemplateListingConstants.MODAL_YEAR_INPUT_PLACEHOLDER}
                                required
                            >
                                {getTaxYears().map((year, index) => (
                                    <option key={index}>{year}</option>
                                ))}
                            </Form.Select>
                            <Form.Control.Feedback type="invalid">
                                {TemplateValidationConstants.YEAR_REQUIRED_TEXT}
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group as={Col} xs={4} controlId="dueDate">
                            <Row>
                                <Col>
                                    <Form.Label>{TemplateListingConstants.MODAL_DUE_DATE_INPUT_LABEL}</Form.Label>
                                </Col>
                            </Row>

                            <CustomDatePicker
                                value={templateData?.dueDate ? getDisplayDueDate() : undefined}
                                className={isDueDateInvalid ? "warning-highlight" : ""}
                                minDate={new Date()}
                                clearDatePicker={clearDatePicker}
                                onChange={handleDueDateChange}
                                maxDate={moment().add(NewDRConstants.MAX_DUE_DATE, "year").toDate()}
                                clearFilter={onClearDueDate}
                                onSubmit={() => {
                                    //
                                }}
                            />
                            {isDueDateInvalid && <p className="text-danger">Please enter a valid date</p>}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Label>{TemplateListingConstants.MODAL_FILES_REQUEST_INPUT_LABEL}</Form.Label>
                        <div tabIndex={0} className="add-files-btn" onClick={onClickAddFiles}>
                            <PlusCircleIcon color={ColorConstants.brand_pimary_blue_1} />
                            <p className="margin-0-px">{NewDRConstants.ADD_FILES_TEXT}</p>
                        </div>
                        <div className="add-files-wrapper">
                            {filesData &&
                                filesData.length > 0 &&
                                filesData.map((file, fileIndex, files) => {
                                    return (
                                        <AddFilesRow
                                            key={fileIndex}
                                            name={file.name}
                                            description={file.description}
                                            documentCategory={file.documentCategory || null}
                                            fileIndex={fileIndex}
                                            onClickRemoveRow={onClickRemoveRow}
                                            filesLength={files.length}
                                            onFilesFormChange={handleFilesFormChange}
                                            onCategoryChange={handleCategoryChange}
                                        />
                                    );
                                })}
                        </div>
                    </Row>
                </Form>
                <Loader loading={isLoading} text="" />
            </LoadingOverlay>
        </CustomModal>
    );
};
