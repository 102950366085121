import { AppThunkAction } from "../store";
import { actionTypes } from "../types/commonTypes";
import { actionTypes as notifactionAction } from "../types/notificationTypes";
import { DispatchActions } from "../actions/commonActions";
import {
  NotificationAction,
  StatusType,
} from "../reducers/notificationReducer";

import axios, { AxiosResponse } from "axios";
import { IDelegateeUser, IReportProblemModel, IUserLoginHistoryResponse } from "../core/viewModels/user/UserViewModel";
import { AppNotifier } from "../helper/AppNotifier";
import { getMailboxUserDataFromLocal } from "../helper/HelperFunctions";
import { appInsightsData } from "../helper/AppInsightsData";
import { logger } from "../oidcClient/authProvider";
import { ToasterMessages } from "../helper/ToasterMessages";

export type KnownActions = DispatchActions | NotificationAction;

export const requestForceUpdate =
  (): AppThunkAction<KnownActions> => (dispatch) => {
    dispatch({
      type: actionTypes.REQUEST_FORCE_UPDATE,
    });
  };

export const requestHelpCenterUrl =
  (): AppThunkAction<KnownActions> => (dispatch) => {
    dispatch({ type: actionTypes.REQUEST_FORCE_UPDATE });

    axios.get(`${process.env.REACT_APP_API_URL}/api/Support/GetHelpCenterUrl`)
      .then((response: AxiosResponse<string>) => {
        const { data } = response;
        dispatch({ type: actionTypes.RECEIVE_HELP_CENTER_URL, payload: data });
      }).catch((error: Error) => {
        dispatch({
          type: notifactionAction.NOTIFICATION,
          statusMessage: error.message,
          statusType: StatusType.Error,
        });
        dispatch({ type: actionTypes.COMPLETE_HELP_CENTER_URL });
      });
  };

export const fetchUploadReportAttachmentSasLink =
  (
    fileName: string,
    callback?: (data: { sas: string }) => void
  ): AppThunkAction<KnownActions> =>
    (dispatch) => {
      axios
        .get(
          `${process.env.REACT_APP_API_URL}/api/Support/GetUploadAttachmentsLink?fileName=${fileName}`
        )
        .then((response: AxiosResponse<{ sas: string }>) => {
          const { data } = response;
          callback && callback(data);
        })
        .catch(function (error: Error) {
          dispatch({
            type: notifactionAction.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        });
    };

export const submitProblem =
  (payload: IReportProblemModel):
    AppThunkAction<KnownActions> =>
    (dispatch) => {
      logger.trackEvent({
        name: `${appInsightsData.ReportProblem.PageTitle} - ${appInsightsData.ReportProblem.Operation.SubmitButtonClick}`,
      properties: {
        page: appInsightsData.ReportProblem.PageTitle,
        ...payload
      }});
      axios.post(`${process.env.REACT_APP_API_URL}/api/Support/SaveReportedProblem`, payload)
        .then((response: AxiosResponse<string>) => {
          const { data } = response;
          dispatch({
            type: actionTypes.COMPLETE_SUBMIT_PROBLEM
          });
          AppNotifier.Success(`${ToasterMessages.SUCCESS.REPORT_A_PROBLEM} ${data}.`);
        })
        .catch(function (error: Error) {
          dispatch({
            type: notifactionAction.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        });
    };

export const saveMailboxUserToStore =
  (): AppThunkAction<KnownActions> => (dispatch) => {
    const dataFromLocal = getMailboxUserDataFromLocal();
    if (dataFromLocal) {
      dispatch({
        type: actionTypes.RECEIVE_OTHER_USER_DATA,
        payload: dataFromLocal
      });
    }

  };

export const fetchMailboxUserList =
  (): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_OTHERS_INBOX_LIST
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/User/AllowedMailboxUsers`)
        .then((response: AxiosResponse<IDelegateeUser[]>) => {
          const { data } = response;
          dispatch({
            type: actionTypes.RECEIVE_OTHERS_INBOX_LIST,
            payload: data,
          });
        })
        .catch(function (error: Error) {
          dispatch({
            type: notifactionAction.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });

        }).finally(() => {
          dispatch({
            type: actionTypes.COMPLETE_OTHERS_INBOX_LIST,
          });
        });
    };

export const fetchLoginHistory =
  (pageNumber: number, pageSize: number): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: actionTypes.REQUEST_LOGIN_HISTORY
      });
      axios
        .get(`${process.env.REACT_APP_API_URL}/api/LoginHistory/${pageNumber}/${pageSize}`)
        .then((response: AxiosResponse<IUserLoginHistoryResponse>) => {
          const { data } = response;
          dispatch({
            type: actionTypes.RECEIVE_LOGIN_HISTORY,
            payload: data
          });
        })
        .catch(function (error: Error) {
          dispatch({
            type: notifactionAction.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        }).finally(() => {
          dispatch({
            type: actionTypes.COMPLETE_LOGIN_HISTORY,
          });
        });
    };