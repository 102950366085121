import React from "react";
import { IDocumentRequestTemplate } from "../../../../../core/viewModels/template/TemplateModel";
import { DeleteIcon, EditIcon, CopyIcon } from "../../../../svg/IconCollection";

interface ITemplateInlineActionsProps {
    selectedTemplate: IDocumentRequestTemplate;
    handleOnDelete: (selectedTemplate: IDocumentRequestTemplate[]) => void;
    handleOnEditTemplate: (selectedTemplate: IDocumentRequestTemplate) => void;
    handleOnDuplicateTemplate: (selectedTemplate: IDocumentRequestTemplate) => void;
}

export const TemplateInlineActions: React.FC<ITemplateInlineActionsProps> = ({
    selectedTemplate,
    handleOnDelete,
    handleOnEditTemplate,
    handleOnDuplicateTemplate
}) => {
    return (
        <div className="inline-actions-bar-container">
            <div className="folder-toolbar__action">
                <div className={"pointer"} title={"Edit"} onClick={() => handleOnEditTemplate(selectedTemplate)}>
                    <EditIcon width={16} height={16} disabled={false} />
                </div>
            </div>

            <div className="folder-toolbar__action">
                <span onClick={() => handleOnDelete([selectedTemplate])} title={"Delete"} className="pointer">
                    <DeleteIcon width={16} height={16} disabled={false} />
                </span>
            </div>

            <div className="folder-toolbar__action">
                <span title={"Duplicate"} className="pointer" onClick={()=>handleOnDuplicateTemplate(selectedTemplate)}>
                    <CopyIcon disabled={false} />
                </span>
            </div>
        </div>
    );
};
