import React, { useEffect, useState } from "react";
import { debounce } from "throttle-debounce";
import { CustomModal } from "../../common/ui/Modals/CustomModal";
import { creatableStyle } from "../../common/compose/parts/Recipient";
import {
    IFolderPermissionViewModel,
    ISharedFolderGroupPermissionDetailViewModel,
    ISharedFolderGroupPermissionViewModel,
    ISharedFolderPermissionDetailViewModel,
    ISharedFolderUserModel,
} from "../../../core/viewModels/folder/FolderModel";
import { createOption, getShareFolderOptions } from "../../../helper/HelperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { ShareFolderTypes, ShareFolderUserType } from "../../../core/common/enums";
import { AppNotifier } from "../../../helper/AppNotifier";
import { ArchiveConstants } from "../../../helper/Constants";
import { IFolderModel, ISharedFolderPermissionViewModel } from "../../../core/viewModels/folder/FolderModel";
import {
    fetchSharedFolderUsers,
    removeSharedFolderUsers,
    shareFolderToUsers,
    updateSharedFolderUsers,
    fetchShareFolderRecipients,
} from "../../../actionCreators/folderActionCreator";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import Select from "react-select";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";
import { ToasterMessages } from "../../../helper/ToasterMessages";

interface IShareFolderModalProps {
    show: boolean;
    onHide: () => void;
    onSubmit: () => void;
    folder?: IFolderModel;
}
export const ShareFolderModal: React.FC<IShareFolderModalProps> = ({ show, onHide, onSubmit, folder }) => {
    const dispatch = useDispatch();
    const [selectedUsers, setSelectedUsers] = useState<ISharedFolderUserModel[]>([]);
    const foldersData = useSelector((state: ApplicationState) => state.folderState.foldersData);
    const sharedFolderData = foldersData?.sharedFolderData;
    const [sharedFolderUsers, setSharedFolderUsers] = useState<ISharedFolderPermissionDetailViewModel[]>([]);
    const [sharedFolderGroups, setSharedFolderGroups] = useState<ISharedFolderGroupPermissionDetailViewModel[]>([]);
    const [topLevelDropDownPermission, setTopLevelDropDownPermission] = useState(ShareFolderTypes.VIEW.toString());
    const [usersData, setUsersData] = useState<ISharedFolderUserModel[]>([]);
    const [refreshRecipients, setRefreshRecipients] = useState<boolean>(true);

    const [optionsLoading, setOptionsLoading] = useState<boolean>(false);
    const [userOptions, setUserOptions] = useState<any[]>([]);

    const signalRState = useSelector((appState: ApplicationState) => appState.signalRState);

    useEffect(() => {
        // Dispatch GetSharedFolder Users API Call
        show && folder?.folderId && fetchSharedFolderUsersApi();
    }, [show, folder?.folderId, signalRState.refreshSharedFolderRecipient]);

    useEffect(() => {
        //fetch all users for sharing folder
        refreshRecipients &&
            dispatch(
                fetchShareFolderRecipients("", folder?.folderId || -1, data => {
                    data && setUsersData(data);
                    setRefreshRecipients(false);
                })
            );
    }, [refreshRecipients]);

    const fetchSharedFolderUsersApi = () => {
        // Dispatch GetSharedFolder Users API Call to get users and groups with which folder been shared initially
        folder?.folderId &&
            dispatch(
                fetchSharedFolderUsers(folder?.folderId || 1, data => {
                    setSharedFolderUsers(data.users);
                    setSharedFolderGroups(data.groups);
                })
            );
    };

    const getUser = (selectedOptionValue: string, userType: ShareFolderUserType) => {
        return (
            usersData &&
            usersData?.find((user: ISharedFolderUserModel) =>
                userType === ShareFolderUserType.User
                    ? user.emailAddress === selectedOptionValue
                    : user.userId === +selectedOptionValue
            )
        );
    };

    const handleUserChange = (newValue: any, actionMeta: any) => {
        const input = actionMeta.removedValue || actionMeta.option;
        if (!input) {
            return;
        }
        const userType: ShareFolderUserType = Number.isNaN(+input.value)
            ? ShareFolderUserType.User
            : ShareFolderUserType.Group;
        const user = getUser(input.value, userType);
        if (!(user && user?.userId && user?.userId > 0)) {
            AppNotifier.Warning(ToasterMessages.WARNING.EMAIL_NOT_FOUND);
            return;
        }
        switch (actionMeta.action) {
            case "select-option":
            case "create-option":
                setSelectedUsers(prevUsers => [...prevUsers, user]);
                break;
            case "remove-value":
            case "pop-value":
                setSelectedUsers(prevUsers =>
                    prevUsers.filter(prevUser => {
                        if (userType === ShareFolderUserType.User) {
                            return prevUser.emailAddress !== user.emailAddress;
                        } else if (userType === ShareFolderUserType.Group) {
                            return !(
                                prevUser.shareFolderUserType === ShareFolderUserType.Group &&
                                prevUser.userId === user.userId
                            );
                        }
                    })
                );
                break;
        }
    };

    const onTopLevelDropdownPermissionChange = (option: any) => {
        setTopLevelDropDownPermission(option.value.toString());
    };

    const onUserLevelPermissionChange = (user: ISharedFolderPermissionViewModel, option: any) => {
        // Dispatch Folder Permission Change for User
        const userPermissionRequest: ISharedFolderPermissionViewModel[] = [];
        userPermissionRequest.push({
            folderId: user?.folderId || -1,
            userId: user.userId || -1,
            permissionGroupId: Number(option.value),
            sharedFolderId: user.sharedFolderId || -1,
        });
        const folderPermissionRequest: IFolderPermissionViewModel = {
            folderId: user.folderId,
            userPermissions: userPermissionRequest,
            groupPermissions: [],
        };
        if (Number(option.value) === ShareFolderTypes.REMOVE) {
            removeSharedFolderPermission(folderPermissionRequest);
        } else {
            updateSharedFolderPermission(folderPermissionRequest);
        }
    };

    const onGroupLevelPermissionChange = (group: ISharedFolderGroupPermissionViewModel, option: any) => {
        // Dispatch Folder Permission Change for Group
        const shareFolderRequest: ISharedFolderGroupPermissionViewModel[] = [];
        shareFolderRequest.push({
            folderId: group?.folderId || -1,
            groupId: group.groupId || -1,
            permissionGroupId: Number(option.value),
            sharedFolderGroupId: group.sharedFolderGroupId || -1,
        });
        if (Number(option.value) === ShareFolderTypes.REMOVE) {
            removeSharedFolderPermission({
                folderId: group.folderId,
                groupPermissions: shareFolderRequest,
                userPermissions: [],
            });
        } else {
            updateSharedFolderPermission({
                folderId: group.folderId,
                groupPermissions: shareFolderRequest,
                userPermissions: [],
            });
        }
    };

    const removeSharedFolderPermission = (folderPermission: IFolderPermissionViewModel) => {
        dispatch(
            removeSharedFolderUsers(folderPermission, (status: number) => {
                if (status === 200) {
                    if (folderPermission.userPermissions.length) {
                        const updatedSharedFolderUsers = sharedFolderUsers.filter(
                            x => x.userId !== folderPermission.userPermissions[0].userId
                        );
                        setSharedFolderUsers(updatedSharedFolderUsers);
                    }
                    if (folderPermission.groupPermissions.length) {
                        const updatedSharedFolderGroups = sharedFolderGroups.filter(
                            x => x.groupId !== folderPermission.groupPermissions[0].groupId
                        );
                        setSharedFolderGroups(updatedSharedFolderGroups);
                    }

                    setRefreshRecipients(true);
                    AppNotifier.Success(ToasterMessages.SUCCESS.REMOVE_SHARED_FOLDER_USER);
                }
            })
        );
    };

    const updateSharedFolderPermission = (folderPermission: IFolderPermissionViewModel) => {
        dispatch(
            updateSharedFolderUsers(folderPermission, (status: number) => {
                if (status === 200) {
                    AppNotifier.Success(ToasterMessages.SUCCESS.UPDATE_SHARED_FOLDER_PERMISSION);
                }
            })
        );
    };

    const onHideShareModal = () => {
        setSelectedUsers([]);
        onHide();
    };

    const onShareFolderClick = () => {
        // Dispatch Share Folders Api Call
        if (selectedUsers && selectedUsers.length > 0) {
            const shareFolderRequest: IFolderPermissionViewModel = {
                userPermissions: [],
                groupPermissions: [],
                folderId: folder!.folderId,
            };
            selectedUsers.forEach(selectedUser => {
                selectedUser.shareFolderUserType === ShareFolderUserType.User &&
                    shareFolderRequest.userPermissions!.push({
                        folderId: folder?.folderId || -1,
                        userId: selectedUser.userId || 25547,
                        permissionGroupId: Number(topLevelDropDownPermission),
                    });
                selectedUser.shareFolderUserType === ShareFolderUserType.Group &&
                    shareFolderRequest.groupPermissions!.push({
                        folderId: folder?.folderId || -1,
                        groupId: selectedUser.userId || 25547,
                        permissionGroupId: Number(topLevelDropDownPermission),
                    });
            });

            dispatch(
                shareFolderToUsers(shareFolderRequest, () => {
                    setSelectedUsers([]);
                    AppNotifier.Success(ToasterMessages.SUCCESS.SHARE_FOLDER);
                    fetchSharedFolderUsersApi();
                })
            );
        }
    };

    const getUserPermissionDropDown = (user: ISharedFolderPermissionViewModel) => {
        return (
            <Select
                key={user.userId}
                className="simple-dropdown inline-action-dropdown"
                options={getShareFolderOptions()}
                classNamePrefix={"share-folder-dropdown"}
                components={{
                    IndicatorSeparator: null,
                }}
                menuPlacement="auto"
                menuPosition="fixed"
                defaultValue={getShareFolderOptions().find(x => Number(x.value) === user.permissionGroupId)}
                onChange={(option: any) => onUserLevelPermissionChange(user, option)}
            />
        );
    };

    const getGroupPermissionDropDown = (group: ISharedFolderGroupPermissionViewModel) => {
        return (
            <Select
                key={group.groupId}
                className="simple-dropdown inline-action-dropdown"
                options={getShareFolderOptions()}
                classNamePrefix={"share-folder-dropdown"}
                components={{
                    IndicatorSeparator: null,
                }}
                menuPlacement="auto"
                menuPosition="fixed"
                defaultValue={getShareFolderOptions().find(x => Number(x.value) === group.permissionGroupId)}
                onChange={(option: any) => onGroupLevelPermissionChange(group, option)}
            />
        );
    };
    const getOptionLabel = (item: ISharedFolderUserModel): string => {
        let label = item.firstName;
        if (item.shareFolderUserType === ShareFolderUserType.User) {
            label = item.firstName || item.lastName ? `${item.firstName} ${item.lastName}` : item.emailAddress;
        }
        return label;
    };

    const getOptionValue = (item: ISharedFolderUserModel) => {
        let value = item.userId.toString();
        if (item.shareFolderUserType === ShareFolderUserType.User) {
            value = item.emailAddress;
        }
        return value;
    };

    const handleSharedFolderUsersSuccessCallback = (data: ISharedFolderUserModel[]) => {
        const options = data.map((item: ISharedFolderUserModel) =>
            createOption(getOptionValue(item), getOptionLabel(item))
        );
        setUserOptions(options);
        setOptionsLoading(false);
    };

    const handleInputChange = (inputValue: string) => {
        if (inputValue.length < 3) return;
        else {
            setOptionsLoading(true);
            // Dispatch share folder users
            dispatch(
                fetchShareFolderRecipients(inputValue, folder?.folderId || 1, (data: ISharedFolderUserModel[]) => {
                    handleSharedFolderUsersSuccessCallback(data);
                })
            );
        }
    };

    return (
        <CustomModal
            show={show}
            onHide={onHideShareModal}
            onSubmit={onSubmit}
            hideFooter={true}
            title={ArchiveConstants.ShareFolder.SHARE_FOLDER_MODAL_TITLE}
            className={`share-folder-modal ${sharedFolderUsers?.length > 5 ? "more-users-exception" : ""}`}
        >
            <LoadingOverlay>
                <div style={{overflow: "visible"}}>
                    <span className="share-folder-text">
                        You are sharing <strong>{folder?.folderName}</strong> with:
                    </span>
                    <div className="share-folder-top-section">
                        <div className="recipients-container">
                            <div className="recipient-wrapper">
                                <Select
                                    isMulti
                                    cacheOptions
                                    isClearable={false}
                                    placeholder=""
                                    blurInputOnSelect={false}
                                    hideSelectedOptions={false}
                                    options={userOptions.filter((option: any) => {
                                        const userEmails = sharedFolderUsers.map(
                                            (user: ISharedFolderPermissionDetailViewModel) => user.emailAddress
                                        );
                                        const groupIds = sharedFolderGroups.map(
                                            (group: ISharedFolderGroupPermissionDetailViewModel) => group.groupId
                                        );
                                        return !userEmails.includes(option.value) && !groupIds.includes(+option.value);
                                    })}
                                    onChange={handleUserChange}
                                    onInputChange={debounce(1000, handleInputChange)}
                                    theme={theme => {
                                        return { ...theme, borderRadius: 0 };
                                    }}
                                    components={{
                                        DropdownIndicator: null,
                                    }}
                                    styles={creatableStyle}
                                    value={selectedUsers.map((item: ISharedFolderUserModel) =>
                                        createOption(getOptionValue(item), getOptionLabel(item))
                                    )}
                                    isLoading={optionsLoading}
                                />
                            </div>
                            <Select
                                className="simple-dropdown header-dropdown"
                                options={getShareFolderOptions().slice(0, 2)}
                                classNamePrefix={"share-folder-dropdown"}
                                defaultValue={getShareFolderOptions().find(
                                    x => Number(x.value) === Number(topLevelDropDownPermission)
                                )}
                                components={{
                                    IndicatorSeparator: null,
                                }}
                                onChange={(option: any) => onTopLevelDropdownPermissionChange(option)}
                            />
                        </div>

                        <button
                            className="button-primary-blue-2"
                            data-auto-test={AutomationIdConstants.shareFolderModal.ShareBtn}
                            disabled={selectedUsers.length === 0}
                            onClick={onShareFolderClick}
                        >
                            {ArchiveConstants.ShareFolder.SHARE_FOLDER_BUTTON_TEXT}
                        </button>
                    </div>
                    <div>
                        <div className={"share-folder-users-section"}>
                            {sharedFolderGroups &&
                                Array.isArray(sharedFolderGroups) &&
                                sharedFolderGroups.length > 0 &&
                                sharedFolderGroups?.map(group => (
                                    <div className="user-card" key={group.groupId}>
                                        <span>{group.groupName}</span>
                                        {getGroupPermissionDropDown(group)}
                                    </div>
                                ))}
                            {sharedFolderUsers &&
                                Array.isArray(sharedFolderUsers) &&
                                sharedFolderUsers.length > 0 &&
                                sharedFolderUsers?.map(user => (
                                    <div className="user-card" key={user.userId}>
                                        <span>{user.firstName + " " + user.lastName}</span>
                                        {getUserPermissionDropDown(user)}
                                    </div>
                                ))}
                            {sharedFolderUsers.length == 0 && sharedFolderGroups.length == 0 && (
                                <div className="no-users-found">{ArchiveConstants.ShareFolder.NO_DATA_MESSAGE}</div>
                            )}
                        </div>
                    </div>
                </div>
                <Loader loading={sharedFolderData?.isLoading} />
            </LoadingOverlay>
        </CustomModal>
    );
};
