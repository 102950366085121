import React, { FC } from "react";
import { ReactSortable } from "react-sortablejs";
import { IItem } from "../../../../core/viewModels/report/reportViewModel";
import { appInsightsData } from "../../../../helper/AppInsightsData";
import { logger } from "../../../../oidcClient/authProvider";
import { GripIcon } from "../../../svg/IconCollection";
import CustomToggleSwitch from "../../../common/ui/switch/CustomToggleSwitch";

interface IColumnOption {
    columnOrder: IItem[];
    setColumnOrder: React.Dispatch<React.SetStateAction<IItem[]>>;
    visibleColumns: IItem[];
    setVisibleColumns: React.Dispatch<React.SetStateAction<IItem[]>>;
    setNotSaved: React.Dispatch<React.SetStateAction<boolean>>;
    pageTitle?: string;
}
const ColumnOption: FC<IColumnOption> = ({ columnOrder, setColumnOrder, visibleColumns, setVisibleColumns, setNotSaved, pageTitle }) => {

    const isVisibleColumn = (id: number) => visibleColumns.some(item => item.id === id);

    const handleVisibility = (item: IItem) => {
        if (isVisibleColumn(item.id)) {
            const alteredColumn = visibleColumns.filter(column => column.id !== item.id);
            setVisibleColumns(alteredColumn);
        } else {
            setVisibleColumns([...visibleColumns, item]);
        }
        setNotSaved(true);
        logger.trackEvent({
            name: `${pageTitle} - ${appInsightsData.MessageHistory.Operation.ColumnVisibilityChanged}`,
            properties: {
                page: pageTitle
            }
        });
    };

    const handleSortUpdate = () => {
        setNotSaved(true);
        logger.trackEvent({
            name: `${pageTitle} - ${appInsightsData.MessageHistory.Operation.ColumnOrderChanged}`,
            properties: {
                page: pageTitle
            }
        });
    };

    return <div>
        <ReactSortable
            className="sortable-container"
            list={columnOrder} setList={setColumnOrder}
            dragClass="drag"
            ghostClass="ghost"
            onUpdate={() => handleSortUpdate()}
        >
            {columnOrder.map((item) => <div className="draggable-item" key={item.id}>
                <GripIcon />
                <div className="label margin-left-5-px">
                    <span>{item.name}</span>
                    <span className={`visibility-icon ${item.required ? "disabled" : ""}`} onClick={() => !item.required && handleVisibility(item)}>
                        <CustomToggleSwitch
                            switched={isVisibleColumn(item.id)}
                            handleChange={() => !item.required && handleVisibility(item)}
                            disabled={item.required}
                        />
                    </span>
                </div>
            </div>)}
        </ReactSortable>
    </div>;
};

export default ColumnOption;