import * as React from "react";
import { ToastContainer, toast } from "react-toastify";
import { FC } from "react";
import "react-toastify/dist/ReactToastify.min.css";
import { NotifierTypes } from "../core/common/enums";
import { Colors } from "../styles/colors";
import { CheckCircleFill, WarningIcon } from "../components/svg/IconCollection";
export class AppNotifier extends React.Component<any, any> {
    static Error = (message: string) =>
        message && toast.error(<ToastComponent
            message={message}
            type={NotifierTypes.Error}
        />, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toaster-body toaster-error",
        });

    static Warning = (message: string) =>
        message && toast.warn(<ToastComponent
            message={message}
            type={NotifierTypes.Warning}
        />, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toaster-body toaster-warning",
        });

    static Success = (message: string) =>
        message && toast.success(<ToastComponent
            message={message}
            type={NotifierTypes.Success}
        />, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toaster-body toaster-success",
        });

    static Info = (message: string) =>
        message && toast.info(<ToastComponent
            message={message}
            type={NotifierTypes.Info}
        />, {
            position: toast.POSITION.TOP_RIGHT,
            className: "toaster-body toaster-info",
        });
}
export const ToastComponent: FC<any> = ({ message, type }) => {
    const getNotificationIcon = (notificationType: NotifierTypes) => {
        switch (notificationType) {
            case NotifierTypes.Error:
                return <WarningIcon color={Colors["$error-600"]} />;
            case NotifierTypes.Success:
                return <CheckCircleFill width={16} height={16} color={Colors["$success-500"]} />;
            case NotifierTypes.Info:
                return <CheckCircleFill width={16} height={16} color={Colors.$sapphire} />;
            case NotifierTypes.None:
            case NotifierTypes.Warning:
                return <CheckCircleFill width={16} height={16} color={Colors["$theme-warning"]} />;
            default:
                return <WarningIcon color={Colors["$error-600"]} />;
        }
    };
    return (<div className="" style={{ display: "inline-flex" }}>
        <div className="toaster-icon">{getNotificationIcon(type)}</div>
        <div className="toaster-text">{message}</div>
    </div>);
};
export const Toaster: FC<any> = () => {
    return (
        <div>
            <ToastContainer
                autoClose={5000}
                className="toaster-container"
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick={false}
                rtl={false}
                pauseOnHover
                style={{ fontSize: "14px" }}
            />
        </div>
    );
};
