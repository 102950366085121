import { ShareFolderTypes, TabType} from "../../../../core/common/enums";
import { ITabModel } from "../../../../core/viewModels/settings/Settings";
import "./style.scss";
import { DropOffConfig } from "../tabs/DropOff/DropOffConfig";
import { DropOffUsers } from "../tabs/DropOff/DropOffUsers";
import { MessageHistory } from "../tabs/History/MessageHistory";
import RequestHistory from "../tabs/History/RequestHistory";
import MessageNotification from "../tabs/Notification/MessageNotification";
import RequestNotification from "../tabs/Notification/RequestNotification";
import { TemplateListing } from "../tabs/Templates.tsx/TemplateListing";
import DocumentCategory from "../tabs/Document Request/DocumentCategory";
import ContactSupport from "../tabs/General/ContactSupport";
import { AuthenticationQuestions } from "../tabs/General/AuthenticationQuestions";
import { MessageOptions } from "../tabs/General/MessageOptions";
import "../../adminSettings/style.scss";
import React from "react";
import { MessageEmailTemplate } from "../tabs/Saved Message/EmailTemplate";
import { RequestEmailTemplate } from "../tabs/Saved Message/RequestEmailTemplate";
import ArchiveMessageTab, { IArchiveMessageTabProps } from "../../folders/ArchiveMessageTab";
import { IFolderModel } from "../../../../core/viewModels/folder/FolderModel";
import { DocumentRequest } from "../../requestDocuments/DocumentRequest";
import { UnlockTable} from "../../Unlock/Unlocktable";

export interface ITabProps {
    tabs: ITabModel[];
    handleTabClick: (type: TabType) => void;
    activeTab: TabType | undefined;
    archiveProps?: IArchiveMessageTabProps;
    archiveFolder?: IFolderModel;
}

const initialFolder: IFolderModel = {
    folderId: 0,
    folderName: "My Files",
    parentFolderIds: "0 ",
    description: "My Files Description",
    createdBy: 0,
    createdDate: new Date(),
    expiresDays: 0,
    parentId: 0,
    owner: "Only You",
    permissionGroupId: ShareFolderTypes.NONE,
    isPermissiomInherited: false,
    isPrivate: false,
};

const defaultMethod = () =>{
    //default method
} ;

const initialArchiveProps: IArchiveMessageTabProps = {
    currentFolder: initialFolder,
    searchText: "",
    selectedFolderId: 0,
    pagination: { currentPage: 1, pageSize: 10 },
    selectedSortOption: "",
    reload: false,
    setPagination: defaultMethod,
    handleDownloadSingleAttachment: undefined,
    handleResetSearchAndSort: defaultMethod,
    showDownloadModal: false,
    setShowDownloadModal: defaultMethod,
    selectedFiles: [],
    setSelectedFiles: defaultMethod,
    fileViewSort: undefined,
    searchTextBox: "",
    onFilter: defaultMethod,
    setSelectedSortOption: defaultMethod,
    handleRefreshButtonClick: defaultMethod,
    handleDownloadButtonClick: defaultMethod,
    handleMessageViewToogle: undefined,
    isFileDownloading: false,
    messageViewType: "",
};

const Tab = ({ tabs, handleTabClick, activeTab, archiveProps = initialArchiveProps, archiveFolder }: ITabProps) => {
    const isActiveTab = (tabData: ITabModel): boolean => {
        return tabData.isActive === true;
    };

    const GetTabContent = () => {
        switch (activeTab) {
            case TabType.Configuration:
                return <DropOffConfig />;
            case TabType.User:
                return <DropOffUsers />;
            case TabType.MessageHistory:
                return <MessageHistory />;
            case TabType.RequestHistory:
                return <RequestHistory />;
            case TabType.MessageNotification:
                return <MessageNotification />;
            case TabType.RequestNotification:
                return <RequestNotification />;
            case TabType.DocumentCategory:
                return <DocumentCategory />;
            case TabType.RequestTemplate:
                return <TemplateListing />;
            case TabType.SupportContact:
                return <ContactSupport />;
            case TabType.AuthenticationQuestions:
                return <AuthenticationQuestions />;
            case TabType.MessageOption:
                return <MessageOptions />;
            case TabType.MessageEmailTemplate:
                return <MessageEmailTemplate />;
            case TabType.RequestsEmailTemplate:
                return <RequestEmailTemplate />;
            case TabType.ArchiveRequest:
                return <DocumentRequest isArchive={true} currentFolder={archiveFolder} />;
            case TabType.Messages:
            case TabType.Requests:
            case TabType.Onehub:
                return <UnlockTable activeTab={activeTab} />;
            case TabType.ArchiveMessage:
                return (
                    <ArchiveMessageTab
                        currentFolder={archiveProps.currentFolder}
                        searchText={archiveProps?.searchText}
                        selectedFolderId={archiveProps?.selectedFolderId}
                        pagination={archiveProps?.pagination}
                        selectedSortOption={archiveProps?.selectedSortOption}
                        reload={archiveProps?.reload}
                        setPagination={archiveProps?.setPagination}
                        handleDownloadSingleAttachment={archiveProps.handleDownloadSingleAttachment}
                        handleResetSearchAndSort={archiveProps.handleResetSearchAndSort}
                        showDownloadModal={archiveProps.showDownloadModal}
                        setShowDownloadModal={archiveProps.setShowDownloadModal}
                        selectedFiles={archiveProps.selectedFiles}
                        setSelectedFiles={archiveProps.setSelectedFiles}
                        fileViewSort={archiveProps.fileViewSort}
                        searchTextBox={archiveProps.searchTextBox}
                        onFilter={archiveProps.onFilter}
                        setSelectedSortOption={archiveProps.setSelectedSortOption}
                        handleRefreshButtonClick={archiveProps.handleRefreshButtonClick}
                        handleDownloadButtonClick={archiveProps.handleDownloadButtonClick}
                        handleMessageViewToogle={archiveProps.handleMessageViewToogle}
                        isFileDownloading={archiveProps.isFileDownloading}
                        messageViewType={archiveProps.messageViewType}
                    />
                );
            default:
                return <h1>{activeTab}</h1>;
        }
    };

    return (
        <div className={(activeTab == 13 || activeTab == 14) ? "tab-container archive-tabs" :"tab-container"}>
            <div className={(activeTab == 13 || activeTab == 14) ? "tab-header-container archive-tabs-height" :"tab-header-container"}>
                <div className={(activeTab == 13 || activeTab == 14) ? "tab-header-wrapper archive-tabs" :"tab-header-wrapper"}>
                    {tabs.map((tab, index) => (
                        <div key={index} className={isActiveTab(tab) == true ? "tab-active" : ""}>
                            <div className="tab-wrapper">
                                <p
                                    className={isActiveTab(tab) == true ? "tab-selected" : "tab-default"}
                                    onClick={() => handleTabClick(tab.type)}
                                >
                                    {tab.title}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className={(activeTab == 13 || activeTab == 14) ? "tab-content archive-tabs": "tab-content"}>{GetTabContent()}</div>
        </div>
    );
};

export default Tab;
