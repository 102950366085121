import axios from "axios";
import {
    completedMessageMailTemplates,
    createMessageMailTemplates,
    deleteMessageMailTemplates,
    receivedMessageMailTemplates,
    requestMessageMailTemplates,
    updateMessageMailTemplates,
} from "../actions/SavedMessagesActions";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import { AppThunkAction } from "../store";
import { actionTypes } from "../types/SavedMessageTypes";
import { ISavedMessageTemplateModel } from "../core/viewModels/settings/SavedMessageTemplate";
import { actionTypes as notificationActions } from "../types/notificationTypes";
import { AppNotifier } from "../helper/AppNotifier";
import { SavedMessageEmailTemplate } from "../helper/Constants";
import { TemplateSource } from "../core/common/enums";
import { ToasterMessages } from "../helper/ToasterMessages";

export type KnownActions =
    | requestMessageMailTemplates
    | receivedMessageMailTemplates
    | completedMessageMailTemplates
    | createMessageMailTemplates
    | updateMessageMailTemplates
    | deleteMessageMailTemplates
    | NotificationAction;

export const getSavedMessageTemplates =
    (fetchTemplate: TemplateSource, callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.REQUEST_MESSAGE_MAIL_TEMPLATES_LIST });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/MailMessageTemplate/GetSavedMailMessageTemplates?fetchTemplates=${fetchTemplate}`)
            .then(response => {
                const data = response.data as ISavedMessageTemplateModel[];
                dispatch({ type: actionTypes.RECEIVE_MESSAGE_MAIL_TEMPLATES_LIST, data });
                if (callback) callback();
            })
            .catch(error => {
                dispatch({
                    type: notificationActions.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.COMPLETED_MESSAGE_MAIL_TEMPLATES });
            });
    };

export const addMessageMailTemplate =
    (payload: ISavedMessageTemplateModel, callback?: (data?:any,dataIsDefault?:boolean) => void,isDefault?:boolean): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.CREATE_MESSAGE_MAIL_TEMPLATE });
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/MailMessageTemplate/CreateMailTemplate`, payload)
            .then(response => {
                const data = response.data;
                AppNotifier.Success(ToasterMessages.SUCCESS.ADD_EMAIL_TEMPLATE);
                if (callback) callback(data,isDefault);
            })
            .catch(error => {
                const message: string = error.response.data.message;
                if (message.includes(SavedMessageEmailTemplate.TEMPLATE_EXIST_MESSAGE)) {
                    AppNotifier.Error(ToasterMessages.ERROR.TEMPLATE_ALREADY_EXIST);
                    return;
                }
                dispatch({
                    type: notificationActions.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.COMPLETED_MESSAGE_MAIL_TEMPLATES });
            });
    };

export const deleteMessageMailTemplate =
    (payload: number, callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.DELETE_MESSAGE_MAIL_TEMPLATE });
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/MailMessageTemplate/Delete?id=${payload}`)
            .then(() => {
                const message = ToasterMessages.SUCCESS.DELETE_EMAIL_TEMPLATE;
                AppNotifier.Success(message);
                if (callback) callback();
            })
            .catch(error => {
                dispatch({
                    type: notificationActions.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.COMPLETED_MESSAGE_MAIL_TEMPLATES });
            });
    };

export const updateMessageMailTemplate =
    (payload: ISavedMessageTemplateModel, callback?: (data:any,dataIsDefault?:boolean,defaultTemplateReverted?:boolean) => void ,isDefault?:boolean,defaultTemplateReverted?:boolean): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.UPDATE_MESSAGE_MAIL_TEMPLATE });
        axios
            .put(`${process.env.REACT_APP_API_URL}/api/MailMessageTemplate/UpdateMailTemplates`, payload)
            .then(() => {
                AppNotifier.Success(ToasterMessages.SUCCESS.UPDATE_EMAIL_TEMPLATE);
                if (callback) callback(payload.savedMessageTemplateId,isDefault,defaultTemplateReverted);
            })
            .catch(error => {
                const message: string = error.response.data.message;
                if (message.includes(SavedMessageEmailTemplate.TEMPLATE_EXIST_MESSAGE)) {
                    AppNotifier.Error(ToasterMessages.ERROR.TEMPLATE_ALREADY_EXIST);
                    return;
                }
                dispatch({
                    type: notificationActions.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.COMPLETED_MESSAGE_MAIL_TEMPLATES });
            });
    };
