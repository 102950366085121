import { AuthenticationProvider } from "../company/CompanySettingsViewModel";
import { AuthenticationMethod, DropOffLinkType } from "../../common/enums";

export interface IUserMetaData {
    readonlyFields: IUserReadonlyFields;
}

export interface IUserReadonlyFields {
    providerData: IExternalAuthenticationProviderFields[];
}

export interface IExternalAuthenticationProviderFields {
    provider: AuthenticationProvider;
    fields: string[];
}

export interface IDelegateeUser {
    userId: number;
    firstName: string;
    lastName: string;
    emailAddress: string;
    email: string; // added for now as it is required for automation task. its coming as email from GetAllowedMailboxUsersAsync api
    metadata?: string;
}

export interface IUserMfaSettings {
    isMfaEnabled: boolean;
}

export interface IUserSendOptionSettings {
    defaultAuthenticationMethod: number | AuthenticationMethod;
}

export interface IUserRetentionSettings {
    defaultRetentionPeriods: {
        // loginTransfer: number;
        questionAndAnswer: number;
        accessCode: number;
        dropOffTransfer: number;
    };
}

export interface IMessageSecurityQuestionSettings {
    defaultSecurityQuestionId: number;
}

export interface ISavedMessageTemplateSettings {
    defaultSavedMessageTemplateId: number;
}

export interface ISavedMessageDRTemplateSettings {
    defaultSavedMessageDRTemplateId: number;
}

export interface IUserSettingsModel {
    userId: number;
    mfaSettings: IUserMfaSettings;
    authenticationMethodSettings: IUserSendOptionSettings;
    retentionSettings: IUserRetentionSettings;
    messageSecurityQuestionSettings: IMessageSecurityQuestionSettings;
    savedMessageTemplateSettings: ISavedMessageTemplateSettings;
    savedMessageDRTemplateSettings: ISavedMessageDRTemplateSettings;
    mailboxDelegationSettings: number[] | null;
    dropOffSettings: {
        isMailNotificationToSend: boolean;
        dropOffLinkType: number | DropOffLinkType;
    };
    notificationsSettings: INotificationsSettings;
    documentRequestNotificationsSettings: IDocumentRequestNotificationSettings;
}

export interface IReportProblemModel {
    cpaEmail: string;
    cpaPhoneNumber?: string;
    description: string;
    attachment?: string;
    isAttachmentPresent: boolean;
}

export interface IUserLoginHistory {
    userEmail: string;
    loggedInOn: string;
    loggedOutOn?: string;
    IPAddress: string;
    clientApp: string;
    deviceId: string;
    customData: string;
}

export interface IUserLoginHistoryResponse {
    count: number;
    deviceId: string;
    loginHistory: IUserLoginHistory[];
}

export enum PageDisabledTypes {
    SubscriptionTier = 1,
    ProductDisabled,
    NoPermission,
    PageAccessDenied,
}

export interface IDropOffSettings {
    isMailNotificationToSend: boolean;
    dropOffLinkType: DropOffLinkType;
}

export interface INotificationsSettings {
    isExpiryNotificationEnable: boolean;
    isDownloadNotificationEnable: boolean;
}

export interface IDocumentRequestNotificationSettings{
    isDocumentRequestCompletionNotificationEnabled: boolean;
    isDocumentRequestReopenNotificationEnabled: boolean;
}