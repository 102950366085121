import * as React from "react";
import ReactToPrint from "react-to-print";
import { PrintDocumentIcon } from "../../svg/IconCollection";

interface IPrintComponentProps {
  showPrintText?: boolean;
  printText?: string;
  showPrintIcon?: boolean;
  children?: React.ReactNode;
}
export const PrintComponent: React.FC<IPrintComponentProps> = ({ showPrintIcon, showPrintText, printText, children }) => {
  let componentRef: any = React.useRef();

  return (
    <>
      <div>
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => <span>
            {showPrintIcon ? <PrintDocumentIcon/> : null}
            {showPrintText ? printText : null}
          </span>}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <div style={{ display: "none" }}>
          {
            React.Children.map(children, (child) => {
              return React.cloneElement(child as React.ReactElement, {
                ref: (el: any) => (componentRef = el),
              });
            })
          }

        </div>
      </div>
    </>
  );
};
