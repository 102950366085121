import { AuthenticationMethod, MessageSource, MessageStatus, MessageType } from "../../common/enums";
import { ISentMessageRecipientModel } from "./SentMessageRecipientModel";

export interface ISentMessageModel {
    type?: MessageType.SENT; //discriminator
    folderId?: number;
    sentMessageId: number;
    sentMessageGuid: string;
    senderId: number;
    senderEmail: string;
    senderFirstName: string;
    senderLastName: string;
    subject: string;
    message: string;
    notifyWhenDownloaded: number;
    sentDate: Date;
    expiresDays: number;
    authenticationMethod: AuthenticationMethod;
    linkId: string;
    messageStatus: MessageStatus;
    messageSource: MessageSource;
    deletedBySender: boolean;
    uploadRetry: number;
    isDeleted: boolean;
    sentMessageRecipients: ISentMessageRecipientModel[];
    recipientList: string;
    filteredMessage: string;
    senderFilePath: string;
}

export const initialSentMessage: ISentMessageModel = {
    sentMessageId: 0,
    sentMessageGuid: "",
    senderId: 0,
    senderEmail: "",
    senderFirstName: "",
    senderLastName: "",
    subject: "",
    message: "",
    notifyWhenDownloaded: 0,
    sentDate: new Date(),
    expiresDays: 0,
    authenticationMethod: AuthenticationMethod.ACCESSCODE,
    linkId: "",
    messageStatus: MessageStatus.Pending,
    messageSource: MessageSource.MAILBOX,
    deletedBySender: false,
    uploadRetry: 1,
    isDeleted: false,
    sentMessageRecipients: [],
    recipientList: "",
    filteredMessage: "",
    senderFilePath: "",
};

export interface IPagination {
    pageSize: number;
    currentPage: number;
    totalRecords?: number;
}
export interface IDocumentPagination {
    pageSize: number;
    currentPage: number;
}
