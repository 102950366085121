import React from "react";
import { AddIcon } from "../../../../../svg/IconCollection";
import { SavedMessageEmailTemplate } from "../../../../../../helper/Constants";
import { ISavedMessageTemplateModel} from "../../../../../../core/viewModels/settings/SavedMessageTemplate";

interface IUserTemplateSetting{
    allowedToAdd:boolean;
    allowedToChangeDefault : boolean;
   }
export interface IMessageListBoxProps {
    SavedMessageTemplatedata: ISavedMessageTemplateModel[];
    selectedMessage: ISavedMessageTemplateModel;
    onSelectMessage: (index: number, data: ISavedMessageTemplateModel) => void;
    onAddMessageTemplate: () => void;
    defaultTemplate?: ISavedMessageTemplateModel;
    settings?: IUserTemplateSetting;
}

const MessageListBox: React.FC<IMessageListBoxProps> = ({
    SavedMessageTemplatedata,
    selectedMessage,
    onSelectMessage,
    onAddMessageTemplate,
    defaultTemplate,
    settings,
}) => {

    const isActive = (id: number) => {
        if (selectedMessage.savedMessageTemplateId === id) {
            return true;
        } else return false;
    };
    return (
        <div className="saved-message-list-box">
            <div className="saved-message-list">
                {SavedMessageTemplatedata &&
                    SavedMessageTemplatedata.map((ele, index) => {
                        return (
                            <div
                                key={ele.savedMessageTemplateId}
                                className={`message-wrapper ${
                                    isActive(ele.savedMessageTemplateId) ? "active-msg" : ""
                                }`}
                                onClick={() => onSelectMessage(index, ele)}
                                title={ele.templateName}
                            >
                                <p className="d-inline-block text-truncate">{ele.templateName}</p>
                                 {defaultTemplate &&( ele.savedMessageTemplateId === defaultTemplate.savedMessageTemplateId && <span>Default</span>)}
                            </div>
                        );
                    })}
            </div>
            <button
            
                disabled={settings ? !settings.allowedToAdd : false}
                onClick={()=>{onAddMessageTemplate();}}
                className={`btn-with-icon ${settings && !settings.allowedToAdd ? "disabled input-disabled-list" : ""}`}
                title={SavedMessageEmailTemplate.ADD_BUTTON_TOOL_TIP}
            >
                <AddIcon />
                Add
            </button>
        </div>
    );
};

export default MessageListBox;
