import React, { Dispatch, SetStateAction, useLayoutEffect, useState } from "react";
import { Option } from "react-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { deleteFolders } from "../../../actionCreators/folderActionCreator";
import { IFolderModel } from "../../../core/viewModels/folder/FolderModel";
import { AppNotifier } from "../../../helper/AppNotifier";
import { FolderEventType, FolderToolbarConstants } from "../../../helper/Constants";

import { CustomModal } from "../../common/ui/Modals/CustomModal";
import { ChangeRetentionPeriodModal } from "../../common/ui/Modals/FolderModal/ChangeRetentionPeriodModal";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { ArchiveFolderType, ShareFolderTypes } from "../../../core/common/enums";
import { ApplicationState } from "../../../store";
import { isFolderOwner } from "../../../helper/HelperFunctions";
import { CreateFolderModal } from "../../common/ui/Modals/FolderModal/CreateFolderModal";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";
import FolderDropOffModal from "../../common/ui/Modals/FolderModal/FolderDropOffModal";
import { ChangeRetentionPeriodIcon, DeleteIcon, DropOffIcon, EditIcon } from "../../svg/IconCollection";
import { ToasterMessages } from "../../../helper/ToasterMessages";

interface IFolderActionsPopUp {
    selectedFolders: IFolderModel[];
    retentionOptions: Option[];
    onHide: () => void;
    setReload: (value: boolean, updatedRetention?: number) => void;
    goToParentFolder: () => void;
    setFolderName?: (name: string) => void;
    setDescription?: (name: string) => void;
    setShowFolderActionPopup: Dispatch<SetStateAction<boolean>>;
}

const FolderActionsPopUp: React.FC<IFolderActionsPopUp> = ({
    retentionOptions,
    selectedFolders,
    setReload,
    onHide,
    goToParentFolder,
    setShowFolderActionPopup,
}) => {
    const dispatch = useDispatch();
    const [showRenameModal, setShowRenameModal] = useState(false);
    const [showChangeRetentionModal, setShowChangeRetentionModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showFolderDropOffModal, setShowFolderDropOffModal] = useState(false);
    const user = useSelector((state: ApplicationState) => state.userProfile);

    useLayoutEffect(() => {
        showRenameModal || showChangeRetentionModal || showDeleteModal;
    }, [showRenameModal, showChangeRetentionModal, showDeleteModal]);

    const handleRenameModalSubmition = () => {
        setShowRenameModal(false);
        setReload(true);
        onHide();
    };

    const handleOnClickChangeRetention = () => {
        setShowChangeRetentionModal(true);
    };

    const handleChangeRetentionSubmit = (updatedRetention?: number) => {
        setShowChangeRetentionModal(false);
        onHide();
        setReload(true, updatedRetention);
    };

    const handleDeleteIconClick = () => {
        setShowDeleteModal(true);
    };

    const handleOnSubmitDelete = () => {
        dispatch(
            deleteFolders(
                selectedFolders.map(f => f.folderId),
                result => {
                    if (result) {
                        AppNotifier.Success(
                            selectedFolders.length > 1
                                ? ToasterMessages.SUCCESS.DELETE_FOLDERS
                                : ToasterMessages.SUCCESS.DELETE_FOLDER
                        );
                        setReload(true);
                        goToParentFolder();
                    }
                    setShowDeleteModal(false);
                    onHide();
                },
                ResourceIdLocators.MyFiles.MyFilesDrpDwnDeleteFolder
            )
        );
    };

    const handleRenameModalHide = () => {
            setShowRenameModal(false);
            setShowFolderActionPopup(false);
        },
        handleChangeRetentionModalHide = () => {
            setShowChangeRetentionModal(false);
            setShowFolderActionPopup(false);
        },
        handleDeleteModalHide = () => {
            setShowDeleteModal(false);
            setShowFolderActionPopup(false);
        };

    const enableHeaderAction = (folder: IFolderModel) => {
        if (
            isFolderOwner(folder, user) ||
            (folder.permissionGroupId == ShareFolderTypes.EDIT && folder.parentId !== 0 && folder.isPermissiomInherited)
        ) {
            return true;
        } else if (
            (folder.permissionGroupId == ShareFolderTypes.EDIT && folder.parentId === 0) ||
            folder.permissionGroupId == ShareFolderTypes.VIEW
        ) {
            return false;
        } else if (folder.permissionGroupId === ShareFolderTypes.NONE && !folder.isPermissiomInherited) {
            return true;
        }

        return false;
    };

    const handleHideFolderDropOffModal = () => {
        setShowFolderDropOffModal(false);
    };

    return (
        <div className="folder-actions-container">
            <div
                className={
                    selectedFolders[0].folderType === ArchiveFolderType.FOLDER
                        ? "folder-action-wrapper toolbar-icon-disabled"
                        : "folder-action-wrapper"
                }
                data-resource-id={ResourceIdLocators.MyFiles.MyFilesBtnCopyFolder}
                onClick={() => {
                    selectedFolders[0].folderType !== ArchiveFolderType.FOLDER && setShowFolderDropOffModal(true);
                }}
                title={
                    selectedFolders[0].folderType === ArchiveFolderType.FOLDER
                        ? FolderToolbarConstants.TITLE_FOLDER_DROPOFF_DISABLED
                        : ""
                }
            >
                <DropOffIcon
                    width={16}
                    height={16}
                    disabled={selectedFolders[0].folderType === ArchiveFolderType.FOLDER}
                />
                <span>{FolderToolbarConstants.TITLE_FOLDER_DROPOFF}</span>
            </div>

            {enableHeaderAction(selectedFolders[0]) ? (
                <div
                    className="folder-action-wrapper"
                    data-resource-id={ResourceIdLocators.MyFiles.MyFilesDrpDwnRenameFolder}
                    onClick={() => {
                        setShowRenameModal(true);
                    }}
                >
                    <EditIcon/>
                    <span>{FolderToolbarConstants.TITLE_EDIT}</span>
                </div>
            ) : (
                <div
                    className="folder-action-wrapper toolbar-icon-disabled"
                    data-resource-id={ResourceIdLocators.MyFiles.MyFilesDrpDwnRenameFolder}
                >
                    <EditIcon/>
                    <span>{FolderToolbarConstants.TITLE_EDIT}</span>
                </div>
            )}

            {enableHeaderAction(selectedFolders[0]) ? (
                <div
                    className="folder-action-wrapper"
                    data-resource-id={ResourceIdLocators.MyFiles.MyFilesDrpDwnChangeRetention}
                    onClick={handleOnClickChangeRetention}
                >
                    <ChangeRetentionPeriodIcon />

                    <span>{FolderToolbarConstants.TITLE_CHANGE_RETENTION}</span>
                </div>
            ) : (
                <div
                    className="folder-action-wrapper toolbar-icon-disabled"
                    data-resource-id={ResourceIdLocators.MyFiles.MyFilesDrpDwnChangeRetention}
                >
                    <ChangeRetentionPeriodIcon />

                    <span>{FolderToolbarConstants.TITLE_CHANGE_RETENTION}</span>
                </div>
            )}

            {enableHeaderAction(selectedFolders[0]) ? (
                <div
                    className="folder-action-wrapper"
                    data-auto-test={AutomationIdConstants.deleteBtnModal.CancelBtn}
                    data-resource-id={ResourceIdLocators.MyFiles.MyFilesDrpDwnDeleteFolder}
                    onClick={() => {
                        handleDeleteIconClick();
                    }}
                >
                    <DeleteIcon />

                    <span>{FolderToolbarConstants.TITLE_DELETE}</span>
                </div>
            ) : (
                <div
                    className="folder-action-wrapper toolbar-icon-disabled"
                    data-auto-test={AutomationIdConstants.deleteBtnModal.CancelBtn}
                    data-resource-id={ResourceIdLocators.MyFiles.MyFilesDrpDwnDeleteFolder}
                >
                    <DeleteIcon />

                    <span>{FolderToolbarConstants.TITLE_DELETE}</span>
                </div>
            )}

            {showRenameModal && (
                <CreateFolderModal
                    modalType={FolderEventType.Edit}
                    show={showRenameModal}
                    currentFolder={selectedFolders[0]}
                    onHide={handleRenameModalHide}
                    onSubmit={handleRenameModalSubmition}
                    renameBtnResourceId={ResourceIdLocators.MyFiles.MyFilesDrpDwnRenameFolder}
                />
            )}
            {showChangeRetentionModal && (
                <ChangeRetentionPeriodModal
                    selectedFolders={selectedFolders}
                    showState={showChangeRetentionModal}
                    retentionOptions={retentionOptions}
                    changeRtnResourceId={ResourceIdLocators.MyFiles.MyFilesDrpDwnChangeRetention}
                    onHide={handleChangeRetentionModalHide}
                    onSubmit={handleChangeRetentionSubmit}
                />
            )}
            {showDeleteModal && (
                <CustomModal
                    show={showDeleteModal}
                    cancelButtonName="Cancel"
                    confirmButtonName="Confirm"
                    title={FolderToolbarConstants.TITLE_DELETE_FOLDER}
                    onSubmit={handleOnSubmitDelete}
                    onHide={handleDeleteModalHide}
                    isConfirmModal={true}
                    className="delete-folder-modal"
                >
                    <div>
                        <span>{FolderToolbarConstants.DELETE_FOLDER}</span>
                    </div>
                </CustomModal>
            )}
            {showFolderDropOffModal && (
                <FolderDropOffModal
                    show={showFolderDropOffModal}
                    folderId={selectedFolders[0]?.folderId}
                    onHide={handleHideFolderDropOffModal}
                />
            )}
        </div>
    );
};

export default FolderActionsPopUp;
