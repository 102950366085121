import React, { FC, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchSharedFolderMessageFileViewData, requestDownloadLater, requestDownloadNow } from "../../../actionCreators/folderActionCreator";
import { IMessageFileViewModel } from "../../../core/viewModels/message/MessageFileViewModel";
import { MyDownloadConstants, MyDownloadType, initialPagination } from "../../../helper/Constants";
import { getDocumentRequestPayload, getMessagePayload } from "../../../helper/HelperFunctions";
import { DownloadLaterIcon, DownloadNowIcon } from "../../svg/IconCollection";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";
import { IFolderModel } from "../../../core/viewModels/folder/FolderModel";
import { IDocumentRequestFileModel } from "../../../core/viewModels/documentRequest/DocumentRequestFileModel";
import { CustomModal } from "../../common/ui/Modals/CustomModal";
import { downloaAllDocumentRequestFiles, fetchDocumentRequestFileList, requestDRDownloadLater } from "../../../actionCreators/documentRequestActionCreators";
import { FolderType } from "../../../core/common/enums";

interface IDownloadModalProps {
    show: boolean;
    onHide: () => void;
    selectedFiles: IMessageFileViewModel[] | IDocumentRequestFileModel[];
    setSelectedFiles: React.Dispatch<React.SetStateAction<IMessageFileViewModel[]>> | React.Dispatch<React.SetStateAction<IDocumentRequestFileModel[]>>;
    folder: IFolderModel;
    handleResetSearchAndSort: () => void;
    resourceId?:string
    isDR?:boolean
}

const DownloadModal: FC<IDownloadModalProps> = ({
    show,
    onHide,
    selectedFiles,
    setSelectedFiles,
    handleResetSearchAndSort,
    folder: { folderId,folderName },
    resourceId,
    isDR

}) => {
    const [selectedType, setSelectedType] = useState<number>(0);
    const dispatch = useDispatch();

    const downloadCallback = () => {
        dispatch(
            fetchSharedFolderMessageFileViewData(
                folderId,
                initialPagination.currentPage,
                initialPagination.pageSize,
                "",
                ""
            ));
        setSelectedFiles([]);
        handleResetSearchAndSort();
    };

    const downloadDRCallback = () => {
        dispatch(
            fetchDocumentRequestFileList(folderId || FolderType.DOCUMENTREQUEST,
                initialPagination.currentPage,
                initialPagination.pageSize,
                "",
                ""
            )
        );
        setSelectedFiles([]);
    };
    const handleOk: React.MouseEventHandler<HTMLButtonElement> = () => {
        if (selectedType === MyDownloadType.DOWNLOAD_NOW) {
            if(isDR){
                dispatch(downloaAllDocumentRequestFiles(getDocumentRequestPayload(selectedFiles as IDocumentRequestFileModel[]),resourceId,() => downloadDRCallback()));
            }
            else{
                dispatch(requestDownloadNow(getMessagePayload(selectedFiles as IMessageFileViewModel[]), () => downloadCallback(),resourceId));
            }
            handleOnHide();
        } else if (selectedType === MyDownloadType.DOWNLOAD_LATER) {

            if(isDR){
                dispatch(
                    requestDRDownloadLater(getDocumentRequestPayload(selectedFiles as IDocumentRequestFileModel[]), folderName, () => downloadCallback())
                );
            }
            else{
                dispatch(
                requestDownloadLater(getMessagePayload(selectedFiles as IMessageFileViewModel[]), folderName, () => downloadCallback())
            );
        }
            handleOnHide();
        }
    };
    const handleOnHide = () => {
        onHide();
        setSelectedType(0);
    };
    const handleTypeSelection = (type: number) => {
        setSelectedType(type);
    };
    return (
        <CustomModal
            show={show}
            onHide={handleOnHide}
            className="file-view-download-modal"
            onSubmit={handleOk}
            isConfirmButtonDisabled={!selectedType}
            confirmButtonName="Ok"
            cancelButtonName="Cancel"
            title={MyDownloadConstants.MODAL_TITLE}
            cancelButtonAutomationId={AutomationIdConstants.deleteBtnModal.CancelBtn}
            conformButtonAutomationId={AutomationIdConstants.deleteBtnModal.ConfirmBtn}
            isConfirmModal={true}
        >

            <div className="description">
                <i className="fas fa-info-circle margin-right-5-px" />
                <span>{MyDownloadConstants.INFO_TEXT}</span>
            </div>
            <div className="download-type-container">
                <div
                    className={`download-card ${selectedType === MyDownloadType.DOWNLOAD_NOW ? "selected" : ""}`}
                    onClick={() => handleTypeSelection(MyDownloadType.DOWNLOAD_NOW)}
                >
                    <div className="title">{MyDownloadConstants.DOWNLOAD_NOW}</div>
                    <DownloadNowIcon height={60} width={60} />
                    <div className="download-description">{MyDownloadConstants.DOWNLOAD_NOW_DESCRIPTION}</div>
                </div>
                <div
                    className={`download-card ${selectedType === MyDownloadType.DOWNLOAD_LATER ? "selected" : ""}`}
                    onClick={() => handleTypeSelection(MyDownloadType.DOWNLOAD_LATER)}
                >
                    <div className="title">{MyDownloadConstants.DOWNLOAD_LATER}</div>
                    <DownloadLaterIcon height={60} width={60} />
                    <div className="download-description">{MyDownloadConstants.DOWNLOAD_LATER_DESCRIPTION}</div>
                </div>
            </div>
        </CustomModal>
    );
};

export default DownloadModal;
