import { LoadingOverlay } from "react-overlay-loader";
import React, { useEffect, useState } from "react";
import "./style.scss";
import { ITabModel, getSettingsTab } from "../../../core/viewModels/settings/Settings";
import Tab from "./parts/Tab";
import { useLocation } from "react-router-dom";
import { TabType } from "../../../core/common/enums";

const AdminSettings = () => {
    const [title, setTitle] = useState<string>("");
    const [tabList, setTabList] = useState<ITabModel[]>([]);
    const [activeTab, setActiveTab] = useState<TabType>();
    const path = useLocation().pathname.split("/").pop();
    const handleTabClick = (tabType: TabType) => {
        setTabList(
            tabList.map(tab => (tab.type === tabType ? { ...tab, isActive: true } : { ...tab, isActive: false }))
        );
        setActiveTab(tabType);
    };
    useEffect(() => {
        const settings = path && getSettingsTab(path);
        if (settings) {
            setTabList(settings.items);
            setActiveTab(settings.items.filter(tab => tab.isActive)[0].type);
            setTitle(settings.title);
        }
    }, [path]);

    return (
        <LoadingOverlay className={`settings-container ${activeTab===TabType.RequestNotification && "request-notification"}`}>
            <h3 className="settings-title">{title}</h3>
            <Tab tabs={tabList} handleTabClick={handleTabClick} activeTab={activeTab} />
        </LoadingOverlay>
    );
};

export default AdminSettings;
