import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

import {
    deleteDropOffSender,
    requestDropOffSendersList,
    updateDropOffSender,
} from "../../../../../../../actionCreators/dropOffActionCreators";
import { IDropOffSender } from "../../../../../../../core/viewModels/company/settings/DropOffSettings";
import { AppNotifier } from "../../../../../../../helper/AppNotifier";
import { DropOffModalConstants, DropOffUsersConstants } from "../../../../../../../helper/Constants";
import { ApplicationState } from "../../../../../../../store";
import { AddSenderModal } from "../../../../../../common/ui/Modals/SettingsModal/AddSenderModal";
import { CustomModal } from "../../../../../../common/ui/Modals/CustomModal";
import { EditSenderModal } from "../../../../../../common/ui/Modals/SettingsModal/EditSenderModal";
import { SenderSearch } from "./SenderSearch";
import { SenderTable } from "./SenderTable";
import { ResourceIdLocators } from "../../../../../../../helper/ResourceIdLocators";
import { ToasterMessages } from "../../../../../../../helper/ToasterMessages";

export const initialSenderModalState = {
    dropOffSenderId: 0,
    firstName: "",
    lastName: "",
    emailAddress: "",
    isBlacklisted: false,
    isDeleted: false,
};

export const DropOffSender: React.FC = () => {
    const [showDeleteSenderModal, setShowDeleteSenderModal] = useState(false);
    const [showEditSenderModal, setShowEditSenderModal] = useState(false);
    const [showAddSenderModal, setShowAddSenderModal] = useState(false);
    const [selectedSender, setSelectedSender] = useState<IDropOffSender[]>([]);
    const [senderPayloadState, setSenderPayloadState] = useState<IDropOffSender[]>([]);
    const [senderTableData, setSenderTableData] = useState<IDropOffSender[]>([]);
    const [senderModalState, setSenderModalState] = useState<IDropOffSender>(initialSenderModalState);
    const [reload, setReload] = useState(false);
    const [isBulkOperation, setIsBulkOperation] = useState(false);
    const dispatch = useDispatch();

    const dropOffSenders = useSelector(
        (state: ApplicationState) => state.dropOffState.dropOffSendersData.dropOffSenders
    );

    useEffect(() => {
        dispatch(requestDropOffSendersList(ResourceIdLocators.AdminSetting.DropOff));
        setSelectedSender([]);
    }, [reload]);

    useEffect(() => {
        setSenderTableData(dropOffSenders);
    }, [dropOffSenders]);

    const handleDeleteSenderSubmission = () => {
        const deleteSendersPayload = senderPayloadState.map(sender => sender.dropOffSenderId);
        dispatch(
            deleteDropOffSender(deleteSendersPayload,ResourceIdLocators.AdminSetting.DropOff, (result: boolean, senderIds: number[]) => {
                if (result) {
                    AppNotifier.Success(
                        senderIds.length > 1
                            ? ToasterMessages.SUCCESS.DELETE_DROP_OFF_SENDERS
                            : ToasterMessages.SUCCESS.DELETE_DROP_OFF_SENDER
                    );
                    setReload(reload => !reload);
                    clearModalState();
                }
            })
        );

        setShowEditSenderModal(false);
    };

    const handlEditSenderSubmission = (emailId: string) => {
        const senderPayload = [];
        const updatedSender = { ...senderModalState, ["emailAddress"]: emailId };
        senderPayload.push(updatedSender);
        dispatch(
            updateDropOffSender(senderPayload,ResourceIdLocators.AdminSetting.DropOff, (result: boolean) => {
                if (result) {
                    AppNotifier.Success(ToasterMessages.SUCCESS.EDIT_SENDER_EMAIL);
                    setReload(reload => !reload);
                    clearModalState();
                }
            })
        );

        setShowEditSenderModal(false);
    };
    const handlAddSenderSubmission = (emailId: string, isBlackListed: boolean) => {
        const senderPayload = [];
        const updatedSender = {
            ...senderModalState,
            ["emailAddress"]: emailId.trim(),
            [DropOffUsersConstants.IS_BLACKLISTED]: isBlackListed,
        };
        senderPayload.push(updatedSender);
        dispatch(
            updateDropOffSender(senderPayload, ResourceIdLocators.AdminSetting.DropOff,(result: boolean) => {
                if (result) {
                    AppNotifier.Success(ToasterMessages.SUCCESS.ADD_SENDER);
                    setReload(reload => !reload);
                    clearModalState();
                }
            })
        );

        setShowAddSenderModal(false);
    };

    const handleDeleteSenderClick = (selectedSender: IDropOffSender[]) => {
        if (selectedSender.length > 1) {
            setIsBulkOperation(true);
        } else {
            setIsBulkOperation(false);
        }
        setShowDeleteSenderModal(true);
        setSenderPayloadState(selectedSender);
    };

    const handleEditSenderClick = (selectedSender: IDropOffSender) => {
        setSelectedSender([selectedSender]);
        setSenderModalState({
            ...selectedSender,
            ["emailAddress"]: selectedSender.emailAddress,
        });
        setShowEditSenderModal(true);
    };

    const handleWhitelistSenderClick = (selectedSender: IDropOffSender[]) => {
        const senderPayload = [];
        if (selectedSender.length > 1) {
            for (let i = 0; i < selectedSender.length; i++) {
                senderPayload.push({ ...selectedSender[i], [DropOffUsersConstants.IS_BLACKLISTED]: false });
            }
        } else {
            const individualSender = { ...selectedSender[0], [DropOffUsersConstants.IS_BLACKLISTED]: false };
            senderPayload.push(individualSender);
        }

        dispatch(
            updateDropOffSender(senderPayload,ResourceIdLocators.AdminSetting.DropOff, (result: boolean) => {
                if (result) {
                    AppNotifier.Success(
                        senderPayload.length > 1
                            ? ToasterMessages.SUCCESS.WHITELIST_DROP_OFF_SENDERS
                            : ToasterMessages.SUCCESS.WHITELIST_DROP_OFF_SENDER
                    );
                    setReload(reload => !reload);
                }
            })
        );
    };
    const handleBlacklistSenderClick = (selectedSender: IDropOffSender[]) => {
        const senderPayload = [];
        if (selectedSender.length > 1) {
            for (let i = 0; i < selectedSender.length; i++) {
                senderPayload.push({ ...selectedSender[i], [DropOffUsersConstants.IS_BLACKLISTED]: true });
            }
        } else {
            const individualSender = { ...selectedSender[0], [DropOffUsersConstants.IS_BLACKLISTED]: true };
            senderPayload.push(individualSender);
        }

        dispatch(
            updateDropOffSender(senderPayload,ResourceIdLocators.AdminSetting.DropOff, (result: boolean) => {
                if (result) {
                    AppNotifier.Success(
                        senderPayload.length > 1
                            ? ToasterMessages.SUCCESS.BLACKLIST_DROP_OFF_SENDERS
                            : ToasterMessages.SUCCESS.BLACKLIST_DROP_OFF_SENDER
                    );
                    setReload(reload => !reload);
                }
            })
        );
    };

    const clearModalState = () => {
        setShowDeleteSenderModal(false);
        setShowEditSenderModal(false);
        setShowAddSenderModal(false);
        setSelectedSender([]);
        setSenderPayloadState([]);
        setSenderModalState(initialSenderModalState);
    };

    return (
        <div className="DropUser-wrapper">
            <div className="search-button-wrapper">
                <SenderSearch reload={reload} setSenderTableData={setSenderTableData} />
                <Button
                    onClick={() => setShowAddSenderModal(true)}
                    className="add-question-btn"
                    variant="default"
                >
                    Add Sender
                </Button>
            </div>
            <div className="dropOff-table-container">
                <SenderTable
                    senderTableData={senderTableData}
                    handleEditClick={handleEditSenderClick}
                    updateSelectedSender={setSelectedSender}
                    selectedSender={selectedSender}
                    handleDeleteSenderClick={handleDeleteSenderClick}
                    handleWhitelistSenderClick={handleWhitelistSenderClick}
                    handleBlacklistSenderClick={handleBlacklistSenderClick}
                />
            </div>

            {showDeleteSenderModal && (
                <CustomModal
                    show={showDeleteSenderModal}
                    onHide={() => {
                        clearModalState();
                    }}
                    onSubmit={handleDeleteSenderSubmission}
                    cancelButtonName="Cancel"
                    confirmButtonName="Ok"
                    isConfirmModal={true}
                    className="delete-senders-modal"
                    title={
                        isBulkOperation
                            ? DropOffModalConstants.DROP_OFF_SENDERS__DELETE_MODAL_TITLE
                            : DropOffModalConstants.DROP_OFF_SENDER__DELETE_MODAL_TITLE
                    }
                >
                    {isBulkOperation
                        ? DropOffModalConstants.DROP_OFF_SENDERS_DELETE_MODAL_BODY
                        : DropOffModalConstants.DROP_OFF_SENDER_DELETE_MODAL_BODY}
                </CustomModal>
            )}
            {showEditSenderModal && (
                <EditSenderModal
                    intialValue={senderModalState.emailAddress}
                    showEditSenderModal={showEditSenderModal}
                    clearModalState={clearModalState}
                    handleEditModalSubmission={handlEditSenderSubmission}
                />
            )}
            {showAddSenderModal && (
                <AddSenderModal
                    showAddSenderModal={showAddSenderModal}
                    clearModalState={clearModalState}
                    handlAddSenderSubmission={handlAddSenderSubmission}
                />
            )}
        </div>
    );
};
