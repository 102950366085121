import React, { useState } from "react";
import { useEffect } from "react";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchMessageSecurityQuestionSettings,
    updateAdminSettings,
} from "../../../../../actionCreators/adminSettingsActionCreators";
import {
    deleteCompanySecurityQuestions,
    fetchMessageSecurityQuestions,
} from "../../../../../actionCreators/securityQuestionCreators";
import { CompanySettingsName, SettingMode } from "../../../../../core/common/enums";
import { IMessageSecurityQuestion } from "../../../../../core/viewModels/message/MessageSecurityQuestionModel";
import { AppNotifier } from "../../../../../helper/AppNotifier";
import { CompanyAuthQuestionsConstants } from "../../../../../helper/Constants";
import { ApplicationState } from "../../../../../store";
import { AddQuestionModal, initialAddQuestionState } from "../../../../common/ui/Modals/SettingsModal/AddQuestionModal";
import { CustomModal } from "../../../../common/ui/Modals/CustomModal";
import CustomToggleSwitch from "../../../../common/ui/switch/CustomToggleSwitch";
import { AuthQuestionsTable } from "./AuthQuestionsTable";
import { appInsightsData } from "../../../../../helper/AppInsightsData";
import { logger } from "../../../../../oidcClient/authProvider";
import { ToasterMessages } from "../../../../../helper/ToasterMessages";

export interface ICompanyAuthQuestionsState {
    questions: IMessageSecurityQuestion[];
    isUserAllowedToAddSecurityQuestion: boolean;
    isUserAllowedToChangeDefaultSecurityQuestion: boolean;
    defaultSecurityQuestionId: number;
}

export const AuthenticationQuestions: React.FC = () => {
    const dispatch = useDispatch();
    const securityQuestionStore = useSelector((state: ApplicationState) => state.securityQuestionState);
    const adminSettingsStore = useSelector((state: ApplicationState) => state.adminSettingsState);
    const { messageSecuritySettings, isLoading: isAdminLoading } = adminSettingsStore;
    const storeQuestions = securityQuestionStore.companySecurityQuestions.data;
    const [authQuestionsState, setAuthQuestionsState] = useState<ICompanyAuthQuestionsState>({
        questions: storeQuestions,
        isUserAllowedToAddSecurityQuestion: true,
        isUserAllowedToChangeDefaultSecurityQuestion: true,
        defaultSecurityQuestionId: 0,
    });
    const [showAddQuestionsModal, setShowAddQuestionsModal] = useState(false);
    const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);
    const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);
    const [selectedDeleteOption, setSelectedDeleteOption] = useState("");
    const [selectedQuestions, setSelectedQuestions] = useState<IMessageSecurityQuestion[]>([]);
    const [selectedQuestionsPayload, setSelectedQuestionsPayload] = useState<IMessageSecurityQuestion[]>([]);
    const [reload, setReload] = useState(false);
    const [isUserAllowedToUpdateAdminSettings, setIsUserAllowedToUpdateAdminSettings] = useState(false);

    useEffect(() => {
        // Track Page view event
        logger.trackPageView(appInsightsData.AdminSettings.AuthenticationQuestions.PageTitle);
    }, []);

    useEffect(() => {
        dispatch(fetchMessageSecurityQuestionSettings());
        dispatch(fetchMessageSecurityQuestions(SettingMode.COMPANY));
    }, [reload]);

    useEffect(() => {
        const {
            defaultSecurityQuestionId,
            isUserAllowedToAddSecurityQuestion,
            isUserAllowedToChangeDefaultSecurityQuestion,
        } = { ...messageSecuritySettings };
        if (storeQuestions.length > 0) {
            setAuthQuestionsState({
                ...authQuestionsState,
                questions: storeQuestions,
                defaultSecurityQuestionId,
                isUserAllowedToAddSecurityQuestion,
                isUserAllowedToChangeDefaultSecurityQuestion,
            });
        }
    }, [
        messageSecuritySettings,
        authQuestionsState.questions,
        authQuestionsState.defaultSecurityQuestionId,
        storeQuestions,
    ]);

    useEffect(() => {
        if (isUserAllowedToUpdateAdminSettings) {
            const updateSettingsPayload = getMessageSecuritySettingsPayload(authQuestionsState);
            dispatch(
                updateAdminSettings(
                    CompanySettingsName.MessageSecurityQuestionSettings,
                    {
                        ...updateSettingsPayload,
                    },
                    () => {
                        dispatch(fetchMessageSecurityQuestions(SettingMode.COMPANY));
                        dispatch(fetchMessageSecurityQuestionSettings());
                        setIsUserAllowedToUpdateAdminSettings(false);
                    }
                )
            );
        }
    }, [isUserAllowedToUpdateAdminSettings]);

    const getMessageSecuritySettingsPayload = (data: ICompanyAuthQuestionsState) => {
        return {
            isUserAllowedToAddSecurityQuestion: data.isUserAllowedToAddSecurityQuestion,
            isUserAllowedToChangeDefaultSecurityQuestion: data.isUserAllowedToChangeDefaultSecurityQuestion,
            defaultSecurityQuestionId: data.defaultSecurityQuestionId,
        };
    };

    const handleToggleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        const value = e.target.checked;

        setAuthQuestionsState(prevState => {
            return {
                ...prevState,
                [name]: value,
            };
        });

        setIsUserAllowedToUpdateAdminSettings(true);
    };

    const handleDeleteQuestionSubmission = () => {
        //Question deletion dispatch calls

        const deleteQuestionsPayload = selectedQuestionsPayload.map(question => question.messageSecurityQuestionId);
        dispatch(
            deleteCompanySecurityQuestions(deleteQuestionsPayload, (result: number, ids: number[]) => {
                if (result) {
                    AppNotifier.Success(
                        ids.length > 1
                            ? ToasterMessages.SUCCESS.DELETE_QUESTIONS
                            : ToasterMessages.SUCCESS.DELETE_QUESTION
                    );
                    setReload(!reload);
                }
            })
        );
        setSelectedQuestions([]);
        setShowDeleteQuestionModal(false);
    };

    const handleEditQuestionClick = (selectedQuestion: IMessageSecurityQuestion) => {
        if (selectedQuestion) {
            setSelectedQuestionsPayload([selectedQuestion]);
            setShowEditQuestionModal(true);
        }
    };

    const handleSetDefaultClick = (selectedQuestion: IMessageSecurityQuestion) => {
        if (!selectedQuestion.isDefault) {
            setAuthQuestionsState(prevState => ({
                ...prevState,
                defaultSecurityQuestionId: selectedQuestion.messageSecurityQuestionId,
            }));
            setIsUserAllowedToUpdateAdminSettings(true);

            setSelectedQuestions([]);
            setReload(!reload);
        }
    };

    const handleDeleteQuestionClick = (selectedQuestions: IMessageSecurityQuestion[]) => {
        if (selectedQuestions.length > 0) {
            setSelectedQuestionsPayload(selectedQuestions);
            setShowDeleteQuestionModal(true);
        }
    };

    const clearModalState = () => {
        setShowAddQuestionsModal(false);
        setShowEditQuestionModal(false);
        setSelectedQuestions([]);
        setSelectedQuestionsPayload([]);
        setShowDeleteQuestionModal(false);
    };

    return (
        <LoadingOverlay>
            <>
                <header>
                    <span>{CompanyAuthQuestionsConstants.TITLE_HELPER_TEXT}</span>
                </header>
                <section className="margin-top-10-px">
                    <div className="settings-label-toggle-wrapper">
                        <CustomToggleSwitch
                            name={CompanyAuthQuestionsConstants.ALLOW_TO_ADD_OWN_QUESTIONS}
                            handleChange={handleToggleSwitchChange}
                            switched={authQuestionsState.isUserAllowedToAddSecurityQuestion}
                            label={CompanyAuthQuestionsConstants.ALLOW_TO_ADD_QUESTION_TOGGLE_TEXT}
                        />
                    </div>

                    <div className="settings-label-toggle-wrapper">
                        <CustomToggleSwitch
                            name={CompanyAuthQuestionsConstants.ALLOW_TO_SET_DEFAULT_QUESTIONS}
                            handleChange={handleToggleSwitchChange}
                            switched={authQuestionsState.isUserAllowedToChangeDefaultSecurityQuestion}
                            label={CompanyAuthQuestionsConstants.ALLOW_TO_CHANGE_DEFAULT_QS_TEXT}
                        />
                    </div>
                </section>

                <div className="margin-top-10-px authentication-questions-table-container">
                    <AuthQuestionsTable
                        isContentLoading={false}
                        data={authQuestionsState.questions}
                        selectedQuestions={selectedQuestions}
                        updateSelectedQuestions={setSelectedQuestions}
                        handleEditQuestionClick={handleEditQuestionClick}
                        setShowAddQuestionsModal={value => setShowAddQuestionsModal(value)}
                        handleSetDefaultClick={handleSetDefaultClick}
                        handleDeleteQuestionClick={handleDeleteQuestionClick}
                        setSelectedDeleteOption={setSelectedDeleteOption}
                    />
                </div>

                {showAddQuestionsModal && (
                    <AddQuestionModal
                        isUserSecurityQuestion={false}
                        modalType="Add"
                        showModal={showAddQuestionsModal}
                        hideModal={clearModalState}
                        initialData={initialAddQuestionState}
                        isProcessing={securityQuestionStore.companySecurityQuestions.isProcessing}
                        setReload={() => setReload(!reload)}
                        clearModalState={clearModalState}
                    />
                )}

                {showEditQuestionModal && (
                    <AddQuestionModal
                        isUserSecurityQuestion={false}
                        modalType="Edit"
                        showModal={showEditQuestionModal}
                        hideModal={clearModalState}
                        initialData={selectedQuestionsPayload[0]}
                        isProcessing={securityQuestionStore.companySecurityQuestions.isLoading || isAdminLoading}
                        setReload={() => setReload(!reload)}
                        clearModalState={clearModalState}
                    />
                )}
                {showDeleteQuestionModal && (
                    <CustomModal
                        className="delete-question-modal"
                        isConfirmModal={true}
                        show={showDeleteQuestionModal}
                        onHide={clearModalState}
                        onSubmit={handleDeleteQuestionSubmission}
                        cancelButtonName="Cancel"
                        confirmButtonName="Ok"
                        title={
                            selectedDeleteOption === "headerDelete"
                                ? CompanyAuthQuestionsConstants.DELETE_QUESTIONS_MODAL_TITLE
                                : CompanyAuthQuestionsConstants.DELETE_QUESTION_MODAL_TITLE
                        }
                    >
                        {selectedDeleteOption === "headerDelete"
                            ? CompanyAuthQuestionsConstants.DELETE_QUESTIONS_MODAL_TEXT
                            : CompanyAuthQuestionsConstants.DELETE_QUESTION_MODAL_TEXT}
                    </CustomModal>
                )}
            </>
            <Loader loading={securityQuestionStore.companySecurityQuestions.isLoading || isAdminLoading} />
        </LoadingOverlay>
    );
};
