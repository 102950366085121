
import { ISavedMessageTemplateModel } from "../core/viewModels/settings/SavedMessageTemplate";
import produce from "immer";
import { actionTypes } from "../types/SavedMessageTypes";
import { Action ,Reducer} from "redux";
import * as SavedMessageTemplate  from "../actionCreators/SavedMessageActionCreator";

export interface ISavedMessageTemplateStore {
 data: ISavedMessageTemplateModel[];
 isLoading: boolean;
}

const initialState: ISavedMessageTemplateStore = {
    data: [],
    isLoading: false,
    };

export const reducer : Reducer<ISavedMessageTemplateStore> = produce(
    (state: ISavedMessageTemplateStore = initialState, incomingAction: Action) => {
        const action = incomingAction as SavedMessageTemplate.KnownActions;
        switch (action.type) {
            case actionTypes.REQUEST_MESSAGE_MAIL_TEMPLATES_LIST:
            case actionTypes.CREATE_MESSAGE_MAIL_TEMPLATE:
            case actionTypes.DELETE_MESSAGE_MAIL_TEMPLATE:
            case actionTypes.UPDATE_MESSAGE_MAIL_TEMPLATE:
                return { ...state, isLoading: true };
            case actionTypes.RECEIVE_MESSAGE_MAIL_TEMPLATES_LIST:
                return { ...state, isLoading: false, data: action.data };
            case actionTypes.COMPLETED_MESSAGE_MAIL_TEMPLATES:
                return { ...state, isLoading: false };
            default:
                return state;
        }
    }
);