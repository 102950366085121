import React, { Dispatch, SetStateAction } from "react";
import { Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import DefaultPagination from "../../common/ui/DefaultPagination";
import { DocumentToolbar } from "./DocumentToolbar";
import { IPagination } from "../../../core/viewModels/message/SentMessageModel";
import {
    DocumentRequestConstants,
} from "../../../helper/Constants";
import { ApplicationState } from "../../../store";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { DetailedStatus, FolderType } from "../../../core/common/enums";
import { DocumentRequestListItem } from "./DocumentRequestListItem";
import {
    IDocumentRequestListModel,
    IDocumentRequestSearchModel,
} from "../../../core/viewModels/documentRequest/DocumentRequestModel";
import { listingDeleteDocumentRequest } from "../../../actionCreators/documentRequestActionCreators";
import { IFolderModel } from "../../../core/viewModels/folder/FolderModel";

interface IDocumentRequestListProps {
    documents: IDocumentRequestListModel[];
    isArchive?: boolean;
    folder?: IFolderModel;
    selectedDocumentList: IDocumentRequestListModel[];
    selectedDocument: IDocumentRequestListModel;
    totalDocuments: number;
    pagination: IPagination;
    resetSortAndFilterOptions: boolean;
    setPagination: Dispatch<SetStateAction<IPagination>>;
    setSelectedDocument: Dispatch<SetStateAction<IDocumentRequestListModel>>;
    getDocumentDetail: (documentRequestId: number) => void;
    handleDocumentResendAccessLink: (documentRequestIds: number[], isListingAction?: boolean) => void;
    handleDocumentSendReminder: (documentRequestIds: number[], isListingAction: boolean, resourceId: string) => void;
    fetchDocumentRequests: (documentRequestSearchModel: IDocumentRequestSearchModel) => void;
    updateSelectedDocumentList: Dispatch<SetStateAction<IDocumentRequestListModel[]>>;
    resetSelection: (drlRefresh?:boolean) => void;
    handleDRListRelaod: () => void;
    handleOnPageChange: (pageNo: number, pageSize?: number) => void;
}

export const DocumentRequestList: React.FC<IDocumentRequestListProps> = ({
    documents,
    isArchive,
    folder,
    totalDocuments,
    selectedDocument,
    setSelectedDocument,
    selectedDocumentList,
    updateSelectedDocumentList,
    pagination,
    getDocumentDetail,
    resetSelection,
    handleDocumentResendAccessLink,
    handleDocumentSendReminder,
    handleDRListRelaod,
    handleOnPageChange,
    
}) => {
    const dispatch = useDispatch();
    const documentState = useSelector((state: ApplicationState) => state.documentState);


    const handleOnSelect = (document: IDocumentRequestListModel, e: any) => {
        const selectedDocumentListCopy = [...selectedDocumentList];
        if (e.target.checked) {
            selectedDocumentListCopy.push(document);
        } else {
            const index = selectedDocumentListCopy.findIndex(
                item => item.documentRequestId === document.documentRequestId
            );
            if (index > -1) {
                selectedDocumentListCopy.splice(index, 1);
            }
        }
        updateSelectedDocumentList(selectedDocumentListCopy);
    };

    const handleOnSelectAll = (isSelect: boolean) => {
        if (isSelect) {
            updateSelectedDocumentList(documents);
        } else {
            updateSelectedDocumentList([]);
        }
    };
    const handleDocumentItemClick = (document: IDocumentRequestListModel) => {
        setSelectedDocument(document);
        getDocumentDetail(document.documentRequestId);
    };

    const handleDeleteDocumentRequest = () => {
        dispatch(
            listingDeleteDocumentRequest(
                selectedDocumentList,
                isArchive ? ResourceIdLocators.MyFiles.MyFilesBtnRequestDelete : ResourceIdLocators.DRL.DRLListBtnDeleteRequest,
                folder?.folderId ? folder?.folderId : FolderType.DOCUMENTREQUEST,
                resetSelection
            )
        );
    };

    const onClickDocumentResendAccessLink = () => {
        const documentRequestIds = selectedDocumentList.map(item => item.documentRequestId);
        handleDocumentResendAccessLink(documentRequestIds, true);
    };

    const onClickDocumentSendReminder = () => {
        const documentRequestIds: number[] = [];
        selectedDocumentList.map(item => {
            if (item.status !== DetailedStatus.COMPLETED && item.documentRequestId) {
                documentRequestIds.push(item.documentRequestId);
            }
        });
        handleDocumentSendReminder(documentRequestIds, true, ResourceIdLocators.DRL.DRLListBtnSendReminder);
    };

    return (
        <>
                <div className={`message-list-wrapper ${isArchive ? "my-files-request-padding" : ""}`}>
                    <Row>
                        <Col>
                            <DocumentToolbar
                                isAllSelected={selectedDocumentList.length === documents?.length}
                                isAnySelected={selectedDocumentList.length > 0}
                                isArchive={isArchive}
                                folder={folder}
                                showSelectAllCheckbox={documents && documents?.length > 0}
                                selectedDocumentList={selectedDocumentList}
                                handleOnSelectAll={handleOnSelectAll}
                                resetSelection={resetSelection}
                                handleDeleteDocumentRequest={handleDeleteDocumentRequest}
                                handleDocumentResendAccessLink={onClickDocumentResendAccessLink}
                                handleDocumentSendReminder={onClickDocumentSendReminder}
                                handleDRListRelaod={handleDRListRelaod}
                                />
                        </Col>
                        <Col>
                            {documents && documents?.length > 0 && (
                                <DefaultPagination
                                    pageSize={pagination.pageSize}
                                    currentPage={pagination.currentPage}
                                    totalRecords={totalDocuments}
                                    onPageChange={handleOnPageChange}
                                    />
                            )}
                        </Col>
                    </Row>
                    <LoadingOverlay style={{ innerHeight: "400px", outerHeight: "500px" }}>
                        <div className="message-list document-list">
                            {documents && documents?.length > 0 ? (
                                documents.map((document, index) => {
                                    const isChecked =
                                    selectedDocumentList.includes(document) ||
                                        selectedDocumentList.length === documents?.length;
                                    const selectedDocumentId =
                                        selectedDocument.documentRequestId === 0
                                        ? documents[0].documentRequestId
                                        : selectedDocument.documentRequestId;
                                    return (
                                        <DocumentRequestListItem
                                            key={index}
                                            onClickDocumentItem={handleDocumentItemClick}
                                            onClickCheckBox={handleOnSelect}
                                            document={document}
                                            folder={folder}
                                            index={index}
                                            isChecked={isChecked}
                                            selectedDocumentId={selectedDocumentId}
                                            />
                                    );
                                })
                            ) : (
                                <div className="vertical-middle">{DocumentRequestConstants.NO_DOCUMENT_REQUESTS}</div>
                            )}
                        </div>
                        <Loader loading={documentState.isLoading} />
                    </LoadingOverlay>
                </div>
            </>
    );
};
