
export enum actionTypes {
    
    REQUEST_MESSAGE_MAIL_TEMPLATES_LIST = "request_message_mail_templates",
    RECEIVE_MESSAGE_MAIL_TEMPLATES_LIST = "received_message_mail_templates",
    COMPLETED_MESSAGE_MAIL_TEMPLATES = "completed_message_mail_templates",

    CREATE_MESSAGE_MAIL_TEMPLATE = "create_message_mail_template",

    UPDATE_MESSAGE_MAIL_TEMPLATE = "update_message_mail_template",

    DELETE_MESSAGE_MAIL_TEMPLATE = "delete_message_mail_template",

}
