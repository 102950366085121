import React, { FC, useState } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { MyAccountTitle } from "../../../helper/Constants";
import { logger } from "../../../oidcClient/authProvider";
import MyAccountContent from "./MyAccountContent";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";

export interface IMyAccountLayout {
    onHide: () => void;
}

const iconKey: { [key: string]: string } = {
    profile: "Profile",
    password: "ChangePassword",
};

const MyAccountLayout: FC<IMyAccountLayout> = ({ onHide }) => {
    const menuKeys = Object.keys(MyAccountTitle),
        [selectedTab, setSelecteTab] = useState<string>(menuKeys[0]);
    

    const handleTabChange = (key: string) => {
        setSelecteTab(key);
        if (key === iconKey.profile) {
            logger.trackPageView(appInsightsData.MyAccount.ProfilePageTitle);
        } else if (key === iconKey.password) {
            logger.trackPageView(appInsightsData.MyAccount.PasswordPageTitle);
        }
    };
    return <Tab.Container defaultActiveKey={menuKeys[0]} onSelect={(key) => key && handleTabChange(key)} activeKey={selectedTab}>
        <Row className="my-settings-tabs my-account">
            <Col xs={3} className="bg-grey settings-sidebar padding-0 padding-top-bottom-2">
                <Nav className="flex-column">
                    {menuKeys.map((data: string, index: number) => <Nav.Item key={`${data}-${index}`}>
                        <Nav.Link eventKey={data}>
                            <span className="margin-left-3" data-auto-test={AutomationIdConstants.userSettingsMyAccount.MyAccountProfiletitle}>{MyAccountTitle[data]}</span>
                        </Nav.Link>
                    </Nav.Item>)}
                </Nav>
            </Col>
            <Col className="height-100-percent">
                <Tab.Content className="padding-4 height-100-percent">
                    {menuKeys.map((data: string, index: number) => <Tab.Pane eventKey={data} key={`${data}-${index}`}>
                        <MyAccountContent eventKey={data} onHide={onHide} />
                    </Tab.Pane>)}
                </Tab.Content>
            </Col>
        </Row>
    </Tab.Container>;
};

export default MyAccountLayout;