import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchLastLoginEnable, updateLastLoginEnable } from "../../actionCreators/userSettingsActionCreators";
import { IProfileDropdownModalProps } from "../../core/viewModels/layout/HeaderModel";
import { appInsightsData } from "../../helper/AppInsightsData";
import { LoginHistoryConstants } from "../../helper/Constants";
import { logger } from "../../oidcClient/authProvider";
import { ApplicationState } from "../../store";
import CustomToggleSwitch from "../common/ui/switch/CustomToggleSwitch";
import LoginHistoryTable from "./parts/LoginHistroyTable";
import { CustomModal } from "../common/ui/Modals/CustomModal";

const LoginHistory: FC<IProfileDropdownModalProps> = ({ show, onHide }) => {
    const [enablePrompt, setEnablePrompt] = useState(false);
    const dispatch = useDispatch();
    const { enableLastLogin } = useSelector((state: ApplicationState) => state.userSettingsState.defaultSettings);
    const handleCancel = () => {
        onHide();
    };
    const handleToggleChange = (enable: boolean) => {
        dispatch(updateLastLoginEnable(!enable));
        setEnablePrompt(!enable);
    };
    useEffect(() => {
        setEnablePrompt(enableLastLogin);
    }, [enableLastLogin]);
    useEffect(() => {
        if (show) {
            dispatch(fetchLastLoginEnable());
            logger.trackPageView(appInsightsData.LoginHistory.PageTitle);
        }
    }, [show]);
    return (
        <CustomModal
            show={show}
            onHide={handleCancel}
            className="login-history-modal"
            cancelButtonName="Close"
            isConfirmModal={true}
            additionalFooterElement={
                <div className="prompt-control">
                    <CustomToggleSwitch
                        label={LoginHistoryConstants.PROMPT_TEXT}
                        switched={enablePrompt}
                        handleChange={() => handleToggleChange(enablePrompt)}
                    />
                    <i
                        className="fas fa-exclamation-circle margin-left-5-px"
                        title={LoginHistoryConstants.PROMPT_INFO}
                    />
                </div>
            }
            title={LoginHistoryConstants.TITLE}
            onSubmit={() => {
                // do nothing
            }
            }
        >

            <LoginHistoryTable />

        </CustomModal>
    );
};

export default LoginHistory;
