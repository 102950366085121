import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import { FolderType } from "./../../../../core/common/enums";
import { IDraftMessageModel } from "../../../../core/viewModels/message/DraftMailMessage";
import MessageToolbar from "../../receivedMessage/parts/MessageToolbar";
import DefaultPagination from "../../../common/ui/DefaultPagination";
import {
    ArchiveConstants,
    DEFAULT_PAGE_SIZE,
    MessageListConstants,
    SortAndFilterConstants,
    SortAndFilterIconType,
    initialPagination,
} from "../../../../helper/Constants";
import { IPagination } from "../../../../core/viewModels/message/SentMessageModel";
import DraftMessageListItem from "./DraftMessageListItem";
import { Col, FormControl, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { ResourceIdLocators } from "../../../../helper/ResourceIdLocators";
import { RefreshIcon, SearchIcon } from "../../../svg/IconCollection";
import { debounce } from "throttle-debounce";
import { FiltersCustomComponent } from "../../../common/ui/Filters/FiltersCustomComponent";
import { getFolderMessageSortOptions } from "../../../../helper/HelperFunctions";
interface IMessageListProps {
    messages: IDraftMessageModel[];
    totalRecords: number;
    folderId: number;
    folderType: FolderType;
    isLoading: boolean;
    reload?: boolean;
    deleteMessages: (messages: IDraftMessageModel[], callback: any) => void;
    getMessageDetail: (message: IDraftMessageModel) => void;
    fetchMessages: (pageNo: number, pageSize: number, sortColumn?: string, sortOrder?: string, search?: string) => void;
    markAsNotSpam?: (messageIds: number[], callback?: any) => void;
    moveToInbox?: (messageIds: number[], callback?: any) => void;
    hideMessageDetailView(hide: boolean): void;
    setPagination: Dispatch<SetStateAction<IPagination>>;
    pagination: IPagination;
}

const sortingData = [
    {
        title: SortAndFilterConstants.SORT_BY_TEXT,
        menus: [SortAndFilterConstants.NEWEST_FIRST_ORDER, SortAndFilterConstants.OLDEST_FIRST_ORDER],
    },
];

const DraftMessageList: React.FC<IMessageListProps> = props => {
    const { pagination, setPagination } = props;
    const [selectedMessages, setSelectedMessages] = useState<IDraftMessageModel[]>([]);
    const selectedDraftMessage = useSelector(
        (state: ApplicationState) => state.draftMessageState.mailData.data.messageDetail
    );
    const [searchTextBox, setSearchTextBox] = useState<string>("");
    const [selectedSortOption, setSelectedSortOption] = useState<string>(SortAndFilterConstants.NEWEST_FIRST_ORDER);

    const isAllSelected = selectedMessages.length === props.messages?.length;
    useEffect(() => {
        const sort = getFolderMessageSortOptions(selectedSortOption);
        setPagination(initialPagination);
        props.fetchMessages(
            initialPagination.currentPage,
            initialPagination.pageSize,
            sort.sortColumn,
            sort.sortOrder,
            searchTextBox
        );
    }, [selectedSortOption]);

    useEffect(() => {
        handleResetSearchAndSort();
    }, [props.reload]);

    useEffect(() => {
        if (searchTextBox === "") {
            setSelectedMessages([]);
        }
    }, [searchTextBox]);

    const handleOnPageChange = (pageNo: number, pageSize: number = DEFAULT_PAGE_SIZE) => {
        setPagination({
            pageSize,
            currentPage: pageNo,
            totalRecords: props.totalRecords,
        });
        const sort = getFolderMessageSortOptions(selectedSortOption);
        props.fetchMessages(pageNo, pageSize, sort.sortColumn, sort.sortOrder, searchTextBox);
    };

    const handleMessageReload = () => {
        props.fetchMessages(pagination.currentPage, pagination.pageSize, "");
        handleResetSearchAndSort();
        resetSelection();
    };

    const handleMessageDetailView = () => props.hideMessageDetailView(true);

    const handleOnSelected = (message: IDraftMessageModel, e: any) => {
        if (e.target.checked) {
            const messages = [...selectedMessages];
            messages.push(message);
            setSelectedMessages(messages);
        } else {
            const messages = [...selectedMessages];
            const messageIndex = messages.findIndex(mssg => mssg.draftedMessageId === message.draftedMessageId);
            messages.splice(messageIndex, 1);
            setSelectedMessages(messages);
        }
    };

    const handleOnAllSelected = (e: any) => {
        if (e.target.checked) {
            setSelectedMessages(props.messages);
        } else {
            setSelectedMessages([]);
        }
    };

    const handleDeleteMessages = (callback: any) => {
        setSearchTextBox("");
        props.deleteMessages(selectedMessages, callback);
        setSelectedMessages([]);
    };

    const handleOnMessageItemClick = (message: IDraftMessageModel, e: any) => {
        e.stopPropagation();
        props.getMessageDetail(message);
    };

    const resetSelection = () => {
        setSelectedMessages([]);
    };

    const onFilter: React.ChangeEventHandler<HTMLInputElement> = e => {
        const value = (e.target as HTMLInputElement).value.toLocaleLowerCase();
        //Set only search box
        setSearchTextBox(value);
        debounceFn(value);
    };

    const getData = (value: string) => {
        const sort = getFolderMessageSortOptions(selectedSortOption);
        setPagination(initialPagination);
        props.fetchMessages(
            initialPagination.currentPage,
            initialPagination.pageSize,
            sort.sortColumn,
            sort.sortOrder,
            value
        );
    };

    const debounceFn = useCallback(debounce(1000, getData), [selectedSortOption]);

    const handleResetSearchAndSort = () => {
        setSearchTextBox("");
        setSelectedSortOption && setSelectedSortOption(SortAndFilterConstants.NEWEST_FIRST_ORDER);
    };

    return (
        <>
            <Row className="search-header-wrapper nopadding-left">
                <Col sm={10} md={8} className="search-input-wrapper-col padding-left" style={{flexGrow:1}}>
                    <div className="search-input-wrapper">
                        <FormControl
                            placeholder={ArchiveConstants.ShareFolder.PLACEHOLDER_SEARCH_TEXT}
                            value={searchTextBox}
                            onChange={onFilter}
                            className="search-input search-textbox"
                        />
                        <div className="search-icon">
                            <SearchIcon/>
                        </div>
                    </div>
                </Col>
                <Col sm={2} md={2} className="filter-icon-container">
                    <div>
                        <FiltersCustomComponent
                            groups={sortingData}
                            selectedSortOption={selectedSortOption}
                            iconType={SortAndFilterIconType.Sort}
                            setSelectedSortOption={setSelectedSortOption}
                        />
                    </div>
                </Col>
                <Col sm={2} md={2} className="list-refresh-icon-container">
                    <div className="list-refresh-button" onClick={handleMessageReload}  title={ArchiveConstants.REFRESH_TITLE}>
                        <RefreshIcon />
                    </div>
                </Col>
            </Row>
            <div className="message-list-wrapper">
                <Row>
                    <Col lg={5}>
                        <MessageToolbar
                            folderType={props.folderType}
                            folderId={props.folderId}
                            selectedMessages={selectedMessages}
                            isAllSelected={isAllSelected}
                            isAnySelected={selectedMessages.length > 0}
                            selectAll={handleOnAllSelected}
                            deleteMessages={handleDeleteMessages}
                            reloadMessages={handleMessageReload}
                            hideMessageDetailView={handleMessageDetailView}
                            resetSelection={resetSelection}
                            showSelectAllCheckbox={props.messages && props.messages?.length > 0}
                            resourceLocators={ResourceIdLocators.Drafts}
                            resetSearchAndSort={handleResetSearchAndSort}
                        />
                    </Col>
                    <Col lg={7}>
                        {props.messages && props.messages?.length > 0 && (
                            <DefaultPagination
                                pageSize={pagination.pageSize}
                                currentPage={pagination.currentPage}
                                totalRecords={props.totalRecords}
                                onPageChange={handleOnPageChange}
                            />
                        )}
                    </Col>
                </Row>
                <LoadingOverlay style={{ innerHeight: "400px", outerHeight: "500px" }}>
                    <div className="message-list">
                        {props.messages && props.messages?.length > 0 ? (
                            props.messages?.map((item: IDraftMessageModel, index: number) => {
                                const isChecked = selectedMessages.includes(item) || isAllSelected;
                                const selectedMessageId =
                                    selectedDraftMessage.draftedMessageId === 0
                                        ? props.messages[0].draftedMessageId
                                        : selectedDraftMessage.draftedMessageId;
                                return (
                                    <DraftMessageListItem
                                        message={item}
                                        key={index}
                                        index={index}
                                        isChecked={isChecked}
                                        selectedMessageId={selectedMessageId}
                                        onSelected={handleOnSelected}
                                        onClick={handleOnMessageItemClick}
                                    />
                                );
                            })
                        ) : (
                            <div className="vertical-middle">{MessageListConstants.NO_MESSAGE_TEXT}</div>
                        )}
                    </div>
                    <Loader loading={props.isLoading} />
                </LoadingOverlay>
            </div>
        </>
    );
};

export default DraftMessageList;
