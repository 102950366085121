import React, { Dispatch, useEffect } from "react";
import { useDispatch } from "react-redux";
import BootstrapTable, { ColumnDescription, SelectRowProps } from "react-bootstrap-table-next";
import { formatBytes, getFileViewWrapperHeight } from "../../../helper/HelperFunctions";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { getFormattedLocalDateTime } from "../../../helper/DateHelperFunctions";
import { ArchiveConstants, DateFormatConstants } from "../../../helper/Constants";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import Checkbox, { CheckBoxSize } from "../../common/ui/Checkbox";
import { IDocumentRequestFileModel } from "../../../core/viewModels/documentRequest/DocumentRequestFileModel";
import { IFolderModel, initialFolder } from "../../../core/viewModels/folder/FolderModel";
import DownloadModal from "./DownloadModal";
import { FileFormatIcon } from "../../svg/FileIconCollection";
import { getFileExtension } from "../../../helper/Validations";
import { actionTypes } from "../../../types/documentRequestTypes";

interface RequestFileViewProps {
    downloadSingleAttachment: (
        fileGuid: string,
        uploadedDate: Date,
        fileName: string,
    ) => void;
    setShowDownloadModal: Dispatch<React.SetStateAction<boolean>>;
    showDownloadModal: boolean;
    selectedFiles: IDocumentRequestFileModel[];
    setSelectedFiles: Dispatch<React.SetStateAction<IDocumentRequestFileModel[]>>;
    currentFolder?: IFolderModel;
    sortBy?: any;
    handleResetSearchAndSort: () => void;
}

const RequestFileView: React.FC<RequestFileViewProps> = props => {
    const {
        downloadSingleAttachment,
        showDownloadModal,
        setShowDownloadModal,
        selectedFiles,
        setSelectedFiles,
        handleResetSearchAndSort,
        currentFolder,
    } = props;
    const dispatch = useDispatch();

    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);
    const { isLoading, fileData } = useSelector((state: ApplicationState) => state.documentState.documentRequestFileList);
    const fileSizeFormatter = (cell: string): JSX.Element => {
        return <span className="filesize-cell">{formatBytes(Number(cell))}</span>;
    };

    useEffect(() => {
        return () => {
            // clean up code
            dispatch({
                type: actionTypes.RESET_REQUEST_FILE_VIEW_DATA,
            });
        };
    }, []);

    const downloadDateFormatter = (cell: Date): JSX.Element => {
        return (
            <span className="sent-date-cell">
                {cell != null ? getFormattedLocalDateTime(cell, DateFormatConstants.REPORTS_DATE_FORMAT) : "NA"}
            </span>
        );
    };

      const fileNameFormatter = (cell: string, row: any): JSX.Element => {
        return (
            <div
                className="file-icon-name-container"
                title={row.fileName}
                data-resource-id={ResourceIdLocators.MyFiles.MyFilesBtnDownloadFile}
                onClick={() => {
                    downloadSingleAttachment(row.fileGuid, row.uploadedDate, row.fileName);
                    }
                }
            >
                <FileFormatIcon extension={getFileExtension(cell)} width={40} height={40}></FileFormatIcon>
                <p className="overflowText">{row.fileName}</p>
            </div>
        );
    };

    const setNoContent = () => {
        if (!isLoading && fileData.length === 0) {
            return ArchiveConstants.RequestView.NO_DATA_MESSAGE;
        }
    };

    const subjectFormatter = (cell: string): JSX.Element => {
        return (
            <span className="subject-cell overflowText" title={cell}>
                {cell? cell : "-"}
            </span>
        );
    };

    const uploadDateFormatter = (cell: Date): JSX.Element => {
        return (
            <span className="sent-date-cell">
                {getFormattedLocalDateTime(cell, DateFormatConstants.DEFAULT_DATE_FORMAT)}
            </span>
        );
    };

    const columns: ColumnDescription[] = [
        {
            text: "File Name",
            dataField: "fileName",
            formatter: fileNameFormatter,
            sort: true,
            headerStyle: { width: "18%" },
        },
        {
            text: "Document Name",
            dataField: "documentName",
            formatter: subjectFormatter,
            sort: true,
        },
        {
            text: "Document Category",
            dataField: "documentCategory",
            formatter: subjectFormatter,
            sort: true,
        },
        {
            text: "Last Download",
            dataField: "downloadDate",
            formatter: downloadDateFormatter,
            sort: true,
        },
        {
            text: "Size",
            dataField: "fileSize",
            formatter: fileSizeFormatter,
            sort: true,
            headerStyle: { width: "12%" },

        },
        {
            text: "Uploaded By",
            dataField: "taxPayerEmail",
            formatter: subjectFormatter,
            sort: true,
        },
        {
            text: "Uploaded Date",
            dataField: "uploadedDate",
            formatter: uploadDateFormatter,
            sort: true,
        },
    ];

    const handleOnSelect = (row: IDocumentRequestFileModel, isSelect: boolean) => {
        let selectedFilesCopy = [...selectedFiles];
        if (isSelect) {
            selectedFilesCopy.push(row);
        } else {
            selectedFilesCopy = selectedFilesCopy.filter(file => file.fileId != row.fileId);
        }
        setSelectedFiles(selectedFilesCopy);
    };
    
    const handleOnSelectAll = (isSelect: boolean, rows: IDocumentRequestFileModel[]) => {
        if (isSelect) {
            setSelectedFiles(rows);
        } else {
            setSelectedFiles([]);
        }
    };

    const selectRow: SelectRowProps<any> = {
        mode: "checkbox",
        clickToSelect: true,
        nonSelectable: [],
        selected: selectedFiles.map(file => file.fileId),
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        selectionHeaderRenderer: ({ indeterminate }) => (
            <Checkbox
                id="mpiCheckbox"
                size={CheckBoxSize.sm}
                checked={!!selectedFiles.length}
                indeterminate={indeterminate}
                onClick={e => e.stopPropagation()}
                onChange={e => e.stopPropagation()}
                text={""}
            />
        ),
    };

    return (
        <LoadingOverlay className="file-view-loader" style={{ padding:"0px" }}>
            <div className="archive-table-container" style={{maxHeight: getFileViewWrapperHeight(mailboxUserData)}}>
                <BootstrapTable
                    classes="settings-table"
                    keyField="fileId"
                    columns={columns}
                    data={fileData}
                    noDataIndication={setNoContent()}
                    bordered={false}
                    selectRow={selectRow}
                    sort={props.sortBy}
                />
            </div>
            <DownloadModal
                show={showDownloadModal}
                onHide={() => setShowDownloadModal(false)}
                selectedFiles={selectedFiles}
                setSelectedFiles={setSelectedFiles}
                folder={currentFolder || initialFolder}
                isDR={true}
                resourceId={ResourceIdLocators.MyFiles.MyFilesBtnDownloadFiles}
                handleResetSearchAndSort={handleResetSearchAndSort}
            />

            <Loader loading={isLoading} />
        </LoadingOverlay>
    );
};

export default RequestFileView;