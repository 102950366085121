import axios, { AxiosResponse } from "axios";
import { AppThunkAction } from "../store";
import { actionTypes as documentRequestActions } from "../types/documentRequestTypes";
import { actionTypes as notificationAction } from "../types/notificationTypes";
import { StatusType, NotificationAction } from "../reducers/notificationReducer";
import {
    ReceivedDocumentRequestDetail,
    ReceivedDocumentRequestList,
    StartComposeLoader,
    StartDetailedLoader,
    StartListingLoader,
    StopComposeLoader,
    StopDetailedLoader,
    StopListingLoader,
    CompletedRetentionPeriods,
    ReceivedDocumentRequestFileModel,
    StartFileListLoader,
    StopFileListLoader,
    ResetRequestFileViewData,
} from "../actions/documentRequestActions";
import {
    IDocumentRequestFile,
    IDocumentRequestListModel,
    IDocumentRequestListResponseModel,
    IDocumentRequestModel,
    IDocumentRequestMoreModel,
    IDocumentRequestSearchModel,
    INewMultipleDocumentRequestModel,
} from "../core/viewModels/documentRequest/DocumentRequestModel";
import { AppNotifier } from "../helper/AppNotifier";
import { DocumentToolbarConstants, ReportDownloadContansts } from "../helper/Constants";
import moment from "moment";
import { DetailedStatus } from "../core/common/enums";
import { logger } from "../oidcClient/authProvider";
import { appInsightsData } from "../helper/AppInsightsData";
import { ICustomProperties } from "@microsoft/applicationinsights-web";
import { getMailboxUserEmailId, removeSpecialCharacters } from "../helper/HelperFunctions";
import { ToasterMessages } from "../helper/ToasterMessages";
import { IDocumentRequestFileModel } from "../core/viewModels/documentRequest/DocumentRequestFileModel";
import fileDownload from "js-file-download";
export type DispatchActions =
    | ReceivedDocumentRequestList
    | ReceivedDocumentRequestDetail
    | StartListingLoader
    | StopListingLoader
    | StartDetailedLoader
    | StopDetailedLoader
    | StartComposeLoader
    | StopComposeLoader
    | CompletedRetentionPeriods
    | ReceivedDocumentRequestFileModel
    | StartFileListLoader
    | StopFileListLoader
    | ResetRequestFileViewData;

export type KnownActions = DispatchActions | NotificationAction;

export const fetchDocumentRequestList =
    (
        payload: IDocumentRequestSearchModel,
        folderId: number,
        callback?: (result: IDocumentRequestListResponseModel) => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: documentRequestActions.START_LISTING_LOADER,
        });

        axios
            .post(
                `${
                    process.env.REACT_APP_API_URL
                }/api/DocumentRequest/All?folderId=${folderId}&mailboxUserEmailId=${getMailboxUserEmailId()}`,
                payload
            )
            .then((response: AxiosResponse<IDocumentRequestListResponseModel>) => {
                const data = response.data;
                dispatch({
                    type: documentRequestActions.RECEIVED_DOCUMENT_REQUEST_LIST,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                logger.trackError(
                    `fetchDocumentRequestList failed with error ${error.message} for data ${JSON.stringify(payload)}`
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_LISTING_LOADER,
                });
            });
    };

export const getDocumentRequestDetail =
    (documentRequestId: number, callback?: (result: IDocumentRequestModel) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.DocumentRequestViewed}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                documentRequestId: documentRequestId,
            },
        });
        dispatch({
            type: documentRequestActions.START_DETAILED_LOADER,
        });

        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/DocumentRequest?documentRequestId=${documentRequestId}&mailboxUserEmailId=${getMailboxUserEmailId()}`
            )
            .then((response: AxiosResponse<IDocumentRequestModel>) => {
                const data = response.data;
                dispatch({
                    type: documentRequestActions.RECEIVED_DOCUMENT_REQUEST_DETAIL,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                logger.trackError(
                    `getDocumentRequestDetail failed with error ${error.message} for document request id: ${documentRequestId}`
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_DETAILED_LOADER,
                });
            });
    };

export const detailDeleteDocumentRequest =
    (documentRequestId: number,
        folderId: number,
        resourceId: string,
        callback?: () => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.RequestDetailDelete}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                documentRequestId: documentRequestId,
            },
        });
        dispatch({
            type: documentRequestActions.START_DETAILED_LOADER,
        });

        axios
            .delete(
                `${
                    process.env.REACT_APP_API_URL
                }/api/DocumentRequest?folderId=${folderId}&documentRequestId=${documentRequestId}&mailboxUserEmailId=${getMailboxUserEmailId()}`,
                {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then(() => {
                AppNotifier.Success(ToasterMessages.SUCCESS.DELETE_REQUEST_DETAILED);
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                const customProperty: ICustomProperties = {};
                customProperty["resourceId"] = resourceId;
                logger.trackError(
                    `detailDeleteDocumentRequest failed with error ${error.message} for document request id: ${documentRequestId}`,
                    customProperty
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_DETAILED_LOADER,
                });
            });
    };
export const listingDeleteDocumentRequest =
    (
        requestedDocuments: IDocumentRequestListModel[],
        resourceId: string,
        folderId: number,
        callback?: () => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.RequestListDelete}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                requestedDocuments: requestedDocuments,
            },
        });
        dispatch({
            type: documentRequestActions.START_LISTING_LOADER,
        });

        axios
            .delete(
                `${
                    process.env.REACT_APP_API_URL
                }/api/DocumentRequest/DeleteDocumentRequests?folderId=${folderId}&mailboxUserEmailId=${getMailboxUserEmailId()}`,
                {
                    data: requestedDocuments,
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then(() => {
                AppNotifier.Success(ToasterMessages.SUCCESS.DELETE_REQUEST_LISTING);
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                const customProperty: ICustomProperties = {};
                customProperty["resourceId"] = resourceId;
                logger.trackError(
                    `listingDeleteDocumentRequest failed with error ${error.message} for request document : ${requestedDocuments}`,
                    customProperty
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_LISTING_LOADER,
                });
            });
    };
export const deleteAllUploadedFiles =
    (
        documentRequestId: number,
        documentRequestFileId: number,
        resourceId: string,
        callback?: () => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.DeleteFile}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                documentRequestId: documentRequestId,
                documentRequestFileId: documentRequestFileId,
            },
        });
        dispatch({
            type: documentRequestActions.START_DETAILED_LOADER,
        });

        axios
            .delete(
                `${
                    process.env.REACT_APP_API_URL
                }/api/DocumentRequest/UploadedDocument?documentRequestId=${documentRequestId}&documentRequestFileId=${documentRequestFileId}&mailboxUserEmailId=${getMailboxUserEmailId()}`,
                {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then(() => {
                AppNotifier.Success(ToasterMessages.SUCCESS.DELETE_DOCUMENT_DETAILED);
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                const customProperty: ICustomProperties = {};
                customProperty["documentRequestFileId"] = documentRequestFileId;
                customProperty["resourceId"] = resourceId;
                logger.trackError(
                    `deleteUploadedDocument failed with error ${error.message} for document request id: ${documentRequestId}`,
                    customProperty
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_DETAILED_LOADER,
                });
            });
    };

export const deleteUploadedFile =
    (
        fileId: number,
        documentRequestId: number,
        resourceId: string,
        callback?: () => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.DeleteFile}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                fileId,
            },
        });
        dispatch({
            type: documentRequestActions.START_DETAILED_LOADER,
        });

        axios
            .delete(
                `${process.env.REACT_APP_API_URL}/api/DocumentRequest/UploadedFile?fileId=${fileId}&documentRequestId=${documentRequestId}`,
                {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then(() => {
                AppNotifier.Success(ToasterMessages.SUCCESS.DELETE_FILE_DETAILED);
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                const customProperty: ICustomProperties = {};
                customProperty["fileId"] = fileId;
                customProperty["resourceId"] = resourceId;
                logger.trackError(
                    `deleteUploadedDocument failed with error ${error.message} for document request id: ${fileId}`,
                    customProperty
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_DETAILED_LOADER,
                });
            });
    };
export const downloadUploadedFile =
    (
        fileName: string,
        fileGuid: string,
        requestedYear: number,
        resourceId: string,
        callback?: (data: string) => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.DownloadFile}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                fileName: fileName,
                fileGuid: fileGuid,
                requestedYear: requestedYear,
            },
        });
        dispatch({
            type: documentRequestActions.START_DETAILED_LOADER,
        });

        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/DocumentRequest/UploadedDocument/Download?fileName=${encodeURIComponent(
                    fileName
                )}&fileGuid=${fileGuid}&requestedYear=${requestedYear}&mailboxUserEmailId=${getMailboxUserEmailId()}`,
                {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then((response: AxiosResponse<string>) => {
                const data = response.data;
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                const customProperty: ICustomProperties = {};
                customProperty["fileName"] = fileName;
                customProperty["fileGuid"] = fileGuid;
                customProperty["requestedYear"] = requestedYear;
                customProperty["resourceId"] = resourceId;
                logger.trackError(
                    `downloadUploadedDocument failed with error ${error.message} for document fileName: ${fileName}`,
                    customProperty
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_DETAILED_LOADER,
                });
            });
    };
export const downloadUploadedFiles =
    (
        documentRequestFile: IDocumentRequestFile,
        requestedYear: number,
        resourceId: string,
        callback?: (data: string) => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.DownloadFile}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                documentRequestFile,
                requestedYear: requestedYear,
            },
        });
        dispatch({
            type: documentRequestActions.START_DETAILED_LOADER,
        });
        const date = moment().format(ReportDownloadContansts.DATE_FORMAT_FILE_NAME);
        const filename = `${DocumentToolbarConstants.DR_ZIP_FILE_NAME}_${removeSpecialCharacters(
            documentRequestFile.name
        )}_${date}`;

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/DocumentRequest/UploadedDocumentFile/Download/All`,
                documentRequestFile,
                {
                    params: {
                        requestedYear,
                        zipFileName: filename,
                    },
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then((response: AxiosResponse<string>) => {
                const data = response.data;
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                const customProperty: ICustomProperties = {};

                customProperty["documentRequestFile"] = documentRequestFile;
                customProperty["requestedYear"] = requestedYear;
                customProperty["resourceId"] = resourceId;
                logger.trackError(
                    `downloadUploadedFiles failed with error ${error.message} for document: ${documentRequestFile.name}`,
                    customProperty
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_DETAILED_LOADER,
                });
            });
    };
export const downloadAllUploadedDocument =
    (
        requestedFiles: IDocumentRequestFile[],
        requestedYear: number,
        resourceId: string,
        callback?: (data: string) => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.DownloadAll}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                requestedFiles: requestedFiles,
                requestedYear: requestedYear,
            },
        });
        dispatch({
            type: documentRequestActions.START_DETAILED_LOADER,
        });
        const date = moment().format(ReportDownloadContansts.DATE_FORMAT_FILE_NAME);
        const filename = `${DocumentToolbarConstants.DR_ZIP_FILE_NAME}_${date}`;
        axios
            .post(
                `${
                    process.env.REACT_APP_API_URL
                }/api/DocumentRequest/UploadedDocument/Download/All?mailboxUserEmailId=${getMailboxUserEmailId()}`,
                requestedFiles,
                {
                    params: {
                        requestedYear,
                        zipFileName: filename,
                    },
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then((response: AxiosResponse<string>) => {
                const data = response.data;
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                const customProperty: ICustomProperties = {};
                customProperty["requestedYear"] = requestedYear;
                customProperty["resourceId"] = resourceId;
                logger.trackError(
                    `downloadAllUploadedDocument failed with error ${error.message} for document request files: ${requestedFiles}`,
                    customProperty
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_DETAILED_LOADER,
                });
            });
    };

export const downloaAllDocumentRequestFiles =
(
    files: IDocumentRequestFile[],
    resourceId?: string,
    callback?: (data: string) => void
): AppThunkAction<KnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.DownloadAll}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                files: files,
                }
            });
            dispatch({
                type: documentRequestActions.START_DETAILED_LOADER,
            });
            AppNotifier.Warning(ToasterMessages.WARNING.DOWNLOAD_PROGRESS);
            const date = moment().format(ReportDownloadContansts.DATE_FORMAT_FILE_NAME);
            const zipFileName = `${DocumentToolbarConstants.DR_ZIP_FILE_NAME}_${date}`;
            axios
                .post(
                    `${process.env.REACT_APP_API_URL}/api/DocumentRequest/MyFiles/Download/All?mailboxUserEmailId=${mailboxUserEmailId}`,
                    files,
                    {
                        params: {
                            zipFileName: zipFileName,
                        },
                        headers: {
                            "X-Resource-Id": resourceId ?? ""
                        },
                        responseType: "blob"
                    }
                )
                .then((response: AxiosResponse<string>) => {
                    const data = response.data;
                    const fileExtension = "zip";
                    fileDownload(data, `${zipFileName}.${fileExtension}`);
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({
                        type: notificationAction.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                    const customProperty: ICustomProperties = {};
                    customProperty["resourceId"] = resourceId;
                    logger.trackError(`downloaAllDocumentRequestFiles failed with error ${error.message} for document request files: ${files}`, customProperty);
                })
                .finally(() => {
                    dispatch({
                        type: documentRequestActions.STOP_DETAILED_LOADER,
                    });
                });
    };    

export const requestDRDownloadLater =
(payload: IDocumentRequestFile[], parentFolder: string, callback: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.DownloadAll}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                files: payload,
                }
            });
            dispatch({
                type: documentRequestActions.START_DETAILED_LOADER,
            });
        axios
            .post(
                `${
                    process.env.REACT_APP_API_URL
                }/api/DocumentRequest/Files/MyDownloads?mailboxUserEmailId=${getMailboxUserEmailId()}&parentFolder=${encodeURIComponent(
                    parentFolder
                )}
                &createdOn=${moment().toISOString()}&offset=${moment().utcOffset()}`,
                payload
            )
            .then(() => {
                AppNotifier.Success(ToasterMessages.SUCCESS.DOWNLOAD_LATER);
                dispatch({type: documentRequestActions.STOP_DETAILED_LOADER,});
                callback();
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_DETAILED_LOADER,
                });
            });
    };


export const requestMoreDocument =
    (
        documentRequestMore: IDocumentRequestMoreModel,
        resourceId: string,
        callback?: () => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.RequestMoreSendRequestClick}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                documentRequestMore: documentRequestMore,
            },
        });
        dispatch({
            type: documentRequestActions.START_DETAILED_LOADER,
        });

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/DocumentRequest/RequestMore`, documentRequestMore, {
                params: {
                    mailboxUserEmailId,
                },
                headers: {
                    "X-Resource-Id": resourceId ?? "",
                },
            })

            .then(() => {
                AppNotifier.Success(ToasterMessages.SUCCESS.SEND_DR_MORE);
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                const customProperty: ICustomProperties = {};
                customProperty["resourceId"] = resourceId;
                logger.trackError(
                    `requestNewDocument failed with error ${error.message} for document request more: ${documentRequestMore}`,
                    customProperty
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_DETAILED_LOADER,
                });
            });
    };

export const updateDocumentStatus =
    (
        documentRequestId: number,
        status: DetailedStatus,
        resourceId: string,
        notifyOnCompletion: boolean,
        callback?: () => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.NotifyOnComplete}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                documentRequestId: documentRequestId,
                status: status,
            },
        });
        dispatch({
            type: documentRequestActions.START_DETAILED_LOADER,
        });

        axios
            .patch(
                `${
                    process.env.REACT_APP_API_URL
                }/api/DocumentRequest/Status?documentRequestId=${documentRequestId}&status=${status}&mailboxUserEmailId=${getMailboxUserEmailId()}&notifyOnCompletion=${notifyOnCompletion}`,
                {},
                {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then(() => {
                if (callback) {
                    callback();
                }
                switch (status) {
                    case DetailedStatus.OPEN:
                    case DetailedStatus.REOPEN:
                        AppNotifier.Success(ToasterMessages.SUCCESS.UPDATE_DR_TO_OPEN_STATUS);
                        break;
                    case DetailedStatus.COMPLETED:
                        AppNotifier.Success(ToasterMessages.SUCCESS.UPDATE_DR_TO_COMPLETED_STATUS);
                        break;
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                const customProperty: ICustomProperties = {};
                customProperty["status"] = status;
                customProperty["resourceId"] = resourceId;
                customProperty["notifyOnCompletion"] = notifyOnCompletion;
                logger.trackError(
                    `updateDocumentStatus failed with error ${error.message} for document request id: ${documentRequestId}`,
                    customProperty
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_DETAILED_LOADER,
                });
            });
    };

export const resendDocumentAccesLink =
    (documentRequestIds: number[], resourceId: string, callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.ResendRequest}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                documentRequestIds: documentRequestIds,
            },
        });
        dispatch({
            type: documentRequestActions.START_LISTING_LOADER,
        });

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/DocumentRequest/AccessLink/Multiple`, documentRequestIds, {
                params: {
                    mailboxUserEmailId,
                },
                headers: {
                    "X-Resource-Id": resourceId ?? "",
                },
            })
            .then(() => {
                AppNotifier.Success(ToasterMessages.SUCCESS.RESEND_REQUEST_ACCESS_LINK);
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                const customProperty: ICustomProperties = {};
                customProperty["resourceId"] = resourceId;
                logger.trackError(
                    `resendDocumentAccesLink failed with error ${error.message} for document request ids: ${documentRequestIds}`,
                    customProperty
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_LISTING_LOADER,
                });
            });
    };

export const resendDocumentAccesLinkDetailed =
    (documentRequestIds: number[], resourceId: string, callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.ResendDocumentAccesLink}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                documentRequestIds: documentRequestIds,
            },
        });
        dispatch({
            type: documentRequestActions.START_DETAILED_LOADER,
        });

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/DocumentRequest/AccessLink/Multiple`, documentRequestIds, {
                params: {
                    mailboxUserEmailId,
                },
                headers: {
                    "X-Resource-Id": resourceId ?? "",
                },
            })
            .then(() => {
                AppNotifier.Success(ToasterMessages.SUCCESS.RESEND_REQUEST_ACCESS_LINK);
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                const customProperty: ICustomProperties = {};
                customProperty["resourceId"] = resourceId;
                logger.trackError(
                    `resendDocumentAccesLinkDetailed failed with error ${error.message} for document request ids: ${documentRequestIds}`,
                    customProperty
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_DETAILED_LOADER,
                });
            });
    };

export const sendDocumentReminder =
    (
        documentRequestIds: number[],
        isListingAction: boolean,
        resourceId: string,
        callback?: () => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.DocumentSendReminder}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                documentRequestIds: documentRequestIds,
                isListingAction,
            },
        });
        dispatch({
            type: documentRequestActions.START_LISTING_LOADER,
        });

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/DocumentRequest/SendReminder/Multiple`, documentRequestIds, {
                params: {
                    mailboxUserEmailId,
                },
                headers: {
                    "X-Resource-Id": resourceId ?? "",
                },
            })
            .then(() => {
                AppNotifier.Success(
                    isListingAction
                        ? ToasterMessages.SUCCESS.SEND_DR_REQUEST_REMINDER_LIST
                        : ToasterMessages.SUCCESS.SEND_REMINDER
                );
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                const customProperty: ICustomProperties = {};
                customProperty["isListingAction"] = isListingAction;
                customProperty["resourceId"] = resourceId;
                logger.trackError(
                    `sendDocumentReminder failed with error ${error.message} for document request ids: ${documentRequestIds}`,
                    customProperty
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_LISTING_LOADER,
                });
            });
    };

export const sendSpecificFileReminder =
    (requestId: number, fileName: string, resourceId: string, callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.FileSpecicficReminder}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                fileName: fileName,
            },
        });
        dispatch({
            type: documentRequestActions.START_DETAILED_LOADER,
        });

        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/DocumentRequest/SendFileReminder?documentRequestId=${requestId}&fileName=${fileName}&mailboxUserEmailId=${getMailboxUserEmailId()}`,
                {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then(() => {
                AppNotifier.Success(ToasterMessages.SUCCESS.SEND_DR_DOCUMENT_REMINDER_SPECIFIC_FILE);
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                const customProperty: ICustomProperties = {};
                customProperty["fileName"] = fileName;
                customProperty["resourceId"] = resourceId;
                logger.trackError(
                    `sendSpecificFileReminder failed with error ${error.message} for request id: ${requestId}`,
                    customProperty
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_DETAILED_LOADER,
                });
            });
    };

export const sendNewDocumentRequest =
    (
        payload: INewMultipleDocumentRequestModel,
        resourceId: string,
        callback?: () => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.NewRequestSendRequestClick}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                ...payload,
            },
        });
        dispatch({
            type: documentRequestActions.STOP_COMPOSE_LOADER,
        });

        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/DocumentRequest?mailboxUserEmailId=${getMailboxUserEmailId()}`,
                payload,
                {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then(() => {
                AppNotifier.Success(ToasterMessages.SUCCESS.SEND_DR);
                if (callback) {
                    callback();
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                logger.trackError(
                    `sendNewDocumentRequest failed with error ${error.message} for document request id: ${resourceId}`
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_COMPOSE_LOADER,
                });
            });
    };

export const updateRetentionPeriod =
    (
        documentRequestId: number,
        expiryDays: number,
        resourceId?: string,
        callback?: (result: any) => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.RetentionPeriod}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                documentRequestId: documentRequestId,
                expiryDays,
            },
        });
        dispatch({
            type: documentRequestActions.START_DETAILED_LOADER,
        });
        axios
            .patch(
                `${process.env.REACT_APP_API_URL}/api/DocumentRequest/UpdateRetentionPeriod?documentRequestId=${documentRequestId}&retentionPeriod=${expiryDays}`,
                {},
                {
                    params: {
                        mailboxUserEmailId,
                    },
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then((response: any) => {
                const data = response.data;
                AppNotifier.Success(ToasterMessages.SUCCESS.UPDATE_REQUEST_RETENTION_PERIOD);
                dispatch({
                    type: documentRequestActions.COMPLETED_RETENTION_PERIODS,
                    documentRequestId: documentRequestId,
                    result: true,
                    expiryDays: expiryDays,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                const customProperty: ICustomProperties = {};
                customProperty["expiryDays"] = expiryDays;
                customProperty["resourceId"] = resourceId;
                logger.trackError(
                    `updateRetentionPeriod failed with error ${error.message} for document request id: ${documentRequestId}`,
                    customProperty
                );
            })
            .finally(() => {
                dispatch({
                    type: documentRequestActions.STOP_DETAILED_LOADER,
                });
            });
    };

export const requestMoveToFolder =
    (
        requests: number[],
        destinationFolderId: number,
        callback?: any,
        resourceId?: string
    ): AppThunkAction<KnownActions> =>
        dispatch => {
            logger.trackEvent({
                name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.RetentionPeriod}`,
                properties: {
                    page: appInsightsData.RequestDocument.PageTitle,
                    requests: requests,
                    destinationFolderId: destinationFolderId,
                }
            });
            dispatch({
                type: documentRequestActions.START_DETAILED_LOADER,
            });
            axios
                .post(`${process.env.REACT_APP_API_URL}/api/DocumentRequest/MoveToFolder?destinationFolderId=${destinationFolderId}&mailboxUserEmailId=${getMailboxUserEmailId()}`, requests, {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                })
                .then((response: any) => {
                    const data = response.data;
                    AppNotifier.Success(DocumentToolbarConstants.REQUESTS_MOVED_SUCCESS_MESSAGE);
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({
                        type: notificationAction.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                    const customProperty: ICustomProperties = {};
                    customProperty["requests"] = requests;
                    customProperty["resourceId"] = resourceId;
                    logger.trackError(`moveToFolder failed with error ${error.message} for document request id: ${requests}`, customProperty);
                })
                .finally(() => {
                    dispatch({
                        type: documentRequestActions.STOP_DETAILED_LOADER,
                    });
                });
        };


export const previewUploadedDocument =
    (
        fileName: string,
        fileGuid: string,
        requestedYear: number,
        callback?: (data: string) => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.RequestDocument.PageTitle} - ${appInsightsData.RequestDocument.Operation.Preview}`,
            properties: {
                page: appInsightsData.RequestDocument.PageTitle,
                fileName: fileName,
                fileGuid: fileGuid,
                requestedYear: requestedYear,
            },
        });

        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/DocumentRequest/UploadedDocument/Download?fileName=${encodeURIComponent(
                    fileName
                )}&fileGuid=${fileGuid}&requestedYear=${requestedYear}&mailboxUserEmailId=${getMailboxUserEmailId()}`
            )
            .then((response: AxiosResponse<string>) => {
                const data = response.data;

                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({
                        type: notificationAction.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                    const customProperty: ICustomProperties = {};
                    customProperty["fileName"] = fileName;
                    customProperty["fileGuid"] = fileGuid;
                    customProperty["requestedYear"] = requestedYear;
                    logger.trackError(`previewUploadedDocument failed with error ${error.message} for preview the document: ${customProperty}`);
                });
        };
export const fetchDocumentRequestFileList =
    (   
        folderId: number,
        pageNo: number,
        pageSize: number,
        mailboxUserEmailId: string,
        searchText?: string,
        callback?: (data: IDocumentRequestFileModel[]) => void
    ): AppThunkAction<KnownActions> =>
        dispatch => {
            dispatch({
                type: documentRequestActions.START_FILE_LIST_LOADER,
            });

            axios
                .get(`${process.env.REACT_APP_API_URL}/api/DocumentRequest/SharedDocumentRequestFiles?mailboxUserEmailId=${getMailboxUserEmailId()}`,
                {
                    params: {
                        folderId,
                        pageNo,
                        pageSize,
                        mailboxUserEmailId,
                        searchText,
                    },
                } )
                .then((response: AxiosResponse) => {
                    const data = response.data;
                    dispatch({
                        type: documentRequestActions.RECEIVED_DOCUMENT_REQUEST_FILE_LIST,
                        payload: data,
                    });
                    if (callback) {
                        callback(data);
                    }
                })
                .catch(error => {
                    dispatch({
                        type: notificationAction.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                    logger.trackError(`fetchDocumentRequestFileList failed with error ${error.message} for folderId ${folderId} and mailboxUserEmailId ${mailboxUserEmailId}`);
                })
                .finally(() => {
                    dispatch({
                        type: documentRequestActions.STOP_FILE_LIST_LOADER,
                    });
                });
        };

