import axios from "axios";
import { AppThunkAction } from "../store";
import { actionTypes as composeActions } from "../types/composeTypes";
import { actionTypes as notifactionAction } from "../types/notificationTypes";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import {
    CompletedComposeRecipients,
    ReceivedComposeRecipients,
    RequestComposeRecipients,
    RequestUploadAttachmentSasAction,
    RequestSendMessage,
    ReceivedSendMessage,
    CompletedSendMessage,
    RequestAuthRetentions,
    ReceivedAuthRetentions,
    CompletedAuthRetentions,
    RequestRemoveAttachment,
    CompletedRemoveAttachment,
    RequestSetDefaultAuthentication,
    CompletedSetDefaultAuthentication,
} from "../actions/composeActions";
import { ISentMailMessage } from "../core/viewModels/message/SentMailMessageModel";
import { AuthenticationMethod } from "../core/common/enums";
import { IRecipientModel } from "../core/viewModels/message/RecipientModel";
import { IAuthenticationMethodSettings } from "../components/navigation/profile/MySettings";
import { appInsightsData } from "../helper/AppInsightsData";
import { logger } from "../oidcClient/authProvider";
import { ToasterMessages } from "../helper/ToasterMessages";

export type DispatchActions =
    | RequestComposeRecipients
    | ReceivedComposeRecipients
    | CompletedComposeRecipients
    | RequestUploadAttachmentSasAction
    | RequestSendMessage
    | ReceivedSendMessage
    | CompletedSendMessage
    | RequestAuthRetentions
    | ReceivedAuthRetentions
    | CompletedAuthRetentions
    | RequestRemoveAttachment
    | CompletedRemoveAttachment
    | RequestSetDefaultAuthentication
    | CompletedSetDefaultAuthentication;

export type KnownActions = DispatchActions | NotificationAction;

export const fetchComposeRecipients =
    (searchString: string, callback?: (data: IRecipientModel[]) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: composeActions.REQUEST_COMPOSE_RECIPIENTS,
        });

        axios
            .get(`${process.env.REACT_APP_API_URL}/api/ComposeMessage/GetRecipients?filter=${searchString}`)
            .then(response => {
                const data = response.data;
                dispatch({
                    type: composeActions.RECEIVED_COMPOSE_RECIPIENTS,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: composeActions.COMPLETED_COMPOSE_RECIPIENTS,
                });
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const fetchUploadAttachmentSasLink =
    (documentGuid: string, callback?: (data: any) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/ComposeMessage/GetDocumentUploadLink?documentGuid=${documentGuid}`
            )
            .then(response => {
                if (response.status == 200) {
                    const result = response.data;
                    const data = {
                        guid: documentGuid,
                        sas: result,
                    };
                    callback && callback(data);
                } else {
                    dispatch({
                        type: notifactionAction.NOTIFICATION,
                        statusMessage: ToasterMessages.ERROR.ATTACHMENT_UPLOAD_FAILED,
                        statusType: StatusType.Error,
                    });
                }
            })
            .catch(function () {
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: ToasterMessages.ERROR.ATTACHMENT_UPLOAD_FAILED,
                    statusType: StatusType.Error,
                });
            });
    };

export const removeAttachment =
    (documentGuid: string, callback?: (guid: string) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.Compose.PageTitle} - ${appInsightsData.Compose.Operation.AttachmentRemoved}`,
            properties: {
                page: appInsightsData.Compose.ComposeTabTitle,
                guid: documentGuid,
            },
        });
        dispatch({ type: composeActions.REQUEST_REMOVE_ATTACHMENT });
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/ComposeMessage/DeleteAttachment?documentGuid=${documentGuid}`)
            .then(() => {
                dispatch({ type: composeActions.COMPLETED_REMOVE_ATTACHMENT });
                callback && callback(documentGuid);
            })
            .catch(function () {
                dispatch({ type: composeActions.COMPLETED_REMOVE_ATTACHMENT });
            });
    };

export const fetchAuthRetentionPeriods =
    (authMethod: AuthenticationMethod, callback?: (data: any[]) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: composeActions.REQUEST_AUTH_RETENTIONS,
        });

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/ComposeMessage/AuthMethodRetentionPeriods?authMethod=${authMethod}`
            )
            .then(response => {
                const data = response.data;
                dispatch({
                    type: composeActions.RECEIVED_AUTH_RETENTIONS,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: composeActions.COMPLETED_AUTH_RETENTIONS,
                });
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const sendMessage =
    (
        sentMailMessage: ISentMailMessage,
        draftedMessageId: number,
        successCallback?: (messageId: number) => void,
        errorCallback?: () => void,
        resourceId?: string
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.Compose.PageTitle} - ${appInsightsData.Compose.Operation.SendButtonClick}`,
            properties: {
                page: appInsightsData.Compose.PageTitle,
                draftedMessageId: draftedMessageId,
            },
        });
        dispatch({
            type: composeActions.REQUEST_SEND_MESSAGE,
        });
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/ComposeMessage/SendMessage?draftedMessageId=${draftedMessageId}`,
                sentMailMessage,
                {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then(response => {
                const data = response.data;
                dispatch({
                    type: composeActions.RECEIVED_SEND_MESSAGE,
                });
                if (successCallback) {
                    successCallback(data);
                }
            })
            .catch(() => {
                dispatch({
                    type: composeActions.COMPLETED_SEND_MESSAGE,
                });
                errorCallback && errorCallback();
            });
    };

export const setDefaultAuthMethod =
    (
        authMethod: AuthenticationMethod,
        callback?: (authenticationMethodSettings?: IAuthenticationMethodSettings) => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: composeActions.REQUEST_SET_DEFAULT_AUTHENTICATION,
        });
        logger.trackEvent({
            name: `${appInsightsData.Compose.PageTitle} - ${appInsightsData.Compose.Operation.AuthMethodDefaultButtonClicked}`,
            properties: {
                page: appInsightsData.Compose.MessageOptionTabTitle,
                authMethod,
            },
        });
        axios
            .put(`${process.env.REACT_APP_API_URL}/api/ComposeMessage/SetDefaultAuthMethod/${authMethod}`)
            .then(response => {
                const data = response.data;
                dispatch({
                    type: composeActions.COMPLETED_SET_DEFAULT_AUTHENTICATION,
                });
                if (callback) callback(data);
            })
            .catch((error: Error) => {
                dispatch({
                    type: composeActions.COMPLETED_SET_DEFAULT_AUTHENTICATION,
                });
                dispatch({
                    type: notifactionAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };
