import axios from "axios";
import { requestDRMailTemplates, receivedDRMailTemplates, completedDRMailTemplates, createDRMailTemplates, updateDRMailTemplates, deleteDRMailTemplates } from "../actions/SavedRequestMailTemplates";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import { AppThunkAction } from "../store";
import { actionTypes } from "../types/savedDRTemplateTypes";
import { ISavedMessageTemplateModel } from "../core/viewModels/settings/SavedMessageTemplate";
import { actionTypes as notificationActions } from "../types/notificationTypes";
import { AppNotifier } from "../helper/AppNotifier";
import { SavedMessageEmailTemplate } from "../helper/Constants";
import { TemplateSource } from "../core/common/enums";
import { ToasterMessages } from "../helper/ToasterMessages";

export type KnownActions = 
    | requestDRMailTemplates
    | receivedDRMailTemplates
    | completedDRMailTemplates
    | createDRMailTemplates
    | updateDRMailTemplates
    | deleteDRMailTemplates
    | NotificationAction;

export const getSavedDRMailTemplates = (fetchTemplate: TemplateSource, callback?: () => void): AppThunkAction<KnownActions> =>    
    dispatch => {
        dispatch({type: actionTypes.REQUEST_DR_MAIL_TEMPLATES_LIST});
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/DRTemplate/GetDRMailTemplates?fetchTemplates=${fetchTemplate}`)
            .then(response => {
                const data = response.data as ISavedMessageTemplateModel[];
                dispatch({ type: actionTypes.RECEIVE_DR_MAIL_TEMPLATES_LIST, data });
                if (callback) callback();
            })
            .catch(error => {
                dispatch({
                    type: notificationActions.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.COMPLETED_DR_MAIL_TEMPLATES });
            });
    };

export const addDRMailTemplate =
    (payload: ISavedMessageTemplateModel, callback?: (data?:any,dataIsDefault?:boolean) => void,isDefault?:boolean): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.CREATE_DR_MAIL_TEMPLATE });
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/DRTemplate/CreateDRTemplate`, payload)
            .then(response => {
                const data = response.data;
                AppNotifier.Success(ToasterMessages.SUCCESS.ADD_EMAIL_TEMPLATE);
                if (callback) callback(data,isDefault);
            })
            .catch(error => {
                const message: string = error.response.data.message;
                if (message.includes(SavedMessageEmailTemplate.TEMPLATE_EXIST_MESSAGE)) {
                    AppNotifier.Error(ToasterMessages.ERROR.TEMPLATE_ALREADY_EXIST);
                    return;
                }
                dispatch({
                    type: notificationActions.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.COMPLETED_DR_MAIL_TEMPLATES });
            });
    };

export const deleteDRMailTemplate =
    (payload: number, callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.DELETE_DR_MAIL_TEMPLATE });
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/DRTemplate/Delete?id=${payload}`)
            .then(() => {
                const message = ToasterMessages.SUCCESS.DELETE_EMAIL_TEMPLATE;
                AppNotifier.Success(message);
                if (callback) callback();
            })
            .catch(error => {
                dispatch({
                    type: notificationActions.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.COMPLETED_DR_MAIL_TEMPLATES });
            });
    };

export const updateDRMailTemplate =
    (payload: ISavedMessageTemplateModel, callback?: (data:any,dataIsDefault?:boolean,defaultTemplateReverted?: boolean,) => void ,isDefault?:boolean,defaultTemplateReverted?: boolean): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.UPDATE_DR_MAIL_TEMPLATE });
        axios
            .put(`${process.env.REACT_APP_API_URL}/api/DRTemplate/UpdateDRTemplates`, payload)
            .then(() => {
                AppNotifier.Success(ToasterMessages.SUCCESS.UPDATE_EMAIL_TEMPLATE);
                if (callback) callback(payload.savedMessageTemplateId,isDefault,defaultTemplateReverted);
            })
            .catch(error => {
                const message: string = error.response.data.message;
                if (message.includes(SavedMessageEmailTemplate.TEMPLATE_EXIST_MESSAGE)) {
                    AppNotifier.Error(ToasterMessages.ERROR.TEMPLATE_ALREADY_EXIST);
                    return;
                }
                dispatch({
                    type: notificationActions.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({ type: actionTypes.COMPLETED_DR_MAIL_TEMPLATES });
            });
    };