import React, { useEffect, useState } from "react";
import BootstrapTable, { ColumnDescription, SelectRowProps } from "react-bootstrap-table-next";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { QuestionType } from "../../../../../core/common/enums";
import { IMessageSecurityQuestion } from "../../../../../core/viewModels/message/MessageSecurityQuestionModel";
import Checkbox, { CheckBoxSize } from "../../../../common/ui/Checkbox";
import AuthQuestionActionBar from "./AuthQuestionsActionBar";
import { DeleteIcon } from "../../../../svg/IconCollection";

export interface IAuthQuestionsTableProps {
    isContentLoading: boolean;
    data: IMessageSecurityQuestion[];
    selectedQuestions: IMessageSecurityQuestion[];
    hideAddNewQuestionButton?: boolean;
    fromUserSettings?: boolean;
    updateSelectedQuestions: (selectedQuestions: IMessageSecurityQuestion[]) => void;
    setShowAddQuestionsModal: (value: boolean) => void;
    handleEditQuestionClick: (selectedQuestion: IMessageSecurityQuestion) => void;
    handleSetDefaultClick: (selectedQuestion: IMessageSecurityQuestion) => void;
    handleDeleteQuestionClick: (selectedQuestions: IMessageSecurityQuestion[]) => void;
    setSelectedDeleteOption: (selectedDeleteOption: string) => void;
}

export const AuthQuestionsTable: React.FC<IAuthQuestionsTableProps> = props => {
    const {
        isContentLoading,
        data,
        selectedQuestions,
        fromUserSettings,
        setShowAddQuestionsModal,
        handleSetDefaultClick,
        handleDeleteQuestionClick,
        updateSelectedQuestions,
        setSelectedDeleteOption,
        hideAddNewQuestionButton,
    } = props;

    const [questionsData, setQuestionsData] = useState(data);
    const isAnySelected = props.selectedQuestions.length > 0;
    const companyQuestionsIds = fromUserSettings
        ? questionsData
              .filter(qs => qs.questionType == QuestionType.COMPANY)
              .map(item => item.messageSecurityQuestionId)
        : [];

    useEffect(() => {
        setQuestionsData(data);
    }, [data]);

    useEffect(() => {
        if (questionsData && questionsData[0] && !questionsData[0].isDefault) {
            const defaultQuestionIndex = questionsData?.findIndex(question => question.isDefault === true) || 0;
            const replaceData = [...questionsData];
            //Add the default question at index 0
            replaceData.splice(0, 0, replaceData[defaultQuestionIndex]);
            //Remove question at previous index
            replaceData.splice(defaultQuestionIndex + 1, 1);
            setQuestionsData(replaceData);
        }
    }, [questionsData]);

    const AuthActionBar = (cell: string, row: IMessageSecurityQuestion): JSX.Element => {
        return (
            <AuthQuestionActionBar
                showRenameOption={fromUserSettings ? row.questionType == QuestionType.USER : true}
                showDeleteOption={fromUserSettings ? row.questionType == QuestionType.USER : true}
                selectedQuestion={row}
                fromUserSettings={fromUserSettings}
                handleEditQuestionClick={handleEditQuestionClick}
                handleSetDefaultClick={handleSetDefaultClick}
                handleDeleteQuestionClick={handleDeleteQuestionClick}
                setSelectedDeleteOption={setSelectedDeleteOption}
            />
        );
    };

    const HeaderActionsBar = (): JSX.Element => {
        return (
            <div className="header-toolbar-action">
                {
                    <span
                        onClick={() => {
                            setSelectedDeleteOption("headerDelete");
                            handleDeleteQuestionClick(selectedQuestions);
                        }}
                        title={"Delete"}
                        className={isAnySelected ? "pointer" : "isDisabled"}
                    >
                        <DeleteIcon width={16} height={16} disabled={!isAnySelected} />
                    </span>
                }

                {!hideAddNewQuestionButton && (
                    <button className="add-question-btn" onClick={() => setShowAddQuestionsModal(true)}>
                        Add New Question
                    </button>
                )}
            </div>
        );
    };

    const questionTitleFormatter = (cell: string, row: IMessageSecurityQuestion): JSX.Element => {
        return (
            <span
                style={{
                    fontWeight: row.isDefault ? "700" : "500",
                }}
                title={cell}
                className={`table-name-cell overflowText ${
                    fromUserSettings ? "my-settings-question-cell" : "question-cell"
                }`}
            >
                {cell}
            </span>
        );
    };
    const questionFormatter = (cell: string, row: IMessageSecurityQuestion): JSX.Element => {
        return (
            <div className="cell-actions-wrapper">
                <span
                    style={{
                        fontWeight: row.isDefault ? "700" : "500",
                    }}
                    title={cell}
                    className={`table-email-cell overflowText ${
                        fromUserSettings ? "my-settings-question-description-cell" : "question-description-cell"
                    }`}
                >
                    {cell}
                </span>
                {AuthActionBar(cell, row)}
            </div>
        );
    };

    const columns: ColumnDescription[] = [
        {
            text: "Question Title",
            dataField: "questionTitle",
            formatter: questionTitleFormatter,
        },
        {
            text: "Question",
            dataField: "question",
            formatter: questionFormatter,
        },
        {
            text: "",
            dataField: "toolbar",
            headerFormatter: HeaderActionsBar,
        },
    ];

    const setNoContent = () => {
        if (isContentLoading) {
            return (
                <LoadingOverlay style={{ height: 400 }}>
                    <Loader loading={isContentLoading} />
                </LoadingOverlay>
            );
        } else {
            return "No matching records found";
        }
    };

    const handleEditQuestionClick = (selectedQuestion: IMessageSecurityQuestion) => {
        props.handleEditQuestionClick(selectedQuestion);
    };

    const handleOnSelect = (row: IMessageSecurityQuestion, isSelect: boolean) => {
        let selectedQuestionsCopy = [...selectedQuestions];

        if (isSelect) {
            selectedQuestionsCopy.push(row);
        } else {
            selectedQuestionsCopy = selectedQuestionsCopy.filter(
                question => question.messageSecurityQuestionId != row.messageSecurityQuestionId
            );
        }

        updateSelectedQuestions(selectedQuestionsCopy);
    };

    const handleOnSelectAll = (isSelect: boolean, rows: IMessageSecurityQuestion[]) => {
        if (isSelect) {
            updateSelectedQuestions(rows);
        } else {
            updateSelectedQuestions([]);
        }
    };

    const selectRow: SelectRowProps<IMessageSecurityQuestion> = {
        mode: "checkbox",
        clickToSelect: true,
        nonSelectable: [questionsData[0]?.messageSecurityQuestionId, ...companyQuestionsIds],
        selected: selectedQuestions.map((question: IMessageSecurityQuestion) => question.messageSecurityQuestionId),
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        selectionHeaderRenderer: ({ indeterminate }) => (
            <Checkbox
                id="mpiCheckbox"
                size={CheckBoxSize.sm}
                checked={!!props.selectedQuestions.length}
                indeterminate={selectedQuestions.length !== questionsData.length - 1}
                onClick={e => e.stopPropagation()}
                onChange={e => e.stopPropagation()}
                text={indeterminate ? "" : ""}
            />
        ),
    };

    return (
        <BootstrapTable
            classes="settings-table authentication-questions-table"
            keyField="messageSecurityQuestionId"
            columns={columns}
            data={questionsData}
            remote
            selectRow={selectRow}
            noDataIndication={setNoContent()}
            bordered={false}
        />
    );
};

export default AuthQuestionsTable;
