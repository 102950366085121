import { TemplateSource, TemplateType } from "../../common/enums";

export interface ISavedMessageTemplateModel {
    savedMessageTemplateId: number;
    templateName: string;
    text: string;
    isSystemDefault : boolean;
    templateType:TemplateType;
    createdOn : Date;
    createdBy : string;
    templateSource:TemplateSource;
}

export const initialSavedMessageTemplate: ISavedMessageTemplateModel = {
    savedMessageTemplateId: 0,
    templateName: "",
    text: "",
    isSystemDefault: false,
    templateType: 0,
    createdOn: new Date(),
    createdBy: "",
    templateSource: 0,
};

export interface ISavedMessageTemplateSettings {
    savedMessageMailTemplateId: number;
    isUserAllowedToAddSecureMailTemplate: boolean;
    isUserAllowedToChangeSecureMailTemplate: boolean;
}
export interface ISavedMessageDRTemplateSettings{
    savedMessageDRMailTemplateId: number;
    isUserAllowedToChangeDRMailTemplate: boolean;
    isUserAllowedToAddDRMailTemplate: boolean;
}

export const initialSavedMessageSettings: ISavedMessageTemplateSettings = {
    savedMessageMailTemplateId: 1,
    isUserAllowedToAddSecureMailTemplate: true,
    isUserAllowedToChangeSecureMailTemplate: true,
   
};

export const initialSavedMessageDRSettings: ISavedMessageDRTemplateSettings = {
    savedMessageDRMailTemplateId: 1,
    isUserAllowedToAddDRMailTemplate: true,
    isUserAllowedToChangeDRMailTemplate: true,
};
