import axios, { AxiosResponse } from "axios";
import { AppThunkAction } from "../store";
import { actionTypes as notifactionAction } from "../types/notificationTypes";
import {
  NotificationAction,
  StatusType,
} from "../reducers/notificationReducer";
import { actionTypes as unlockActions } from "../types/unlockTypes";
import { RequestUnlockLinks, ReceiveUnlockLinks, CompleteUnlockLinks, UpdateLinkLock } from "../actions/unlockActions";
import { IOnehubLockedTPViewModel, ILockLinkModel } from "../core/viewModels/unlock/UnlockModel";
import { AppNotifier } from "../helper/AppNotifier";
import { appInsightsData } from "../helper/AppInsightsData";
import { logger } from "../oidcClient/authProvider";
import { TabType} from "../core/common/enums";
import { ToasterMessages } from "../helper/ToasterMessages";

export type DispatchActions =
  | RequestUnlockLinks
  | ReceiveUnlockLinks
  | CompleteUnlockLinks
  | UpdateLinkLock

export type KnownActions = DispatchActions | NotificationAction;

export const getUnlockLinks = (unlockType:TabType): AppThunkAction<KnownActions> =>
    (dispatch) => {
      dispatch({
        type: unlockActions.REQUEST_UNLOCK_LINKS,
      });
      let url : string;
      switch(unlockType){
        case TabType.Requests:
          url = `${process.env.REACT_APP_API_URL}/api/DocumentRequest/GetLockedLinks`;
          break;
        case TabType.Onehub:
          url = `${process.env.REACT_APP_API_URL}/api/OnehubUser/GetOnehubLockedTaxPayer`;
          break;
        default:
          url = `${process.env.REACT_APP_API_URL}/api/LockedLinks/GetLockedLinks`;
          break;
      }

      axios
        .get(url)
        .then((response: AxiosResponse) => {
          const data = response.data;
          dispatch({
            type: unlockActions.RECEIVE_UNLOCK_LINKS,
            payload: data,
          });
        })
        .catch(function (error: any) {
          dispatch({
            type: notifactionAction.NOTIFICATION,
            statusMessage: error.message,
            statusType: StatusType.Error,
          });
        }).finally(() => {
          dispatch({
            type: unlockActions.COMPLETE_UNLOCK_LINKS,
          });
        });
    };

    export const updateUnlockLinks =
        (
            unlockLinks: ILockLinkModel[] | IOnehubLockedTPViewModel[],
            unlockType: TabType,
            callback?: (reload: boolean) => void
        ): AppThunkAction<KnownActions> =>
        dispatch => {
            dispatch({
                type: unlockActions.UPDATE_LINK_LOCK,
            });

            unlockLinks &&
                unlockLinks.map(linkData => {
                    logger.trackEvent({
                        name: `${appInsightsData.Unlock.PageTitle} - ${appInsightsData.Unlock.UnlockLinkClicked}`,
                        properties: {
                            page: appInsightsData.Unlock.PageTitle,
                            unlockType: TabType[unlockType],
                            ...linkData,
                        },
                    });
                });
            let url = "";
            let payload : any = {};
            switch (unlockType) {
                case TabType.Requests:
                    url = `${process.env.REACT_APP_API_URL}/api/DocumentRequest/UnlockLockedRequestLinks`;
                    payload = unlockLinks;
                    break;
                case TabType.Onehub:
                  if(unlockLinks.length === 1){
                      const emailAddress = (unlockLinks[0] as IOnehubLockedTPViewModel).tpEmail;
                      url = `${process.env.REACT_APP_API_URL_SUITE}/api/one-hub/unlock-account?emailAddress=${emailAddress}`;
                      payload = {};
                  }else if(unlockLinks.length > 1) { 
                      url = `${process.env.REACT_APP_API_URL_SUITE}/api/one-hub/unlock-accounts`;
                      payload = unlockLinks.map((link) => (link as IOnehubLockedTPViewModel).tpEmail);
                  }
                    break;
                default:
                    url = `${process.env.REACT_APP_API_URL}/api/LockedLinks/UnlockLockedLinks`;
                    payload = unlockLinks;
                    break;
            }
            if(url) {
              const method = unlockType === TabType.Onehub  && unlockLinks.length > 1 ? "post" : "put";
              axios[method](url, payload)
                .then(() => {
                  if(unlockType === TabType.Onehub){
                    AppNotifier.Success(ToasterMessages.SUCCESS.UNLOCK_ONEHUB);
                  } else {
                     AppNotifier.Success(ToasterMessages.SUCCESS.UNLOCK_LINKS);
                  }
                    if (callback) {
                        callback(true);
                    }
                })
                .catch(function (error: any) {
                    dispatch({
                        type: notifactionAction.NOTIFICATION,
                        statusMessage: error.message,
                        statusType: StatusType.Error,
                    });
                })
                .finally(() => {
                    dispatch({
                        type: unlockActions.COMPLETE_UNLOCK_LINKS,
                    });
                });
            }
        };
