import React, { useEffect, useState } from "react";
import { Modal, Alert } from "react-bootstrap";
import ModalHeader from "react-bootstrap/esm/ModalHeader";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { IFolderModel } from "../../../../../core/viewModels/folder/FolderModel";
import { DocumentToolbarConstants, FolderEventType, FolderToolbarConstants, MessageToolbarConstants } from "../../../../../helper/Constants";
import { AppNotifier } from "../../../../../helper/AppNotifier";
import { fetchFoldersTree, fetchRetentionPeriods, moveFolders } from "../../../../../actionCreators/folderActionCreator";
import TreeList from "../../../../common/ui/TreeList/TreeList";
import { ArchiveFolderType, DetailedStatus, FolderType } from "../../../../../core/common/enums";
import { IReceivedMessageModel } from "../../../../../core/viewModels/message/ReceivedMessageModel";
import { ISentMessageModel } from "../../../../../core/viewModels/message/SentMessageModel";
import { AutomationIdConstants } from "../../../../../helper/AutomationConstants";
import { CreateFolderModal } from "./CreateFolderModal";
import { getRetentionOptions } from "../../../../../helper/HelperFunctions";
import { Option } from "react-dropdown";
import { IDocumentRequestListModel } from "../../../../../core/viewModels/documentRequest/DocumentRequestModel";
import { ToasterMessages } from "../../../../../helper/ToasterMessages";
import { isMoveToFolderPopupOpen } from "../../../../../actionCreators/signalRActionCreator";

interface IMoveFolderModalProps {
    showState: boolean;
    isDocumentRequest: boolean;
    selectedFolders: IFolderModel[];
    folderType: FolderType;
    isMovingFolder: boolean;
    selectedMessages?: IReceivedMessageModel[] | ISentMessageModel[] | IDocumentRequestListModel[] | any;
    onHide: () => void;
    onSubmit: () => void;
    setMoveToFolder?: (folder: IFolderModel) => void;
    onError: (isReload: boolean) => void;
    moveFolderResourceId?: string;
}

export const MoveFolderModal: React.FC<IMoveFolderModalProps> = props => {
    const { isMovingFolder, folderType, selectedFolders, moveFolderResourceId, isDocumentRequest } = props;
    const dispatch = useDispatch();
    const [moveToFolder, setMoveToFolder] = useState<IFolderModel | undefined>();
    const [showMoveToFolderWarning, toggleMoveToFolderWarning] = useState(false);
    const [allowFormSubmit, toggleAllowFormSubmit] = useState(true);
    const [warningMessageText, setWarningMessageText] = useState("");
    const [showAddFolderModal, setShowAddFolderModal] = useState(false);
    const [retentionPeriods, setRetentionPeriods] = useState<Option[]>([]);
    const foldersTreeData = useSelector((appState: ApplicationState) => appState.folderState.foldersTreeData);
    const retentionData = useSelector((appState: ApplicationState) => appState.folderState.retentionPeriodsData);

    useEffect(() => {
        dispatch(fetchRetentionPeriods());
    }, []);

    useEffect(() => {
        if (moveToFolder) {
            moveToFolder?.folderId === 0
                ? setRetentionPeriods(getRetentionOptions(retentionData.retentionPeriods))
                : setRetentionPeriods(
                    getRetentionOptions(
                        retentionData.retentionPeriods.filter(rp => rp.days <= moveToFolder.expiresDays)
                    )
                );
        }
    }, [retentionData.retentionPeriods.length, moveToFolder?.folderId]);

    const checkFolderExpiry = (destinationFolder: IFolderModel): boolean => {
        if (isMovingFolder) {
            // Check retention period of the folders
            if (!props.selectedFolders.every(f => f.expiresDays <= destinationFolder.expiresDays)) {
                setWarningMessageText(FolderToolbarConstants.DESTINATION_FOLDER_EXPIRY_WARNING);
                toggleMoveToFolderWarning(true);
                toggleAllowFormSubmit(false);
                return true;
            }
            toggleAllowFormSubmit(false);
            toggleMoveToFolderWarning(false);
        } else {
            // Check retention period of the messages
            if (props.selectedMessages) {
                let isMessageExpired = false;
                let isFolderExpired = false;
                props.selectedMessages.some((f: any) => {
                    if (destinationFolder.expiresDays > f.expiresDays) {
                        isMessageExpired = true;
                        setWarningMessageText(FolderToolbarConstants.DESTINATION_FOLDER_GREATER_EXPIRY_WARNING_FOR_MESSAGE);
                        toggleMoveToFolderWarning(true);
                        toggleAllowFormSubmit(true);
                        return true;
                    }
                    if (destinationFolder.expiresDays < f.expiresDays) {
                        isFolderExpired = false;
                        setWarningMessageText(FolderToolbarConstants.DESTINATION_FOLDER_EXPIRY_WARNING_FOR_MESSAGE);
                        toggleMoveToFolderWarning(true);
                        toggleAllowFormSubmit(false);
                    }
                    if (destinationFolder.expiresDays == f.expiresDays) {
                        toggleAllowFormSubmit(false);
                        toggleMoveToFolderWarning(false);
                    }

                });

                return (isMessageExpired ? true : isFolderExpired ? false : true);
            }
        }
        return false;
    };

    const handleFolderSelection = (folder: IFolderModel) => {
        let continueSelection = true;

        if (!isMovingFolder) {
            // Scenarios while moving messages

            // Allow if folder is empty
            if (folder.subFolders?.length === 0) {
                continueSelection = true;
            }

            // Allow if folder has messages
            if (folder.folderType == ArchiveFolderType.MESSAGE) {
                continueSelection = true;
            }

            // Don't Allow for folder with multiple sub folders
            if (folder?.subFolders && folder?.subFolders?.length > 0) {
                toggleMoveToFolderWarning(true);
                setWarningMessageText(isDocumentRequest === true ? DocumentToolbarConstants.DR_MOVING_MULTIPLE_FOLDERS_WARNING : MessageToolbarConstants.MESSAGE_MOVING_MULTIPLE_FOLDERS_WARNING);
                continueSelection = false;
            }

            // Don't Allow for moving to same folder
            if (props.selectedMessages && folder.folderId == props.selectedMessages[0].folderId) {
                continueSelection = false;
                return;
            }
        }

        if (isMovingFolder) {
            // Scenarios while moving folder

            // Allow if folder is empty
            if (folder.subFolders?.length === 0) {
                continueSelection = true;
            }

            // Don't allow moving to folders containing messages
            if (folder.folderType == ArchiveFolderType.MESSAGE) {
                return;
            }

            // Don't Allow for moving to same folder
            if (
                props.selectedFolders &&
                props.selectedFolders.length > 0 &&
                props.selectedFolders[0].folderId == folder.folderId
            ) {
                continueSelection = false;
                return;
            }

            // Don't Allow for moving to parent folder
            if (props.selectedFolders.length > 0 && props.selectedFolders[0].parentId === folder.folderId) {
                toggleMoveToFolderWarning(true);
                setWarningMessageText(FolderToolbarConstants.FOLDER_MOVING_TO_PARENT_FOLDER_WARNING);
                continueSelection = false;
            }

            // Don't allow for moving to sub folders of selected folder
            if (
                selectedFolders &&
                selectedFolders.length > 0 &&
                selectedFolders.some(node => node.path && folder.path?.includes(node.path))
            ) {
                continueSelection = false;
                return;
            }
        }


        if (!continueSelection) {
            toggleAllowFormSubmit(true);
        }

        if (continueSelection) {
            if (!isDocumentRequest) {
                checkFolderExpiry(folder!);
            } else {
                checkIfAllRequestsAreCompleted();
            }
        }

        const moveFolder = { ...folder };
        delete moveFolder.subFolders;
        setMoveToFolder(moveFolder);
        if (props.setMoveToFolder) {
            props.setMoveToFolder(moveFolder);
        }
    };

    const checkIfAllRequestsAreCompleted = () =>{
        if (!(props.selectedMessages as IDocumentRequestListModel[]).every(request => request.status === DetailedStatus.COMPLETED )){
            toggleMoveToFolderWarning(true);
            setWarningMessageText(FolderToolbarConstants.REQUESTS_INCLUDE_ACTIVE);
        }
        else
        {
            toggleMoveToFolderWarning(false);
            toggleAllowFormSubmit(false);
        }
        toggleAllowFormSubmit(false);
    };
    const resetMoveToFolderPopup = () => {
        toggleMoveToFolderWarning(false);
        setMoveToFolder(undefined);
        dispatch(isMoveToFolderPopupOpen(false));
        props.onHide();
    };
    const handleOnSubmitMoveToFolder = () => {
        if (isMovingFolder && moveToFolder) {
            dispatch(
                moveFolders(
                    props.selectedFolders.map(f => f.folderId),
                    props.selectedFolders,
                    moveToFolder,
                    (result: boolean) => {
                        if (result) {
                            const path = moveToFolder.path || "";
                            AppNotifier.Success(
                                props.selectedFolders.length > 1
                                    ?ToasterMessages.SUCCESS.MOVE_FOLDERS_TO_FOLDER.replace(
                                        "<destination folder>",
                                        path
                                    )
                                    : ToasterMessages.SUCCESS.MOVE_FOLDER_TO_FOLDER.replace(
                                        "<destination folder>",
                                        path
                                    )
                            );
                        } else {
                            props.onError(true);
                        }

                        resetMoveToFolderPopup();
                        props.onSubmit();
                    },
                    moveFolderResourceId
                )
            );
        } else {
            if (props.setMoveToFolder && moveToFolder) {
                props.setMoveToFolder(moveToFolder);
                props.onSubmit();
            }
        }
    };

    const handleOnCreateFolder = (folder?: IFolderModel) => {

        let folderIds: number[] = [];
        switch (folderType) {
            case FolderType.SENT:
                folderIds = [FolderType.SENT, FolderType.INBOX,FolderType.DOCUMENTREQUEST];
                break;
            case FolderType.ARCHIVE:
                if (isDocumentRequest) {
                    folderIds = [FolderType.INBOX];
                }
                else{
                    folderIds = [FolderType.DOCUMENTREQUEST];
                }

                if(isMovingFolder){
                    folderIds = [FolderType.DOCUMENTREQUEST, FolderType.INBOX];
                }
                break;
            case FolderType.INBOX:
            case FolderType.DOCUMENTREQUEST:
                folderIds = [FolderType.INBOX, FolderType.DOCUMENTREQUEST];
                break;
            default:
                break;
        }

        dispatch(fetchFoldersTree([], folderIds));
        dispatch(isMoveToFolderPopupOpen(true));
        setShowAddFolderModal(false);
        if (folder) {
            handleFolderSelection(folder);
        }
    };

    return (
        <>
            <Modal
                className="move-folder-modal-container custom-modal-container confirm-modal"
                show={props.showState}
                onHide={resetMoveToFolderPopup}
                onClick={(e: React.SyntheticEvent) => {
                    e.stopPropagation();
                }}
            >
                <ModalHeader closeButton>
                    <Modal.Title>Move To</Modal.Title>
                </ModalHeader>
                <Modal.Body>
                    <div>
                        <TreeList
                            isMovingFolder={isMovingFolder}
                            folderType={folderType}
                            isDR={isDocumentRequest}
                            handleFolderSelection={handleFolderSelection}
                            setShowAddFolderModal={setShowAddFolderModal}
                            selectedMessages={props.selectedMessages}
                            selectedFolders={props.selectedFolders}
                            destinationFolder={moveToFolder}
                            foldersTreeData={foldersTreeData.treeFolders}
                        />
                    </div>
                    <Alert show={showMoveToFolderWarning} variant="warning">
                        {warningMessageText}
                    </Alert>
                </Modal.Body>
                <Modal.Footer style={{ display: "flex", justifyContent: isDocumentRequest ? "space-between" : "flex-end" }}>
                    {isDocumentRequest &&
                        <div className="move-to-info-description" style={{ display: "flex", alignItems: "center", fontSize: "12px" }}>
                            <i className="fas fa-info-circle margin-right-5-px move-to-info-icon" style={{ color: "#0973ba" }} />
                            <span className="info-padding">{DocumentToolbarConstants.DR_EXPIRY_DOES_NOT_APPLY}</span>
                        </div>
                    }
                    <div style={{ display: "flex", gap: "2px" }}>
                        <button
                            type="button"
                            className="button-grey"
                            data-auto-test={AutomationIdConstants.moveToModal.MoveToCancelBtn}
                            onClick={resetMoveToFolderPopup}
                        >
                            Cancel
                        </button>
                        <button
                            type="button"
                            className="button-primary-blue-2"
                            disabled={allowFormSubmit}
                            data-auto-test={AutomationIdConstants.moveToModal.MoveToOkBtn}
                            onClick={handleOnSubmitMoveToFolder}
                        >
                            Ok
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
            {showAddFolderModal &&
                <CreateFolderModal
                    show={showAddFolderModal}
                    onHide={() => setShowAddFolderModal(false)}
                    onSubmit={handleOnCreateFolder}
                    currentFolder={moveToFolder!}
                    modalType={FolderEventType.Create}
                    retentionOptions={retentionPeriods}
                    isSharedFolderDisabled={true}
                />
            }
        </>
    );
};
