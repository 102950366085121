import React, { useState } from "react";
import BootstrapTable, {
    ColumnDescription,
    SortOrder,
    TableChangeState,
    TableChangeType,
    SelectRowProps,
} from "react-bootstrap-table-next";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { FolderColumnName, SortOrder as EnumSortOrder, ShareFolderTypes } from "../../../core/common/enums";
import { IRetentionPeriod } from "../../../core/viewModels/company/settings/RetentionSettings";
import { IFolderModel, rootFolder } from "../../../core/viewModels/folder/FolderModel";
import { getFolderModelColumns, isFolderOwner } from "../../../helper/HelperFunctions";
import Checkbox, { CheckBoxSize } from "../../common/ui/Checkbox";
import FolderToolbar from "./FolderToolbar";
import { FolderTypeIcon } from "../../svg/FolderIcons";
import {
    MessageListConstants,
    DateFormatConstants,
    ArchiveConstants,
    FolderToolbarConstants,
} from "../../../helper/Constants";
import { getFormattedLocalDateTime } from "../../../helper/DateHelperFunctions";
import { RBACPermission, ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { ListGroup, OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";
import FolderBreadCrumb from "./FolderBreadCrumb";

interface IArchiveFolderTableProps {
    folderData: IFolderModel[];
    selectedFolders: IFolderModel[];
    isContentLoading: boolean;
    retentionPeriods: IRetentionPeriod[];
    isMoveToFolderEnabled: boolean;
    isToolbarReset: boolean;
    currentfolder: IFolderModel;
    isSearched?: boolean;
    updateFolderSelection: (folders: IFolderModel[]) => void;
    requestFolders: (sortOptions: { sortColumn: FolderColumnName; sortOrder: EnumSortOrder }) => void;
    handleFolderClick: (
        folder: IFolderModel,
        sortOptions: { sortColumn: FolderColumnName; sortOrder: EnumSortOrder },
        isTitleBreadcrumb: boolean,
        breadCrumbArrayArchiveTable: IFolderModel[]
    ) => void;
    setReload: (value: boolean) => void;
    handleOnClickCreateFolder: () => void;
    resetSearchFolderTextAndSort: () => void;
    goToFolder: (folderId: number, breadcrumbIndex: number, isTitleBreadcrumb: boolean) => void;
    foldersCountUnderRoot: number;
}

export interface IFolderFilterOptions {
    sortBy?: string;
    sortOrder?: SortOrder;
}

export const ArchiveFolderTable: React.FC<IArchiveFolderTableProps> = props => {
    const [sortOptions, setSortOptions] = useState<IFolderFilterOptions>();
    const isAnySelected = props.selectedFolders?.length > 0;
    const user = useSelector((state: ApplicationState) => state.userProfile);
    const defaultSorted: [{ dataField: string; order: SortOrder }] | undefined = [
        {
            dataField: (sortOptions && sortOptions.sortBy) ?? "folderName",
            order: (sortOptions && sortOptions.sortOrder) ?? "asc",
        },
    ];

    const enableAddButton = (folder: IFolderModel): boolean => {
        if (
            folder.folderId === 0 ||
            isFolderOwner(folder, user) ||
            folder.permissionGroupId === ShareFolderTypes.EDIT
        ) {
            return true;
        } else if (folder.permissionGroupId === ShareFolderTypes.NONE && !folder.isPermissiomInherited) {
            return true;
        }
        return false;
    };

    const HeaderActionsBar = (): JSX.Element => {
        return (
            <div className="header-toolbar-action">
                <FolderToolbar
                    selectedFolders={props.selectedFolders}
                    isAnySelected={isAnySelected}
                    reset={props.isToolbarReset}
                    isHeaderToolbar={true}
                    isMoveToFolderEnabled={props.isMoveToFolderEnabled}
                    setReload={props.setReload}
                    resetSelection={resetSelection}
                    currentfolder={props.currentfolder}
                    resetSearchFolderTextAndSort={props.resetSearchFolderTextAndSort}
                    isSearched={props.isSearched}
                />
                <button
                    className={`add-folder-btn ${!enableAddButton(props.currentfolder) ? "toolbar-icon-disabled" : ""}`}
                    data-auto-test={AutomationIdConstants.addFolderModal.AddFolderBtn}
                    data-r-disabled={""}
                    data-r-events={"click,hover"}
                    data-r-title={RBACPermission.operation}
                    data-resource-id={ResourceIdLocators.MyFiles.MyFilesBtnAddFolder}
                    onClick={props.handleOnClickCreateFolder}
                    disabled={!enableAddButton(props.currentfolder)}
                >
                    {FolderToolbarConstants.ADD_FOLDER_BUTTON_TEXT}
                </button>
            </div>
        );
    };

    const sharedWithFormatter = (cell: string, row: IFolderModel) => {
        const sharedWith = row.sharedWith?.split(",") ?? [];
        const sharedWithFirstNames = (row && row.sharedWithFirstName?.split(",")) || [];
        if (sharedWith.length === 0) {
            return <span>{ArchiveConstants.NotSharedFolderText}</span>;
        } else if (sharedWith.length === 1) {
            return (
                <span className="sharedWith" style={{ display: "inline-block" }} title={sharedWith[0]}>
                    {sharedWith[0]}
                </span>
            );
        } else {
            return (
                <div className="pointer d-flex">
                    <span className="sharedWith" title={sharedWith[0]}
                    >
                        {sharedWith[0]}
                    </span>
                    <OverlayTrigger trigger="click" placement="right" overlay={popoverRecipient(sharedWith)} rootClose>
                        <span className="ms-2">&nbsp;+{sharedWithFirstNames.length - 1}</span>
                    </OverlayTrigger>
                </div>
            );
        }
    };

    const popoverRecipient = (cell: string[]) => (
        <Popover id="sharedFolderPopover">
            <Popover.Body className="reports-popover-body shared-folder-popover-body">
                <ListGroup>
                    {cell.map((data, index) => (
                        <ListGroup.Item className="ellipsis" key={index} title={data}>
                            {data}
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Popover.Body>
        </Popover>
    );

    const getBreadcrumb = (folder: IFolderModel) => {
        const parentFolderIds = folder.parentFolderIds.split(", ").map(Number);
        const breadCrumbArray: IFolderModel[] = [];
        folder.path?.split(" > ").forEach((x, index) => {
            const folderId = parentFolderIds[index];
            breadCrumbArray.push({
                folderName: x,
                folderId,
                parentId: index === 0 ? 0 : parentFolderIds[index - 1],
                path: folder.path,
                parentFolderIds: folder.parentFolderIds,
                description: folder.description,
                expiresDays: folder.expiresDays,
                createdBy: folder.createdBy,
                createdDate: folder.createdDate,
                owner: folder.owner,
                isPrivate: folder.isPrivate,
                permissionGroupId: folder.permissionGroupId,
                folderType: folder.folderType,
            });
        });
        return breadCrumbArray;
    };

    const folderNameFormatter = (cell: string, row: IFolderModel): JSX.Element => {
        return (
            <>
                <div className="folder-name-container">
                    <FolderTypeIcon folderType={row.folderType} height={34} width={34} />
                    <div className="folder-name">
                        <p
                            className="overflowText"
                            onClick={(e: any) => {
                                e.stopPropagation();
                                resetSelection();
                                props.handleFolderClick(row, {
                                    sortColumn: sortOptions
                                        ? getFolderModelColumns(sortOptions.sortBy)
                                        : FolderColumnName.FOLDERNAME,
                                    sortOrder:
                                        sortOptions && sortOptions.sortOrder === "asc"
                                            ? EnumSortOrder.ASC
                                            : sortOptions
                                                ? EnumSortOrder.ASC
                                                : EnumSortOrder.ASC,
                                }, !row.path, row.path ? getBreadcrumb(row) : []);
                            }}
                            title={cell}
                        >
                            {cell}
                        </p>
                        {row.path && (
                            <div className="folder-path-text" title={row.path}>
                                <FolderBreadCrumb
                                    breadCrumbArray={getBreadcrumb(row)}
                                    goToFolder={props.goToFolder}
                                    isTitleBreadcrumb={false}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    const folderDescrptionFormatter = (cell: string): JSX.Element => {
        return (
            <>
                <div style={{ textAlign: "left" }}>
                    <p className="overflowText folder-description" title={cell}>
                        {cell ? cell : "-"}
                    </p>
                </div>
            </>
        );
    };

    const createdDateFormatter = (cell: string): JSX.Element => {
        return (
            <span className="folders-date-cell">
                {getFormattedLocalDateTime(cell, DateFormatConstants.DEFAULT_DATE_FORMAT)}
            </span>
        );
    };

    const toolbarFormatter = (cell: string, row: IFolderModel): JSX.Element => {
        return <div className="cell-actions-wrapper justify-content-end">{folderToolbar(row)}</div>;
    };

    const folderToolbar = (row: IFolderModel): JSX.Element => {
        return (
            <FolderToolbar
                selectedFolders={[row]}
                isAnySelected={true}
                reset={false}
                isHeaderToolbar={false}
                //enable when multiple subfolders under parent folder
                //or when searching under root folder having multiple folders
                isMoveToFolderEnabled={!(props.folderData?.length == 1 &&
                    props.folderData[0].parentId === rootFolder.folderId)
                    || (props.folderData[0].parentId === rootFolder.folderId &&
                        props.foldersCountUnderRoot > 1 &&
                        props.isSearched == true)}
                setReload={props.setReload}
                resetSelection={resetSelection}
                currentfolder={props.currentfolder}
                resetSearchFolderTextAndSort={props.resetSearchFolderTextAndSort}
            />
        );
    };

    const onTableChange = (type: TableChangeType, newState: TableChangeState<IFolderModel>) => {
        switch (type) {
            case "sort":
                setSortOptions({
                    sortBy: newState.sortField,
                    sortOrder: newState.sortOrder,
                });
                break;
        }

        props.requestFolders({
            sortColumn: sortOptions ? getFolderModelColumns(newState.sortField) : FolderColumnName.FOLDERNAME,
            sortOrder: newState.sortOrder === "asc" ? EnumSortOrder.ASC : EnumSortOrder.DESC,
        });
    };

    const setNoContent = () => {
        if (props.isContentLoading) {
            return (
                <LoadingOverlay style={{ height: 400 }}>
                    <Loader loading={props.isContentLoading} />
                </LoadingOverlay>
            );
        } else {
            return MessageListConstants.NO_MESSAGES_OR_REQUESTS;
        }
    };

    const resetSelection = () => {
        props.updateFolderSelection([]);
    };

    const handleOnSelect = (row: IFolderModel, isSelect: boolean) => {
        let selectedFolders = [...props.selectedFolders];
        if (isSelect) {
            selectedFolders.push(row);
        } else {
            selectedFolders = selectedFolders.filter(c => c.folderId !== row.folderId);
        }
        props.updateFolderSelection(selectedFolders);
    };

    const handleOnSelectAll = (isSelect: boolean, rows: IFolderModel[]) => {
        if (isSelect) {
            props.updateFolderSelection(rows);
        } else {
            resetSelection();
        }
    };

    const enableEditPermission = (folder?: IFolderModel) => {
        if (
            !folder ||
            isFolderOwner(folder, user) ||
            (folder.permissionGroupId == ShareFolderTypes.EDIT && folder.isPermissiomInherited)
        ) {
            return true;
        } else if (folder.permissionGroupId == ShareFolderTypes.VIEW) {
            return false;
        } else if (folder.permissionGroupId === ShareFolderTypes.NONE && folder.isPermissiomInherited) {
            return true;
        }
        return false;
    };

    const enableSubFolderEditPermission = (folder: IFolderModel) => {
        if (folder.parentId === 0 || !folder.isPermissiomInherited) {
            return true;
        } else {
            return false;
        }
    };

    const nonSelectableFolders = props.folderData
        .filter(
            x =>
                (x.permissionGroupId === ShareFolderTypes.VIEW && !enableEditPermission(x)) ||
                (x.permissionGroupId === ShareFolderTypes.EDIT && enableSubFolderEditPermission(x))
        )
        .map(y => y.folderId);

    const selectRow: SelectRowProps<IFolderModel> = {
        mode: "checkbox",
        clickToSelect: true,
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        selected: props.selectedFolders?.map(f => f.folderId),
        nonSelectable: nonSelectableFolders,
        selectionHeaderRenderer: () => {
            return (
                <div className="checkbox-container">
                    <Checkbox
                        id="mpiCheckbox"
                        size={CheckBoxSize.sm}
                        checked={!!props.selectedFolders.length}
                        indeterminate={
                            props.folderData.length - nonSelectableFolders.length !== props.selectedFolders.length
                        }
                        onClick={e => e.stopPropagation()}
                        onChange={e => e.stopPropagation()}
                        text=""
                    />
                </div>
            );
        },
    };

    const columns: ColumnDescription[] = [
        {
            text: "Name",
            dataField: "folderName",
            sort: true,
            formatter: folderNameFormatter,
            headerStyle: { width: "20%" },
            style: { width: "20%", overflow: "initial" },
        },
        {
            text: "Description",
            dataField: "description",
            sort: true,
            formatter: folderDescrptionFormatter,
            headerStyle: { width: "20%" },
            style: { width: "20%" },
        },
        {
            text: "Who can see",
            dataField: "sharedWith",
            sort: true,
            formatter: sharedWithFormatter,
            headerStyle: { width: "15%" },
            style: { width: "15%" },
        },
        {
            text: "Owner",
            dataField: "ownerFirstName",
            sort: true,
            formatter: (cell: string, row: IFolderModel) => row.ownerFirstName,
            headerStyle: { width: "13%" },
            style: { width: "13%", whiteSpace: "pre" },
        },
        {
            text: "Created on",
            dataField: "createdDate",
            sort: true,
            formatter: createdDateFormatter,
            headerStyle: { width: "10%" },
            style: { width: "10%" },
        },
        {
            text: "",
            dataField: "toolbar",
            headerFormatter: HeaderActionsBar,
            formatter: toolbarFormatter,
            headerStyle: { width: "22%" },
            style: { width: "22%" },
        },
    ];

    return (
        <BootstrapTable
            classes="settings-table folder-table"
            keyField="folderId"
            columns={columns}
            data={Array.isArray(props.folderData) ? props.folderData : []}
            remote
            defaultSorted={defaultSorted}
            selectRow={selectRow}
            onTableChange={onTableChange}
            noDataIndication={setNoContent()}
            bordered={false}
        />
    );
};

ArchiveFolderTable.displayName = ArchiveFolderTable.name;

export default ArchiveFolderTable;
