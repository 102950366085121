import React, { useRef } from "react";
import { Form, Row } from "react-bootstrap";
import { SavedMessageEmailTemplate } from "../../../../../helper/Constants";
import { CustomModal } from "../../../../common/ui/Modals/CustomModal";
import { LoadingOverlay ,Loader} from "react-overlay-loader";
import {
    initialSavedMessageTemplate,
    ISavedMessageTemplateModel,
} from "../../../../../core/viewModels/settings/SavedMessageTemplate";
import { useEffect, useState } from "react";
import VariablesList from "./parts/VariableList";
import { TemplateSource, TemplateType } from "../../../../../core/common/enums";
import { convertHtmlToText, convertToHtmlString } from "../../../../../helper/HelperFunctions";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";

interface IUserTemplateSetting{
    allowedToAdd:boolean;
    allowedToChangeDefault : boolean;
   }
interface IAddSavedMessageModalProps {
    show: boolean;
    onHide: () => void;
    handleSubmit: (templateData: ISavedMessageTemplateModel, isUpdate: boolean ,isDefault?:boolean ,defaultTemplateReverted?:boolean) => void;
    isEdit: boolean;
    data?: ISavedMessageTemplateModel;
    templateTypeValue?: TemplateType;
    templateSourceValue?: TemplateSource;
    defaultTemplate?: ISavedMessageTemplateModel;
    isAdminTemplate?: boolean;
    settings?: IUserTemplateSetting;
}
export const AddSavedeMessagesModal = ({
    show,
    onHide,
    handleSubmit,
    isEdit,
    data,
    templateTypeValue,
    defaultTemplate,
    isAdminTemplate,
    settings,
}: IAddSavedMessageModalProps) => {
    const isCurrentMessageDefault = data? (data?.savedMessageTemplateId === defaultTemplate?.savedMessageTemplateId) : false;
    const isVariableListEnabled =isEdit && isAdminTemplate || data?.isSystemDefault;
    const [templateData, setTemplateData] = useState<ISavedMessageTemplateModel>(initialSavedMessageTemplate);
    const [isClose, setIsClose] = useState(true);
    const [isDefault, setIsDefault] = useState(isCurrentMessageDefault);
    const [validated, setValidated] = useState(false);
    const modalTitle = isEdit
        ? templateTypeValue == TemplateType.DocumentRequest
            ? SavedMessageEmailTemplate.EDIT_REQUEST_TEMPLATE_MODAL_HEADER
            : SavedMessageEmailTemplate.EDIT_TEMPLATE_MODAL_HEADER
        : templateTypeValue == TemplateType.DocumentRequest
        ? SavedMessageEmailTemplate.ADD_REQUEST_TEMPLATE_MODAL_HEADER
        : SavedMessageEmailTemplate.ADD_TEMPLATE_MODAL_HEADER;
    const formRef = useRef<HTMLFormElement>(null);
    const [isFieldEmpty, setIsFieldEmpty] = useState<boolean>(false);
    const { isLoading } = useSelector((state: ApplicationState) => state.SavedMessageTemplateState);
    const { isLoading:isRequestTemplateLoading }  = useSelector((state: ApplicationState) => state.SavedRequestTemplateState);

    useEffect(() => {
        if (isEdit && data) {
            setTemplateData(data);
        }
    }, []);

    const handleOnSubmit = () => {
        let defaultTemplateReverted=false;
        if(isCurrentMessageDefault === true && isDefault === false){
            defaultTemplateReverted =true;
        }
        const form = formRef.current;
        const completeTemplateData:ISavedMessageTemplateModel ={ ...templateData,
            templateName :templateData.templateName.trim(),
            text:convertToHtmlString(templateData.text)};

        const isTemplateNameValid = completeTemplateData.templateName.trim().length > 0;
        const isTextValid = templateData.text.trim().length > 0;
        if (!isTemplateNameValid || !isTextValid) {
            setIsFieldEmpty(true);
        }
        if (!!form && form.checkValidity() && isTemplateNameValid && isTextValid) {
            const isUpdate = isEdit ? true : false;
            handleSubmit(completeTemplateData, isUpdate ,isDefault ,defaultTemplateReverted);
        }
        setValidated(true);
    };

    const onFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { id, value } = e.target;
        setTemplateData(prevState => ({
            ...prevState,
            [id]: value,
        }));
    };

    const onDefaultPermissionChange = () => {
        setIsDefault(!isDefault);
    };
    const checkBoxComponent = (
        <div
            className="settings-form-item margin-top-10-px"
            title={
                settings && !settings.allowedToChangeDefault
                    ? SavedMessageEmailTemplate.ACTION_DISABLED
                    : "Set As Default"
            }
        >
            <Form.Check
                className={`btn-with-icon ${settings && !settings.allowedToChangeDefault ? "disabled" : ""}`}
                type="checkbox"
                checked={isDefault}
                onChange={onDefaultPermissionChange}
                disabled={settings ? !settings?.allowedToChangeDefault : false}
            />
            <Form.Label>Set as Default</Form.Label>
        </div>
    );
    const buttonName = isEdit
        ? SavedMessageEmailTemplate.CONFIRM_SAVE_BUTTON
        : SavedMessageEmailTemplate.CONFIRM_ADD_BUTTON;
    return (
        <CustomModal
            show={show}
            onHide={onHide}
            onSubmit={handleOnSubmit}
            cancelButtonName="Cancel"
            confirmButtonName={buttonName}
            title={modalTitle}
            className="add-email-template-modal"
            additionalFooterElement={checkBoxComponent}
        >
            <LoadingOverlay>
                <Form noValidate onSubmit={e => e.preventDefault()} validated={validated} ref={formRef}>
                    <Row>
                        <Form.Group controlId="templateName">
                            <Form.Label className="form-header">
                                {SavedMessageEmailTemplate.MODAL_NAME_INPUT_LABEL}
                            </Form.Label>
                            <Form.Control
                                disabled={(isEdit && isAdminTemplate) || data?.isSystemDefault}
                                id="templateName"
                                onChange={onFormChange}
                                value={templateData.templateName}
                                className={`add-template-input ${
                                    (isEdit && isAdminTemplate) || data?.isSystemDefault ? "input-disabled" : ""
                                }`}
                                type="text"
                                required
                                maxLength={SavedMessageEmailTemplate.MAX_TEMPLATE_NAME_LENGTH}
                                title={
                                    (isEdit && isAdminTemplate) || data?.isSystemDefault
                                        ? SavedMessageEmailTemplate.ACTION_DISABLED
                                        : SavedMessageEmailTemplate.MODAL_NAME_INPUT_LABEL
                                }
                            />
                            {isFieldEmpty && !(templateData.templateName.trim().length > 0) && (
                                <h6 className="text-danger">{SavedMessageEmailTemplate.NAME_REQUIRED_TEXT}</h6>
                            )}
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group controlId="text">
                            <Form.Label className="form-header">
                                {SavedMessageEmailTemplate.MODAL_NAME_MESSAGE_INPUT_LABEL}
                            </Form.Label>
                            <Form.Control
                                disabled={(isEdit && isAdminTemplate) || data?.isSystemDefault}
                                as="textarea"
                                id="text"
                                style={{
                                    whiteSpace: "pre-wrap",
                                    minHeight: "156px",
                                    maxHeight: "156px",
                                    overflowY: "auto",
                                    resize: "none",
                                }}
                                onChange={onFormChange}
                                value={convertHtmlToText(templateData.text)}
                                className={`add-template-input ${
                                    (isEdit && isAdminTemplate) || data?.isSystemDefault ? "input-disabled" : ""
                                }`}
                                type="text"
                                required
                                title={
                                    (isEdit && isAdminTemplate) || data?.isSystemDefault
                                        ? SavedMessageEmailTemplate.ACTION_DISABLED
                                        : SavedMessageEmailTemplate.MODAL_NAME_MESSAGE_INPUT_LABEL
                                }
                                isInvalid={isFieldEmpty && !(templateData.text.trim().length > 0)}
                            />
                            {isFieldEmpty && !(templateData.text.trim().length > 0) && (
                                <h6 className="text-danger">{SavedMessageEmailTemplate.MESSAGE_REQUIRED_TEXT}</h6>
                            )}
                        </Form.Group>
                    </Row>
                    <VariablesList
                        isVariableListEnabled={isVariableListEnabled}
                        isClose={isClose}
                        setIsClose={setIsClose}
                        templateType={templateTypeValue}
                    />
                </Form>
                <Loader loading={isLoading || isRequestTemplateLoading} />
            </LoadingOverlay>
        </CustomModal>
    );
};
