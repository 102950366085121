import {
    CompleteProductEnabled,
    CompleteProductSubscribed,
    ReceiveProductEnabled,
    ReceiveProductSubscribed,
    RequestProductEnabled,
    RequestProductSubscribed,
    SetExchangeEnableStatus,
    SetPageAccessStatus,
} from "../actions/postAuthActions";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import { actionTypes as postAuthActions } from "../types/postAuthTypes";
import { actionTypes as notifactionAction } from "../types/notificationTypes";

import { AppThunkAction } from "../store";
import axios, { AxiosResponse } from "axios";
import { setCookie } from "../helper/HelperFunctions";

export type DispatchActions =
    | RequestProductEnabled
    | ReceiveProductEnabled
    | CompleteProductEnabled
    | RequestProductSubscribed
    | ReceiveProductSubscribed
    | CompleteProductSubscribed
    | SetExchangeEnableStatus
    | SetPageAccessStatus;

export type KnownActions = DispatchActions | NotificationAction;

export const getProductEnabledData = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: postAuthActions.REQUEST_PRODUCT_ENABLED,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Company/IsProductEnabledInCompany`)
        .then((response: AxiosResponse) => {
            const data = response.data;
            dispatch({
                type: postAuthActions.RECEIVE_PRODUCT_ENABLED,
                exchangeEnabled: data.exchangeEnabled,
                isTestCompany: data.isTestCompany});
        })
        .catch(function (error: Error) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
        })
        .finally(() =>
            dispatch({
                type: postAuthActions.COMPLETE_PRODUCT_ENABLED,
            })
        );
};

export const getProductSubscribed = (): AppThunkAction<KnownActions> => dispatch => {
    dispatch({
        type: postAuthActions.REQUEST_PRODUCT_SUBSCRIBED,
    });
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Company/IsProductSubscribedInCompany`)
        .then((response: AxiosResponse) => {
            const data = response.data;
            dispatch({
                type: postAuthActions.RECEIVE_PRODUCT_SUBSCRIBED,
                payload: data.exchangeSubscribed,
            });
            dispatch(getProductEnabledData());
        })
        .catch(function (error: Error) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
        })
        .finally(() =>
            dispatch({
                type: postAuthActions.COMPLETE_PRODUCT_SUBSCRIBED,
            })
        );
};

export const getXSRFAntiForgeryToken = (): AppThunkAction<KnownActions> => dispatch => {
    axios
        .get(`${process.env.REACT_APP_API_URL}/api/Account/GetAntiforgeryToken`)
        .then((response: AxiosResponse) => {
            const xsrfToken = response.data.antiForgeryToken;
            setCookie("XSRF-TOKEN", xsrfToken, 8);
        })
        .catch(function (error: Error) {
            dispatch({
                type: notifactionAction.NOTIFICATION,
                statusMessage: error.message,
                statusType: StatusType.Error,
            });
        });
};
