import React from "react";
import { Form } from "react-bootstrap";
import { CustomModal } from "../../../../../common/ui/Modals/CustomModal";
import { SavedMessageEmailTemplate } from "../../../../../../helper/Constants";

type DeleteCategoryProps = {
    show: boolean;
    onClose: () => void;
    handleSubmit: () => void;
    isMultiple?: boolean;
};

const DeleteSavedMessageTemplate = ({ show, onClose, handleSubmit }: DeleteCategoryProps) => {
    return (
        <CustomModal
            show={show}
            onHide={() => {
                onClose();
            }}
            onSubmit={handleSubmit}
            isConfirmModal={true}
            className="delete-saved-message-modal"
            cancelButtonName="Cancel"
            confirmButtonName="Ok"
            title={SavedMessageEmailTemplate.DELETE_TEMPLATE_MODAL_TITLE}
        >
            <Form className="settings-form" onSubmit={e => e.preventDefault()}>
                <Form.Group className="marB15" controlId="DeleteTemplate">
                    <Form.Label>
                        {SavedMessageEmailTemplate.DELETE_TEMPLATES_MODAL_TEXT}
                    </Form.Label>
                </Form.Group>
            </Form>
        </CustomModal>
    );
};

export default DeleteSavedMessageTemplate;
