import { IPasswordState } from "../core/viewModels/profile/ProfileViewModel";
import { actionTypes } from "../types/userProfileActionTypes";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import { AppThunkAction } from "../store";
import axios from "axios";
import { actionTypes as notificationTypes } from "../types/notificationTypes";
import { IMyAccountUserProfile } from "../components/navigation/parts/Profile";
import { ToasterMessages } from "../helper/ToasterMessages";

interface RequestUserProfileAction {
    type: actionTypes.REQUEST_USER_PROFILE;
}

interface ReceiveUserProfileAction {
    type: actionTypes.RECEIVE_USER_PROFILE;
    user: IMyAccountUserProfile;
}

interface UpdateUserProfileAction {
    type: actionTypes.UPDATE_USER_PROFILE;
    userDetails: IMyAccountUserProfile;
}

interface UpdatePasswordAction {
    type: actionTypes.UPDATE_PASSWORD;
    passwordState: IPasswordState;
}
export type DispatchActions =
    | RequestUserProfileAction
    | ReceiveUserProfileAction
    | UpdateUserProfileAction
    | UpdatePasswordAction;

export type KnownAction = DispatchActions | NotificationAction;

export const requestUserProfile =
    (reload = false): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
        if (reload || !getState().userProfile.emailAddress) {
            dispatch({ type: actionTypes.REQUEST_USER_PROFILE });

            axios.get(`${process.env.REACT_APP_API_URL_SUITE}/api/user-management/user-details`).then((response: any) => {
                const data = response.data;                
                dispatch({ type: actionTypes.RECEIVE_USER_PROFILE, user: data });
            });
        }
    };

export const saveMyAccount =
    (
        userInfo: IMyAccountUserProfile,
        notification: boolean,
        onResponse: (response: any, error: any) => void
    ): AppThunkAction<KnownAction> =>
    dispatch => {
        dispatch({ type: actionTypes.REQUEST_USER_PROFILE });
        axios
            .put(`${process.env.REACT_APP_API_URL_SUITE}/api/user-management/user-details`, userInfo)
            .then((response: any) => {
                const data = response.data;

                if (onResponse) {
                    onResponse(data, null);
                }
                if (notification) {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: ToasterMessages.SUCCESS.UPDATE_USER_DETAILS,
                        statusType: StatusType.Success,
                    });
                }
            })
            .catch(error => {
                if (onResponse) {
                    onResponse(null, error);
                }

                if (notification) {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: ToasterMessages.ERROR.SAVE_USER_DETAILS,
                        statusType: StatusType.Error,
                    });
                }
            });
    };

export const savePassword =
    (
        passwordState: IPasswordState,
        notification: boolean,
        onResponse: (response: any, error: any) => void
    ): AppThunkAction<KnownAction> =>
    dispatch => {
        axios
            .put(`${process.env.REACT_APP_API_URL_SUITE}/api/user-management/user-password`, passwordState)
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.UPDATE_PASSWORD,
                    passwordState: passwordState,
                });
                if (onResponse) {
                    onResponse(data, null);
                }
                if (notification) {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: ToasterMessages.SUCCESS.UPDATE_USER_DETAILS,
                        statusType: StatusType.Success,
                    });
                }
            })
            .catch(error => {
                if (onResponse) {
                    onResponse(null, error);
                }
                dispatch({
                    type: actionTypes.UPDATE_PASSWORD,
                    passwordState: passwordState,
                });
                if (notification) {
                    dispatch({
                        type: notificationTypes.NOTIFICATION,
                        statusMessage: ToasterMessages.ERROR.SAVE_USER_DETAILS,
                        statusType: StatusType.Error,
                    });
                }
            });
    };
