import { AppNotifier } from "./AppNotifier";
import { ComposeValidations, FileConstants, ValidationContants } from "./Constants";
import { IPasswordSettings, PasswordOptions } from "../core/viewModels/company/CompanySettingsViewModel";
import { IValidationResult } from "./ValidationResult";
import { IRecipientModel } from "../core/viewModels/message/RecipientModel";
import { ISentMailMessage } from "../core/viewModels/message/SentMailMessageModel";
import { AuthenticationMethod, ValidationType } from "../core/common/enums";
import { ISentMessageModel } from "../core/viewModels/message/SentMessageModel";
import { IFileModel } from "../core/viewModels/file/FileModel";
import { IMyAccountUserProfile } from "../components/navigation/parts/Profile";
import { ToasterMessages } from "./ToasterMessages";

export function isValidEmailAddress(emailAddress: any) {
    if (emailAddress) {
        const pattern = new RegExp(
            /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?$/i
        );
        return pattern.test(emailAddress);
    }
    return false;
}

export function validateFax(faxId: any, hideMessage?: boolean) {
    const fax = faxId.trim();
    if (fax.trim().length < 10 || /^[0-9]{10}$/.test(fax) == false) {
        {
            !hideMessage && AppNotifier.Warning(ToasterMessages.WARNING.INVALID_FAX_NUMBER);
        }
        return false;
    } else return true;
}

export function validateQuestion(question: string) {
    if (question.length <= 100) {
        return true;
    }
    return false;
}

export function validateQuestionTitle(questionTitle: string) {
    if (questionTitle.length <= 50) {
        return true;
    }
    return false;
}

function hasLowerCase(data: string) {
    const pattern = new RegExp(".*[a-z].*");
    return pattern.test(data);
}

function hasUpperCase(data: string) {
    const pattern = new RegExp(".*[A-Z].*");
    return pattern.test(data);
}

function hasNumber(data: string) {
    const pattern = new RegExp(/[\d]/i);
    return pattern.test(data);
}

function hasSpecialCharacter(data: string) {
    const pattern = new RegExp(/[~!@#$%^&*_]/i);
    return pattern.test(data);
}

export function getFileExtension(fileName: string) {
    return fileName.slice(((fileName.lastIndexOf(".") - 1) >>> 0) + 2);
}

export function getContentType(file: File): string {
    const contentType: string = file.type.slice(0, file.type.indexOf("/"));
    if (contentType == "application") {
        return contentType + "/" + getFileExtension(file.name);
    }
    return file.type;
}

export function GetSafeFilename(fileName: string) {
    const pattern = new RegExp(/[^a-z A-Z 0-9. _\-]+/g);
    return fileName.replace(pattern, "_");
}
export function isValidSSN(ssn: string) {
    if (ssn.replace(/-/g, "").length == 9) {
        return true;
    }
}

export function isValidEIN(ein: string) {
    if (ein.replace(/-/g, "").length === 9) {
        return true;
    }
}

export function isValidDate(date: any) {
    const date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/;
    return date_regex.test(date);
}

export function isDigit(zip: any) {
    const date_regex = /^[0-9]{1,10}$/;
    return date_regex.test(zip);
}

export function NullandEmptyCheck(text: string) {
    if (text == undefined || text == null || text.trim() == "") {
        return false;
    }
    return true;
}

export function isValidatePTIN(ptin: string) {
    const ptin_regex = /^[Pp][0-9]{8}$/;
    return ptin_regex.test(ptin) || ptin === "";
}

export function isValidFirmKey(firmKey: string) {
    const guid_regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guid_regex.test(firmKey);
}

export function isValidGuid(guid: string) {
    const guid_regex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return guid_regex.test(guid);
}

export function validatePhoneLength(phoneNoId: any) {
    const phoneNo = phoneNoId.trim();
    if (phoneNo.trim().length < 10 || /^[0-9]{1,10}$/.test(phoneNo) == false) {
        return false;
    } else return true;
}

export function validateFaxLength(FaxId: any) {
    const Fax = FaxId.trim();
    if (Fax.trim().length < 10 || /^[0-9]{10}$/.test(Fax) == false) {
        return false;
    } else return true;
}

export function validateValidCity(cityId: any) {
    const city = cityId.trim();
    if (/^(?:[a-zA-Z]+(?:[.'\-,])?\s?)+$/.test(city) == false) {
        return false;
    } else return true;
}
export function validateZipLength(zipNoId: any) {
    const zipNo = zipNoId.trim();
    if (zipNo.trim().length > 5 || zipNo.trim().length < 5 || /^[0-9]{5}$/.test(zipNo) == false) {
        return false;
    } else return true;
}
export function ValidateWebsite(website: string) {
    if (
        /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z A-Z 0-9]+([\-\.]{1}[a-z A-Z 0-9]+)*\.[a-z A-Z]{2,5}(:[0-9]{1,5})?(\/.*)?$/.test(
            website
        ) == false
    ) {
        return false;
    } else {
        return true;
    }
}

export function validateExtensionLength(extensionId: any) {
    const extension = extensionId.trim();
    if (extension.trim().length > 6 || /^[0-9]{1,10}$/.test(extension) == false) {
        return false;
    } else return true;
}
export function validatePasswordLength(passwordId: any) {
    const password = passwordId.trim();
    if (password.length < 8) {
        return false;
    }
    return true;
}
export function validatePasswordSpace(passwordId: any) {
    const password = passwordId.trim();
    if (password.indexOf(" ") >= 0) {
        return false;
    }
    return true;
}

export function ValidateTenDigitNumber(event: any) {
    const pattern = new RegExp(/^[0-9]{0,10}$/);
    return pattern.test(event.target.value);
}

export function ValidateTenDigitphoneNumber(value: string) {
    const pattern = new RegExp(/^[0-9\-\s)\(]{0,14}$/);
    return pattern.test(value);
}

export function validatePasswordBasedOnPolicy(password: any, passwordSettings: IPasswordSettings): IValidationResult {
    password = password.trim();
    const passwordOptions = passwordSettings.passwordOptions;
    if (password.trim().length < passwordSettings.length) {
        return {
            isError: true,
            errorDescription: "Password must be minimum " + passwordSettings.length + " characters.",
        };
    } else if (password.trim().indexOf(" ") >= 0) {
        return {
            isError: true,
            errorDescription: ValidationContants.PasswordWithoutSpaceWarning,
        };
    } else if ((passwordOptions & PasswordOptions.UpperCase) === PasswordOptions.UpperCase && !hasUpperCase(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.UpperCaseValidationError,
        };
    } else if ((passwordOptions & PasswordOptions.LowerCase) === PasswordOptions.LowerCase && !hasLowerCase(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.LowerCaseValidationError,
        };
    } else if ((passwordOptions & PasswordOptions.Numbers) === PasswordOptions.Numbers && !hasNumber(password)) {
        return {
            isError: true,
            errorDescription: ValidationContants.NumbersValidationError,
        };
    } else if (
        (passwordOptions & PasswordOptions.SpecialCharacters) === PasswordOptions.SpecialCharacters &&
        !hasSpecialCharacter(password)
    ) {
        return {
            isError: true,
            errorDescription: ValidationContants.SpecialCharactersValidationError,
        };
    }

    return {
        isError: false,
        errorDescription: "Success",
    };
}
export const fileSizeExceedsLimit = (fileName: string) =>
    `${fileName} cannot be uploaded since the size is greater than 3 GB.`;

export function validateFileSize(file: any, maxSize?: string, message?: string): boolean {
    const maximumSize = maxSize || FileConstants.MaximumFileSize;
    if (file.size === 0) {
        return false;
    }
    if (file.size > maximumSize) {
        AppNotifier.Error(message || fileSizeExceedsLimit(file.name) );
        return false;
    }
    return true;
}

export const isMaxUploadSizeLimitExceeded = (totalSize: number, maxSize?: number): boolean => {
    const maximumSize = maxSize || FileConstants.MaximumTotalFileSize;
    if (totalSize === 0) {
        return false;
    }
    if (totalSize > maximumSize) {
        AppNotifier.Error(ToasterMessages.ERROR.FILE_SIZE_EXCEEDED);
        return false;
    }
    return true;
};

export const validateRecipientQnA = (rowData: IRecipientModel, showAlert: boolean) => {
    if (!rowData.emailAddress) {
        showAlert && AppNotifier.Warning(ToasterMessages.WARNING.REQUIRE_RECIPIENT_EMAIL);
        return false;
    } else if (!isValidEmailAddress(rowData.emailAddress)) {
        showAlert && AppNotifier.Warning(ToasterMessages.WARNING.INVALID_EMAIL_FORMAT);
        return false;
    } else if (!rowData.question?.trim()) {
        showAlert && AppNotifier.Warning(ToasterMessages.WARNING.REQUIRE_QUESTION);
        return false;
    } else if (!rowData.answer?.trim()) {
        showAlert && AppNotifier.Warning(ComposeValidations.ANSWER_REQUIRED);
        return false;
    }
    return true;
};

export const validateAllRecipientsQnA = (messageDetail: ISentMessageModel, recipients: IRecipientModel[]) => {
    let isValid = true;
    if (messageDetail.authenticationMethod === AuthenticationMethod.QUESTIONANSWER) {
        for (let i = 0; i < recipients.length; i++) {
            isValid = validateRecipientQnA(recipients[i], false);
            if (!isValid) {
                break;
            }
        }
    }
    return isValid;
};

export const validateAllFilesUploaded = (attachments: IFileModel[]) => {
    let isValid = true;
    if (!attachments.every(a => a.isUploadComplete == true)) {
        isValid = false;
    }
    return isValid;
};

export interface IValidationMessage {
    validationType: ValidationType;
    messageProperty: string;
    message: string;
}

export const validateComposeMessageModel = (composeMessage: ISentMailMessage, recipients: IRecipientModel[]) => {
    const alert: IValidationMessage = {
        validationType: ValidationType.VALID,
        messageProperty: "",
        message: "",
    };
    const uniqueRecipients: string[] = [];
    composeMessage.recipients.map(r => {
        if (uniqueRecipients.indexOf(r.email) === -1) {
            uniqueRecipients.push(r.email);
        }
    });

    if (!composeMessage.recipients || composeMessage.recipients.length === 0) {
        alert.validationType = ValidationType.INVALID;
        alert.message = ToasterMessages.WARNING.REQUIRE_RECIPIENT;
    } else if (!validateAllRecipientsQnA(composeMessage.messageDetail, recipients)) {
        alert.validationType = ValidationType.INVALID;
        alert.message = ToasterMessages.WARNING.REQUIRE_RECIPIENT_QUESTION_ANSWER;
    } else if (composeMessage.recipients.length > uniqueRecipients.length) {
        alert.validationType = ValidationType.INVALID;
        alert.message = ToasterMessages.WARNING.RECIPIENT_ALREADY_EXIST;
    } else if (!composeMessage.attachments || composeMessage.attachments.length === 0) {
        alert.validationType = ValidationType.WARNING;
        alert.message = ToasterMessages.WARNING.WITHOUT_ATTACHMENT;
        alert.messageProperty = "attachment";
    } else if (!validateAllFilesUploaded(composeMessage.attachments)) {
        alert.validationType = ValidationType.INVALID;
        alert.message = ToasterMessages.WARNING.ATTACHMENT_UPLOAD_INCOMPLETE;
    } else if (!composeMessage.messageDetail.subject) {
        alert.validationType = ValidationType.WARNING;
        alert.message = ToasterMessages.WARNING.WITHOUT_SUBJECT;
        alert.messageProperty = "subject";
    }
    return alert;
};

export const invalidFolderNameRegex = new RegExp(/[\\\/:\*\?\"<>\|]/);

export const isFolderNameValid = (folderName: string) => {
    return !invalidFolderNameRegex.test(folderName);
};

export const invalidFileNameRegex = new RegExp(/[\\/:*?"<>|\u00A0]/g);

export const simplePhoneValidation = (char: string, callback: (char: string) => void) => {
    const regNumber = /^[0-9]+$/;
    if (char.length > 10) return false;
    if (!char || char.match(regNumber)) callback(char);
};

export const fileTypeValidation = (name: string, supportedTypes: string[], errorMessage: string) => {
    const type = getFileExtension(name);
    if (supportedTypes.includes(type)) {
        return true;
    } else {
        AppNotifier.Error(errorMessage);
        return false;
    }
};

export const isControlDisabled = (property: string, profile: IMyAccountUserProfile): boolean => {
    return profile.readonlyFields.some(field => field === property);
};
