import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Option } from "react-dropdown";
import { Col, FormControl, Row } from "react-bootstrap";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { debounce } from "throttle-debounce";

import {
    initialDocumentPagination,
    DocumentRequestConstants,
    viewingOthersInboxLabel,
    MyDownloadConstants,
    SortAndFilterConstants,
    SortAndFilterIconType,
    ArchiveConstants,
    DEFAULT_PAGE_SIZE,
} from "../../../helper/Constants";
import { DocumentRequestList } from "./DocumentRequestList";
import { DocumentRequestDetailed } from "./DocumentRequestDetailed";
import { getDocumentRequestPayload, getDRSortAndFilterOptions, getRetentionOptions, isDelegatedUser } from "../../../helper/HelperFunctions";
import { fetchRetentionPeriods } from "../../../actionCreators/folderActionCreator";
import {
    IDocumentRequestListModel,
    IDocumentRequestListResponseModel,
    IDocumentRequestModel,
    IDocumentRequestSearchModel,
    initialDocumentListState,
    initialDocumentSearchState,
} from "../../../core/viewModels/documentRequest/DocumentRequestModel";
import { DownloadIcon, FileEarMarkArrowPlus, RefreshIcon, SearchIcon } from "../../svg/IconCollection";
import {
    downloadUploadedFile,
    fetchDocumentRequestFileList,
    fetchDocumentRequestList,
    getDocumentRequestDetail,
    requestDRDownloadLater,
    resendDocumentAccesLink,
    resendDocumentAccesLinkDetailed,
    sendDocumentReminder,
} from "../../../actionCreators/documentRequestActionCreators";
import { ApplicationState } from "../../../store";
import ComposeDocumentRequestModal from "./ComposeDocumentRequestModal";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { getRestrictedFileTypes } from "../../../actionCreators/restrictedFileTypeActionCreators";
import "./style.scss";
import { getDocumentCategoriesDropdown } from "../../../actionCreators/documentCategoryActionCreator";
import { FolderType, ListingStatus, RequestViewType } from "../../../core/common/enums";
import SwitchButtonGroup from "../../common/ui/switch/SwitchButtonGroup";
import { IDocumentRequestFileModel } from "../../../core/viewModels/documentRequest/DocumentRequestFileModel";
import { getYearFromDate } from "../../../helper/DateHelperFunctions";
import RequestFileView from "../folders/RequestFileView";
import { IFolderModel } from "../../../core/viewModels/folder/FolderModel";
import { FiltersCustomComponent } from "../../common/ui/Filters/FiltersCustomComponent";

export interface IDocumentRequestProps {
    isArchive?: boolean;
    currentFolder?: IFolderModel;
}
export const DocumentRequest: React.FC<IDocumentRequestProps> = props => {
    const {
        isArchive,
        currentFolder,
    } = props;

    const [showComposeDocumentRequestModal, setShowComposeDocumentRequestModal] = useState<boolean>(false);
    const [pagination, setPagination] = useState(initialDocumentPagination);
    const [selectedDocumentList, setSelectedDocumentList] = useState<IDocumentRequestListModel[]>([]);
    const [selectedDocument, setSelectedDocument] = useState<IDocumentRequestListModel>(initialDocumentListState);
    const [showDocumentDetail, toggleShowDocumentDetail] = useState(false);
    const [retentionPeriods, setRetentionPeriods] = useState<Option[]>([]);
    const [resetSortAndFilterOptions, callResetSortAndFilter] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<IDocumentRequestFileModel[]>([]);
    const [selectedSortOption, setSelectedSortOption] = useState<string>(SortAndFilterConstants.NEWEST_FIRST_ORDER);
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
    const [searchText, setSearchText] = useState<string>("");

    const defaultFileViewSort = {
        dataField: "documentName",
        order: "asc",
    };
    const [fileViewSort, setFileViewSort] = useState(defaultFileViewSort);
    const requestView = ["Request View", "File View"];
    const [requestViewType, setRequestViewType] = useState<string>(requestView[RequestViewType.Request]);

    const documentState = useSelector((state: ApplicationState) => state.documentState);
    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);
    const retentionData = useSelector((appState: ApplicationState) => appState.folderState.retentionPeriodsData);
    const [currentSelectedOption, setCurrentSelectedOption] = useState<string>("");
    const [selectedFilterOption, setSelectedFilterOption] = useState<string>(SortAndFilterConstants.ALL_STATUSES_ORDER);
    const [timeOut, setActionTimeout] = useState<boolean>(false);

    const filtersData = [
        {
            title: SortAndFilterConstants.SORT_BY_TEXT,
            menus: [
                SortAndFilterConstants.ALPHABETICAL_ORDER_ASC,
                SortAndFilterConstants.ALPHABETICAL_ORDER_DESC,
                SortAndFilterConstants.NEWEST_FIRST_ORDER,
                SortAndFilterConstants.OLDEST_FIRST_ORDER,
            ],
        },
        {
            title: SortAndFilterConstants.FILTER_BY_TEXT,
            menus: [
                SortAndFilterConstants.ALL_STATUSES_ORDER,
                SortAndFilterConstants.COMPLETED_ORDER,
                SortAndFilterConstants.PENDING_UPLOAD_ORDER,
                SortAndFilterConstants.PARTIALLY_UPLOAD_ORDER,
                SortAndFilterConstants.OVERDUE_ORDER,
            ],
        },
    ];

    const sortingData = [
        {
            title: SortAndFilterConstants.SORT_BY_TEXT,
            menus: [
                SortAndFilterConstants.ALPHABETICAL_ORDER_ASC,
                SortAndFilterConstants.ALPHABETICAL_ORDER_DESC,
                SortAndFilterConstants.NEWEST_FIRST_ORDER,
                SortAndFilterConstants.OLDEST_FIRST_ORDER,
            ],
        },
    ];


    const dispatch = useDispatch();

    const fetchDocumentRequests = (documentRequestSearchModel: IDocumentRequestSearchModel) => {
        dispatch(fetchDocumentRequestList(documentRequestSearchModel, currentFolder?.folderId ?? FolderType.DOCUMENTREQUEST, onFetchDocumentRequestComplete));
    };

    useEffect(() => {
        setPagination(initialDocumentPagination);
        getData(searchText, true);
    }, [currentSelectedOption]);

    const getData = (value: string, isFilter: boolean) => {
        const sortAndFilterOptions = getDRSortAndFilterOptions(
            currentSelectedOption,
            selectedFilterOption,
            selectedSortOption
        );

        const payload = {
            ...initialDocumentSearchState,
            taxpayerEmail: value.trim(),
            pageNumber: isFilter ? initialDocumentPagination.currentPage : pagination.currentPage,
            sortOrder: sortAndFilterOptions.sortOrder,
            sortColumn: sortAndFilterOptions.sortColumn,
            listingStatus: sortAndFilterOptions.listingStatus,
        };
        fetchDocumentRequests(payload);
    };

    useEffect(() => {
        if (requestViewType && isArchive) {
            setFileViewSort(defaultFileViewSort);
            dispatch(
                fetchDocumentRequestFileList(
                    currentFolder?.folderId || FolderType.DOCUMENTREQUEST,
                    pagination.currentPage,
                    pagination.pageSize,
                    "",
                    searchText
                )
            );
        }
    }, [requestViewType, searchText]);

    useEffect(() => {
        dispatch(fetchRetentionPeriods());
        dispatch(getRestrictedFileTypes());
        dispatch(getDocumentCategoriesDropdown());
    }, []);

    useEffect(() => {
        setRetentionPeriods(getRetentionOptions(retentionData.retentionPeriods));
    }, [retentionData]);

    useEffect(() => {
        resetSortAndFilter();
    }, [resetSortAndFilterOptions]);

    useEffect(() => {
        if (searchText === "") {
            setSelectedFiles([]);
        }
    }, [searchText]);

    const resetSortAndFilter = () => {
        setSearchText("");
        setCurrentSelectedOption(SortAndFilterConstants.NEWEST_FIRST_ORDER);
        setSelectedFilterOption(SortAndFilterConstants.ALL_STATUSES_ORDER);
        setSelectedSortOption(SortAndFilterConstants.NEWEST_FIRST_ORDER);
    };

    const onFetchDocumentRequestComplete = (result: IDocumentRequestListResponseModel) => {
        if (result && result.documentRequests && result.documentRequests.length > 0) {
            setSelectedDocument(result.documentRequests[0]);
            dispatch(
                getDocumentRequestDetail(result.documentRequests[0].documentRequestId, documentRequestDetailCallback)
            );
        } else toggleShowDocumentDetail(false);
    };

    const documentRequestDetailCallback = (result: IDocumentRequestModel) => {
        if (result) toggleShowDocumentDetail(true);
        else toggleShowDocumentDetail(false);
    };

    const getTotalFileSize = () => {
        if (selectedFiles?.length) {
            let totalSize = 0;
            selectedFiles.map(file => (totalSize += file.fileSize));
            return totalSize;
        } else return 0;
    };

    const handleDownloadSingleAttachment = (
        fileGuid: string,
        sentDate: Date,
        fileName: string,
    ) => {
        dispatch(
            downloadUploadedFile(
                fileName,
                fileGuid,
                getYearFromDate(sentDate),
                ResourceIdLocators.DRL.DRLBtnDownloadFile,
                (result: any) => {
                    if (result) {
                        //reload UI after download
                        dispatch(fetchDocumentRequestFileList(currentFolder?.folderId || 6,
                            pagination.currentPage,
                            pagination.pageSize,
                            "",
                            ""
                        )
                        );
                        window.open(result, "_blank");
                        setSelectedFiles([]);
                    }
                },
            )
        );
    };

    const handleDownloadButtonClick: React.MouseEventHandler<HTMLButtonElement> = () => {
        if (selectedFiles.length == 1) {
            const file = selectedFiles[0];
            handleDownloadSingleAttachment(file.fileGuid, file.sentDate, file.fileName);

        } else if (getTotalFileSize() > MyDownloadConstants.MAX_FILE_SIZE) {
            dispatch(
                requestDRDownloadLater(getDocumentRequestPayload(selectedFiles as IDocumentRequestFileModel[]), currentFolder?.folderName || "",
                    () => {
                        //reload UI after download
                        dispatch(
                            fetchDocumentRequestFileList(currentFolder?.folderId || 6,
                                pagination.currentPage,
                                pagination.pageSize,
                                "",
                                ""
                            )
                        );
                        setSelectedFiles([]);
                    })
            );
        } else {
            setShowDownloadModal(true);
        }
    };
    const handleGetDocumentDetail = (documentRequestId: number) => {
        dispatch(getDocumentRequestDetail(documentRequestId, documentRequestDetailCallback));
    };

    const resetSelection = (drlRefresh?: boolean,
        sortOrder?: string,
        sortColumn?: string,
        listingStatus?: ListingStatus) => {
        setSelectedDocumentList([]);
        const payload =
            drlRefresh === true
                ? {
                    ...initialDocumentSearchState,
                    pageNumber: initialDocumentPagination.currentPage,
                    pageSize: pagination.pageSize,
                }
                : {
                    ...initialDocumentSearchState,
                    pageNumber: pagination.currentPage,
                    pageSize: pagination.pageSize,
                    sortOrder: sortOrder ?? initialDocumentSearchState.sortOrder,
                    sortColumn: sortColumn ?? initialDocumentSearchState.sortColumn,
                    listingStatus: listingStatus ?? initialDocumentSearchState.listingStatus
                };
        setTimeout(() => {
            fetchDocumentRequests(payload);
            drlRefresh && setPagination(initialDocumentPagination);
        }, 1000);
        toggleShowDocumentDetail(false);
        setSelectedDocument(initialDocumentListState);
        setShowComposeDocumentRequestModal(false);
        callResetSortAndFilter(prevState => !prevState);
    };

    const detailedActionSuccessCallback = (documentId: number) => {
        handleGetDocumentDetail(documentId);
    };

    const handleDocumentResendAccessLink = (documentRequestIds: number[], isListingAction?: boolean) => {
        if (isListingAction) {
            dispatch(resendDocumentAccesLink(documentRequestIds, isArchive ? ResourceIdLocators.MyFiles.MyFilesBtnRequestResendAccess : ResourceIdLocators.DRL.DRLListBtnResendAccess));
        } else {
            dispatch(resendDocumentAccesLinkDetailed(documentRequestIds, ResourceIdLocators.DRL.DRLDtlBtnResendAccess));
        }
    };

    const handleDocumentSendReminder = (documentRequestIds: number[], isListingAction = false, resourceId: string) => {
        dispatch(sendDocumentReminder(documentRequestIds, isListingAction, resourceId));
    };

    const handleDRListRelaod = (drlRefresh?: boolean) => {
        let payload: any = { drlRefresh: true };
        if (!drlRefresh) {
            const sortAndFilterOptions = getDRSortAndFilterOptions(
                currentSelectedOption,
                selectedFilterOption,
                selectedSortOption
            );
            payload = {
                drlRefresh: false,
                sortOrder: sortAndFilterOptions.sortOrder,
                sortColumn: sortAndFilterOptions.sortColumn,
                listingStatus: sortAndFilterOptions.listingStatus
            };
        }
        if (!timeOut) {
            resetSelection(payload.drlRefresh, payload.sortOrder, payload.sortColumn, payload.listingStatus);
            setActionTimeout(true);
            setTimeout(() => {
                setActionTimeout(false);
            }, 3000);
        }
    };

    const handleOnPageChange = (pageNo: number, pageSize: number = DEFAULT_PAGE_SIZE) => {
        setPagination({
            pageSize,
            currentPage: pageNo,
        });
        const sortAndFilterOptions = getDRSortAndFilterOptions(
            currentSelectedOption,
            selectedFilterOption,
            selectedSortOption
        );
        const payload = {
            ...initialDocumentSearchState,
            pageNumber: pageNo,
            pageSize: pageSize,
            taxpayerEmail: searchText.trim(),
            sortOrder: sortAndFilterOptions.sortOrder,
            sortColumn: sortAndFilterOptions.sortColumn,
            listingStatus: sortAndFilterOptions.listingStatus,
        };
        fetchDocumentRequests(payload);
    };

    const onFilter: React.ChangeEventHandler<HTMLInputElement> = e => {
        const value = (e.target as HTMLInputElement).value.toLocaleLowerCase();
        setSearchText(value);
        setPagination(initialDocumentPagination);
        debounceFn(value, false);
    };

    const debounceFn = useCallback(debounce(1000, getData), [selectedFilterOption, selectedSortOption]);


    const handleRequestViewToogle = () => {
        // Reset sorting to default
        if (requestViewType === requestView[RequestViewType.File]) {
            setRequestViewType(requestView[RequestViewType.Request]);
            resetSelection(true);
        } else {
            setRequestViewType(requestView[RequestViewType.File]);
        }
    };

    const handleResetSearchAndSort = () => {
        setSearchText("");
        setSelectedSortOption(SortAndFilterConstants.NEWEST_FIRST_ORDER);
    };

    return (
        <div className={`row ${isArchive ? "" : "height-100-percent document-request"} width-100-percent margin-left-0`}>
            {!isArchive && (
                <>
                    {mailboxUserData && (
                        <p className="others-inbox-info-label">
                            {viewingOthersInboxLabel(mailboxUserData.firstName, mailboxUserData.lastName)}
                        </p>
                    )}
                    <Row className="padding-0-px margin-0-px">
                        <div className="request-document-header-wrapper">
                            <header>
                                <h3>{DocumentRequestConstants.REQUEST_DOCUMENT_TITLE_TEXT}</h3>
                                <span>{DocumentRequestConstants.REQUEST_DOCUMENT_HELPER_TEXT}</span>
                            </header>
                            {!isDelegatedUser() && (
                                <button
                                    className="new-request-button"
                                    onClick={() => setShowComposeDocumentRequestModal(true)}
                                    data-resource-id={ResourceIdLocators.DRL.DRLBtnNewRequest}
                                >
                                    <FileEarMarkArrowPlus color="white" />
                                    New Request
                                </button>
                            )}
                        </div>
                    </Row>
                </>
            )}
            <div className="file-search-wrapper" style={isArchive ? { height: "46px", paddingRight: "0px" } : {}}>
                <Row className="search-header-wrapper col-sm-4" style={isArchive ? { marginLeft: "-8px", width: "33.3%", paddingRight: "0px" } : {}}>
                    <Col sm={10} md={8} className="search-input-wrapper-archive padding-left width-27p">
                        <div className="search-input-wrapper">
                            <FormControl
                                placeholder={requestViewType === requestView[RequestViewType.File] ? DocumentRequestConstants.PLACEHOLDER_REQUEST_SEARCH_FILE_VIEW : DocumentRequestConstants.PLACEHOLDER_SEARCH_TEXT}
                                value={searchText}
                                onChange={onFilter}
                                className={"search-input search-textbox"}
                            />
                            <div className="search-icon">
                                <SearchIcon />
                            </div>
                        </div>
                    </Col>
                    {(!isArchive || requestViewType === requestView[RequestViewType.Request]) && (
                        <Col sm={2} md={2} className="filter-icon-container">
                            <div>
                                <FiltersCustomComponent
                                    groups={isArchive ? sortingData : filtersData}
                                    selectedSortOption={selectedSortOption}
                                    iconType={isArchive ? SortAndFilterIconType.Sort : SortAndFilterIconType.Filter}
                                    setSelectedSortOption={setSelectedSortOption}
                                    setCurrentSelectedOption={setCurrentSelectedOption}
                                    {...(!isArchive && {
                                        selectedFilterOption: selectedFilterOption,
                                        setSelectedFilterOption: setSelectedFilterOption
                                    })} />
                            </div>
                        </Col>
                    )}
                    {!isArchive && (
                        <Col sm={2} md={2} className="list-refresh-icon-container">
                            <div
                                className={`list-refresh-button ${timeOut && "disabled"}`}
                                onClick={() => handleDRListRelaod(true)}
                                title={ArchiveConstants.REFRESH_TITLE}
                            >
                                <RefreshIcon disabled={timeOut} />
                            </div>
                        </Col>
                    )}
                </Row>
                {isArchive && (
                    <div className="file-view-toggle-container col-sm-8" style={{ paddingRight: "0px" }}>
                        {requestViewType === requestView[RequestViewType.File] && (
                            <button
                                className="button-secondary-blue-default download-button"
                                data-resource-id={ResourceIdLocators.MyFiles.MyFilesBtnDownloadFiles}
                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    handleDownloadButtonClick(event);
                                }}
                                disabled={!selectedFiles.length
                                }
                                style={{ borderRadius: "2px", height: "34px" }}
                            >
                                <DownloadIcon width={18} height={18} color="#fff" /> Download
                            </button>
                        )}
                        <SwitchButtonGroup
                            selected={requestViewType}
                            onSwitchChange={handleRequestViewToogle}
                            option2ResourceDataId={ResourceIdLocators.MyFiles.MyFilesBtnFileView}
                            option1Text={requestView[RequestViewType.Request]}
                            option2Text={requestView[RequestViewType.File]}
                        />
                    </div>
                )}
            </div>
            {(!isArchive || requestViewType === requestView[RequestViewType.Request]) && (
                <main>
                    <Row className="padding-right-9px">
                        <Col sm={4} className="no-padding-left">
                            <DocumentRequestList
                                documents={documentState.documentRequestList.documentRequests}
                                isArchive={isArchive}
                                folder={currentFolder}
                                totalDocuments={documentState.documentRequestList.totalRecords}
                                pagination={pagination}
                                selectedDocument={selectedDocument}
                                setSelectedDocument={setSelectedDocument}
                                selectedDocumentList={selectedDocumentList}
                                setPagination={setPagination}
                                getDocumentDetail={handleGetDocumentDetail}
                                handleDocumentResendAccessLink={handleDocumentResendAccessLink}
                                handleDocumentSendReminder={handleDocumentSendReminder}
                                fetchDocumentRequests={fetchDocumentRequests}
                                updateSelectedDocumentList={setSelectedDocumentList}
                                resetSelection={resetSelection}
                                resetSortAndFilterOptions={resetSortAndFilterOptions}
                                handleDRListRelaod={handleDRListRelaod}
                                handleOnPageChange={handleOnPageChange}
                            />
                        </Col>
                        <Col className="padding-right-0-px document-request-detailed" style={isArchive ? {} : { marginTop: "-42px" }} sm={8}>
                            <LoadingOverlay style={{ height: "100%" }}>
                                {showDocumentDetail ? (
                                    <DocumentRequestDetailed
                                        document={documentState.documentRequestDetailed.data}
                                        retentionOptions={retentionPeriods}
                                        isArchive={isArchive}
                                        folder={currentFolder}
                                        handleDocumentResendAccessLink={handleDocumentResendAccessLink}
                                        handleDocumentSendReminder={handleDocumentSendReminder}
                                        resetSelection={resetSelection}
                                        detailedActionSuccessCallback={detailedActionSuccessCallback}
                                        handleGetDocumentDetail={handleGetDocumentDetail}
                                    />
                                ) : (
                                    documentState.documentRequestDetailed.isLoading || (
                                        <div className="no-message-selected">
                                            {DocumentRequestConstants.SELECT_DOCUMENT_REQUESTS}
                                        </div>
                                    )
                                )}
                                <Loader loading={documentState.documentRequestDetailed.isLoading} />
                            </LoadingOverlay>
                        </Col>
                    </Row>
                </main>
            )}
            {showComposeDocumentRequestModal && (
                <ComposeDocumentRequestModal
                    show={showComposeDocumentRequestModal}
                    onHide={() => setShowComposeDocumentRequestModal(false)}
                    resetSelection={resetSelection}
                />
            )}
            {requestViewType === requestView[RequestViewType.File] && (
                <RequestFileView
                    selectedFiles={selectedFiles}
                    setShowDownloadModal={setShowDownloadModal}
                    showDownloadModal={showDownloadModal}
                    setSelectedFiles={setSelectedFiles}
                    currentFolder={currentFolder}
                    sortBy={fileViewSort}
                    downloadSingleAttachment={handleDownloadSingleAttachment}
                    handleResetSearchAndSort={handleResetSearchAndSort}
                />
            )

            }
            <Loader loading={false} />
        </div>
    );
};
