import React from "react";
import { useSelector } from "react-redux";
import { IMessageSecurityQuestion } from "../../../../../core/viewModels/message/MessageSecurityQuestionModel";
import { ApplicationState } from "../../../../../store";
import { DeleteIcon, EditIcon } from "../../../../svg/IconCollection";

interface IAuthQuestionActionBar {
    showRenameOption: boolean;
    showDeleteOption: boolean;
    selectedQuestion: IMessageSecurityQuestion;
    fromUserSettings?: boolean;
    handleEditQuestionClick: (selectedQuestion: IMessageSecurityQuestion) => void;
    handleSetDefaultClick: (selectedQuestion: IMessageSecurityQuestion) => void;
    handleDeleteQuestionClick: (selectedQuestion: IMessageSecurityQuestion[]) => void;
    setSelectedDeleteOption: (selectedDeleteOption: string) => void;
}

const AuthQuestionActionBar: React.FC<IAuthQuestionActionBar> = props => {
    const {
        showRenameOption,
        showDeleteOption,
        selectedQuestion,
        fromUserSettings,
        handleSetDefaultClick,
        handleEditQuestionClick,
        handleDeleteQuestionClick,
        setSelectedDeleteOption,
    } = props;

    const { messageSecuritySettings } = useSelector((state: ApplicationState) => state.adminSettingsState);

    return (
        <div className="inline-actions-bar-container">
            {showRenameOption && (
                <div className="folder-toolbar__action">
                    <div
                        className={`pointer ${selectedQuestion.isDefault ? "edit-question-icon" : ""}`}
                        title={"Edit"}
                        onClick={() => {
                            handleEditQuestionClick(selectedQuestion);
                        }}
                    >
                        <EditIcon width={16} height={16} disabled={false} />
                    </div>
                </div>
            )}

            {showDeleteOption && !selectedQuestion?.isDefault && (
                <div className="folder-toolbar__action" style={{ paddingRight: "20px" }}>
                    <span
                        onClick={() => {
                            setSelectedDeleteOption("inlineDelete");
                            handleDeleteQuestionClick([selectedQuestion]);
                        }}
                        title={"Delete"}
                        className="pointer"
                    >
                        <DeleteIcon width={16} height={16} disabled={false} />
                    </span>
                </div>
            )}

            <button
                className={
                    selectedQuestion?.isDefault ? "button-secondary-blue-default" : "button-secondary-blue-outlined"
                }
                disabled={!messageSecuritySettings.isUserAllowedToChangeDefaultSecurityQuestion && fromUserSettings}
                onClick={e => {
                    e.stopPropagation();
                    handleSetDefaultClick(selectedQuestion);
                }}
            >
                {selectedQuestion?.isDefault ? "Default" : "Set as Default"}
            </button>
        </div>
    );
};

export default AuthQuestionActionBar;
