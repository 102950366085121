import axios, { AxiosResponse } from "axios";
import { actionTypes as sentMessageAction } from "../types/sentMessageTypes";
import { actionTypes as notificationAction } from "../types/notificationTypes";
import { AppThunkAction } from "../store";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import {
    CompletedCancelTransferAction,
    CompletedChangeExpiryAction,
    CompletedDeleteMessagesAction,
    CompletedMoveToFolderAction,
    CompletedPrintAction,
    CompletedResendAccessLinkAction,
    CompletedResendQaAuthAnswerAction,
    CompletedSentMessageDetailAction,
    CompletedSentMessagesAction,
    ReceivedSentMessagesAction,
    ReceivedSentMessagesDetailAction,
    RequestCancelTransferAction,
    RequestChangeExpiryAction,
    RequestDeleteMessagesAction,
    RequestMoveToFolderAction,
    RequestPrintAction,
    RequestResendAccessLinkAction,
    RequestResendQaAuthAnswerAction,
    RequestSentMessageDetailAction,
    RequestSentMessagesAction,
    RequestReplySentMessageAction,
    ReceivedReplySentMessageAction,
    CompletedReplySentMessageAction,
    RequestForwardSentMessageAction,
    ReceivedForwardSentMessageAction,
    CompletedForwardSentMessageAction,
    SentMessageArrivalAction,
    RequestSentMessageActivityLogAction,
    ReceivedSentMessageActivityLogAction,
    CompletedSentMessageActivityLogAction,
} from "../actions/sentMessageActions";
import { ISentMessageModel } from "../core/viewModels/message/SentMessageModel";
import { ISentMailMessage } from "../core/viewModels/message/SentMailMessageModel";
import { IMessageResponseModel } from "../core/viewModels/message/MessageResponseModel";
import { getMailboxUserEmailId } from "../helper/HelperFunctions";
import { appInsightsData } from "../helper/AppInsightsData";
import { logger } from "../oidcClient/authProvider";
import { FolderType, MessageType } from "../core/common/enums";
import { IActivityLogData } from "../core/viewModels/report/reportViewModel";
export type DispatchActions =
    | RequestSentMessagesAction
    | ReceivedSentMessagesAction
    | CompletedSentMessagesAction
    | RequestDeleteMessagesAction
    | CompletedDeleteMessagesAction
    | RequestCancelTransferAction
    | CompletedCancelTransferAction
    | RequestChangeExpiryAction
    | CompletedChangeExpiryAction
    | RequestPrintAction
    | CompletedPrintAction
    | RequestMoveToFolderAction
    | CompletedMoveToFolderAction
    | RequestResendAccessLinkAction
    | CompletedResendAccessLinkAction
    | RequestResendQaAuthAnswerAction
    | CompletedResendQaAuthAnswerAction
    | RequestSentMessageDetailAction
    | ReceivedSentMessagesDetailAction
    | CompletedSentMessageDetailAction
    | RequestReplySentMessageAction
    | ReceivedReplySentMessageAction
    | CompletedReplySentMessageAction
    | RequestForwardSentMessageAction
    | ReceivedForwardSentMessageAction
    | CompletedForwardSentMessageAction
    | SentMessageArrivalAction
    | RequestSentMessageActivityLogAction
    | ReceivedSentMessageActivityLogAction
    | CompletedSentMessageActivityLogAction;

export type KnownActions = DispatchActions | NotificationAction;

export const fetchSentMessages =
    (
        folderId: number,
        pageNo: number,
        pageSize: number,
        sortColumn = "",
        sortOrder = "",
        searchString = "",
        callback?: (result: IMessageResponseModel<ISentMessageModel>) => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: sentMessageAction.REQUEST_SENT_MESSAGES,
        });
        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/SentMessage/GetSentMessages?folderId=${folderId}&pageNo=${pageNo}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&searchString=${encodeURIComponent(searchString.trim())}&mailboxUserEmailId=${getMailboxUserEmailId()}`
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: sentMessageAction.RECEIVED_SENT_MESSAGES,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: sentMessageAction.COMPLETED_SENT_MESSAGES,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const deleteMessages =
    (messages: ISentMessageModel[], callback?: any, resourceId?: string): AppThunkAction<KnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        dispatch({
            type: sentMessageAction.REQUEST_DELETE_MESSAGES,
        });
        messages.map(message => {
            logger.trackEvent({
                name: `${appInsightsData.MailBox.Sent.PageTitle} - ${appInsightsData.MailBox.CommonOperations.Delete}`,
                properties: {
                    page: appInsightsData.MailBox.Sent.PageTitle,
                    ...message,
                },
            });
        });
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/SentMessage/DeleteSentMessages`, {
                params: {
                    mailboxUserEmailId,
                },
                data: messages,
                headers: {
                    "X-Resource-Id": resourceId ?? "",
                },
            })
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: sentMessageAction.COMPLETED_DELETE_MESSAGES,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: sentMessageAction.COMPLETED_DELETE_MESSAGES,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const cancelTransfer =
    (messageIds: number[], resourceId?: string, callback?: (status: number) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: sentMessageAction.REQUEST_CANCEL_TRANSFER_MESSAGES,
        });
        messageIds.map(messageId => {
            logger.trackEvent({
                name: `${appInsightsData.MailBox.Sent.PageTitle} - ${appInsightsData.MailBox.CommonOperations.cancelTransfer}`,
                properties: {
                    page: appInsightsData.MailBox.Sent.PageTitle,
                    messageId,
                },
            });
        });
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/SentMessage/CancelSentMessages`, messageIds, {
                headers: {
                    "X-Resource-Id": resourceId ?? "",
                },
            })
            .then((response: AxiosResponse) => {
                const data = response.status;
                dispatch({
                    type: sentMessageAction.COMPLETED_CANCEL_TRANSFER_MESSAGES,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: sentMessageAction.COMPLETED_CANCEL_TRANSFER_MESSAGES,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const changeExpiry =
    (expiresDate: Date, messages: ISentMessageModel[], callback?: any): AppThunkAction<KnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        dispatch({
            type: sentMessageAction.REQUEST_CHANGE_EXPIRY_SENT_MESSAGE,
        });
        messages.map(message => {
            logger.trackEvent({
                name: `${appInsightsData.MailBox.Sent.PageTitle} - ${appInsightsData.MailBox.CommonOperations.changeExpiry}`,
                properties: {
                    page: appInsightsData.MailBox.Sent.PageTitle,
                    expiresDate,
                    ...message,
                },
            });
        });
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/SentMessage/ChangeSentMessagesExpiry`, messages, {
                params: {
                    expiresDate,
                    mailboxUserEmailId,
                },
            })
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: sentMessageAction.COMPLETED_CHANGE_EXPIRY_SENT_MESSAGE,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: sentMessageAction.COMPLETED_CHANGE_EXPIRY_SENT_MESSAGE,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const printMessages = (): AppThunkAction<KnownActions> => dispatch => {
    setTimeout(() => {
        dispatch({
            type: sentMessageAction.COMPLETED_PRINT_MESSAGES,
        });
    }, 3000);
};

export const moveToFolder =
    (
        messages: ISentMessageModel[],
        destinationFolderId: number,
        callback?: any,
        resourceId?: string
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        dispatch({
            type: sentMessageAction.REQUEST_MOVE_TO_FOLDER,
        });

        messages.map(message => {
            logger.trackEvent({
                name: `${appInsightsData.MailBox.Sent.PageTitle} - ${appInsightsData.MailBox.CommonOperations.MoveTo}`,
                properties: {
                    page: appInsightsData.MailBox.Sent.PageTitle,
                    destinationFolderId,
                    ...message,
                },
            });
        });

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/SentMessage/MoveToFolder`, messages, {
                params: {
                    destinationFolderId,
                    mailboxUserEmailId,
                },
                headers: {
                    "X-Resource-Id": resourceId ?? "",
                },
            })
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: sentMessageAction.COMPLETED_MOVE_TO_FOLDER,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: sentMessageAction.COMPLETED_MOVE_TO_FOLDER,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const resendAccessLink =
    (messages: ISentMessageModel[], resourceId?: string, callback?: any): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: sentMessageAction.REQUEST_RESEND_ACCESS_LINK,
        });
        messages.map(message => {
            logger.trackEvent({
                name: `${appInsightsData.MailBox.Sent.PageTitle} - ${appInsightsData.MailBox.CommonOperations.resendAccessLink}`,
                properties: {
                    page: appInsightsData.MailBox.Sent.PageTitle,
                    ...message,
                },
            });
        });
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/SentMessage/ResendAccessLink`, messages, {
                headers: {
                    "X-Resource-Id": resourceId ?? "",
                },
            })
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: sentMessageAction.COMPLETED_RESEND_ACCESS_LINK,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: sentMessageAction.COMPLETED_RESEND_ACCESS_LINK,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const resendAccessLinkToSingleRecipient =
    (
        sentMessageId: number,
        recipientEmail: string,
        resourceId?: string,
        callback?: (status: number) => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: sentMessageAction.REQUEST_RESEND_ACCESS_LINK,
        });
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/SentMessage/ResendAccessLinkForSingleRecipient?sentMessageId=${sentMessageId}&recipientEmail=${recipientEmail}`,
                {},
                {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then((response: AxiosResponse) => {
                const data = response.status;

                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() =>
                dispatch({
                    type: sentMessageAction.COMPLETED_RESEND_ACCESS_LINK,
                })
            );
    };

export const getMessageDetail =
    (messageId: number, folderId: number, callback?: any): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: sentMessageAction.REQUEST_SENT_MESSAGE_DETAIL,
        });
        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/SentMessage/GetSentMessageDetail?messageId=${messageId}&folderId=${folderId}&mailboxUserEmailId=${getMailboxUserEmailId()}`
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: sentMessageAction.RECEIVED_SENT_MESSAGE_DETAIL,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: sentMessageAction.COMPLETED_SENT_MESSAGE_DETAIL,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const replySentMessage =
    (
        sentMailMessage: ISentMailMessage,
        mailboxUserEmail: string,
        callback?: any,
        errorCallback?: () => void,
        resourceId?: string
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: sentMessageAction.REQUEST_REPLY_SENT_MESSAGE,
        });
        logger.trackEvent({
            name: `${appInsightsData.Reply.PageTitle} - ${appInsightsData.Compose.Operation.SendButtonClick}`,
            properties: {
                page: appInsightsData.Reply.PageTitle,
                sentMailMessage: sentMailMessage,
            },
        });
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/SentMessage/ReplySentMessage?mailboxUserEmailId=${mailboxUserEmail}`,
                sentMailMessage,
                {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: sentMessageAction.RECEIVED_REPLY_SENT_MESSAGE,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(() => {
                dispatch({
                    type: sentMessageAction.COMPLETED_REPLY_SENT_MESSAGE,
                });
                errorCallback && errorCallback();
            });
    };

export const forwardSentMessage =
    (
        sentMailMessage: ISentMailMessage,
        forwardedMessageId: number,
        forwardedFrom: FolderType,
        callback?: any,
        errorCallback?: () => void,
        resourceId?: string
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: sentMessageAction.REQUEST_FORWARD_SENT_MESSAGE,
        });
        logger.trackEvent({
            name: `${appInsightsData.Forward.PageTitle} - ${appInsightsData.Compose.Operation.SendButtonClick}`,
            properties: {
                page: appInsightsData.Forward.PageTitle,
                sentMailMessage: sentMailMessage,
                forwardedMessageId: forwardedMessageId,
            },
        });
        axios
            .post(
                `${process.env.REACT_APP_API_URL}/api/SentMessage/ForwardSentMessage?forwardedMessageId=${forwardedMessageId}&forwardedFrom=${forwardedFrom}`,
                sentMailMessage,
                {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: sentMessageAction.RECEIVED_FORWARD_SENT_MESSAGE,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(() => {
                dispatch({
                    type: sentMessageAction.COMPLETED_FORWARD_SENT_MESSAGE,
                });
                errorCallback && errorCallback();
            });
    };

export const sentMessageArrived =
    (sentMessage: ISentMessageModel): AppThunkAction<DispatchActions> =>
    dispatch => {
        dispatch({
            type: sentMessageAction.SENT_MESSAGE_ARRIVAL,
            newMessage: sentMessage,
        });
    };

export const fetchSentMessageDetailsHistory =
    (
        messageId: number,
        messageType: MessageType,
        callback?: (data: IActivityLogData[]) => void,
        resourceId?: string
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: sentMessageAction.REQUEST_SENT_MESSAGE_ACTIVITY_LOG,
        });
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/SentMessage/GetSentMessageActivityLog?messageId=${messageId}&messageType=${messageType}`,
                {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then((response: AxiosResponse<IActivityLogData[]>) => {
                const data = response.data;
                dispatch({
                    type: sentMessageAction.RECEIVED_SENT_MESSAGE_ACTIVITY_LOG,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch((error: Error) => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: sentMessageAction.COMPLETED_SENT_MESSAGE_ACTIVITY_LOG,
                });
            });
    };
