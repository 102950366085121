import React, { useEffect, useState } from "react";
import BootstrapTable, { ColumnDescription, SelectRowProps } from "react-bootstrap-table-next";
import { DropOffOFFIcon, DropOffONIcon } from "../../../../../../svg/IconCollection";
import RecipientsInlineActions from "./RecipientsInlineActions";
import Checkbox, { CheckBoxSize } from "../../../../../../common/ui/Checkbox";
import { DropOffUsersConstants } from "../../../../../../../helper/Constants";
import { IDropOffRecipient } from "../../../../../../../core/viewModels/company/settings/DropOffSettings";
import { ApplicationState } from "../../../../../../../store";
import { useSelector } from "react-redux";

interface IRecipientsTable {
    recipientTableData: IDropOffRecipient[];
    updateSelectedRecipient: (selectedRecipient: IDropOffRecipient[]) => void;
    selectedRecipient: IDropOffRecipient[];
    handleEnableDropOffRecipients: (selectedRecipients: IDropOffRecipient[]) => void;
    handleDisableDropOffRecipients: (selectedRecipients: IDropOffRecipient[]) => void;
    handleSetAsDefaultRecipient: (selectedRecipients: IDropOffRecipient[]) => void;
    handleRemoveAsDefaultRecipient: (selectedRecipients: IDropOffRecipient[]) => void;
}
export const RecipientsTable: React.FC<IRecipientsTable> = ({
    recipientTableData,
    updateSelectedRecipient,
    selectedRecipient,
    handleEnableDropOffRecipients,
    handleDisableDropOffRecipients,
    handleSetAsDefaultRecipient,
    handleRemoveAsDefaultRecipient
}) => {
    const [recipientsData, setRecipientsData] = useState<IDropOffRecipient[]>(recipientTableData);
    const [defaultRecipient, setDefaultRecipient] = useState<IDropOffRecipient>();

    useEffect(() => {
        setRecipientsData(recipientTableData);
    }, [recipientTableData]);
    const dropOffRecipients = useSelector(
        (state: ApplicationState) => state.dropOffState.dropOffRecipientsData.dropOffRecipients
    );

    useEffect(() => {
        if (dropOffRecipients) {
            const defaultRecipientsIndex = dropOffRecipients?.findIndex(recipient => recipient.isDefault === true);
            setDefaultRecipient(dropOffRecipients[defaultRecipientsIndex]);
        }
    }, [dropOffRecipients]);

    useEffect(() => {
        if (recipientTableData && recipientTableData[0] && !recipientTableData[0].isDefault) {
            const defaultRecipientsIndex = recipientTableData?.findIndex(recipient => recipient.isDefault === true);
            if (defaultRecipientsIndex === -1 && defaultRecipient) {
                const replaceData = [...recipientTableData];
                replaceData.splice(0, 0, defaultRecipient);
                setRecipientsData(replaceData);
            } else {
                const replaceData = [...recipientTableData];
                //Add the default Recipient at index 0
                replaceData.splice(0, 0, replaceData[defaultRecipientsIndex]);
                //Remove Recipient at previous index
                replaceData.splice(defaultRecipientsIndex + 1, 1);
                setRecipientsData(replaceData);
            }
        }
    }, [recipientTableData]);

    const isAnySelected = selectedRecipient.length > 0;

    const inlineActionBar = (cell: string, row: IDropOffRecipient): JSX.Element => {
        return (
            <RecipientsInlineActions
                selectedRecipient={row}
                handleEnableDropOffRecipients={handleEnableDropOffRecipients}
                handleDisableDropOffRecipients={handleDisableDropOffRecipients}
                handleSetAsDefaultRecipient={ handleSetAsDefaultRecipient}
                handleRemoveAsDefaultRecipient={handleRemoveAsDefaultRecipient}
            />
        );
    };

    const HeaderActionsBar = (): JSX.Element => {
        return (
            <div className="header-toolbar-action drop-off-header recipient-header">
                <span
                    id={DropOffUsersConstants.ENABLE_DROPOFF_ICON}
                    title={DropOffUsersConstants.ENABLE_DROP_OFF}
                    onClick={() => isAnySelected && handleEnableDropOffRecipients(selectedRecipient)}
                    className={isAnySelected ? "pointer" : "isDisabled"}
                >
                    <DropOffONIcon width={22} height={22} disabled={!isAnySelected} />
                </span>
                <span
                    id={DropOffUsersConstants.DISABLE_DROPOFF_ICON}
                    title={DropOffUsersConstants.DISABLE_DROP_OFF}
                    onClick={() => isAnySelected && handleDisableDropOffRecipients(selectedRecipient)}
                    className={isAnySelected ? "pointer" : "isDisabled"}
                >
                    <DropOffOFFIcon width={22} height={22} disabled={!isAnySelected} />
                </span>
            </div>
        );
    };

    const userStateFormatter = (cell: boolean): JSX.Element => {
        return (
            <span 
                id={cell ? DropOffUsersConstants.ENABLE_DROPOFF_ICON : DropOffUsersConstants.DISABLE_DROPOFF_ICON} 
                className="user-state">
                {cell ? <DropOffONIcon width={18} height={18} /> : <DropOffOFFIcon width={18} height={18} />}
            </span>
        );
    };
    const userNameFormatter = (cell: string, row: IDropOffRecipient): JSX.Element => {
        return (
            <span
                style={{
                    fontWeight: `${row.isDefault ? "bold" : "normal"}`,
                }}
                title={`${row.firstName ?? ""} ${row.lastName ?? ""}`}
            >{`${row.firstName ?? ""} ${row.lastName ?? ""}`}</span>
        );
    };
    const userEmailFormatter = (cell: string, row: IDropOffRecipient): JSX.Element => {
        return (
            <div className="cell-actions-wrapper">
                <span
                    className="table-email-cell overflowText"
                    style={{
                        fontWeight: `${row.isDefault ? "bold" : "normal"}`,
                    }}
                    title={cell}
                >
                    {cell}
                </span>
                {inlineActionBar(cell, row)}
            </div>
        );
    };

    const columns: ColumnDescription[] = [
        {
            text: DropOffUsersConstants.USER_STATUS_TABLE_LABEL,
            dataField: "isDropOffEnabled",
            formatter: userStateFormatter,
            style: { width: "8%" },
        },
        {
            text: "Employee Name",
            dataField: "EmployeeName",
            formatter: userNameFormatter,
            classes: "table-name-cell overflowText",
        },
        {
            text: "Email Address",
            dataField: "emailAddress",
            formatter: userEmailFormatter,
        },
        {
            text: "",
            dataField: "toolbar",
            headerFormatter: HeaderActionsBar,
        },
    ];

    const handleOnSelect = (row: IDropOffRecipient, isSelect: boolean) => {
        let selectedRecipientsCopy = [...selectedRecipient];
        if (isSelect) {
            selectedRecipientsCopy.push(row);
        } else {
            selectedRecipientsCopy = selectedRecipientsCopy.filter(recipient => recipient.userId != row.userId);
        }
        updateSelectedRecipient(selectedRecipientsCopy);
    };

    const handleOnSelectAll = (isSelect: boolean, rows: IDropOffRecipient[]) => {
        if (isSelect) {
            updateSelectedRecipient(rows);
        } else {
            updateSelectedRecipient([]);
        }
    };

const hasDefaultRecipient = recipientsData.some(recipient => recipient.isDefault === true);

const selectRow: SelectRowProps<IDropOffRecipient> = {
    mode: "checkbox",
    clickToSelect: true,
    nonSelectable: [...recipientsData.filter(recipient => recipient.isDefault === true).map(recipient => recipient.userId)],
    selected: selectedRecipient.map((recipient: IDropOffRecipient) => recipient.userId),
    onSelect: handleOnSelect,
    onSelectAll: handleOnSelectAll,
    selectionHeaderRenderer: () => (
        <Checkbox
            id="mpiCheckbox"
            size={CheckBoxSize.sm}
            checked={!!selectedRecipient.length}
            indeterminate={hasDefaultRecipient ? (selectedRecipient.length !== recipientsData.length-1) : (selectedRecipient.length !== recipientTableData.length)}
            onClick={e => e.stopPropagation()}
            onChange={e => e.stopPropagation()}
            text={""}
        />
    ),
};

    return (
        <BootstrapTable
            classes="settings-table dropOff-recipients-table"
            keyField="userId"
            columns={columns}
            data={recipientsData}
            remote
            selectRow={selectRow}
            noDataIndication={DropOffUsersConstants.NO_DATA_TEXT}
            bordered={false}
        />
    );
};
