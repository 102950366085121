export const ToasterMessages = {
    SUCCESS: {
        ADD_CATEGORIES: "Document Category added successfully.",
        ADD_EMAIL_TEMPLATE: "Email Template added successfully.",
        ADD_FILTER: "Filter added successfully.",
        ADD_NEW_FOLDER: "New folder created successfully.",
        ADD_QUESTION: "Security Question added successfully.",
        ADD_SECURITY_QUESTION: "Security question saved successfully.",
        ADD_SENDER: "Sender added successfully.",
        ADD_TEMPLATE: "Template added successfully.",
        APPLY_ADMIN_CHANGES: "Admin settings have been updated.",
        APPLY_USER_SETTINGS: "Your settings have been updated.",
        APPLY_FILTER: "Filter applied successfully.",        
        BLACKLIST_DROP_OFF_SENDER: "The selected sender was blacklisted successfully.",
        BLACKLIST_DROP_OFF_SENDERS: "The selected sender(s) were blacklisted successfully.",
        CANCEL_MESSAGES: "The selected message(s) cancelled successfully.",
        CHANGE_EXPIRY: "Message expiry updated successfully.",
        CHANGE_RETENTION: "Retention period of the selected folder updated successfully.",
        CHANGE_RETENTIONS: "Retention period of the selected folder(s) updated successfully.",
        DELETE_CATEGORIES: "Document Category(s) deleted successfully.",
        DELETE_CATEGORY: "Document Category deleted successfully.",
        DELETE_DOCUMENT_DETAILED: "Document deleted successfully.",
        DELETE_DROP_OFF_SENDER: "The selected sender was deleted successfully.",
        DELETE_DROP_OFF_SENDERS: "The selected sender(s) were deleted successfully.",
        DELETE_EMAIL_TEMPLATE: "Email Template deleted successfully.",
        DELETE_FILE_DETAILED: "File deleted successfully.",
        DELETE_FILTER: "Selected filter deleted successfully.",
        DELETE_FOLDER: "The selected folder deleted successfully.",
        DELETE_FOLDERS: "The selected folder(s) were deleted successfully.",
        DELETE_MESSAGES: "The selected message(s) have been deleted successfully.",
        DELETE_QUESTION: "Selected Question deleted successfully.",
        DELETE_QUESTIONS: "Selected Question(s) deleted successfully.",
        DELETE_REQUEST_DETAILED: "Request deleted successfully.",
        DELETE_REQUEST_LISTING: "Request(s) deleted successfully.",
        DELETE_REQUEST_TEMPLATE: "Selected Template deleted successfully.",
        DELETE_REQUEST_TEMPLATES: "Selected Template(s) deleted successfully.",
        DISABLE_DROP_OFF_RECIPIENT: "Drop Off Disabled for selected recipient successfully.",
        DISABLE_DROP_OFF_RECIPIENTS: "Drop Off Disabled for selected recipient(s) successfully.",
        DOWNLOAD_LATER: "Download in progress. Once ready, go to 'My Downloads' to access your file(s).",
        EDIT_CATEGORY: "Document Category updated successfully.",
        EDIT_QUESTION: "Security Question updated successfully.",
        EDIT_SENDER_EMAIL: "Sender email address updated successfully.",
        EDIT_TEMPLATE: "Template updated successfully.",
        ENABLE_DROP_OFF_RECIPIENT: "Drop Off Enabled for selected recipient successfully.",
        ENABLE_DROP_OFF_RECIPIENTS: "Drop Off Enabled for selected recipient(s) successfully.",
        MARK_AS_NOT_SPAM_MESSAGE: "Message(s) have been marked as not spam.",
        MOVE_FOLDER_TO_FOLDER: "The selected folder moved to <destination folder> successfully.",
        MOVE_FOLDERS_TO_FOLDER: "The selected folder(s) moved to <destination folder> successfully.", // Here <destination folder> is a placeholder and should be replaced with the actual folder name.
        MOVE_MESSAGE_TO_FOLDER: "Message(s) moved successfully.",
        MOVE_REQUESTS_TO_FOLDER:"Request(s) moved successfully.",
        MOVE_TO_INBOX: "Message(s) have been moved to inbox.",
        REMOVE_AS_DEFAULT_DROP_OFF_RECIPIENT: "The default option for the selected recipient has been removed.",
        REMOVE_FILTER: "Filter removed successfully.",
        REMOVE_SHARED_FOLDER_USER: "User removed successfully.",
        RENAME_FOLDER: "Folder updated successfully.",
        REPORT_A_PROBLEM: "Problem reported successfully. Your tracking ID is", // Period is added at the end of dynamic tracking ID.
        RESEND_ACCESS_LINK: "Access link resent successfully.",
        RESEND_REQUEST_ACCESS_LINK: "Request resent successfully.",
        SAVE_MESSAGE_TO_DRAFT: "Message saved to draft successfully.",
        SAVE_RESTRICT_FILE_TYPE: "Restricted file type(s) updated successfully.",
        SEND_DR_DOCUMENT_REMINDER_SPECIFIC_FILE: "Document reminder sent successfully.",
        SEND_DR_MORE: "Additional documents request sent successfully.",
        SEND_DR_REQUEST_REMINDER_LIST: "Reminder sent successfully.\n Note: This will not apply to Completed Request(s).",
        SEND_DR: "Request sent successfully.",
        SEND_MESSAGE: "Message Sent Successfully!",
        SEND_REMINDER: "Reminder sent successfully.",
        SET_AS_DEFAULT_DROP_OFF_RECIPIENT: "Selected recipient has been updated as default recipient successfully.",
        SHARE_FOLDER: "Folder shared successfully.",
        UNLOCK_LINKS: "Link(s) unlocked successfully.",
        UNLOCK_ONEHUB: "Client Portal has been unlocked!",
        UPDATE_DR_TO_COMPLETED_STATUS: "Request completed successfully.",
        UPDATE_DR_TO_OPEN_STATUS: "Request reopened successfully.",
        UPDATE_EMAIL_TEMPLATE: "Email Template updated successfully.",
        UPDATE_FILTER: "Filter saved successfully.",
        UPDATE_PASSWORD: "Password updated successfully.",
        UPDATE_REQUEST_RETENTION_PERIOD: "Request Retention Period updated successfully.",
        UPDATE_SHARED_FOLDER_PERMISSION: "Permission updated successfully.",
        UPDATE_USER_DETAILS: "User details have been updated.",
        UPDATE_USER: "User updated successfully.",
        VERIFY_ACCESS_CODE: "Access code verified successfully.",
        WHITELIST_DROP_OFF_SENDER: "The selected sender was whitelisted successfully.",
        WHITELIST_DROP_OFF_SENDERS: "The selected sender(s) were whitelisted successfully.",

    },
    ERROR: {
        ATTACHMENT_UPLOAD_FAILED:"Upload failed. Please try again.",
        CATEGORY_ALREADY_EXIST: "Category name already exists.",
        COMPANY_SETTINGS_ERROR: "Error occurred while fetching company settings.",
        DROP_OFF_RECIPIENT_REMOVED_UNSUCCESSFUL_ONE_RECIPIENT:"It is necessary to have at least one enabled drop-off recipient.",
        DROP_OFF_RECIPIENT_REMOVED_UNSUCCESSFUL: "It is necessary to have at least one enabled drop-off recipient. Please unselect the intended recipient and try again.",
        ERROR_IN_FILE_UPLOAD: "Error in file upload. Please try again.",
        FILE_SIZE_EXCEEDED: "The file(s) you are attaching exceed the allowable size limit (3GB). Please reduce total file size and try again.",
        FILE_TYPE_UNSUPPORTED: "Unsupported file type. Only doc, docx, pdf, png, jpg and jpeg file types are allowed.",
        SAVE_USER_DETAILS: "Saving user details failed, please re-login.",
        SEND_MESSAGE_FAILED: "There was an error while sending the message. Please try again.",
        TEMPLATE_ALREADY_EXIST:"This template name is already in use by another user within your firm. Please pick another template name.",
        UNABLE_TO_DUPLICATE_TEMPLATE: "Unable to duplicate the selected template. Name character limit exceeded.",
        UPDATE_PASSWORD_FAILED: "Updating password failed, please re-login.",
        UPDATE_USER_DETAILS_FAILED: "Updating user details failed, please re-login.",
    },
    WARNING: {
        ATTACHMENT_UPLOAD_INCOMPLETE: "Upload in progress. Please wait.",
        CHANGE_FOLDER_PERMISSION: "The owner has made changes to this folder. The page will automatically refresh to display these updates.",
        DEFAULT_QA_METHOD: "To add more recipients, switch to access code authentication mode.",
        DOWNLOAD_PROGRESS: "Download in progress.",
        EMAIL_NOT_FOUND: "No email found.",
        INVALID_ACCESS_CODE: "Invalid access code.",
        INVALID_EMAIL_FORMAT: "Email is invalid.",
        INVALID_FAX_NUMBER: "Please enter a 10 digit fax number.",
        QUESTION_TITLE_ALREADY_EXIST: "Question title already exists. Please pick a new name.",
        REACHED_MAXIMUM_RECIPIENTS: "Exceeded the maximum number of recipients.",
        RECIPIENT_ALREADY_EXIST: "Recipient already exists.",  
        REQUIRE_ANSWER: "Answer is required.",
        REQUIRE_EXPIRY_DATE: "Please select an Expiry Date.",
        REQUIRE_QUESTION_NAME: "Question name is required.",
        REQUIRE_QUESTION: "Question is required.",
        REQUIRE_RECIPIENT_EMAIL: "Recipient email is required.",
        REQUIRE_RECIPIENT_QUESTION_ANSWER: "Please fill out the fields in Message Options.",
        REQUIRE_RECIPIENT: "Please add at least one recipient.",
        UPLOAD_IN_PROGRESS: "Please wait until attachment has finished uploading.",
        WITHOUT_ATTACHMENT: "Do you want to send this message without attachment(s)?",
        WITHOUT_SUBJECT: "Do you want to send this message without a subject?",
    }
};