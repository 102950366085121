import React, { useEffect, useState } from "react";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { ApplicationState } from "../../../../../store";
import { useDispatch, useSelector } from "react-redux";
import { CompanySettingsName } from "../../../../../core/common/enums";
import CustomToggleSwitch from "../../../../common/ui/switch/CustomToggleSwitch";

import MessageListBox from "./parts/SavedMessageTemplateList";
import MessageBox from "./parts/SavedMessageDetailBox";
import {
    initialSavedMessageDRSettings,
    initialSavedMessageTemplate,
    ISavedMessageDRTemplateSettings,
    ISavedMessageTemplateModel,
} from "../../../../../core/viewModels/settings/SavedMessageTemplate";
import { TemplateSource, TemplateType } from "../../../../../core/common/enums";
import { AddSavedeMessagesModal } from "./AddSavedMessageModal";
import DeleteSavedMessageTemplate from "./parts/DeleteSavedMessageModal";
import { getSavedDRMailTemplates, updateDRMailTemplate, addDRMailTemplate, deleteDRMailTemplate } from "../../../../../actionCreators/SavedRequestActionCreator";
import { fetchSavedMessageDRMailTemplateSettings, updateAdminSettings } from "../../../../../actionCreators/adminSettingsActionCreators";
import { SavedMessageEmailTemplate } from "../../../../../helper/Constants";
import { CustomModal } from "../../../../common/ui/Modals/CustomModal";

export const RequestEmailTemplate: React.FC = () => {
    const adminSettingsStore = useSelector((state: ApplicationState) => state.adminSettingsState);
    const savedMessageDRTemplateSettings = adminSettingsStore.savedMessageDRTemplateSettings;
    const [savedMessageDRSettingSettingState, setSavedMessageSettingState] = useState<ISavedMessageDRTemplateSettings>(initialSavedMessageDRSettings);
    const [isUpdateCompanySettings, setIsUpdateCompanySettings] = useState(false);
    const { data, isLoading } = useSelector((state: ApplicationState) => state.SavedRequestTemplateState);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [selectedMessage, setSelectedMessage] = useState<ISavedMessageTemplateModel>(initialSavedMessageTemplate);
    const [selectedTemplateId, setSelectedTemplateId] = useState<number>();
    const dispatch = useDispatch();
    const [filteredData, setFilteredData] = useState<ISavedMessageTemplateModel[]>([]);
    const [defaultMessage ,setDefaultMessage] = useState<ISavedMessageTemplateModel>(initialSavedMessageTemplate);
    const [isTemplateEdited , setTemplateEdited] = useState(false);
    const [showDefaultMethodWarningModal, setDefaultMethodWarningModal] = useState(false);

    useEffect(() => {
        dispatch(fetchSavedMessageDRMailTemplateSettings());
        dispatch(getSavedDRMailTemplates(TemplateSource.AdminCreated));
    }, []);

    useEffect(() => {
        setSelectedMessage(defaultMessage);
    },[defaultMessage]);
    
    useEffect(() => {
        const filtered = data.filter(
            item => item.templateSource === TemplateSource.AdminCreated || item.isSystemDefault
        );
        const defMessage =
            data.find(
                x => x.savedMessageTemplateId === savedMessageDRSettingSettingState.savedMessageDRMailTemplateId
            ) || initialSavedMessageTemplate;
        const topItemIndex = filtered.findIndex(
            item => item.savedMessageTemplateId === defMessage.savedMessageTemplateId
        );
        if (topItemIndex > 0) {
            const [topItem] = filtered.splice(topItemIndex, 1);
            filtered.unshift(topItem);
        }
        setFilteredData(filtered);
    }, [data]);

    useEffect(() => {
        if (selectedMessage.templateName == "" && filteredData.length > 0) {
            setSelectedMessage(filteredData[0]);
        }
        if (isTemplateEdited) {
            const editedData =
                filteredData.find(x => x.savedMessageTemplateId === selectedMessage.savedMessageTemplateId) ||
                initialSavedMessageTemplate;
            setTemplateEdited(false);
            setSelectedMessage(editedData);
        } else {
            const filtered = filteredData.find(
                x => x.savedMessageTemplateId === savedMessageDRSettingSettingState.savedMessageDRMailTemplateId
            );
            setDefaultMessage(filtered || initialSavedMessageTemplate);
        }
    }, [filteredData]);

    useEffect(() => {
        if(savedMessageDRTemplateSettings){
            setSavedMessageSettingState({...savedMessageDRSettingSettingState,
                savedMessageDRMailTemplateId: savedMessageDRTemplateSettings.savedMessageDRMailTemplateId,
                isUserAllowedToAddDRMailTemplate: savedMessageDRTemplateSettings.isUserAllowedToAddDRMailTemplate,
                isUserAllowedToChangeDRMailTemplate: savedMessageDRTemplateSettings.isUserAllowedToChangeDRMailTemplate
        });
    }
    }, [savedMessageDRTemplateSettings]);
    const isBaseTemplateEditable = filteredData.find(x => x.templateName === "BaseTemplate")?.savedMessageTemplateId!==defaultMessage.savedMessageTemplateId;

    const handleToggleSwitchChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        const value = e.target.checked;
        if(name === SavedMessageEmailTemplate.ALLOW_TO_SET_DEFAULT_DR_Template && !value){
            setDefaultMethodWarningModal(true);
            return;
        }
        setSavedMessageSettingState(prevPermissions => ({
            ...prevPermissions,
            [name]: value,
        }));
        setIsUpdateCompanySettings(true);
    };

    const updateSavedMessageSettingState = () => {
        setSavedMessageSettingState(prevPermissions => ({
            ...prevPermissions,
            isUserAllowedToChangeDRMailTemplate: false,
            isUserAllowedToAddDRMailTemplate: false,
        }));
        setDefaultMethodWarningModal(false);
        setIsUpdateCompanySettings(true);
    };

    useEffect(() => {
        if (isUpdateCompanySettings) {
            const updateSettingsPayload = {
                savedMessageDRMailTemplateId: savedMessageDRSettingSettingState.savedMessageDRMailTemplateId,
                isUserAllowedToAddDRMailTemplate: savedMessageDRSettingSettingState.isUserAllowedToAddDRMailTemplate,
                isUserAllowedToChangeDRMailTemplate: savedMessageDRSettingSettingState.isUserAllowedToChangeDRMailTemplate
            };
            dispatch(
                updateAdminSettings(
                    CompanySettingsName.SavedMessageDRTemplateSettings,
                    {
                        ...updateSettingsPayload,
                    },
                    () => {
                        dispatch(fetchSavedMessageDRMailTemplateSettings());
                        dispatch(getSavedDRMailTemplates(TemplateSource.AdminCreated));
                        setIsUpdateCompanySettings(false);
                    }
                )
            );
        }
    }, [isUpdateCompanySettings]);

     const OnSuccessfullTemplateDeleteion =()=>{
        dispatch(getSavedDRMailTemplates(TemplateSource.AdminCreated));
       };

     const OnSuccessfullTemplateAction = (data?:number,isDefault?:boolean,defaultTemplateReverted?: boolean) => {
        if(data && isDefault){
            setSavedMessageSettingState({
                ...savedMessageDRSettingSettingState,
                savedMessageDRMailTemplateId: data,
            });
            setIsUpdateCompanySettings(true);
        }
        else if( data && defaultTemplateReverted){
            const baseTemplateId = filteredData.find(x => x.templateName === "BaseTemplate")?.savedMessageTemplateId;
            const updateId = defaultTemplateReverted === true ? baseTemplateId : data;
            setSavedMessageSettingState({
                ...savedMessageDRSettingSettingState,
                savedMessageDRMailTemplateId: updateId || data,
            });
            setIsUpdateCompanySettings(true);
        }
        setTemplateEdited(true);
        dispatch(getSavedDRMailTemplates(TemplateSource.AdminCreated));
        setShowEditModal(false);
    };

    const OnSuccessfullTemplateCreateAction = (data?:number,isDefault?:boolean) => {
  
        if(data && isDefault){
         setSavedMessageSettingState({
             ...savedMessageDRSettingSettingState,
             savedMessageDRMailTemplateId: data,
         });
         setIsUpdateCompanySettings(true);
        }
        dispatch(getSavedDRMailTemplates(TemplateSource.AdminCreated));
        setShowAddModal(false);
     };

    const handleDataSubmit = (templateData: ISavedMessageTemplateModel, isUpdate: boolean ,isDefault?:boolean ,defaultTemplateReverted?: boolean) => {
        const CompleteTemplateData :ISavedMessageTemplateModel={
            ...templateData,
            templateType: TemplateType.DocumentRequest,
            templateSource: TemplateSource.AdminCreated,
        };
        if (isUpdate) {
            dispatch(updateDRMailTemplate(templateData,OnSuccessfullTemplateAction,isDefault,defaultTemplateReverted));
        } else {
            dispatch(addDRMailTemplate(CompleteTemplateData,OnSuccessfullTemplateCreateAction ,isDefault));
        }
    };

    const handleTemplateDeleteClick = (id: number) => {
        setShowDeleteModal(true);
        setSelectedTemplateId(id);
    };

    const handleOnDeleteSubmit = () => {
        selectedTemplateId && dispatch(deleteDRMailTemplate(selectedTemplateId,OnSuccessfullTemplateDeleteion));
        setShowDeleteModal(false);
        setSelectedMessage(initialSavedMessageTemplate);
      
    };
    
    const onSelectMessage = (index: number, data: ISavedMessageTemplateModel) => {
        setSelectedMessage(data);
    };

    return (
        <LoadingOverlay>
            <div className="savedMessage sm-admin">
                <header>
                    <span>{SavedMessageEmailTemplate.REQUEST_EMAILTEMPLATE_HELPER_TEXT}</span>
                </header>
                <h3 className="settings-title">{SavedMessageEmailTemplate.REQUEST_EMAILTEMPLATE_HEADER}</h3>
                <section className="margin-top-10-px">
                    <div className="switch-wrapper">
                        <CustomToggleSwitch
                            name={SavedMessageEmailTemplate.ALLOW_TO_ADD_NEW_DR_Template}
                            handleChange={handleToggleSwitchChange}
                            switched={savedMessageDRSettingSettingState.isUserAllowedToAddDRMailTemplate}
                            label={SavedMessageEmailTemplate.ALLOW_TO_ADD_NEW_Template_TEXT}
                        />
                    </div>

                    <div className="switch-wrapper">
                        <CustomToggleSwitch
                            name={SavedMessageEmailTemplate.ALLOW_TO_SET_DEFAULT_DR_Template}
                            handleChange={handleToggleSwitchChange}
                            switched={savedMessageDRSettingSettingState.isUserAllowedToChangeDRMailTemplate}
                            label={SavedMessageEmailTemplate.ALLOW_TO_CHANGE_DEFAULT_Template_TEXT}
                        />
                    </div>
                </section>

                <div className="saved-message-container">
                    <MessageListBox
                        SavedMessageTemplatedata={filteredData}
                        selectedMessage={selectedMessage}
                        onSelectMessage={onSelectMessage}
                        onAddMessageTemplate={() => setShowAddModal(true)}
                        defaultTemplate={defaultMessage}
                    />
                    <MessageBox
                        selectedMessage={selectedMessage || defaultMessage}
                        onDelete={handleTemplateDeleteClick}
                        onEdit={() => {
                            setShowEditModal(true);
                        }}
                        SavedMessageTemplatedata={filteredData}
                        defaultTemplate={defaultMessage}
                        templateSouceValue={TemplateSource.AdminCreated}
                        isBaseTemplateEditable={isBaseTemplateEditable}
                    />
                </div>
                {showAddModal && (
                    <AddSavedeMessagesModal
                        templateSourceValue={TemplateSource.AdminCreated}
                        templateTypeValue={TemplateType.DocumentRequest}
                        show={showAddModal}
                        onHide={() => setShowAddModal(false)}
                        isEdit={false}
                        handleSubmit={handleDataSubmit}
                        defaultTemplate={defaultMessage}
                    />
                )}

                {showEditModal && (
                    <AddSavedeMessagesModal
                        templateSourceValue={TemplateSource.AdminCreated}
                        templateTypeValue={TemplateType.DocumentRequest}
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        isEdit={true}
                        handleSubmit={handleDataSubmit}
                        data={selectedMessage}
                        defaultTemplate={defaultMessage}
                    />
                )}
                {showDeleteModal && selectedMessage && (
                    <DeleteSavedMessageTemplate
                        show={showDeleteModal}
                        onClose={() => {
                            setShowDeleteModal(false);
                        }}
                        handleSubmit={handleOnDeleteSubmit}
                    />
                )}

                {showDefaultMethodWarningModal && (
                    <CustomModal
                        show={showDefaultMethodWarningModal}
                        cancelButtonName="No"
                        confirmButtonName="Yes"
                        title={"Confirm Selection"}
                        isConfirmModal={true}
                        className="confirmation-modal"
                        onSubmit={updateSavedMessageSettingState}
                        onHide={() => setDefaultMethodWarningModal(false)}
                    >
                        {SavedMessageEmailTemplate.CHANGE_DEFAULT_TEMPLATE_METHOD_WARNING_TEXT}
                    </CustomModal>
                )}
                <Loader loading={isLoading} />
            </div>
        </LoadingOverlay>
    );
};
