export enum actionTypes {
    RECEIVED_DOCUMENT_REQUEST_LIST = "received_document_request_list",

    RECEIVED_DOCUMENT_REQUEST_DETAIL = "received_document_request_detail",
    COMPLETED_RETENTION_PERIODS = "completed_retention_periods",

    RECEIVED_DOCUMENT_REQUEST_FILE_LIST = "received_document_request_file_list",
    RESET_REQUEST_FILE_VIEW_DATA= "reset_message_file_view_data",

    START_LISTING_LOADER = "start_listing_loader",
    STOP_LISTING_LOADER = "stop_listing_loader",

    START_DETAILED_LOADER = "start_detailed_loader",
    STOP_DETAILED_LOADER = "stop_detailed_loader",

    START_COMPOSE_LOADER = "start_compose_loader",
    STOP_COMPOSE_LOADER = "stop_compose_loader",

    START_FILE_LIST_LOADER = "start_file_list_loader",
    STOP_FILE_LIST_LOADER = "stop_file_list_loader",

}
