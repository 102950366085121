import React, { useEffect, useState } from "react";
import MessageListBox from "../../pages/adminSettings/tabs/Saved Message/parts/SavedMessageTemplateList";
import { AddSavedeMessagesModal } from "../../pages/adminSettings/tabs/Saved Message/AddSavedMessageModal";
import DeleteSavedMessageTemplate from "../../pages/adminSettings/tabs/Saved Message/parts/DeleteSavedMessageModal";
import MessageBox from "../../pages/adminSettings/tabs/Saved Message/parts/SavedMessageDetailBox";
import {
    initialSavedMessageDRSettings,
    initialSavedMessageSettings,
    initialSavedMessageTemplate,
    ISavedMessageDRTemplateSettings,
    ISavedMessageTemplateModel,
    ISavedMessageTemplateSettings,
} from "../../../core/viewModels/settings/SavedMessageTemplate";
import { TemplateSource, TemplateType } from "../../../core/common/enums";
import { useDispatch, useSelector } from "react-redux";
import {
    addDRMailTemplate,
    deleteDRMailTemplate,
    getSavedDRMailTemplates,
    updateDRMailTemplate,
} from "../../../actionCreators/SavedRequestActionCreator";
import {
    addMessageMailTemplate,
    deleteMessageMailTemplate,
    getSavedMessageTemplates,
    updateMessageMailTemplate,
} from "../../../actionCreators/SavedMessageActionCreator";
import { ApplicationState } from "../../../store";
import { updateUserSettings } from "../../../actionCreators/userSettingsActionCreators";
interface IEmailTemplateManagerProps {
    fetchedData: ISavedMessageTemplateModel[];
    templateTypeValue: TemplateType;
}

interface IUserTemplateSetting{
 allowedToAdd:boolean;
 allowedToChangeDefault : boolean;
}

const EmailTemplateManager = ({ fetchedData, templateTypeValue }: IEmailTemplateManagerProps) => {
    const [data,setData] =useState<ISavedMessageTemplateModel[]>([]);
    const adminSettingsStore = useSelector((state: ApplicationState) => state.adminSettingsState);
    const { userSettings } = useSelector((state: ApplicationState) => state.userSettingsState);
    const savedMessageDRTemplateSettings = adminSettingsStore.savedMessageDRTemplateSettings;
    const savedMessageTemplateSettings = adminSettingsStore.savedMessageTemplateSettings;

    const[settings ,setUpSettings] = useState<IUserTemplateSetting>();
    const [savedMessageSettingSettingState, setSavedMessageSettingState] =useState<ISavedMessageTemplateSettings>(initialSavedMessageSettings);
    const [savedMessageDRSettingState, setSavedMessageDRSettingState] = useState<ISavedMessageDRTemplateSettings>(initialSavedMessageDRSettings);
    const [UserSavedMessageSettingSettingState, setUserSavedMessageSettingState] =useState<number>();
    const [UserSavedMessageDRSettingState, setUserSavedMessageDRSettingState] = useState<number>();
    //Settings Default templates
    const userDefaultMessageTemplateId = UserSavedMessageSettingSettingState;
    const userDefaultDRMssaeTemplateId = UserSavedMessageDRSettingState;
    const initialDefaultMessage =
        templateTypeValue === TemplateType.DocumentRequest
            ?  data.find(x => x.savedMessageTemplateId === (savedMessageDRSettingState.isUserAllowedToChangeDRMailTemplate && userDefaultDRMssaeTemplateId && userDefaultDRMssaeTemplateId>0 ? userDefaultDRMssaeTemplateId : savedMessageDRSettingState.savedMessageDRMailTemplateId)) || initialSavedMessageTemplate
            : data.find(x => x.savedMessageTemplateId === (savedMessageTemplateSettings?.isUserAllowedToChangeSecureMailTemplate && userDefaultMessageTemplateId && userDefaultMessageTemplateId>0 ? userDefaultMessageTemplateId : savedMessageSettingSettingState.savedMessageMailTemplateId)) ||initialSavedMessageTemplate;
    const[defaultMessage , setDefaultMessage] = useState<ISavedMessageTemplateModel>(initialDefaultMessage);     
    const isBaseTemplateEditable = data.find(x => x.templateName === "BaseTemplate")?.savedMessageTemplateId!==defaultMessage.savedMessageTemplateId;
    const [selectedMessage, setSelectedMessage] = useState<ISavedMessageTemplateModel>(defaultMessage);
    const [showAddModal, setShowAddModal] = useState<boolean>(false);
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
    const [selectedTemplateId, setSelectedTemplateId] = useState<number>();
    const dispatch = useDispatch();
    const onSelectMessage = (index: number, data: ISavedMessageTemplateModel) => {
        setSelectedMessage(data);
    };
    const isAdminTemplate = selectedMessage.templateSource === TemplateSource.AdminCreated ? true : false;
    const [isTemplateEdited , setTemplateEdited] = useState(false);
    useEffect(() => {
        setSelectedMessage(defaultMessage);
    }, [defaultMessage]);
    
    useEffect(() => {
        const filtered =[...fetchedData];
        const defaultCompanyMessage =
            templateTypeValue==TemplateType.SecureMail
                ?filtered.find(x => x.savedMessageTemplateId === savedMessageSettingSettingState.savedMessageMailTemplateId)  ||initialSavedMessageTemplate
                :filtered.find(x => x.savedMessageTemplateId === savedMessageDRSettingState.savedMessageDRMailTemplateId)  ||initialSavedMessageTemplate;
        const defaultUserMessage = 
            templateTypeValue==TemplateType.SecureMail
                ?filtered.find(x => x.savedMessageTemplateId === userDefaultMessageTemplateId)  ||initialSavedMessageTemplate
                :filtered.find(x => x.savedMessageTemplateId === userDefaultDRMssaeTemplateId)  ||initialSavedMessageTemplate;
        const defMessage=  settings && settings.allowedToChangeDefault  &&  defaultUserMessage!==null && defaultUserMessage.savedMessageTemplateId >0 ? defaultUserMessage : defaultCompanyMessage;
        const topItemIndex = filtered.findIndex(item => item.savedMessageTemplateId === defMessage.savedMessageTemplateId);
        if (topItemIndex > 0) {
            const [topItem] = filtered.splice(topItemIndex, 1);
            filtered.unshift(topItem);
        }
        setData(filtered);
        
    }, [userSettings?.savedMessageDRTemplateSettings,userSettings?.savedMessageTemplateSettings,fetchedData,settings]);

    useEffect(() => {
        setUserSavedMessageSettingState(userSettings?.savedMessageTemplateSettings?.defaultSavedMessageTemplateId);
        setUserSavedMessageDRSettingState(userSettings?.savedMessageDRTemplateSettings?.defaultSavedMessageDRTemplateId);
   
    },[userSettings ,userSettings?.savedMessageDRTemplateSettings,userSettings?.savedMessageTemplateSettings]);


    useEffect(() => {
        if (templateTypeValue === TemplateType.SecureMail) {
            setUpSettings({...settings,
                allowedToAdd:savedMessageSettingSettingState.isUserAllowedToAddSecureMailTemplate,
                allowedToChangeDefault:savedMessageSettingSettingState.isUserAllowedToChangeSecureMailTemplate});
        } else if (templateTypeValue === TemplateType.DocumentRequest) {
            setUpSettings({...settings,
                allowedToAdd:savedMessageDRSettingState.isUserAllowedToAddDRMailTemplate,
                allowedToChangeDefault:savedMessageDRSettingState.isUserAllowedToChangeDRMailTemplate});
        }
    }, [savedMessageDRSettingState, savedMessageSettingSettingState, templateTypeValue]);
    //FOR COMPANY DEFAULT Settings
    useEffect(() => {
        if (savedMessageTemplateSettings) {
            setSavedMessageSettingState({
                ...savedMessageSettingSettingState,
                savedMessageMailTemplateId: savedMessageTemplateSettings.savedMessageMailTemplateId,
                isUserAllowedToAddSecureMailTemplate: savedMessageTemplateSettings.isUserAllowedToAddSecureMailTemplate,
                isUserAllowedToChangeSecureMailTemplate:
                    savedMessageTemplateSettings.isUserAllowedToChangeSecureMailTemplate,
            });
        }
    }, [savedMessageTemplateSettings]);

    useEffect(() => {
        if(savedMessageDRTemplateSettings){
            setSavedMessageDRSettingState({...savedMessageDRSettingState,
                savedMessageDRMailTemplateId: savedMessageDRTemplateSettings.savedMessageDRMailTemplateId,
                isUserAllowedToAddDRMailTemplate: savedMessageDRTemplateSettings.isUserAllowedToAddDRMailTemplate,
                isUserAllowedToChangeDRMailTemplate: savedMessageDRTemplateSettings.isUserAllowedToChangeDRMailTemplate
        });
    }
    }, [savedMessageDRTemplateSettings]);

    useEffect(() => {
        if (isTemplateEdited) {
            const editedData =
                data.find(x => x.savedMessageTemplateId === selectedMessage.savedMessageTemplateId) ||
                initialSavedMessageTemplate;
            setTemplateEdited(false);
            setSelectedMessage(editedData);
        } else {
            setDefaultMessage(initialDefaultMessage);
        }
    }, [data]);

    const handleTemplateDeleteClick = (id: number) => {
        setShowDeleteModal(true);
        setSelectedTemplateId(id);
    };
    
    const UpdateUserMailTemplateSettings = (dataId: number) => {
        if (userSettings) {
            const updateUserSettingsPayload = { ...userSettings };
            templateTypeValue == TemplateType.DocumentRequest
            ? updateUserSettingsPayload.savedMessageDRTemplateSettings ={defaultSavedMessageDRTemplateId : dataId} 
            : updateUserSettingsPayload.savedMessageTemplateSettings = {defaultSavedMessageTemplateId: dataId};
            dispatch(
                updateUserSettings(updateUserSettingsPayload, () => {
                    templateTypeValue == TemplateType.DocumentRequest
                    ? dispatch(getSavedDRMailTemplates(TemplateSource.UserCreated))
                    : dispatch(getSavedMessageTemplates(TemplateSource.UserCreated));
                })
            );
        }
    };

    const OnSuccessfullTemplateCreateAction = (data?: number, isDefault?: boolean) => {
        if (data && isDefault) {
  
            UpdateUserMailTemplateSettings(data);
        }
        templateTypeValue == TemplateType.DocumentRequest
        ? dispatch(getSavedDRMailTemplates(TemplateSource.UserCreated))
        : dispatch(getSavedMessageTemplates(TemplateSource.UserCreated));
        setShowAddModal(false);
    };

    const onMailTemplateUpdateSuccess = (dataId?: number, isDefault?: boolean, defaultTemplateReverted?: boolean) => {
        if (dataId && isDefault) {
          UpdateUserMailTemplateSettings(dataId);
        } 
        else if (data && defaultTemplateReverted) {
            const baseTemplateId = data.find(x => x.templateName === "BaseTemplate")?.savedMessageTemplateId;
            const updateId = defaultTemplateReverted === true ? baseTemplateId : dataId ;    
            updateId && UpdateUserMailTemplateSettings(updateId);
        }
        setTemplateEdited(true);
        templateTypeValue == TemplateType.DocumentRequest
        ? dispatch(getSavedDRMailTemplates(TemplateSource.UserCreated))
        : dispatch(getSavedMessageTemplates(TemplateSource.UserCreated));
        setShowEditModal(false);
    };

    const OnSuccessfullTemplateAction = () => {
        templateTypeValue == TemplateType.DocumentRequest
            ? dispatch(getSavedDRMailTemplates(TemplateSource.UserCreated))
            : dispatch(getSavedMessageTemplates(TemplateSource.UserCreated));
    };

    const handleDataSubmit = (templateData: ISavedMessageTemplateModel, isUpdate: boolean , isDefault?: boolean, defaultTemplateReverted?: boolean) => {
        const CompleteTemplateData: ISavedMessageTemplateModel = {
            ...templateData,
            templateType: templateTypeValue,
            templateSource: TemplateSource.UserCreated,
        };
        if (isUpdate) {
            templateTypeValue == TemplateType.DocumentRequest
                ? dispatch(updateDRMailTemplate(templateData, onMailTemplateUpdateSuccess,isDefault, defaultTemplateReverted))
                : dispatch(updateMessageMailTemplate(templateData, onMailTemplateUpdateSuccess, isDefault, defaultTemplateReverted));
        } else {
            templateTypeValue == TemplateType.DocumentRequest
                ? dispatch(addDRMailTemplate(CompleteTemplateData, OnSuccessfullTemplateCreateAction, isDefault))
                : dispatch(addMessageMailTemplate(CompleteTemplateData, OnSuccessfullTemplateCreateAction, isDefault));
        }
    };

    const handleOnDeleteSubmit = () => {
        selectedTemplateId &&
            (templateTypeValue == TemplateType.DocumentRequest
                ? dispatch(deleteDRMailTemplate(selectedTemplateId, OnSuccessfullTemplateAction))
                : dispatch(deleteMessageMailTemplate(selectedTemplateId, OnSuccessfullTemplateAction)));
        setShowDeleteModal(false);
        setSelectedMessage(initialSavedMessageTemplate);
    };

    return (
        <div className="saved-message-container">
            <div className="user-settings-sm">
                <MessageListBox
                    SavedMessageTemplatedata={data}
                    selectedMessage={selectedMessage}
                    onSelectMessage={onSelectMessage}
                    onAddMessageTemplate={() => setShowAddModal(true)}
                    defaultTemplate={defaultMessage}
                    settings={settings}
                />
                <MessageBox
                    selectedMessage={selectedMessage || defaultMessage}
                    onDelete={handleTemplateDeleteClick}
                    onEdit={() => setShowEditModal(true)}
                    SavedMessageTemplatedata={data}
                    defaultTemplate={defaultMessage}
                    settings={settings}
                    templateSouceValue={TemplateSource.UserCreated}
                    isBaseTemplateEditable ={isBaseTemplateEditable || false}
                />
                {showAddModal && (
                    <AddSavedeMessagesModal
                        templateSourceValue={TemplateSource.UserCreated}
                        templateTypeValue={templateTypeValue}
                        show={showAddModal}
                        onHide={() => setShowAddModal(false)}
                        isEdit={false}
                        handleSubmit={handleDataSubmit}
                        settings = {settings}
                        defaultTemplate={defaultMessage}
                    />
                )}
                {showEditModal && (
                    <AddSavedeMessagesModal
                        isAdminTemplate={isAdminTemplate}
                        templateSourceValue={TemplateSource.UserCreated}
                        templateTypeValue={templateTypeValue}
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        isEdit={true}
                        handleSubmit={handleDataSubmit}
                        data={selectedMessage}
                        settings = {settings}
                        defaultTemplate={defaultMessage}
                    />
                )}
                {showDeleteModal && selectedMessage && (
                    <DeleteSavedMessageTemplate
                        show={showDeleteModal}
                        onClose={() => setShowDeleteModal(false)}
                        handleSubmit={handleOnDeleteSubmit}
                    />
                )}
            </div>
        </div>
    );
};

export default EmailTemplateManager;
