import React, { FC, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserSettings, updateUserSettings } from "../../../actionCreators/userSettingsActionCreators";
import { IUserSettingsModel } from "../../../core/viewModels/user/UserViewModel";
import { userSettingsConstant } from "../../../helper/Constants";
import { ApplicationState } from "../../../store";
import { actionTypes } from "../../../types/userSettingsTypes";
import UserSettingLayout from "./UserSettingsLayout";
import "./styles.scss";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { logger } from "../../../oidcClient/authProvider";
import { IProfileDropdownModalProps } from "../../../core/viewModels/layout/HeaderModel";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";
import { CustomModal } from "../../common/ui/Modals/CustomModal";

const MySettings: FC<IProfileDropdownModalProps> = ({ show, onHide }) => {
    const dispatch = useDispatch();
    const { userSettings } = useSelector((appState: ApplicationState) => appState.userSettingsState);
    const [userSettingsCopy, setUserSettingsCopy] = useState<IUserSettingsModel>();

    useEffect(() => {
        if (show) {
            dispatch(
                fetchUserSettings(data => {
                    setUserSettingsCopy(data);
                })
            );
            logger.trackPageView(appInsightsData.MySettings.AuthQuestions.PageTitle);
        }
    }, [show]);

    const handleApplySettings = () => {
        if (userSettings) {
            dispatch(updateUserSettings(userSettings));
            onHide();
        }
    };

    const handleCancel = () => {
        dispatch({
            type: actionTypes.UPDATE_USER_SETTINGS,
            payload: userSettingsCopy,
        });
        onHide();
    };

    return (
        <CustomModal
            show={show}
            className="user-settings-modal"
            cancelButtonAutomationId={AutomationIdConstants.deleteBtnModal.CancelBtn}
            conformButtonAutomationId={AutomationIdConstants.deleteBtnModal.ConfirmBtn}
            title={userSettingsConstant.TITLE}
            cancelButtonName="Cancel"
            confirmButtonName="Apply"
            isConfirmModal={true}
            onSubmit={handleApplySettings}
            onHide={handleCancel}>
            <UserSettingLayout />
        </CustomModal>
    );
};

export default MySettings;
