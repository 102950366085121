import React, { FC } from "react";
import { Accordion } from "react-bootstrap";
import copy from "copy-to-clipboard";
import { SavedMessageEmailTemplate, SavedMessageVariableList } from "../../../../../../helper/Constants";
import { TemplateType } from "../../../../../../core/common/enums";

export interface IVariableListData {
    variable: string;
    helpText: string;
}
export interface IVariableList {
    isSystemDefaultType?: boolean;
    isClose: boolean;
    setIsClose: React.Dispatch<React.SetStateAction<boolean>>;
    className?: string;
    templateType?: TemplateType;
    isVariableListEnabled?: boolean;
}

const VariablesList: FC<IVariableList> = ({isClose, setIsClose, className ,templateType ,isVariableListEnabled}) => {
    const getVariableList = (): IVariableListData[] => {
        const tempVariableListModel: IVariableListData[] = [];
        tempVariableListModel.push(
            { variable: "COMPANYNAME", helpText: SavedMessageVariableList.CompanyName },
            { variable: "SENDERNAME", helpText: SavedMessageVariableList.SenderName },
        );
        templateType ==TemplateType.SecureMail? tempVariableListModel.push({ variable: "EXPIRYDATE", helpText: SavedMessageVariableList.ExpiryDate }):null;
        return tempVariableListModel;
    };

    const onToggle = () => {
       !isVariableListEnabled && setIsClose(!isClose);
    };

    const copyToClipBoard = (variableName: string) => {
        copy(variableName);
    };

    return (
        <Accordion className={`variable-list-accordion ${className || ""}`} flush >
            <Accordion.Item eventKey="0">
                <Accordion.Header onClick={onToggle} >
                    <a
                        title={isVariableListEnabled ? SavedMessageEmailTemplate.ACTION_DISABLED : ""}
                        onClick={e => e.preventDefault()}
                        className={`variable-list-header ${isVariableListEnabled ? "input-disabled-list" : ""}`}
                    >
                        {`${!isClose ? "Hide" : "Show"} Variable List`}
                    </a>
                </Accordion.Header>
                {!isVariableListEnabled && (<Accordion.Body>
                    <ul>
                        {getVariableList().map((item, index) => {
                            const value = `<${item.variable}>`;
                            return (
                                <div key={value + index} className="variable-item-wrapper">
                                    <div className="main-text" title={SavedMessageEmailTemplate.CLICK_TO_COPY_TEXT} onClick={()=>{copyToClipBoard(value);}}>
                                        <li className="copy">{value}</li>
                                    </div>
                                    <div className="help-text">{item.helpText}</div>
                                </div>
                            );
                        })}
                    </ul>
                </Accordion.Body>)}
            </Accordion.Item>
        </Accordion>
    );
};

export default VariablesList;
