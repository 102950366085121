import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { SessionTimeoutHelper } from "@sssuite-component-ui/session-timeout";
import { ApplicationState } from "../store";
import { signoutRedirect } from "./userService";
import { actionCreators as PostAuthStore } from "../store/PostAuth/PostAuthStore";

export interface WithLogoutProps{
    onLogout: () => void;
}

export const WithLogout = (Component:any) => {
    return function Wrapper(props:any) {
        const { device_id } = useSelector((state: ApplicationState) => state?.userAuth?.user?.profile ?? "");
        const dispatch = useDispatch();
        return (<Component
            {...props}
        onLogout={() => {
                SessionTimeoutHelper.logoutHelper(device_id, signoutRedirect);
                dispatch(PostAuthStore.loggedOut());
            }}
        />);
    };
};