import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchDropOffSettings, updateAdminSettings } from "../../../../../actionCreators/adminSettingsActionCreators";
import { CompanySettingsName } from "../../../../../core/common/enums";
import {
    IDropOffSettings,
    initialDropOffSettings,
} from "../../../../../core/viewModels/company/settings/DropOffSettings";
import { DropOffConfigConstants } from "../../../../../helper/Constants";
import { ApplicationState } from "../../../../../store";
import CustomToggleSwitch from "../../../../common/ui/switch/CustomToggleSwitch";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { AppNotifier } from "../../../../../helper/AppNotifier";
import { DropOffConfigAccrodian } from "./parts/DropOffConfigAccrodian/DropOffConfigAccrodian";
import { appInsightsData } from "../../../../../helper/AppInsightsData";
import { logger } from "../../../../../oidcClient/authProvider";
import Checkbox, { CheckBoxSize } from "../../../../common/ui/Checkbox";
import { getRestrictedFileTypes } from "../../../../../actionCreators/restrictedFileTypeActionCreators";
import { ToasterMessages } from "../../../../../helper/ToasterMessages";

export interface IgroupedOptionsInterface {
    label: string;
    options: ISelectOption[];
}

interface ISelectOption {
    label: string;
    value: string;
    color: string;
}

export const DropOffConfig: React.FC = () => {
    const [fileTypesGroupedOptions, setFileTypesGroupedOptions] = useState<IgroupedOptionsInterface[]>([]);
    const [restrictedFileTypes, setRestrictedFileTypes] = useState<any[]>([]);
    const dispatch = useDispatch();
    const [dropOffState, setDropOffState] = useState<IDropOffSettings>(initialDropOffSettings);
    const { dropOffSettings, isLoading } = useSelector((appState: ApplicationState) => appState.adminSettingsState);
    const supportedFileFormats = useSelector((appState: ApplicationState) => appState.restrictedFileTypeState);

    useEffect(() => {
        dispatch(fetchDropOffSettings());
        dispatch(getRestrictedFileTypes());
        // Track Page view event
        logger.trackPageView(appInsightsData.AdminSettings.DropOff.DropOffConfig.PageTitle);
    }, []);

    useEffect(() => {
        const fileData = { ...supportedFileFormats };
        const groupedOptions: IgroupedOptionsInterface[] = [];
        Object.entries(fileData).map(([key, value]) => {
            const files: ISelectOption[] = [];
            value.fileExtension.map(file => {
                files.push(createOption(file, file, value.color));
            });
            groupedOptions.push({
                label: key,
                options: files,
            });
        });
        setFileTypesGroupedOptions(groupedOptions);
    }, [supportedFileFormats]);

    useEffect(() => {
        if (dropOffSettings) {
            setDropOffState({ ...dropOffState, ...dropOffSettings });

            const fileTypes = dropOffSettings.restrictedFileTypes.map((item: string) => {
                return createOption(item, item, "");
            });
            setRestrictedFileTypes(fileTypes);
        }
    }, [dropOffSettings]);

    const createOption = (value: string, label: string, color: string) => ({
        label,
        value,
        color,
    });

    const handleToggleSwitch = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
        const value = e.target.checked;
        setDropOffState({ ...dropOffState, [name]: value });
        let updateDropOffSettingsPayload: IDropOffSettings = {
            ...dropOffState,
            [name]: value,
        };
        if (name === DropOffConfigConstants.IS_PERSONAL_DROP_OFF_LINK_TO_DISPLAY && !value) {
            updateDropOffSettingsPayload = {
                ...dropOffState,
                [name]: value,
                [DropOffConfigConstants.IS_DROP_OFF_LINK_TO_DISPLAY_IN_MAIL]: false,
            };
        }
        dispatch(
            updateAdminSettings(CompanySettingsName.DropOffSettings, updateDropOffSettingsPayload, () =>
                AppNotifier.Success(ToasterMessages.SUCCESS.APPLY_ADMIN_CHANGES)
            )
        );
    };

    const handleFileSubmission = () => {
        const fileTypes = restrictedFileTypes.map(item => item.value);
        dispatch(
            updateAdminSettings(
                CompanySettingsName.DropOffSettings,
                { ...dropOffState, restrictedFileTypes: fileTypes },
                () => AppNotifier.Success(ToasterMessages.SUCCESS.SAVE_RESTRICT_FILE_TYPE)
            )
        );
    };

    const handleFileTypeChange = (value: any, data: any) => {
        switch (data.action) {
            case "select-option":
            case "create-option":
                setRestrictedFileTypes([...restrictedFileTypes, data.option]);
                break;
            case "remove-value":
            case "pop-value":
                const index = restrictedFileTypes.findIndex(item => item.value === data.removedValue.value);
                restrictedFileTypes.splice(index, 1);
                setRestrictedFileTypes([...restrictedFileTypes]);
                break;
            case "clear":
                setRestrictedFileTypes([]);
                break;
        }
    };

    return (
        <LoadingOverlay>
            <>
                <header>
                    <span>{DropOffConfigConstants.DROP_OFF_HELPER_TEXT}</span>
                </header>

                <section className="margin-top-10-px">
                    <div className="settings-label-toggle-wrapper-large">
                        <CustomToggleSwitch
                            label={DropOffConfigConstants.DROP_OFF_ALLOW_ADDRESS_BOOL_LOOKUP}
                            name={DropOffConfigConstants.IS_EMAIL_ADDRESS_LOOKUP_ALLOWED}
                            handleChange={handleToggleSwitch}
                            switched={dropOffState.isEmailAddressLookupAllowed}
                        />
                    </div>

                    <div className="settings-label-toggle-wrapper-large">
                        <CustomToggleSwitch
                            label={DropOffConfigConstants.DROP_OFF_ALLOW_PERSONAL_DROP_LINK}
                            name={DropOffConfigConstants.IS_PERSONAL_DROP_OFF_LINK_TO_DISPLAY}
                            handleChange={handleToggleSwitch}
                            switched={dropOffState.isPersonalDropOffLinkToDisplay}
                        />
                    </div>

                    {dropOffState.isPersonalDropOffLinkToDisplay && (
                        <div className="settings-label-toggle-wrapper sub-list-settings-item">
                            <Checkbox
                                id={"checkbox-manage-dropOff-link"}
                                value={DropOffConfigConstants.IS_DROP_OFF_LINK_TO_DISPLAY_IN_MAIL}
                                className="primary-checkbox"
                                text={DropOffConfigConstants.DROP_OFF_ALLOW_MANAGE_LINK_IN_MAIL_NOTIFICATION}
                                size={CheckBoxSize.sm}
                                checked={dropOffState.isUsersAllowedToManageDropOffLink}
                                onChange={e => {
                                    handleToggleSwitch(e, DropOffConfigConstants.IS_DROP_OFF_LINK_TO_DISPLAY_IN_MAIL);
                                }}
                            />
                        </div>
                    )}
                    <div className="settings-label-toggle-wrapper-large">
                        <CustomToggleSwitch
                            label={DropOffConfigConstants.DROP_OFF_SEND_DOWNLOAD_NOTIFICATION}
                            name={DropOffConfigConstants.IS_DOWNLOAD_NOTIFICATION_TO_SEND}
                            handleChange={handleToggleSwitch}
                            switched={dropOffState.isDownloadNotificationToSend}
                        />
                    </div>

                    <div className="settings-label-toggle-wrapper-large">
                        <CustomToggleSwitch
                            label={DropOffConfigConstants.DROP_OFF_ENABLE_SPAM_FILTERING}
                            name={DropOffConfigConstants.IS_SPAM_FILTERING_REQUIRED}
                            handleChange={handleToggleSwitch}
                            switched={dropOffState.isSpamFilteringRequired}
                        />
                    </div>
                    <section>
                        <DropOffConfigAccrodian
                            handleFileSubmission={handleFileSubmission}
                            handleFileTypeChange={handleFileTypeChange}
                            fileTypesGroupedOptions={fileTypesGroupedOptions}
                            restrictedFileTypes={restrictedFileTypes}
                        />
                    </section>
                </section>
            </>
            <Loader loading={isLoading} />
        </LoadingOverlay>
    );
};
