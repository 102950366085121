import React, { useEffect, useState } from "react";
import { IFolderModel } from "../../../../core/viewModels/folder/FolderModel";
import { IReceivedMessageModel } from "../../../../core/viewModels/message/ReceivedMessageModel";
import { ISentMessageModel } from "../../../../core/viewModels/message/SentMessageModel";
import Tree from "../Tree/Tree";
import "../Tree/styles.scss";
import { ArchiveFolderType, FolderType } from "../../../../core/common/enums";
import { AddFolderIcon } from "../../../svg/IconCollection";
interface ITreeListProps {
  foldersTreeData: IFolderModel[],
  folderType: FolderType,
  destinationFolder: IFolderModel | undefined,
  selectedMessages?: IReceivedMessageModel[] | ISentMessageModel[],
  selectedFolders?: IFolderModel[],
  isMovingFolder: boolean,
  isDR: boolean,
  setShowAddFolderModal: (show: boolean) => void,
  handleFolderSelection: (folder: IFolderModel) => void
}

const TreeList: React.FC<ITreeListProps> = (props) => {
  const { foldersTreeData, handleFolderSelection, destinationFolder } = props;
  const selectedPath = destinationFolder?.path ? destinationFolder?.path : "-Select Location-";
  const [isExpanded, setIsExpanded] = useState(false);
  const [isAddFolderEnabled, setIsAddFolderEnabled] = useState(false);

  useEffect(() => {
    if (destinationFolder) {
      setIsAddFolderEnabled(getAddFolderDisabledStatus(destinationFolder));
    }
  }, [destinationFolder]);

  const getAddFolderDisabledStatus = (destinationFolder: IFolderModel) => {
    if (destinationFolder?.folderType === ArchiveFolderType.FOLDER || destinationFolder?.folderType === ArchiveFolderType.UNKNOWN) {
      return true;
    }
    return false;
  };

  return (
    <>
      <div className="treelist-container row">
        <div className="treelist-expand-container">
          <p className="expand-button" onClick={() => setIsExpanded(!isExpanded)}>{isExpanded ? "Collapse All" : "Expand All"} </p>
          <span
            onClick={() => { isAddFolderEnabled && props.setShowAddFolderModal(true); }}
            title="Create Folder"
            className={`add-folder-icon ${!isAddFolderEnabled && "disabled"}`}>
            <AddFolderIcon
              width={20}
              height={20}
            />
          </span>
        </div>
        <div className="treelist-header-container" title={selectedPath}>
          <h4>{selectedPath}</h4>
        </div>
        <div className="treelist-wrapper">
          <Tree
            {...props}
            destinationFolder={destinationFolder}
            selectedFolders={props.selectedFolders}
            isDR={props.isDR}
            data={foldersTreeData}
            isExpanded={isExpanded}
            selectedMessages={props.selectedMessages}
            handleFolderSelection={handleFolderSelection} />
        </div>
      </div>
    </>
  );
};

export default TreeList;
