import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";

import {
    changeExpiry,
    fetchMessages,
    getMessageDetail,
    deleteMessage,
    printMessages,
    moveToFolder,
} from "../../../actionCreators/receivedMessageActionCreators";
import { FolderColumnName, FolderType, MessageSource, SortOrder } from "../../../core/common/enums";
import { ApplicationState } from "../../../store";
import MessageList from "./parts/MessageList";
import MessageDetail from "../../common/message/MessageDetail";
import { initialReceivedMessage, IReceivedMessageModel } from "../../../core/viewModels/message/ReceivedMessageModel";
import { downloadAllAttachments, downloadSingleAttachment } from "../../../actions/attachmentActions";
import { DownloadedFrom, IFileModel } from "../../../core/viewModels/file/FileModel";
import { IMessageResponseModel } from "../../../core/viewModels/message/MessageResponseModel";
import { initialState } from "../../../reducers/receivedMessageReducers";
import { actionTypes as receivedMessageAction } from "../../../types/messageTypes";
import { ISentMessageModel } from "../../../core/viewModels/message/SentMessageModel";
import { MessageListConstants, initialPagination, viewingOthersInboxLabel } from "../../../helper/Constants";
import { fetchSubFolders, fetchUserOwnedFolders } from "../../../actionCreators/folderActionCreator";
import { initialFolder } from "../../../core/viewModels/folder/FolderModel";
import { Col, Row } from "react-bootstrap";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { logger } from "../../../oidcClient/authProvider";
import { currentPageChanged, inboxPageChanged } from "../../../actionCreators/signalRActionCreator";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { getRestrictedFileTypes } from "../../../actionCreators/restrictedFileTypeActionCreators";


const Inbox: React.FC = () => {
    const [isMessageDetailShow, toggleMessageDetailShow] = useState(false);
    const [pagination, setPagination] = useState(initialPagination);
    const [selectedMessage, setSelectedMessage] = useState(initialReceivedMessage);
    const { showAutoLogoutModal } = useSelector((state: ApplicationState) => state.signalRState);
    const dispatch = useDispatch();
    const messageState = useSelector((state: ApplicationState) => state.receivedMessageState);
    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);

    const [reload, setReload] = useState(false);

    useEffect(() => {
        dispatch(currentPageChanged(FolderType.INBOX));
        dispatch(fetchSubFolders(initialFolder.folderId, FolderColumnName.FOLDERNAME, SortOrder.ASC));
        dispatch(getRestrictedFileTypes());
        dispatch(fetchUserOwnedFolders());
        logger.trackPageView(appInsightsData.MailBox.Inbox.PageTitle);
        
        return () => {
            dispatch({
                type: receivedMessageAction.RESET_MESSAGES_ACTION,
                payload: initialState,
            });
        };
    }, []);

    const onSuccessCallback = (
        pageNo: number,
        pageSize: number,
        sortColumn?: string,
        sortOrder?: string,
        search?: string
    ) => {
        dispatch(
            fetchMessages(
                FolderType.INBOX,
                pageNo,
                pageSize,
                sortColumn,
                sortOrder,
                search,
                onFetchInboxMessagesCallBack
            )
        );
    };

    const onFetchInboxMessagesCallBack = () => {
        selectedMessage.receivedMessageId > 0 &&
            dispatch(
                getMessageDetail(selectedMessage.receivedMessageId, selectedMessage.messageType, messageDetailCallback)
            );
    };

    const fetchInboxMessages = (
        pageNo: number,
        pageSize: number,
        sortColumn?: string,
        sortOrder?: string,
        search?: string
    ) => {
        if(!showAutoLogoutModal){
            dispatch(
                fetchMessages(
                    FolderType.INBOX,
                    pageNo,
                    pageSize,
                    sortColumn,
                    sortOrder,
                    search,
                    onFetchInboxMessagesComplete
                )
            );
        }  
    };
    
    const onFetchInboxMessagesComplete = (result: IMessageResponseModel<IReceivedMessageModel>) => {
        if (result && result.messages && result.messages.length > 0) {
            dispatch(
                getMessageDetail(
                    result.messages[0].receivedMessageId,
                    result.messages[0].messageType,
                    messageDetailCallback
                )
            );
        }
    };

    const messageDetailCallback = (result: any) => {
        if (result.messageDetail) toggleMessageDetailShow(true);
        else toggleMessageDetailShow(false);
    };

    const pageNumberChanged = (pageNo: number) => {
        dispatch(inboxPageChanged(pageNo));
    };

    const handleOnChangeExpiry = (
        expiresDate: Date,
        message: ISentMessageModel | IReceivedMessageModel,
        callback?: any
    ): void => {
        const receivedMessage = message as IReceivedMessageModel;
        dispatch(
            changeExpiry(
                expiresDate,
                [receivedMessage],
                callback,
                appInsightsData.MailBox.Inbox.PageTitle,
                ResourceIdLocators.Inbox.MsgDtlBtnChangeExpiry
            )
        );
    };

    const handleOnDeleteMessage = (messages: IReceivedMessageModel[], callback: any) => {
        dispatch(
            deleteMessage(
                messages,
                callback,
                appInsightsData.MailBox.Inbox.PageTitle,
                ResourceIdLocators.Inbox.MsgListBtnDelete
            )
        );
    };

    const handleGetMessageDetail = (message: IReceivedMessageModel | ISentMessageModel) => {
        const receivedMessage = message as IReceivedMessageModel;
        dispatch(
            getMessageDetail(receivedMessage.receivedMessageId, receivedMessage.messageType, messageDetailCallback)
        );
    };

    const handleMessageDetailView = (result: boolean) => {
        if (result) toggleMessageDetailShow(false);
    };

    const handleDownloadSingleAttachment = (
        fileId: number,
        physicalPath: string,
        messageId: number,
        fileName: string,
        senderEmail: string,
        receivedMessageGuid: string,
        messageSource: MessageSource
    ) => {
        dispatch(
            downloadSingleAttachment(
                fileId,
                physicalPath,
                messageId,
                fileName,
                senderEmail,
                receivedMessageGuid,
                messageSource,
                DownloadedFrom.MPI_Download_Inbox_Single_File,
                (result: any) => {
                    if (result) {
                        window.open(result, "_blank");
                    }
                },
                appInsightsData.MailBox.Inbox.PageTitle,
                ResourceIdLocators.Inbox.MsgDtlBtnDownloadFile
            )
        );
    };

    const handleDownloadAllAttachments = (
        files: IFileModel[],
        messageId: number,
        senderEmail: string,
        receivedMessageGuid: string,
        messageSource: MessageSource
    ) => {
        dispatch(
            downloadAllAttachments(
                files,
                messageId,
                senderEmail,
                receivedMessageGuid,
                messageSource,
                DownloadedFrom.MPI_Download_Inbox_Multiple_File,
                (result: any) => {
                    if (result) {
                        window.open(result, "_blank");
                    }
                },
                appInsightsData.MailBox.Inbox.PageTitle,
                ResourceIdLocators.Inbox.MsgDtlBtnDownloadFiles
            )
        );
    };

    const handleOnPrint = (): void => {
        dispatch(printMessages());
    };

    const checkFolderExpiry = (folderId: number): boolean => {
        // let destinationFolderExpiryDays: number | undefined;
        // if (!folderState.foldersData.isLoading) {
        //   destinationFolderExpiryDays = folderState.foldersData.folders?.find(
        //     (f) => f.folderId === folderId
        //   )?.expiresDays;
        // }

        if (folderId) {
            return true;
        }
        return true;
    };

    const handleOnMoveToFolder = (messages: IReceivedMessageModel[], folderId: number, callback?: any): void => {
        dispatch(
            moveToFolder(
                messages,
                FolderType.INBOX,
                folderId,
                callback,
                appInsightsData.MailBox.Inbox.PageTitle,
                ResourceIdLocators.Inbox.MsgListBtnMoveMessage
            )
        );
    };

    const handleResetSearchAndSort = () => {
        setReload(value => !value);
    };

    return (
        <Row className="height-100-percent width-100-percent margin-left-0">
            {mailboxUserData && (
                <p className="others-inbox-info-label">
                    {viewingOthersInboxLabel(mailboxUserData.firstName, mailboxUserData.lastName)}
                </p>
            )}
            <Col sm={4} className="no-padding-left">
                <MessageList
                    folderId={FolderType.INBOX}
                    folderType={FolderType.INBOX}
                    isLoading={messageState.isLoading}
                    messages={messageState.data.messages}
                    totalRecords={messageState.data.totalRecords}
                    fetchMessages={fetchInboxMessages}
                    pageNumberChanged={pageNumberChanged}
                    deleteMessages={handleOnDeleteMessage}
                    getMessageDetail={handleGetMessageDetail}
                    print={handleOnPrint}
                    checkFolderExpiry={checkFolderExpiry}
                    moveToFolder={handleOnMoveToFolder}
                    hideMessageDetailView={handleMessageDetailView}
                    pagination={pagination}
                    setPagination={setPagination}
                    resourceLocators={ResourceIdLocators.Inbox}
                    selectedMessage={selectedMessage}
                    setSelectedMessage={setSelectedMessage}
                    isSearchEnabled={true}
                    reload={reload}
                    reloadMessageList ={onSuccessCallback}
                />
            </Col>
            <Col sm={8}>
                <LoadingOverlay style={{ height: "100%" }}>
                    {isMessageDetailShow && messageState.data.messages.length > 0 ? (
                        <MessageDetail
                            message={messageState.mailData.data}
                            downloadSingleAttachment={handleDownloadSingleAttachment}
                            downloadAllAttachments={handleDownloadAllAttachments}
                            messageId={messageState?.mailData?.data?.messageDetail?.receivedMessageId}
                            folderType={FolderType.INBOX}
                            hideMessageDetailView={handleMessageDetailView}
                            getMessageDetail={handleGetMessageDetail}
                            changeExpiry={handleOnChangeExpiry}
                            pagination={pagination}
                            fetchFolderMessages={fetchInboxMessages}
                            resourceLocators={ResourceIdLocators.Inbox}
                            onSuccessCallback={onSuccessCallback}
                            resetSearchAndSort={handleResetSearchAndSort}
                        />
                    ) : (
                        messageState.mailData.isLoading || (
                            <div className="no-message-selected">{MessageListConstants.NO_MESSAGE_SELECTED}</div>
                        )
                    )}
                    <Loader loading={messageState.mailData.isLoading} />
                </LoadingOverlay>
            </Col>
        </Row>
    );
};

export default Inbox;
