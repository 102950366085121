import React, { FC } from "react";
import { useDispatch } from "react-redux";
import { LogoutCause } from "../../core/viewModels/signalR/SignalRModel";
import { UserAutoLogoutConstants, UserAutoLogoutMessageConstants } from "../../helper/Constants";
import { signoutRedirect } from "../../oidcClient/userService";
import { resetUserCache } from "../../actionCreators/layoutActionCreators";
import { removeCookie, getCookie, setCookie } from "../../helper/HelperFunctions";
import { CustomModal } from "../common/ui/Modals/CustomModal";

interface UserAutoLogoutModalProps {
    show: boolean;
    logoutCause: LogoutCause;
}
const UserAutoLogoutModal: FC<UserAutoLogoutModalProps> = (props) => {

    const dispatch = useDispatch();
    React.useEffect(() => {
        if (props.show && props.logoutCause != LogoutCause.None) {
            dispatch(resetUserCache());
            setCookie("UserPermissionChanged", "true", 1);
        }
        else { LogOutUserOnReloadIfPermissionsChanged(); }
    }, [props.show, props.logoutCause]);



    const LogOutUserOnReloadIfPermissionsChanged = () => {
        if (CheckIfUserPermissionsChanged()) {
            onCloseModal();
        }
    };

    const CheckIfUserPermissionsChanged = (): boolean => {
        if (getCookie("UserPermissionChanged")) return true;
        return false;
    };

    const onCloseModal = (): void => {
        removeCookie("UserPermissionChanged");
        dispatch(resetUserCache());
        signoutRedirect(dispatch);
    };

    return (
        <CustomModal
            show={props.show}
            onHide={onCloseModal}
            onSubmit={onCloseModal}
            title={UserAutoLogoutConstants.PermissionChanged}
            isConfirmModal={true}
            confirmButtonName="Login"
            className="rolechange-notification-modal">

            <div>
                {UserAutoLogoutMessageConstants.PermissionChanged}
            </div>
        </CustomModal>
    );
};
export default UserAutoLogoutModal;

