export const ResourceIdLocators = {
    Inbox: {
        MsgListBtnDelete: "SSE_IL_BtnDeleteMsg",
        MsgListBtnMoveMessage: "SSE_IL_BtnMoveMessage",
        MsgDtlBtnDelete: "SSE_IMD_BtnDeleteMsg",
        MsgDtlBtnDownloadFile: "SSE_IMD_BtnDownloadFile",
        MsgDtlBtnDownloadFiles: "SSE_IMD_BtnDownloadFiles",
        MsgDtlBtnReply: "SSE_IMD_BtnReply",
        MsgDtlBtnReplyAll: "SSE_IMD_BtnReplyAll",
        MsgDtlBtnForward: "SSE_IMD_BtnForward",
        MsgDtlBtnChangeExpiry: "SSE_IMD_BtnChangeExpiry",
    },
    Sent: {
        MsgListBtnDelete: "SSE_SL_BtnDeleteMessage",
        MsgListBtnResendAccessLink: "SSE_SL_BtnResendAccessLink",
        MsgListBtnCancelTransfer: "SSE_SL_BtnCancelTransfer",
        MsgDtlBtnDelete: "SSE_SMD_BtnDeleteMessage",
        MsgDtlBtnDownloadFile: "SSE_SMD_BtnDownloadFile",
        MsgDtlBtnDownloadFiles: "SSE_SMD_BtnDownloadFiles",
        MsgDtlBtnReply: "SSE_SMD_BtnReply",
        MsgDtlBtnReplyAll: "SSE_SMD_BtnReplyAll",
        MsgDtlBtnForward: "SSE_SMD_BtnForward",
    },
    Drafts: {
        MsgListBtnDelete: "SSE_DL_BtnDeleteMessage",
        MsgDtlBtnDelete: "SSE_DMD_BtnDeleteMessage",
        MsgDtlSendMessage: "SSE_DMD_BtnSendMessage",
        MsgDtlBtnDownloadFile: "SSE_DMD_BtnDownloadFile",
        MsgDtlBtnDownloadFiles: "SSE_DMD_BtnDownloadFiles",
    },
    Spam: {
        MsgListBtnDelete: "SSE_SPL_BtnDeleteMessage",
        MsgListBtnMoveToInbox: "SSE_SPL_BtnMoveToInbox",
        MsgListBtnMarkNotSpam: "SSE_SPL_BtnMarkNotSpam",
        MsgDtlBtnDelete: "SSE_SPMD_BtnDeleteMessage",
    },
    MyFiles: {
        MsgListBtnDelete: "SSE_MFL_BtnDeleteMessage",
        MsgListBtnMoveMessage: "SSE_MFL_BtnMoveMessage",
        MyFilesBtnAddFolder: "SSE_MF_BtnAddFolder",
        MyFilesDrpDwnDeleteFolder: "SSE_MF_DrpDwnDeleteFolder",
        MyFilesDrpDwnRenameFolder: "SSE_MF_DrpDwnRenameFolder",
        MyFilesDrpDwnChangeRetention: "SSE_MF_DrpDwnChangeRetention",
        MyFilesBtnDeleteFolder: "SSE_MF_BtnDeleteFolder",
        MyFilesBtnDeleteFolders: "SSE_MF_BtnDeleteFolders",
        MyFilesBtnRenameFolder: "SSE_MF_BtnRenameFolder",
        MyFilesBtnShareFolder: "SSE_MF_BtnShareFolder",
        MyFilesBtnMoveFolder: "SSE_MF_BtnMoveFolder",
        MyFilesBtnMoveFolders: "SSE_MF_BtnMoveFolders",
        MyFilesBtnChangeRetention: "SSE_MF_BtnChangeRetention",
        MyFilesBtnChangeRetentions: "SSE_MF_BtnChangeRetentions",
        MyFilesBtnFileView: "SSE_MF_BtnFileView",
        MyFilesBtnDownloadFile: "SSE_MF_FV_BtnDownloadFile",
        MyFilesBtnDownloadFiles: "SSE_MF_FV_BtnDownloadFiles",
        MsgDtlBtnDelete: "SSE_MFD_BtnDeleteMessage",
        MsgDtlBtnDownloadFile: "SSE_MFD_BtnDownloadFile",
        MsgDtlBtnDownloadFiles: "SSE_MFD_BtnDownloadFiles",
        MsgDtlBtnReply: "SSE_MFD_BtnReply",
        MsgDtlBtnReplyAll: "SSE_MFD_BtnReplyAll",
        MsgDtlBtnForward: "SSE_MFD_BtnForward",
        MyFilesBtnCopyFolder: "SSE_MF_BtnCopyDropOffLink",
        MyFilesBtnRequestMoveTo: "SSE_MF_BtnMoveRequest",
        MyFilesBtnRequestDelete: "SSE_MF_BtnDeleteRequests",
        MyFilesBtnRequestResendAccess: "SSE_MF_BtnRequestAccess",
        MyFilesBtnDRLDetailDeleteRequest: "SSE_MF_DRL_DD_BtnDeleteRequest",
    },
    SIH: {
        SIHBtnResendAcsLink: "SSE_SIH_BtnResendAccessLink",
        SIHBtnCancelTransfer: "SSE_SIH_BtnCancelTransfer",
    },
    Global: {
        ViewOtrInboxBtn: "SSE_DrpDwn_VwOthrInbox",
        ReportPrbBtn: "SSE_Btn_RptPrblm",
        DropOffLinkBtn: "SSE_Btn_DropOffLinks",
        NewMessageBtn: "SSE_Btn_Compose",
        MyDownloadsBtn: "SSE_Btn_MyDownloads",
    },
    DRL: {
        DRLBtnNewRequest: "SSE_DRL_BtnNewRequest",
        DRLListBtnDeleteRequest: "SSE_DRL_BtnDeleteRequests",
        DRLListBtnResendAccess: "SSE_DRL_BtnRequestAccess",
        DRLDtlBtnDeleteRequest: "SSE_DRL_DD_BtnDeleteRequest",
        DRLDtlBtnResendAccess: "SSE_DRL_DD_BtnRequestAccess",
        DRLBtnChangeState: "SSE_DRL_BtnChangeState",
        DRLBtnDeleteDocument: "SSE_DRL_BtnDeleteDocument",
        DRLBtnDownloadFile: "SSE_DRL_BtnDownloadFile",
        DRLBtnDownloadFiles: "SSE_DRL_BtnDownloadFiles",
        DRLBtnChangeExpiry: "SSE_DRL_BtnChangeExpiry",
        DRLListBtnSendReminder: "SSE_DRL_BtnSendReminder",
        DRLDtlBtnSendReminder: "SSE_DRL_DD_BtnSendReminder",
        DRLDtlBtnDocSendReminder: "SSE_DRL_DD_BtnDocSendReminder",
        DRLDtlBtnRequestMore: "SSE_DRL_DD_BtnRequestMore",
        DRLBtnRequestMoveTo: "SSE_DRL_BtnMoveRequest"
    },
    AdminSetting: {
        DropOff: "SSE_DS_DropOff",
    },
};

export interface IResourceLocator {
    [key: string]: string;
}

export const RBACPermission = {
    operation:
        "You do not have permission to perform this operation,\nplease contact your system administrator for more\ninformation.",
    page: "You do not have permissions to access this page,\nplease contact your system administrator for more\ninformation.",
};
