import React, { Dispatch, SetStateAction, useCallback, useEffect, useReducer, useState } from "react";
import { useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { Col, FormControl, Row } from "react-bootstrap";
import { debounce } from "throttle-debounce";

import MessageListItem from "./MessageListItem";
import { FolderType } from "./../../../../core/common/enums";
import { IReceivedMessageModel } from "../../../../core/viewModels/message/ReceivedMessageModel";
import { receivedMessageReducer } from "../../../../reducers/selectedMessageReducer";
import MessageToolbar from "./MessageToolbar";
import DefaultPagination from "../../../common/ui/DefaultPagination";
import {
    ArchiveConstants,
    DEFAULT_PAGE_SIZE,
    MessageListConstants,
    SortAndFilterConstants,
    SortAndFilterIconType,
    initialPagination,
} from "../../../../helper/Constants";
import { IPagination } from "../../../../core/viewModels/message/SentMessageModel";
import { ApplicationState } from "../../../../store";
import { IResourceLocator } from "../../../../helper/ResourceIdLocators";
import { IFolderModel } from "../../../../core/viewModels/folder/FolderModel";
import { getFolderMessageSortOptions, getMessageListHeight } from "../../../../helper/HelperFunctions";
import { RefreshIcon, SearchIcon } from "../../../svg/IconCollection";
import { FiltersCustomComponent } from "../../../common/ui/Filters/FiltersCustomComponent";

interface IMessageListProps {
    messages: IReceivedMessageModel[];
    totalRecords: number;
    folderId: number;
    folderType: FolderType;
    isLoading: boolean;
    resourceLocators: IResourceLocator;
    pagination: IPagination;
    selectedMessage: IReceivedMessageModel;
    folder?: IFolderModel;
    searchText?: string;
    isSearchEnabled?: boolean;
    reload?: boolean;
    deleteMessages: (messages: IReceivedMessageModel[], callback: any) => void;
    getMessageDetail: (message: IReceivedMessageModel) => void;
    fetchMessages: (pageNo: number, pageSize: number, sortColumn?: string, sortOrder?: string, search?: string) => void;
    pageNumberChanged?: (pageNo: number) => void;
    markAsNotSpam?: (messageIds: number[], callback?: any) => void;
    moveToInbox?: (messageIds: number[], callback?: any) => void;
    print: (messages: IReceivedMessageModel[]) => void;
    checkFolderExpiry: (folderId: number) => boolean;
    moveToFolder: (messages: IReceivedMessageModel[], folderId: number, callback?: any) => void;
    hideMessageDetailView(hide: boolean): void;
    setPagination: Dispatch<SetStateAction<IPagination>>;
    setSelectedMessage: Dispatch<SetStateAction<IReceivedMessageModel>>;
    reloadMessageList    : (pageNo: number, pageSize: number, sortColumn?: string, sortOrder?: string, search?: string) => void;
}

const sortingData = [
    {
        title: SortAndFilterConstants.SORT_BY_TEXT,
        menus: [
            SortAndFilterConstants.ALPHABETICAL_ORDER_ASC,
            SortAndFilterConstants.ALPHABETICAL_ORDER_DESC,
            SortAndFilterConstants.NEWEST_FIRST_ORDER,
            SortAndFilterConstants.OLDEST_FIRST_ORDER,
        ],
    },
];

const MessageList: React.FC<IMessageListProps> = props => {
    const { pagination, setPagination, selectedMessage, setSelectedMessage } = props;

    const { mailboxUserData } = useSelector((state: ApplicationState) => state.commonState);
    const [selectedReceivedMessage, dispatchSelectedMessageActions] = useReducer(receivedMessageReducer, {
        messages: [],
        isAllSelected: false,
    });
    const [searchTextBox, setSearchTextBox] = useState("");
    const [selectedSortOption, setSelectedSortOption] = useState<string>(SortAndFilterConstants.NEWEST_FIRST_ORDER);

    useEffect(() => {
        const sort = getFolderMessageSortOptions(selectedSortOption);
        setPagination(initialPagination);
        props.fetchMessages &&
            props.fetchMessages(
                initialPagination.currentPage,
                initialPagination.pageSize,
                sort.sortColumn,
                sort.sortOrder,
                searchTextBox
            );
        if (props.searchText === "") {
            resetSelection();
        }
    }, [selectedSortOption]);

    useEffect(() => {
        handleResetSearchAndSort();
    }, [props.reload]);

    useEffect(() => {
        if (searchTextBox === "") {
            dispatchSelectedMessageActions({
                type: "removeAll",
                payload: null,
            });
        }
    }, [searchTextBox]);

    const handleOnPageChange = (pageNo: number, pageSize: number = DEFAULT_PAGE_SIZE) => {
        setPagination({
            pageSize,
            currentPage: pageNo,
            totalRecords: props.totalRecords,
        });
        props.pageNumberChanged && props.pageNumberChanged(pageNo);
        const sort = getFolderMessageSortOptions(selectedSortOption);
        props.fetchMessages(pageNo, pageSize, sort.sortColumn, sort.sortOrder, searchTextBox);
    };

    const handleMessageReload = () => {
        //cleares searchtext and default soring options and then reloads messages
        setPagination(initialPagination);
        props.fetchMessages(initialPagination.currentPage, pagination.pageSize, "");
        handleResetSearchAndSort();
        resetSelection();
    };

    const handleMessageDetailView = () => props.hideMessageDetailView(true);

    const handleOnSelected = (message: IReceivedMessageModel, e: any) => {
        e.stopPropagation();
        if (e.target.checked) {
            dispatchSelectedMessageActions({
                type: "add",
                payload: message,
                messageCount: props.messages.length,
            });
        } else {
            dispatchSelectedMessageActions({ type: "remove", payload: message });
        }
    };

    const handleOnAllSelected = (e: any) => {
        if (e.target.checked) {
            dispatchSelectedMessageActions({
                type: "addAll",
                payload: props.messages,
            });
        } else {
            dispatchSelectedMessageActions({
                type: "removeAll",
                payload: null,
            });
        }
    };

    const handleMarkAsNotSpam = (callback?: any) => {
        if (props.markAsNotSpam) {
            props.markAsNotSpam(
                selectedReceivedMessage.messages.map(x => x.receivedMessageId),
                callback
            );
            dispatchSelectedMessageActions({
                type: "removeAll",
                payload: null,
            });
        }
    };

    const handleMoveToInbox = (callback?: any) => {
        if (props.moveToInbox) {
            props.moveToInbox(
                selectedReceivedMessage.messages.map(x => x.receivedMessageId),
                callback
            );
            dispatchSelectedMessageActions({
                type: "removeAll",
                payload: null,
            });
        }
    };

    const handleDeleteMessages = (callback: any) => {
        setPagination(initialPagination);
        setSearchTextBox("");
        props.deleteMessages(selectedReceivedMessage.messages, callback);
        dispatchSelectedMessageActions({
            type: "removeAll",
            payload: null,
        });
    };

    const handlePrintDocument = () => {
        props.print(selectedReceivedMessage.messages);
    };

    const handleMoveToFolder = (folderId: number, callback?: any) => {
        setPagination(initialPagination);
        setSearchTextBox("");
        props.moveToFolder(selectedReceivedMessage.messages, folderId, callback);
        dispatchSelectedMessageActions({
            type: "removeAll",
            payload: null,
        });
    };

    const handleOnMessageItemClick = (message: IReceivedMessageModel, e: any) => {
        e.stopPropagation();
        setSelectedMessage(message);
        props.getMessageDetail(message);
    };

    const resetSelection = () => {
        dispatchSelectedMessageActions({
            type: "removeAll",
            payload: null,
        });
    };

    const onFilter: React.ChangeEventHandler<HTMLInputElement> = e => {
        const value = (e.target as HTMLInputElement).value.toLocaleLowerCase();
        //Set only search box
        setSearchTextBox(value);
        debounceFn(value);
    };

    const getData = (value: string) => {
        const sort = getFolderMessageSortOptions(selectedSortOption);
        setPagination(initialPagination);
        props.fetchMessages(pagination.currentPage, pagination.pageSize, sort.sortColumn, sort.sortOrder, value);
    };

    const debounceFn = useCallback(debounce(1000, getData), [selectedSortOption]);

    const handleResetSearchAndSort = () => {
        setPagination(initialPagination);
        setSearchTextBox("");
        setSelectedSortOption && setSelectedSortOption(SortAndFilterConstants.NEWEST_FIRST_ORDER);
    };

    const handleMessageListReload = () => {
        setPagination(initialPagination);
        props.reloadMessageList(initialPagination.currentPage, pagination.pageSize, "");
        handleResetSearchAndSort();
        resetSelection();
    };
    return (
        <>
            {props.isSearchEnabled && (
                <Row className="search-header-wrapper nopadding-left">
                    <Col sm={10} md={8} className="search-input-wrapper-col padding-left" style={{flexGrow:1}}>
                        <div className="search-input-wrapper">
                            <FormControl
                                placeholder={ArchiveConstants.ShareFolder.PLACEHOLDER_SEARCH_TEXT}
                                value={searchTextBox}
                                onChange={onFilter}
                                className="search-input search-textbox"
                            />
                            <div className="search-icon">
                                <SearchIcon/>
                            </div>
                        </div>
                    </Col>
                    <Col sm={2} md={2} className="filter-icon-container">
                        <div>
                            <FiltersCustomComponent
                                groups={sortingData}
                                selectedSortOption={selectedSortOption}
                                iconType={SortAndFilterIconType.Sort}
                                setSelectedSortOption={setSelectedSortOption}
                            />
                        </div>
                    </Col>
                    <Col sm={2} md={2} className="list-refresh-icon-container">
                        <div className="list-refresh-button" onClick={handleMessageListReload}  title={ArchiveConstants.REFRESH_TITLE}>
                            <RefreshIcon />
                        </div>
                    </Col>
                </Row>
            )}
            <div className="message-list-wrapper">
                <Row>
                    <Col>
                        <MessageToolbar
                            folderType={props.folderType}
                            folderId={props.folderId}
                            selectedMessages={selectedReceivedMessage.messages}
                            isAllSelected={selectedReceivedMessage.isAllSelected}
                            isAnySelected={selectedReceivedMessage.messages.length > 0}
                            selectAll={handleOnAllSelected}
                            deleteMessages={handleDeleteMessages}
                            printDocument={handlePrintDocument}
                            markAsNotSpam={handleMarkAsNotSpam}
                            moveToInbox={handleMoveToInbox}
                            checkFolderExpiry={props.checkFolderExpiry}
                            moveToFolder={handleMoveToFolder}
                            reloadMessages={handleMessageReload}
                            hideMessageDetailView={handleMessageDetailView}
                            resetSelection={resetSelection}
                            resourceLocators={props.resourceLocators}
                            showSelectAllCheckbox={props.messages && props.messages.length > 0}
                            folder={props.folder}
                            resetSearchAndSort={handleResetSearchAndSort}
                        />
                    </Col>
                    <Col>
                        {props.messages && props.messages.length > 0 && (
                            <DefaultPagination
                                pageSize={pagination.pageSize}
                                currentPage={pagination.currentPage}
                                totalRecords={props.totalRecords}
                                onPageChange={handleOnPageChange}
                            />
                        )}
                    </Col>
                </Row>
                <LoadingOverlay style={{ innerHeight: "400px", outerHeight: "500px" }}>
                    <div
                        style={{
                            height: getMessageListHeight(mailboxUserData, props.folderType),
                        }}
                        className="message-list"
                    >
                        {props.messages && props.messages.length > 0 ? (
                            props.messages.map((item: any, index: number) => {
                                const isChecked =
                                    selectedReceivedMessage.messages.includes(item) ||
                                    selectedReceivedMessage.isAllSelected;
                                const selectedMessageId =
                                    selectedMessage?.receivedMessageId === 0
                                        ? props.messages[0]?.receivedMessageId
                                        : selectedMessage?.receivedMessageId;

                                //message selection shouldn't change if new message arrived from signal R
                                if (selectedMessage?.receivedMessageId === 0) {
                                    setSelectedMessage(props.messages[0]);
                                }
                                return (
                                    <MessageListItem
                                        message={item}
                                        key={index}
                                        index={index}
                                        isChecked={isChecked}
                                        selectedMessageId={selectedMessageId}
                                        onSelected={handleOnSelected}
                                        onClick={(message, e) => handleOnMessageItemClick(message, e)}
                                        folder={props.folder}
                                    />
                                );
                            })
                        ) : (
                            <div className="vertical-middle">{MessageListConstants.NO_MESSAGE_TEXT}</div>
                        )}
                    </div>
                    <Loader loading={props.isLoading} />
                </LoadingOverlay>
            </div>
        </>
    );
};

export default MessageList;
