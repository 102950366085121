import axios, { AxiosResponse } from "axios";
import { IMessageResponseModel } from "../core/viewModels/message/MessageResponseModel";
import { AppThunkAction } from "../store";
import { actionTypes as notificationAction } from "../types/notificationTypes";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import {
    CompletedDeleteDraftMessageAction,
    CompletedDraftMessageDetailAction,
    CompletedDraftMessagesAction,
    CompletedUpdateDraftMessageAction,
    DeletedDraftMessageAction,
    ReceivedDraftMessageDetailAction,
    ReceivedDraftMessagesAction,
    RequestDeleteDraftMessageAction,
    RequestDraftMessageDetailAction,
    RequestDraftMessagesAction,
    RequestUpdateDraftMessageAction,
} from "../actions/draftMessageActions";
import { actionTypes as draftMessageActions } from "../types/draftMessageTypes";
import { IDraftMailMessage, IDraftMessageModel } from "../core/viewModels/message/DraftMailMessage";
import { DEFAULT_PAGE_SIZE } from "../helper/Constants";
import { initialState } from "../reducers/draftMessageReducer";
import { appInsightsData } from "../helper/AppInsightsData";
import { logger } from "../oidcClient/authProvider";
import {
    RequestUploadAttachmentSasAction,
    RequestRemoveAttachment,
    CompletedRemoveAttachment,
} from "../actions/composeActions";
import { actionTypes as composeActions } from "../types/composeTypes";
import { ToasterMessages } from "../helper/ToasterMessages";

export type DispatchActions =
    | RequestDraftMessagesAction
    | ReceivedDraftMessagesAction
    | CompletedDraftMessagesAction
    | RequestDraftMessageDetailAction
    | ReceivedDraftMessageDetailAction
    | CompletedDraftMessageDetailAction
    | RequestUpdateDraftMessageAction
    | CompletedUpdateDraftMessageAction
    | RequestDeleteDraftMessageAction
    | CompletedDeleteDraftMessageAction
    | DeletedDraftMessageAction
    | RequestUploadAttachmentSasAction
    | RequestRemoveAttachment
    | CompletedRemoveAttachment;

export type KnownActions = DispatchActions | NotificationAction;

export const fetchUploadAttachmentSasLink =
    (
        documentGuid: string,
        draftedMessageCreateDate: Date,
        callback?: (data: any) => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/DraftedMessage/GetDocumentUploadLink?documentGuid=${documentGuid}&draftedMessageCreateDate=${draftedMessageCreateDate}`
            )
            .then(response => {
                if(response.status == 200){

                const result = response.data;
                const data = {
                    guid: documentGuid,
                    sas: result,
                };
                callback && callback(data);
            }else{
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: ToasterMessages.ERROR.ATTACHMENT_UPLOAD_FAILED,
                    statusType: StatusType.Error,
                  });
            }
    })
            .catch(function () {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: ToasterMessages.ERROR.ATTACHMENT_UPLOAD_FAILED,
                    statusType: StatusType.Error,
                });
            });
    };

export const removeAttachment =
    (
        documentGuid: string,
        draftedMessageCreateDate: Date,
        callback?: (guid: string) => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.Compose.PageTitle} - ${appInsightsData.Compose.Operation.AttachmentRemoved}`,
            properties: {
                page: appInsightsData.Compose.ComposeTabTitle,
                guid: documentGuid,
            },
        });
        dispatch({ type: composeActions.REQUEST_REMOVE_ATTACHMENT });
        axios
            .delete(
                `${process.env.REACT_APP_API_URL}/api/DraftedMessage/DeleteAttachment?documentGuid=${documentGuid}&draftedMessageCreateDate=${draftedMessageCreateDate}`
            )
            .then(() => {
                dispatch({ type: composeActions.COMPLETED_REMOVE_ATTACHMENT });
                callback && callback(documentGuid);
            })
            .catch(function () {
                dispatch({ type: composeActions.COMPLETED_REMOVE_ATTACHMENT });
            });
    };

export const fetchDraftMessages =
    (
        pageNo: number,
        pageSize: number,
        sortColumn = "",
        sortOrder = "",
        searchString = "",
        callback?: (result: IMessageResponseModel<IDraftMessageModel>) => void
    ): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: draftMessageActions.REQUEST_DRAFT_MESSAGES,
        });

        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/DraftedMessage?pageNo=${pageNo}&pageSize=${pageSize}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&searchString=${encodeURIComponent(searchString.trim())}`
            )
            .then((response: AxiosResponse<IMessageResponseModel<IDraftMessageModel>>) => {
                const data = response.data;
                dispatch({
                    type: draftMessageActions.RECEIVED_DRAFT_MESSAGES,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
                if (data.totalRecords === 0) {
                    dispatch({
                        type: draftMessageActions.RECEIVED_DRAFT_MESSAGE_DETAIL,
                        payload: initialState.mailData.data,
                    });
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: draftMessageActions.COMPLETED_DRAFT_MESSAGES,
                });
            });
    };

export const getDraftMessageDetail =
    (messageId: number, callback?: (result: IDraftMailMessage) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: draftMessageActions.REQUEST_DRAFT_MESSAGE_DETAIL,
        });

        axios
            .get(`${process.env.REACT_APP_API_URL}/api/DraftedMessage/${messageId}`)
            .then((response: AxiosResponse<IDraftMailMessage>) => {
                const data = response.data;
                dispatch({
                    type: draftMessageActions.RECEIVED_DRAFT_MESSAGE_DETAIL,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: draftMessageActions.COMPLETED_DRAFT_MESSAGE_DETAIL,
                });
            });
    };

export const saveDraftMessage =
    (message: IDraftMailMessage, callback?: (messageId: number) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: draftMessageActions.REQUEST_UPDATE_DRAFT_MESSAGE,
        });
        logger.trackEvent({
            name: `${appInsightsData.Compose.PageTitle} - ${appInsightsData.Compose.Operation.SaveDraftButtonClick}`,
            properties: {
                page: appInsightsData.Compose.PageTitle,
            },
        });
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/DraftedMessage`, message)
            .then(response => {
                const data = response.data;

                if (callback) {
                    callback(data);
                }
                dispatch(
                    fetchDraftMessages(
                        1,
                        DEFAULT_PAGE_SIZE,
                        "",
                        "",
                        "",
                        (result: IMessageResponseModel<IDraftMessageModel>) => {
                            if (result && result.messages) {
                                dispatch(getDraftMessageDetail(result.messages[0].draftedMessageId));
                            }
                        }
                    )
                );
            })
            .catch((error: Error) => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: draftMessageActions.COMPLETED_UPDATE_DRAFT_MESSAGE,
                });
            });
    };

export const updateDraftMessage =
    (message: IDraftMailMessage, callback?: (messageId: number) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: draftMessageActions.REQUEST_UPDATE_DRAFT_MESSAGE,
        });
        logger.trackEvent({
            name: `${appInsightsData.MailBox.Draft.PageTitle} - ${appInsightsData.Compose.Operation.SaveDraftButtonClick}`,
            properties: {
                page: appInsightsData.MailBox.Draft.PageTitle,
            },
        });
        axios
            .put(`${process.env.REACT_APP_API_URL}/api/DraftedMessage`, message)
            .then(response => {
                const statusCode = response.status;
                if (callback) {
                    callback(statusCode);
                }
            })
            .catch((error: Error) => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: draftMessageActions.COMPLETED_UPDATE_DRAFT_MESSAGE,
                });
            });
    };

export const deleteDraftMessage =
    (messageIds: number[], callback?: (messageId: number) => void, resourceId?: string): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: draftMessageActions.REQUEST_DELETE_DRAFT_MESSAGE,
        });
        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/DraftedMessage`, {
                data: messageIds,
                headers: {
                    "X-Resource-Id": resourceId ?? "",
                },
            })
            .then(response => {
                const data = response.status;
                if (callback) {
                    callback(data);
                }
            })
            .catch((error: Error) => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: draftMessageActions.COMPLETED_DELETE_DRAFT_MESSAGE,
                });
            });
    };

export const draftMessageDeleted =
    (messageIds: number[]): AppThunkAction<DispatchActions> =>
    dispatch => {
        dispatch({
            type: draftMessageActions.DRAFT_MESSAGE_DELETED,
            messageIds: messageIds,
        });
    };
