import React, { FC, useEffect } from "react";
import { IProfileDropdownModalProps } from "../../../core/viewModels/layout/HeaderModel";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { logger } from "../../../oidcClient/authProvider";
import MyAccountLayout from "../parts/MyAccountLayout";
import "./style.scss";
import { CustomModal } from "../../common/ui/Modals/CustomModal";
import { AutomationIdConstants } from "../../../helper/AutomationConstants";

const MyAccountModal: FC<IProfileDropdownModalProps> = ({ show, onHide }) => {
    useEffect(() => {
        if (show) logger.trackPageView(appInsightsData.MyAccount.PageTitle);
    }, [show]);
    return <CustomModal 
        className="my-account-modal"
        show={show} 
        onHide={() => onHide()}
        onSubmit={() => {
            // do nothing
        }}
        hideFooter={true}
        title="My Account"
        headerCloseButtonAutomationId={AutomationIdConstants.reportProblem.MyAccountModalTitle}
        >
            <MyAccountLayout onHide={onHide} />
    </CustomModal>;
};

export default MyAccountModal;