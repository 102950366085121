import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux"; // Move this import here
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { RequestHistoryConstants } from "../../../../../helper/Constants";
import {
    fetchRequestHistorySettings,
    updateAdminSettings,
} from "../../../../../actionCreators/adminSettingsActionCreators";
import { AppNotifier } from "../../../../../helper/AppNotifier";
import { CompanySettingsName } from "../../../../../core/common/enums";
import CustomToggleSwitch from "../../../../common/ui/switch/CustomToggleSwitch";
import Checkbox, { CheckBoxSize } from "../../../../common/ui/Checkbox";
import { IRequestHistorySettingsValues } from "../../../../../reducers/adminSettingsReducer";
import { ApplicationState } from "../../../../../store";
import { ToasterMessages } from "../../../../../helper/ToasterMessages";

export const RequestHistory: React.FC = () => {
    const dispatch = useDispatch();
    const { requestHistorySettings, isLoading } = useSelector(
        (appState: ApplicationState) => appState.adminSettingsState
    );

    const initialRequestHistoryValues = {
        isUserAllowedToViewDocumentRequestHistoricalReport: false,
        isUserAllowedToViewDocumentRequestSentByAllUsers: false,
    };
    const [requestHistory, setRequestHistory] = useState(initialRequestHistoryValues);

    useEffect(() => {
        dispatch(fetchRequestHistorySettings());
    }, []);

    useEffect(() => {
        if (requestHistorySettings) {
            setRequestHistory({ ...requestHistory, ...requestHistorySettings });
        }
    }, [requestHistorySettings]);

    const handleToggleSwitchChange = (e: any, name: string) => {
        const value = e.target.checked;

        let updatedRequestHistory = { ...requestHistory };

        if (
            name ===
            RequestHistoryConstants.REQUEST_HISTORY_SETTINGS_PAYLOAD.IS_USER_ALLOWED_TO_VIEW_REQUEST_HISTORICAL_REPORT
        ) {
            updatedRequestHistory = {
                ...updatedRequestHistory,
                isUserAllowedToViewDocumentRequestHistoricalReport: value,
            };

            if (!value) {
                updatedRequestHistory = {
                    ...updatedRequestHistory,
                    isUserAllowedToViewDocumentRequestSentByAllUsers: false,
                };
            }
        } else if (
            name ===
            RequestHistoryConstants.REQUEST_HISTORY_SETTINGS_PAYLOAD
                .IS_USER_ALLOWED_TO_VIEW_REQUEST_HISTORY_REPORT_SENT_BY_ALL_USERS
        ) {
            updatedRequestHistory = {
                ...updatedRequestHistory,
                isUserAllowedToViewDocumentRequestSentByAllUsers: value,
            };
        }

        setRequestHistory(updatedRequestHistory);

        const updateRequestHistorySettingsPayload: IRequestHistorySettingsValues = updatedRequestHistory;

        dispatch(
            updateAdminSettings(CompanySettingsName.RequestHistorySettings, updateRequestHistorySettingsPayload, () =>
                AppNotifier.Success(ToasterMessages.SUCCESS.APPLY_ADMIN_CHANGES)
            )
        );
    };
    return (
        <LoadingOverlay>
            <header>
                <p>{RequestHistoryConstants.REQUEST_HISTORY_HELP_TEXT}</p>
            </header>
            <div className="settings-label-toggle-wrapper">
                <CustomToggleSwitch
                    label={RequestHistoryConstants.REQUEST_HISTORY_VIEW_DOCUMENT_REQUEST_HISTORY_TOGGLE_TEXT}
                    name={
                        RequestHistoryConstants.REQUEST_HISTORY_SETTINGS_PAYLOAD
                            .IS_USER_ALLOWED_TO_VIEW_REQUEST_HISTORICAL_REPORT
                    }
                    handleChange={handleToggleSwitchChange}
                    switched={requestHistory.isUserAllowedToViewDocumentRequestHistoricalReport}
                />
            </div>
            {requestHistory?.isUserAllowedToViewDocumentRequestHistoricalReport && (
                <div className="settings-label-toggle-wrapper sub-list-settings-item">
                    <Checkbox
                        id={"checkbox-received-item"}
                        value={
                            RequestHistoryConstants.REQUEST_HISTORY_SETTINGS_PAYLOAD
                                .IS_USER_ALLOWED_TO_VIEW_REQUEST_HISTORY_REPORT_SENT_BY_ALL_USERS
                        }
                        className="primary-checkbox"
                        text={RequestHistoryConstants.REQUEST_HISTORY_VIEW_DR_SENT_BY_ALL_USERS_TEXT}
                        size={CheckBoxSize.sm}
                        checked={requestHistory.isUserAllowedToViewDocumentRequestSentByAllUsers}
                        onChange={e => {
                            handleToggleSwitchChange(
                                e,
                                RequestHistoryConstants.REQUEST_HISTORY_SETTINGS_PAYLOAD
                                    .IS_USER_ALLOWED_TO_VIEW_REQUEST_HISTORY_REPORT_SENT_BY_ALL_USERS
                            );
                        }}
                    />
                </div>
            )}
            <Loader loading={isLoading} />
        </LoadingOverlay>
    );
};

export default RequestHistory;
