import axios, { AxiosResponse } from "axios";
import fileDownload from "js-file-download";

import { actionTypes } from "../types/folderTypes";
import { actionTypes as notificationAction } from "../types/notificationTypes";
import { AppThunkAction } from "./../store/index";
import {
    IDownloadNowPayload,
    IFolderModel,
    IFolderPermissionViewModel,
    IMyDownloadFile,
    ISharedFolderGroupPermissionDetailViewModel,
    ISharedFolderPermissionDetailViewModel,
    ISharedFolderUserModel,
} from "../core/viewModels/folder/FolderModel";
import { NotificationAction, StatusType } from "../reducers/notificationReducer";
import { ArchiveFolderType, FolderColumnName, SortOrder } from "../core/common/enums";
import { getMailboxUserEmailId } from "../helper/HelperFunctions";
import {
    CompletedAddNewFolderAction,
    CompletedCheckFolderMessagesAction,
    CompletedDeleteFolder,
    CompletedDeleteFolders,
    CompletedFoldersAction,
    CompletedFoldersTreeAction,
    CompletedMessageFileViewData,
    CompletedMoveFolders,
    CompleteDownloadNow,
    CompleteDownloadLater,
    CompletedRetentionPeriodsAction,
    CompletedSubFoldersAction,
    CompletedUpdateFolderExpiry,
    CompletedUpdateFolderName,
    CompletedUpdateFoldersExpiry,
    ReceivedFoldersAction,
    ReceivedFoldersTreeAction,
    ReceiveDownloadNow,
    ReceiveDownloadLater,
    ReceivedRetentionPeriodsAction,
    ReceivedSubFoldersAction,
    ReceiveMessageFileViewData,
    ReceiveUpdateFolderName,
    RequestAddNewFolderAction,
    RequestCheckFolderMessagesAction,
    RequestDeleteFolder,
    RequestDeleteFolders,
    RequestDownloadNow,
    RequestDownloadLater,
    RequestFoldersAction,
    RequestFoldersTreeAction,
    RequestMessageFileViewData,
    RequestMoveFolders,
    RequestRetentionPeriodsAction,
    RequestSubFoldersAction,
    RequestUpdateFolderExpiry,
    RequestUpdateFolderName,
    RequestUpdateFoldersExpiry,
    ResetMessageFileViewData,
    SetResetBreadcrumb,
    RequestMyDownloadsList,
    ReceiveMyDownloadsList,
    CompleteMyDownloadsList,
    RequestDeleteMyDownloadFile,
    ReceiveDeleteMyDownloadFile,
    CompleteDeleteMyDownloadFile,
    RequestDownloadLaterLink,
    ReceiveDownloadLaterLink,
    CompleteDownloadLaterLink,
    ReceiveShareFolder,
    RequestShareFolder,
    CompleteShareFolder,
    RequestFolderBreadcrumbAction,
    ReceivedFolderBreadcrumbAction,
    CompletedFolderBreadcrumbAction,
    RequestFolderMaxRetentionAction,
    ReceivedFolderMaxRetentionAction,
    CompletedFolderMaxRetentionAction,
    RequestFolderDropOffLinkAction,
    ReceiveFolderDropOffLinkAction,
    CompleteFolderDropOffLinkAction,
    RequestFolderIsExistAction,
    ReceiveFolderIsExistAction,
    CompleteFolderIsExistAction,
    RequestFolderDetailAction,
    ReceiveFolderDetailAction,
    CompleteFolderDetailAction,
    RequestUserOwnedFoldersAction,
    ReceivedUserOwnedFoldersAction,
    CompletedUserOwnedFoldersAction,
    RequestUsersToShareFolderAction,
    ReceivedUsersToShareFolderAction,
    CompletedUsersToShareFolderAction,
    RequestFolderDetailsWithPermissionAction,
    ReceiveFolderDetailsWithPermissionAction,
    CompleteFolderDetailsWithPermissionAction,
} from "../actions/folderActions";
import { IMessageFileViewModel } from "../core/viewModels/message/MessageFileViewModel";
import { appInsightsData } from "../helper/AppInsightsData";
import { logger } from "../oidcClient/authProvider";
import { AppNotifier } from "../helper/AppNotifier";
import { ReportDownloadContansts } from "../helper/Constants";
import { DownloadedFrom } from "../core/viewModels/file/FileModel";
import moment from "moment";
import { ToasterMessages } from "../helper/ToasterMessages";

export type DispatchActions =
    | RequestFoldersAction
    | ReceivedFoldersAction
    | CompletedFoldersAction
    | RequestFoldersTreeAction
    | ReceivedFoldersTreeAction
    | CompletedFoldersTreeAction
    | RequestRetentionPeriodsAction
    | ReceivedRetentionPeriodsAction
    | CompletedRetentionPeriodsAction
    | RequestUpdateFolderExpiry
    | CompletedUpdateFolderExpiry
    | RequestAddNewFolderAction
    | CompletedAddNewFolderAction
    | RequestDeleteFolder
    | CompletedDeleteFolder
    | RequestSubFoldersAction
    | ReceivedSubFoldersAction
    | CompletedSubFoldersAction
    | RequestDeleteFolders
    | CompletedDeleteFolders
    | RequestUpdateFoldersExpiry
    | CompletedUpdateFoldersExpiry
    | RequestMoveFolders
    | CompletedMoveFolders
    | RequestCheckFolderMessagesAction
    | CompletedCheckFolderMessagesAction
    | RequestUpdateFolderName
    | CompletedUpdateFolderName
    | ReceiveUpdateFolderName
    | RequestMessageFileViewData
    | ReceiveMessageFileViewData
    | CompletedMessageFileViewData
    | ResetMessageFileViewData
    | RequestDownloadNow
    | ReceiveDownloadNow
    | CompleteDownloadNow
    | SetResetBreadcrumb
    | RequestDownloadLater
    | ReceiveDownloadLater
    | CompleteDownloadLater
    | RequestMyDownloadsList
    | ReceiveMyDownloadsList
    | CompleteMyDownloadsList
    | RequestDeleteMyDownloadFile
    | ReceiveDeleteMyDownloadFile
    | CompleteDeleteMyDownloadFile
    | RequestDownloadLaterLink
    | ReceiveDownloadLaterLink
    | CompleteDownloadLaterLink
    | ReceiveShareFolder
    | RequestShareFolder
    | CompleteShareFolder
    | RequestFolderBreadcrumbAction
    | ReceivedFolderBreadcrumbAction
    | CompletedFolderBreadcrumbAction
    | RequestFolderMaxRetentionAction
    | ReceivedFolderMaxRetentionAction
    | CompletedFolderMaxRetentionAction
    | RequestFolderDropOffLinkAction
    | ReceiveFolderDropOffLinkAction
    | CompleteFolderDropOffLinkAction
    | RequestFolderIsExistAction
    | ReceiveFolderIsExistAction
    | CompleteFolderIsExistAction
    | RequestFolderDetailAction
    | ReceiveFolderDetailAction
    | CompleteFolderDetailAction
    | RequestFolderDetailsWithPermissionAction
    | ReceiveFolderDetailsWithPermissionAction
    | CompleteFolderDetailsWithPermissionAction
    | RequestUserOwnedFoldersAction
    | ReceivedUserOwnedFoldersAction
    | CompletedUserOwnedFoldersAction
    | RequestUsersToShareFolderAction
    | ReceivedUsersToShareFolderAction
    | CompletedUsersToShareFolderAction;

type AllKnownActions = DispatchActions | NotificationAction;

export const fetchFolders =
    (folderTypes: ArchiveFolderType[], callback?: any): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_FOLDERS,
        });
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/Folder/GetFolders?mailboxUserEmailId=${getMailboxUserEmailId()}`,
                {
                    params: { folderTypes },
                }
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVED_FOLDERS,
                    folders: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                dispatch({
                    type: actionTypes.COMPLETED_FOLDERS,
                });
            });
    };

export const fetchFoldersTree =
    (
        folderTypes: ArchiveFolderType[],
        folderIds: number[],
        callback?: (data: IFolderModel[]) => void
    ): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_FOLDERS_TREE,
        });
        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/Folder/GetFoldersTree?mailboxUserEmailId=${getMailboxUserEmailId()}`,
                {
                    params: {
                        folderTypes,
                        folderIds,
                    },
                }
            )
            .then((response: AxiosResponse) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVED_FOLDERS_TREE,
                    folders: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETED_FOLDERS_TREE,
                });
            });
    };

export const fetchBreadcrumbs =
    (folderId: number, callback?: (result: IFolderModel[]) => void): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_FOLDER_BREADCRUMB,
            folderId: folderId,
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/Folder/Breadcrumb/${folderId}`)
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_FOLDER_BREADCRUMB,
                    folders: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_FOLDER_BREADCRUMB,
                });
            });
    };

export const fetchMaxRetentionPeriodLimit =
    (folderId: number, callback?: (result: number) => void): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_FOLDER_MAX_RETENTION,
            folderId: folderId,
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/Folder/MaxRetentionPeriodLimit/${folderId}`)
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_FOLDER_MAX_RETENTION,
                    maxRetention: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_FOLDER_MAX_RETENTION,
                });
            });
    };

export const fetchFolderDropOffLink =
    (folderId: number, callback?: (result: string) => void): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_FOLDER_DROPOFF_LINK,
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/Folder/DropOffLink/${folderId}`)
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_FOLDER_DROPOFF_LINK,
                    dropOffUrl: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_FOLDER_DROPOFF_LINK,
                });
            });
    };

export const fetchFolderDetail =
    (folderId: number, callback?: (data: IFolderModel) => void): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_FOLDER_DETAIL,
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/Folder/GetFolderDetail/${folderId}`)
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_FOLDER_DETAIL,
                    data: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_FOLDER_DETAIL,
                });
            });
    };

export const fetchFolderDetailsWithPermission =
    (folderId: number, callback?: (data: IFolderModel) => void): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_FOLDER_DETAILS_WITH_PERMISSION,
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/Folder/GetFolderDetailsWithPermission/${folderId}`)
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_FOLDER_DETAILS_WITH_PERMISSION,
                    data: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_FOLDER_DETAILS_WITH_PERMISSION,
                });
            });
    };

export const isFolderExist =
    (folderId: number, callback?: (result: boolean) => void): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_FOLDER_IS_EXIST,
        });
        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/Folder/IsFolderExist/${folderId}?mailboxUserEmailId=${getMailboxUserEmailId()}`
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_FOLDER_IS_EXIST,
                    isExist: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_FOLDER_IS_EXIST,
                });
            });
    };

export const fetchSubFolders =
    (
        folderId: number,
        sortColumn: FolderColumnName,
        sortOrder: SortOrder,
        callback?: any,
        searchText = ""
    ): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_SUB_FOLDERS,
            folderId: folderId,
        });
        searchText = searchText.trim();
        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/Folder/GetSubFolders?mailboxUserEmailId=${getMailboxUserEmailId()}&parentFolderId=${folderId}&sortColumn=${sortColumn}&sortOrder=${sortOrder}&searchText=${encodeURIComponent(
                    searchText
                )}`
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVED_SUB_FOLDERS,
                    folders: data,
                    folderId: folderId,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETED_SUB_FOLDERS,
                    folderId: folderId,
                });
            });
    };

export const fetchRetentionPeriods =
    (callback?: (data: any) => void): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_RETENTION_PERIODS,
        });
        axios
            .get(`${process.env.REACT_APP_API_URL}/api/Folder/GetRetentionPeriodList`)
            .then((response: any) => {
                const data = response.data;

                dispatch({
                    type: actionTypes.RECEIVED_RETENTION_PERIODS,
                    data: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                dispatch({
                    type: actionTypes.COMPLETED_FOLDERS,
                });
            });
    };

export const updateFoldersRetentionPeriod =
    (
        folderIds: number[],
        expiresDays: number,
        callback?: (result: any) => void,
        resourceId?: string
    ): AppThunkAction<AllKnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        dispatch({
            type: actionTypes.REQUEST_UPDATE_FOLDERS_EXPIRY,
        });
        folderIds.map(folderId => {
            logger.trackEvent({
                name: `${appInsightsData.Folders.MyFiles.PageTitle} - ${appInsightsData.Folders.MyFiles.Operations.updateFolderRetention}`,
                properties: {
                    folderId,
                    expiresDays,
                },
            });
        });
        axios
            .put(`${process.env.REACT_APP_API_URL}/api/Folder/UpdateFoldersRetention`, folderIds, {
                params: {
                    expiresDays,
                    mailboxUserEmailId,
                },
                headers: {
                    "X-Resource-Id": resourceId ?? "",
                },
            })
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.COMPLETED_UPDATE_FOLDERS_EXPIRY,
                    folderIds: folderIds,
                    result: true,
                    expiresDays: expiresDays,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                dispatch({
                    type: actionTypes.COMPLETED_UPDATE_FOLDERS_EXPIRY,
                    folderIds: folderIds,
                    result: false,
                    expiresDays: expiresDays,
                });
            });
    };

export const updateFolderName =
    (folder: IFolderModel, callback?: (result: any) => void, resourceId?: string): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_UPDATE_FOLDER_NAME,
        });
        logger.trackEvent({
            name: `${appInsightsData.Folders.MyFiles.PageTitle} - ${appInsightsData.Folders.MyFiles.Operations.updateFolderName}`,
            properties: {
                ...folder,
            },
        });
        axios
            .put(
                `${
                    process.env.REACT_APP_API_URL
                }/api/Folder/UpdateFolder?mailboxUserEmailId=${getMailboxUserEmailId()}`,
                folder,
                {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.COMPLETED_UPDATE_FOLDER_NAME,
                });
                dispatch({
                    type: actionTypes.RECEIVE_UPDATE_FOLDER_NAME,
                    payload: folder,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.response.data.message,
                    statusType: StatusType.Error,
                });
                dispatch({
                    type: actionTypes.COMPLETED_UPDATE_FOLDER_NAME,
                });
            });
    };

export const addNewFolder =
    (folder: IFolderModel, callback?: any): AppThunkAction<AllKnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        dispatch({
            type: actionTypes.REQUEST_ADD_NEW_FOLDER,
        });
        logger.trackEvent({
            name: `${appInsightsData.Folders.MyFiles.PageTitle} - ${appInsightsData.Folders.MyFiles.Operations.addNewFolder}`,
            properties: {
                ...folder,
            },
        });
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/Folder/AddNewFolder`, folder, {
                params: { mailboxUserEmailId },
            })
            .then((response: any) => {
                const data = response.data;
                folder.folderId = data;
                dispatch({
                    type: actionTypes.COMPLETED_ADD_NEW_FOLDER,
                    folder: folder,
                });
                if (callback) {
                    callback(folder);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.response.data.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const deleteFolders =
    (folderIds: number[], callback: (result: boolean) => void, resourceId?: string): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_DELETE_FOLDERS,
        });
        logger.trackEvent({
            name: `${appInsightsData.Folders.MyFiles.PageTitle} - ${appInsightsData.Folders.MyFiles.Operations.DeleteFolder}`,
            properties: {
                page: appInsightsData.Folders.MyFiles.PageTitle,
                folderIds,
            },
        });
        axios
            .delete(
                `${
                    process.env.REACT_APP_API_URL
                }/api/Folder/DeleteFolders?mailboxUserEmailId=${getMailboxUserEmailId()}`,
                {
                    data: folderIds,
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then(() => {
                dispatch({
                    type: actionTypes.COMPLETED_DELETE_FOLDERS,
                    folderIds: folderIds,
                    result: true,
                });
                if (callback) {
                    callback(true);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                dispatch({
                    type: actionTypes.COMPLETED_DELETE_FOLDERS,
                    folderIds: folderIds,
                    result: false,
                });
            });
    };

export const moveFolders =
    (
        folderIds: number[],
        sourceFolders: IFolderModel[],
        destinationFolder: IFolderModel,
        callback?: (result: any) => void,
        resourceId?: string
    ): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_MOVE_FOLDERS,
        });
        folderIds.map(folderId => {
            logger.trackEvent({
                name: `${appInsightsData.Folders.MyFiles.PageTitle} - ${appInsightsData.MailBox.CommonOperations.MoveTo}`,
                properties: {
                    page: appInsightsData.Folders.MyFiles.PageTitle,
                    destinationFolder,
                    folderId,
                    ...sourceFolders,
                },
            });
        });
        axios
            .put(
                `${process.env.REACT_APP_API_URL}/api/Folder/MoveFolders?mailboxUserEmailId=${getMailboxUserEmailId()}`,
                {
                    destinationFolderModel: destinationFolder,
                    sourceFolders: sourceFolders,
                },
                {
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then(() => {
                dispatch({
                    type: actionTypes.COMPLETED_MOVE_FOLDERS,
                    folderIds: folderIds,
                    result: true,
                });
                if (callback) {
                    callback(true);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.response.data.message,
                    statusType: StatusType.Error,
                });
                dispatch({
                    type: actionTypes.COMPLETED_MOVE_FOLDERS,
                    folderIds: folderIds,
                    result: false,
                });
                if (callback) {
                    callback(false);
                }
            });
    };

export const fetchMessageFileViewData =
    (
        folderId: number,
        pageNo: number,
        pageSize: number,
        mailboxUserEmailId: string,
        callback?: (data: IMessageFileViewModel[]) => void
    ): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_MESSAGE_FILE_VIEW_DATA,
        });
        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/ReceivedMessage/Files?mailboxUserEmailId=${getMailboxUserEmailId()}`,
                {
                    params: {
                        folderId,
                        pageNo,
                        pageSize,
                        mailboxUserEmailId,
                    },
                }
            )
            .then((response: AxiosResponse) => {
                const data = response.data;

                dispatch({
                    type: actionTypes.RECEIVE_MESSAGE_FILE_VIEW_DATA,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETED_MESSAGE_FILE_VIEW_DATA,
                });
            });
    };

export const fetchSharedFolderMessageFileViewData =
    (
        folderId: number,
        pageNo: number,
        pageSize: number,
        mailboxUserEmailId: string,
        searchText?: string,
        callback?: (data: IMessageFileViewModel[]) => void
    ): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_MESSAGE_FILE_VIEW_DATA,
        });
        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/ReceivedMessage/SharedFolderFiles?mailboxUserEmailId=${getMailboxUserEmailId()}`,
                {
                    params: {
                        folderId,
                        pageNo,
                        pageSize,
                        mailboxUserEmailId,
                        searchText,
                    },
                }
            )
            .then((response: AxiosResponse) => {
                const data = response.data;

                dispatch({
                    type: actionTypes.RECEIVE_MESSAGE_FILE_VIEW_DATA,
                    payload: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETED_MESSAGE_FILE_VIEW_DATA,
                });
            });
    };

export const requestDownloadNow =
    (payload: IDownloadNowPayload[], callback: () => void, resourceId?: string): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.REQUEST_DOWNLOAD_NOW });
        AppNotifier.Warning(ToasterMessages.WARNING.DOWNLOAD_PROGRESS);
        const date = moment().format(ReportDownloadContansts.DATE_FORMAT_FILE_NAME);
        const filename = `${ReportDownloadContansts.MULTIPLE_ATTACHMENT_FILE_NAME}${date}`;
        axios
            .post(
                `${
                    process.env.REACT_APP_API_URL
                }/api/Download/Files/Multiple?mailboxUserEmailId=${getMailboxUserEmailId()}&downloadedFrom=${
                    DownloadedFrom.MPI_Download_Inbox_Multiple_File
                }&friendlyFileNameForZip=${filename}`,
                payload,
                {
                    responseType: "blob",
                    headers: {
                        "X-Resource-Id": resourceId ?? "",
                    },
                }
            )
            .then((response: AxiosResponse) => {
                dispatch({ type: actionTypes.RECEIVE_DOWNLOAD_NOW });
                const data = response.data;
                const date = moment().format(ReportDownloadContansts.DATE_FORMAT_FILE_NAME);
                const filename = `${ReportDownloadContansts.MULTIPLE_ATTACHMENT_FILE_NAME}${date}`;
                const fileExtension = "zip";
                fileDownload(data, `${filename}.${fileExtension}`);
                callback();
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_DOWNLOAD_NOW,
                });
            });
    };

export const requestDownloadLater =
    (payload: IDownloadNowPayload[], parentFolder: string, callback: () => void): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.REQUEST_DOWNLOAD_LATER });
        axios
            .post(
                `${
                    process.env.REACT_APP_API_URL
                }/api/Download/Files/MyDownloads?mailboxUserEmailId=${getMailboxUserEmailId()}&parentFolder=${encodeURIComponent(
                    parentFolder
                )}&downloadedFrom=${
                    DownloadedFrom.MPI_Download_Inbox_Multiple_File
                }&createdOn=${moment().toISOString()}&offset=${moment().utcOffset()}`,
                payload
            )
            .then(() => {
                AppNotifier.Success(ToasterMessages.SUCCESS.DOWNLOAD_LATER);
                dispatch({ type: actionTypes.RECEIVE_DOWNLOAD_LATER });
                callback();
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_DOWNLOAD_LATER,
                });
            });
    };

export const requestMyDownloadsList =
    (pageNumber: number, pageSize: number): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({ type: actionTypes.REQUEST_MY_DOWNLOADS_LIST });
        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/Download/Files/MyDownloads?mailboxUserEmailId=${getMailboxUserEmailId()}&pageNumber=${pageNumber}&pageSize=${pageSize}`
            )
            .then((response: AxiosResponse) => {
                dispatch({
                    type: actionTypes.RECEIVE_MY_DOWNLOADS_LIST,
                    payload: response.data,
                });
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_MY_DOWNLOADS_LIST,
                });
            });
    };

export const requestDeleteMyDownloadFile =
    (payload: IMyDownloadFile, callback: () => void): AppThunkAction<AllKnownActions> =>
    dispatch => {
        const { myDownloadsId, fileGuid, filePath } = payload;
        dispatch({ type: actionTypes.REQUEST_DELETE_MY_DOWNLOAD_FILE });
        axios
            .delete(
                `${
                    process.env.REACT_APP_API_URL
                }/api/Download/MyDownloads?mailboxUserEmailId=${getMailboxUserEmailId()}`,
                {
                    params: {
                        myDownloadsId,
                        fileGuid,
                        filePath,
                    },
                }
            )
            .then(() => {
                callback();
                AppNotifier.Success(ToasterMessages.SUCCESS.DELETE_FILE_DETAILED);
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_DELETE_MY_DOWNLOAD_FILE,
                });
            });
    };

export const requestDownloadLaterLink =
    (payload: IMyDownloadFile): AppThunkAction<AllKnownActions> =>
    dispatch => {
        const { fileName, filePath, fileGuid, myDownloadsId } = payload;
        dispatch({ type: actionTypes.REQUEST_DOWNLOAD_LATER_LINK });
        AppNotifier.Warning(ToasterMessages.WARNING.DOWNLOAD_PROGRESS);
        const fileNameArray = fileName.split("_");
        let updatedFileName = "";
        fileNameArray.forEach((name, index) => {
           updatedFileName += name?.trim() + (index === fileNameArray.length - 1 ? "" : "_");
        });

        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/Download/File/MyDownloads/Link?mailboxUserEmailId=${getMailboxUserEmailId()}`,
                {
                    params: {
                        fileName: updatedFileName,
                        filePath,
                        fileGuid,
                        myDownloadsId,
                    },
                }
            )
            .then((response: AxiosResponse) => {
                if (response.data) {
                    window.open(response.data, "_blank");
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_DOWNLOAD_LATER_LINK,
                });
            });
    };

export const fetchShareFolderRecipients =
    (
        searchString: string,
        folderId: number,
        callback?: (data: ISharedFolderUserModel[]) => void
    ): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_USERS_TO_SHARE,
        });
        const mailboxUserEmailId = getMailboxUserEmailId();

        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/SharedFolder/GetUsers?mailboxUserEmailId=${mailboxUserEmailId}&folderId=${folderId}&searchString=${searchString.trim()}`
            )
            .then(response => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_USERS_TO_SHARE,
                    data: data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: actionTypes.COMPLETE_USERS_TO_SHARE,
                });
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            });
    };

export const fetchSharedFolderUsers =
    (
        folderId: number,
        callback?: (data: {
            users: ISharedFolderPermissionDetailViewModel[];
            groups: ISharedFolderGroupPermissionDetailViewModel[];
        }) => void
    ): AppThunkAction<AllKnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        dispatch({
            type: actionTypes.REQUEST_SHARE_FOLDER,
        });
        axios
            .get(
                `${process.env.REACT_APP_API_URL}/api/SharedFolder/GetAllUserOfSharedFolder?mailboxUserEmailId=${mailboxUserEmailId}&folderId=${folderId}`
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_SHARE_FOLDER,
                    sharedUsersData: data,
                });

                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.response.data.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_SHARE_FOLDER,
                });
            });
    };

export const shareFolderToUsers =
    (folderShareInformation: IFolderPermissionViewModel, callback?: any): AppThunkAction<AllKnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        logger.trackEvent({
            name: `${appInsightsData.Folders.MyFiles.PageTitle} - ${appInsightsData.Folders.MyFiles.Operations.shareFolder}`,
            properties: {
                page: appInsightsData.Folders.MyFiles.PageTitle,
                folderShareInformation,
            },
        });
        dispatch({
            type: actionTypes.REQUEST_SHARE_FOLDER,
        });

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/SharedFolder/AddFolderPermission`, folderShareInformation, {
                params: { mailboxUserEmailId },
            })
            .then((response: any) => {
                const data = response.data;
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.response.data.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_SHARE_FOLDER,
                });
            });
    };

export const updateSharedFolderUsers =
    (
        folderShareInformation: IFolderPermissionViewModel,
        callback?: (status: number) => void
    ): AppThunkAction<AllKnownActions> =>
    dispatch => {
        const mailboxUserEmailId = getMailboxUserEmailId();
        logger.trackEvent({
            name: `${appInsightsData.Folders.MyFiles.PageTitle} - ${appInsightsData.Folders.MyFiles.Operations.updateSharedFolderUserPermission}`,
            properties: {
                page: appInsightsData.Folders.MyFiles.PageTitle,
                folderShareInformation,
            },
        });
        dispatch({
            type: actionTypes.REQUEST_ADD_NEW_FOLDER,
        });

        axios
            .put(`${process.env.REACT_APP_API_URL}/api/SharedFolder/UpdateFolderPermission`, folderShareInformation, {
                params: { mailboxUserEmailId },
            })
            .then((response: AxiosResponse) => {
                const data = response;

                if (callback) {
                    callback(data.status);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.response.data.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_SHARE_FOLDER,
                });
            });
    };

export const removeSharedFolderUsers =
    (
        folderShareInformation: IFolderPermissionViewModel,
        callback?: (status: number) => void
    ): AppThunkAction<AllKnownActions> =>
    dispatch => {
        logger.trackEvent({
            name: `${appInsightsData.Folders.MyFiles.PageTitle} - ${appInsightsData.Folders.MyFiles.Operations.removeSharedFolderUser}`,
            properties: {
                page: appInsightsData.Folders.MyFiles.PageTitle,
                folderShareInformation,
            },
        });
        dispatch({
            type: actionTypes.REQUEST_SHARE_FOLDER,
        });

        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/SharedFolder/DeleteFolderPermission`, {
                params: { mailboxUserEmailId: getMailboxUserEmailId() },
                data: folderShareInformation,
            })
            .then((response: AxiosResponse) => {
                const data = response;
                if (callback) {
                    callback(data.status);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.response.data.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: actionTypes.COMPLETE_SHARE_FOLDER,
                });
            });
    };

export const fetchUserOwnedFolders =
    (callback?: any): AppThunkAction<AllKnownActions> =>
    dispatch => {
        dispatch({
            type: actionTypes.REQUEST_USEROWNED_FOLDERS,
        });
        axios
            .get(
                `${
                    process.env.REACT_APP_API_URL
                }/api/Folder/GetUserOwnedFolders?mailboxUserEmailId=${getMailboxUserEmailId()}`
            )
            .then((response: any) => {
                const data = response.data;
                dispatch({
                    type: actionTypes.RECEIVE_USEROWNED_FOLDERS,
                    data,
                });
                if (callback) {
                    callback(data);
                }
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
                dispatch({
                    type: actionTypes.COMPLETE_USEROWNED_FOLDERS,
                });
            });
    };
