import React, { useState } from "react";
import { useEffect } from "react";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { useDispatch, useSelector } from "react-redux";
import { fetchMessageSecurityQuestionSettings } from "../../../actionCreators/adminSettingsActionCreators";
import {
    deleteMessageSecurityQuestions,
    fetchMessageSecurityQuestions,
} from "../../../actionCreators/securityQuestionCreators";
import { updateUserSettings } from "../../../actionCreators/userSettingsActionCreators";
import { SettingMode } from "../../../core/common/enums";
import { IMessageSecurityQuestion } from "../../../core/viewModels/message/MessageSecurityQuestionModel";
import { AppNotifier } from "../../../helper/AppNotifier";
import { CompanyAuthQuestionsConstants } from "../../../helper/Constants";
import { ApplicationState } from "../../../store";
import { AddQuestionModal, initialAddQuestionState } from "../../common/ui/Modals/SettingsModal/AddQuestionModal";
import { CustomModal } from "../../common/ui/Modals/CustomModal";
import AuthQuestionsTable from "../../pages/adminSettings/tabs/General/AuthQuestionsTable";
import { ToasterMessages } from "../../../helper/ToasterMessages";

export interface ICompanyAuthQuestionsState {
    questions: IMessageSecurityQuestion[];
    defaultSecurityQuestionId: number;
}

export const initialValidationMessages = {
    invalidQuestionMessage: "",
    invalidQuestionTitleMessage: "",
};

export const AuthenticationQuestions: React.FC = () => {
    const dispatch = useDispatch();
    const securityQuestionStore = useSelector((state: ApplicationState) => state.securityQuestionState);
    const adminSettingsStore = useSelector((state: ApplicationState) => state.adminSettingsState);
    const { userSettings } = useSelector((state: ApplicationState) => state.userSettingsState);

    const { messageSecuritySettings } = adminSettingsStore;
    const storeQuestions = securityQuestionStore.securityQuestions.data;
    const [authQuestionsState, setAuthQuestionsState] = useState<ICompanyAuthQuestionsState>({
        questions: storeQuestions,
        defaultSecurityQuestionId: 0,
    });

    const [showAddQuestionsModal, setShowAddQuestionsModal] = useState(false);
    const [showEditQuestionModal, setShowEditQuestionModal] = useState(false);
    const [showDeleteQuestionModal, setShowDeleteQuestionModal] = useState(false);
    const [selectedDeleteOption, setSelectedDeleteOption] = useState("");
    const [selectedQuestions, setSelectedQuestions] = useState<IMessageSecurityQuestion[]>([]);
    const [selectedQuestionsPayload, setSelectedQuestionsPayload] = useState<IMessageSecurityQuestion[]>([]);
    const [reload, setReload] = useState(false);
    useEffect(() => {
        dispatch(fetchMessageSecurityQuestionSettings());
        dispatch(fetchMessageSecurityQuestions(SettingMode.BOTH));
    }, [reload]);

    useEffect(() => {
        const { defaultSecurityQuestionId } = { ...messageSecuritySettings };
        if (storeQuestions.length > 0) {
            setAuthQuestionsState({
                ...authQuestionsState,
                questions: storeQuestions,
                defaultSecurityQuestionId,
            });
        }
    }, [
        messageSecuritySettings,
        authQuestionsState.questions,
        authQuestionsState.defaultSecurityQuestionId,
        storeQuestions,
    ]);

    const handleDeleteQuestionOnSubmit = () => {
        //Question deletion dispatch calls
        const deleteQuestionsPayload = selectedQuestionsPayload.map(question => question.messageSecurityQuestionId);
        dispatch(
            deleteMessageSecurityQuestions(deleteQuestionsPayload, (result: number, ids: number[]) => {
                if (result) {
                    AppNotifier.Success(
                        ids.length > 1
                            ? ToasterMessages.SUCCESS.DELETE_QUESTIONS
                            : ToasterMessages.SUCCESS.DELETE_QUESTION
                    );
                    setReload(reload => !reload);
                }
            })
        );
        setSelectedQuestions([]);
        setShowDeleteQuestionModal(false);
    };

    const handleEditQuestionClick = (selectedQuestion: IMessageSecurityQuestion) => {
        if (selectedQuestion) {
            setSelectedQuestionsPayload([selectedQuestion]);
            setShowEditQuestionModal(true);
        }
    };

    const handleSetDefaultClick = (selectedQuestion: IMessageSecurityQuestion) => {
        if (!selectedQuestion.isDefault) {
            // const previousDefaultQuestion = authQuestionsState.questions.findIndex((item) => item.messageSecurityQuestionId === authQuestionsState.defaultSecurityQuestionId);

            setAuthQuestionsState({
                ...authQuestionsState,
                defaultSecurityQuestionId: selectedQuestion.messageSecurityQuestionId,
            });

            // update default question dispatch call
            if (userSettings) {
                const updateUserSettingsPayload = { ...userSettings };
                updateUserSettingsPayload.messageSecurityQuestionSettings = {
                    defaultSecurityQuestionId: selectedQuestion.messageSecurityQuestionId,
                };
                dispatch(
                    updateUserSettings(updateUserSettingsPayload, () => {
                        setReload(reload => !reload);
                    })
                );
            }
            setSelectedQuestions([]);
        }
    };

    const handleDeleteQuestionClick = (selectedQuestions: IMessageSecurityQuestion[]) => {
        if (selectedQuestions.length > 0) {
            setSelectedQuestionsPayload(selectedQuestions);
            setShowDeleteQuestionModal(true);
        }
    };

    const clearModalState = () => {
        setShowAddQuestionsModal(false);
        setShowEditQuestionModal(false);
        setSelectedQuestions([]);
        setSelectedQuestionsPayload([]);
        setShowDeleteQuestionModal(false);
    };

    return (
        <LoadingOverlay>
            <>
                <header>
                    <h3>{CompanyAuthQuestionsConstants.TITLE_TEXT}</h3>
                    <span>{CompanyAuthQuestionsConstants.TITLE_HELPER_TEXT}</span>
                </header>

                <div className="authentication-questions-table-container">
                    <AuthQuestionsTable
                        isContentLoading={false}
                        data={authQuestionsState.questions}
                        fromUserSettings={true}
                        selectedQuestions={selectedQuestions}
                        updateSelectedQuestions={setSelectedQuestions}
                        handleEditQuestionClick={handleEditQuestionClick}
                        setShowAddQuestionsModal={value => {
                            setShowAddQuestionsModal(value);
                        }}
                        handleSetDefaultClick={handleSetDefaultClick}
                        handleDeleteQuestionClick={handleDeleteQuestionClick}
                        setSelectedDeleteOption={setSelectedDeleteOption}
                        hideAddNewQuestionButton={!messageSecuritySettings.isUserAllowedToAddSecurityQuestion}
                    />
                </div>

                {showAddQuestionsModal && (
                    <AddQuestionModal
                        isUserSecurityQuestion={true}
                        modalType="Add"
                        showModal={showAddQuestionsModal}
                        hideModal={clearModalState}
                        initialData={initialAddQuestionState}
                        isProcessing={securityQuestionStore.companySecurityQuestions.isProcessing}
                        setReload={() => setReload(!reload)}
                        clearModalState={clearModalState}
                    />
                )}

                {showEditQuestionModal && (
                    <AddQuestionModal
                        isUserSecurityQuestion={true}
                        modalType="Edit"
                        showModal={showEditQuestionModal}
                        hideModal={clearModalState}
                        initialData={selectedQuestionsPayload[0]}
                        isProcessing={securityQuestionStore.companySecurityQuestions.isLoading}
                        setReload={() => setReload(!reload)}
                        clearModalState={clearModalState}
                    />
                )}

                {showDeleteQuestionModal && (
                    <CustomModal
                        className="delete-question-modal"
                        isConfirmModal={true}
                        show={showDeleteQuestionModal}
                        onHide={() => {
                            clearModalState();
                        }}
                        onSubmit={handleDeleteQuestionOnSubmit}
                        cancelButtonName="Cancel"
                        confirmButtonName="Ok"
                        title={
                            selectedDeleteOption === "headerDelete"
                                ? CompanyAuthQuestionsConstants.DELETE_QUESTIONS_MODAL_TITLE
                                : CompanyAuthQuestionsConstants.DELETE_QUESTION_MODAL_TITLE
                        }
                    >
                        {selectedDeleteOption === "headerDelete"
                            ? CompanyAuthQuestionsConstants.DELETE_QUESTIONS_MODAL_TEXT
                            : CompanyAuthQuestionsConstants.DELETE_QUESTION_MODAL_TEXT}
                    </CustomModal>
                )}
            </>
            <Loader loading={securityQuestionStore.securityQuestions.isLoading} />
        </LoadingOverlay>
    );
};
