import React, { useEffect, useState } from "react";
import BootstrapTable, { ColumnDescription, SelectRowProps } from "react-bootstrap-table-next";
import { IDropOffSender } from "../../../../../../../core/viewModels/company/settings/DropOffSettings";
import { DropOffUsersConstants } from "../../../../../../../helper/Constants";
import Checkbox, { CheckBoxSize } from "../../../../../../common/ui/Checkbox";
import { BlockUserIcon, UnblockUserIcon } from "../../../../../../svg/IconCollection";
import { DeleteIcon } from "../../../../../../svg/IconCollection";
import SenderInLineActions from "./SenderInLineActions";
interface ISenderTable {
    senderTableData: IDropOffSender[];
    handleEditClick: (selectedSender: IDropOffSender) => void;
    updateSelectedSender: (selectedSender: IDropOffSender[]) => void;
    selectedSender: IDropOffSender[];
    handleDeleteSenderClick: (selectedSender: IDropOffSender[]) => void;
    handleWhitelistSenderClick: (selectedSender: IDropOffSender[]) => void;
    handleBlacklistSenderClick: (selectedSender: IDropOffSender[]) => void;
}

export const SenderTable: React.FC<ISenderTable> = ({
    senderTableData,
    handleEditClick,
    updateSelectedSender,
    selectedSender,
    handleDeleteSenderClick,
    handleWhitelistSenderClick,
    handleBlacklistSenderClick,
}) => {
    const [sendersData, setSendersData] = useState<IDropOffSender[]>(senderTableData);

    useEffect(() => {
        setSendersData(senderTableData);
    }, [senderTableData]);

    const isAnySelected = selectedSender.length > 0;

    const inlineActionBar = (cell: string, row: IDropOffSender): JSX.Element => {
        return (
            <SenderInLineActions
                selectedSender={row}
                updateSelectedSender={updateSelectedSender}
                handleEditClick={handleEditClick}
                handleDeleteSenderClick={handleDeleteSenderClick}
                handleWhitelistSenderClick={handleWhitelistSenderClick}
                handleBlacklistSenderClick={handleBlacklistSenderClick}
            />
        );
    };
    const HeaderActionsBar = (): JSX.Element => {
        return (
            <div className="header-toolbar-action drop-off-header">
                <span
                    onClick={() => isAnySelected && handleDeleteSenderClick(selectedSender)}
                    title={"Delete"}
                    className={isAnySelected ? "pointer" : "isDisabled"}
                >
                    <DeleteIcon width={18} height={18} disabled={!isAnySelected} />
                </span>
                <span
                    id={DropOffUsersConstants.WHITELIST_ICON}
                    title={DropOffUsersConstants.SENDER_WHITELIST_USERS_BUTTON}
                    onClick={() => isAnySelected && handleWhitelistSenderClick(selectedSender)}
                    className={isAnySelected ? "pointer" : "isDisabled"}
                >
                    <UnblockUserIcon width={18} height={18} disabled={!isAnySelected} />
                </span>
                <span
                    id={DropOffUsersConstants.BLACKLIST_ICON}
                    title={DropOffUsersConstants.SENDER_BLACKLIST_USERS_BUTTON}
                    onClick={() => isAnySelected && handleBlacklistSenderClick(selectedSender)}
                    className={isAnySelected ? "pointer" : "isDisabled"}
                >
                    <BlockUserIcon width={18} height={18} disabled={!isAnySelected} />
                </span>
            </div>
        );
    };

    const userStateFormatter = (cell: boolean): JSX.Element => {
        return (
            <span 
                id={cell ? DropOffUsersConstants.BLACKLIST_ICON: DropOffUsersConstants.WHITELIST_ICON} 
                className="user-state">
                {cell ? <BlockUserIcon width={16} height={16} /> : <UnblockUserIcon width={16} height={16} />}
            </span>
        );
    };
    const emailFormatter = (cell: string, row: IDropOffSender): JSX.Element => {
        return (
            <div className="cell-actions-wrapper">
                <span title={cell} className="sender-table-email-cell overflowText">
                    {cell}
                </span>
                {inlineActionBar(cell, row)}
            </div>
        );
    };

    const columns: ColumnDescription[] = [
        {
            text: DropOffUsersConstants.USER_STATUS_TABLE_LABEL,
            dataField: "isBlacklisted",
            formatter: userStateFormatter,
            style: { width: "8%" },
        },
        {
            text: "Sender Email",
            dataField: "emailAddress",
            formatter: emailFormatter,
        },
        {
            text: "",
            dataField: "toolbar",
            headerFormatter: HeaderActionsBar,
        },
    ];

    const handleOnSelect = (row: IDropOffSender, isSelect: boolean) => {
        let selectedSendersCopy = [...selectedSender];
        if (isSelect) {
            selectedSendersCopy.push(row);
        } else {
            selectedSendersCopy = selectedSendersCopy.filter(sender => sender.dropOffSenderId != row.dropOffSenderId);
        }
        updateSelectedSender(selectedSendersCopy);
    };

    const handleOnSelectAll = (isSelect: boolean, rows: IDropOffSender[]) => {
        if (isSelect) {
            updateSelectedSender(rows);
        } else {
            updateSelectedSender([]);
        }
    };

    const selectRow: SelectRowProps<IDropOffSender> = {
        mode: "checkbox",
        clickToSelect: true,
        selected: selectedSender.map((sender: IDropOffSender) => sender.dropOffSenderId),
        onSelect: handleOnSelect,
        onSelectAll: handleOnSelectAll,
        selectionHeaderRenderer: ({ indeterminate }) => (
            <Checkbox
                id="mpiCheckbox"
                size={CheckBoxSize.sm}
                checked={!!selectedSender.length}
                indeterminate={indeterminate}
                onClick={e => e.stopPropagation()}
                onChange={e => e.stopPropagation()}
                text={""}
            />
        ),
    };

    return (
        <BootstrapTable
            classes="settings-table dropOff-sender-table"
            keyField="dropOffSenderId"
            columns={columns}
            data={sendersData}
            remote
            selectRow={selectRow}
            noDataIndication={DropOffUsersConstants.NO_DATA_TEXT}
            bordered={false}
        />
    );
};
