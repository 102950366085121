import React from "react";
import "../../../../assets/custom/customToggleSwitch.css";
import { Form } from "react-bootstrap";

interface ICustomToggleSwitchProps {
    switched?: boolean;
    handleChange(event: any, name?: string): void;
    name?: string;
    disabled?: boolean;
    label?: string
}
const CustomToggleSwitch: React.FC<ICustomToggleSwitchProps> = props => {

    const handleToggleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!props.disabled) {
            props.handleChange(e, props.name);
        }
    };

    return (
            <Form.Switch
                className="custom-switch"
                type="switch"
                onChange={handleToggleChange}
                checked={props.switched}
                label={props.label}
                disabled={props.disabled}
                data-testid="custom-toggle-switch"
            />
    );
};

export default CustomToggleSwitch;
