import React, { FC, useEffect, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import paginationFactory from "react-bootstrap-table2-paginator";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { getBrowserDetails } from "../../../helper/HelperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { fetchLoginHistory } from "../../../actionCreators/commonActionCreators";
import { DateFormatConstants, LoginHistoryConstants } from "../../../helper/Constants";
import { ApplicationState } from "../../../store";
import { IUserLoginHistory } from "../../../core/viewModels/user/UserViewModel";
import { logger } from "../../../oidcClient/authProvider";
import { appInsightsData } from "../../../helper/AppInsightsData";
import { getFormattedLocalDateTime } from "../../../helper/DateHelperFunctions";
import { RefreshIcon } from "../../svg/IconCollection";

const LoginHistoryTable: FC = () => {
  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
  const dispatch = useDispatch();
  const { loginHistoryLoading, loginHistory: { loginHistory, count, deviceId } } = useSelector((state: ApplicationState) => state.commonState);

  const columns = [
    {
      text: "Browser",
      dataField: "clientApp",
      title: (cell: string) => getBrowserDetails(cell),
      headerStyle: { width: "300px" },
      formatter: (cell: string) => getBrowserDetails(cell)
    },
    {
      text: "IP Address",
      dataField: "IPAddress",
      title: (cell: string) => cell,
    },
    {
      text: "Login Date",
      dataField: "loggedInOn",
      title: (cell: string) => cell,
    },
    {
      text: "Logout Date",
      dataField: "loggedOutOn",
      title: (cell: string) => cell,
    }
  ];

  const customTotal = (from: number, to: number) => (
    <span className="total-count">
      Showing <b>{from}</b> to <b>{to}</b> of <b>{count}</b> entries
    </span>
  );

  const getTableData = () => {
    let data = [];
    if (loginHistory) data = JSON.parse(JSON.stringify(loginHistory));
    data.forEach((history: IUserLoginHistory) => {
      history.loggedInOn = getFormattedLocalDateTime(history.loggedInOn, DateFormatConstants.LAST_LOGIN_DATE_FORMAT);
      if (history.deviceId === deviceId)
        history.loggedOutOn = "Current Device";
      else if (!history.loggedOutOn)
        history.loggedOutOn = "Logged In";
      else {
        history.loggedOutOn = getFormattedLocalDateTime(history.loggedOutOn, DateFormatConstants.LAST_LOGIN_DATE_FORMAT);
      }
    });
    return data;
  };

  const handlePageChange = (page: number) => {
    setCurrentPageNumber(page);
    dispatch(fetchLoginHistory(page, LoginHistoryConstants.COUNT_PER_PAGE));
  };
  const handleReload = () => {
    dispatch(fetchLoginHistory(1, LoginHistoryConstants.COUNT_PER_PAGE));
    setCurrentPageNumber(1);
    logger.trackEvent({
      name: `${appInsightsData.LoginHistory.PageTitle} - ${appInsightsData.LoginHistory.Operation.ReloadClicked}`,
      properties: {
        page: appInsightsData.LoginHistory.PageTitle
      }
    });
  };

  const options = {
    totalSize: count,
    sizePerPage: LoginHistoryConstants.COUNT_PER_PAGE,
    page: currentPageNumber,
    onPageChange: (page: number) => handlePageChange(page),
    paginationTotalRenderer: customTotal,
    showTotal: true,
    withFirstAndLast: true,
    alwaysShowAllBtns: true,
  };

  useEffect(() => {
    dispatch(fetchLoginHistory(1, LoginHistoryConstants.COUNT_PER_PAGE));
  }, []);

  return <div className="table-wrapper">
    <button title="Refresh" className="reload-button" onClick={handleReload}>
      <RefreshIcon/>
    </button>
    <LoadingOverlay>
      <BootstrapTable
        classes="custom-table"
        keyField="loginHistory"
        columns={columns}
        data={getTableData()}
        remote
        noDataIndication={"No data"}
        bordered={false}
        pagination={paginationFactory(options)}
      />
      <Loader
        classNamePrefix="sm"
        text=""
        loading={loginHistoryLoading}
      />
    </LoadingOverlay></div>;
};

export default LoginHistoryTable;