import React, { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { LoadingOverlay, Loader } from "react-overlay-loader";
import { SavedMessageEmailTemplate } from "../../../helper/Constants";
import { ApplicationState } from "../../../store";

import { getSavedMessageTemplates } from "../../../actionCreators/SavedMessageActionCreator";
import { TemplateSource, TemplateType } from "../../../core/common/enums";
import EmailTemplateManager from "./UserSettingsTEmplateModal";
import { getSavedDRMailTemplates } from "../../../actionCreators/SavedRequestActionCreator";
import { ISavedMessageTemplateModel } from "../../../core/viewModels/settings/SavedMessageTemplate";
import { fetchSavedMessageDRMailTemplateSettings, fetchSavedMessageMailTemplateSettings } from "../../../actionCreators/adminSettingsActionCreators";
import { fetchUserSettings } from "../../../actionCreators/userSettingsActionCreators";

const EmailMessageTemplates = () => {
    const dispatch = useDispatch();

    const isLoading  = useSelector((appState: ApplicationState) => appState.userSettingsState.isLoading);
    const data = useSelector((state: ApplicationState) => state.SavedMessageTemplateState.data);
    const documentRequestData = useSelector((state: ApplicationState) => state.SavedRequestTemplateState.data);
    const [orderedSecureMailTemplates, setOrderedSecureMailTemplates] = useState<ISavedMessageTemplateModel[]>([]);
    const [orderedDocumentRequestTemplates, setOrderedDocumentRequestTemplates] = useState<ISavedMessageTemplateModel[]>([]);
    useEffect(() => {
        dispatch(getSavedMessageTemplates(TemplateSource.UserCreated));
        dispatch(getSavedDRMailTemplates(TemplateSource.UserCreated));
        dispatch(fetchSavedMessageMailTemplateSettings());
        dispatch(fetchSavedMessageDRMailTemplateSettings());
        dispatch(fetchUserSettings());
    }, []);
    
    useEffect(() => {
        if (data) {
            setOrderedSecureMailTemplates(sortTemplates([...data]));
        }
    }, [data]);

    useEffect(() => {
        if (documentRequestData) {
            setOrderedDocumentRequestTemplates(sortTemplates([...documentRequestData]));
        }
    }, [documentRequestData]);

    const sortTemplates = (templates: ISavedMessageTemplateModel[]) => {
        const order: any = {
            [TemplateSource.UserCreated]: 1,
            [TemplateSource.SystemDefault]: 2,
            [TemplateSource.AdminCreated]: 3,
        };
        return templates.sort((a, b) => order[a.templateSource] - order[b.templateSource]);
    };
    
    return (
        <LoadingOverlay>
            <div className="message-options-parent-container">
                <header>
                    <h3>{SavedMessageEmailTemplate.EMAIL_TEMPLATE_TITLE}</h3>
                    <span>{SavedMessageEmailTemplate.MESSAGE_EMAILTEMPLATE_HELPER_TEXT_USER_SETTINGS}</span>
                </header>
                <div className="settings-accordian-container">
                    <Accordion flush className="settings-accordian user-settings-savedmessage">
                        <Accordion.Item className="second-accordian-item" eventKey="4">
                            <Accordion.Header>
                                <div className="accordion-title-info-container">
                                    <h4>{SavedMessageEmailTemplate.MESSAGE_EMAILTEMPLATE_HEADER}</h4>
                                    <span>{SavedMessageEmailTemplate.MESSAGE_EMAILTEMPLATE_HELPER_TEXT}</span>
                                </div>
                         </Accordion.Header>
                            <Accordion.Body className="savedMessage sm-accordian">
                                <EmailTemplateManager
                                    fetchedData={orderedSecureMailTemplates}
                                    templateTypeValue={TemplateType.SecureMail}
                                />
                            </Accordion.Body>
                            {isLoading && <Loader />}
                        </Accordion.Item>
                        <Accordion.Item className="second-accordian-item" eventKey="3">
                            <Accordion.Header>
                                <div className="accordion-title-info-container">
                                    <h4>{SavedMessageEmailTemplate.REQUEST_EMAILTEMPLATE_HEADER}</h4>
                                    <span>{SavedMessageEmailTemplate.REQUEST_EMAILTEMPLATE_HELPER_TEXT}</span>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body className="savedMessage sm-accordian">
                            <EmailTemplateManager
                                    fetchedData={orderedDocumentRequestTemplates}
                                    templateTypeValue={TemplateType.DocumentRequest}
                                />
                            </Accordion.Body>
                            {isLoading && <Loader />}
                        </Accordion.Item>
                    </Accordion>
                </div>
            </div>
        </LoadingOverlay>
    );
};

export default EmailMessageTemplates;
