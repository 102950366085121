import React from "react";
import { ISavedMessageTemplateModel } from "../../../../../../core/viewModels/settings/SavedMessageTemplate";
import { Form } from "react-bootstrap";
import { SavedMessageDeleteIcon, SavedMessageEditIcon } from "../../../../../svg/IconCollection";
import { TemplateSource } from "../../../../../../core/common/enums";
import { SavedMessageEmailTemplate } from "../../../../../../helper/Constants";
import { convertHtmlToText } from "../../../../../../helper/HelperFunctions";
interface IUserTemplateSetting{
  allowedToAdd:boolean;
  allowedToChangeDefault : boolean;
 }
interface IMessageBoxProps {
  selectedMessage: ISavedMessageTemplateModel;
  onEdit: () => void;
  onDelete: (id:number) => void;
  SavedMessageTemplatedata?: ISavedMessageTemplateModel[];
  defaultTemplate?: ISavedMessageTemplateModel;
  settings?: IUserTemplateSetting;
  templateSouceValue?: TemplateSource;
  isBaseTemplateEditable ? : boolean;
}
 
  const MessageBox: React.FC<IMessageBoxProps> = ({ selectedMessage, onEdit, onDelete ,defaultTemplate,templateSouceValue ,isBaseTemplateEditable,settings}) => {
    
    const isTemplateDeletable = templateSouceValue === TemplateSource.AdminCreated 
                               ? selectedMessage.isSystemDefault 
                               : selectedMessage.isSystemDefault || selectedMessage.templateSource === TemplateSource.AdminCreated;
    const DeleteTitle = templateSouceValue === TemplateSource.AdminCreated ? SavedMessageEmailTemplate.ACTION_DISABLED :  SavedMessageEmailTemplate.ACTION_DISABLED;                       
    const isCurrentMessageDefault = selectedMessage.savedMessageTemplateId ===defaultTemplate?.savedMessageTemplateId;
    const isEditEnabled = selectedMessage.templateName === SavedMessageEmailTemplate.BASE_TEMPLATE_NAME ? !isBaseTemplateEditable :false;
    const isAdminTemplateEditDisabled = selectedMessage.templateSource === TemplateSource.UserCreated ? false :(settings && !settings.allowedToChangeDefault);
    return (
      <div className="message-box-wrapper">
        <header>Preview</header>
        <div>
          <div className="item-group ellipsis">
            <span>Template Name</span>
            <p className="ellipsis"  title={selectedMessage.templateName}>{selectedMessage.templateName}</p>
          </div>
          <div className="item-group body-field ">
            <span>Message</span>
            <p style={{ whiteSpace: "pre-wrap" }}>
           {convertHtmlToText(selectedMessage.text)}
            </p>
          </div>
     
            <div className="settings-form-item margin-top-10-px">
              <Form.Check disabled checked={isCurrentMessageDefault} type="checkbox" />
              <Form.Label>Set As Default</Form.Label>
            </div>
          
        </div>
        <footer>
          <button
            onClick={onEdit}
            className={`btn-with-icon ${ isEditEnabled || isAdminTemplateEditDisabled ? "disabled input-disabled-list" : ""}`}
            title={isEditEnabled || isAdminTemplateEditDisabled ? SavedMessageEmailTemplate.ACTION_DISABLED : "Edit"}
            disabled={isEditEnabled || isAdminTemplateEditDisabled}
          >
            <SavedMessageEditIcon />
            
            Edit
          </button>
          
           <button
            onClick={
                ()=>{onDelete(selectedMessage.savedMessageTemplateId);}}
            className={`btn-with-icon ${isTemplateDeletable || isCurrentMessageDefault? "disabled input-disabled-list" : ""}`}
            title={isTemplateDeletable || isCurrentMessageDefault ? DeleteTitle :"Delete"}
            disabled={isTemplateDeletable || isCurrentMessageDefault}
          >
            <SavedMessageDeleteIcon />
            Delete
          </button>
        </footer>
      </div>
    );
  };
  
  export default MessageBox;
  