import * as React from "react";
import { ColorConstants } from "../../assets/custom/colors";

export type MenuIcon = "DropOffLink";

export interface IMenuIconProps {
    height?: number;
    width?: number;
    color?: string;
    disabled?: boolean;
    icon?: string;
}

export const DownloadIcon: React.FC<IMenuIconProps> = props => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width={props.width || 24}
            height={props.height || 24}
        >
            <path d="M5 20h14v-2H5m14-9h-4V3H9v6H5l7 7 7-7Z" fill={props.color} />
        </svg>
    );
};

export const ViewInboxIcon: React.FC<IMenuIconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || 22}
        height={props.height || 22}
        fill={props.color || "#565A5E"}
        viewBox="0 0 22 18"
    >
        <path d="M20.625 17.25S22 17.25 22 15.875s-1.375-5.5-6.875-5.5-6.875 4.125-6.875 5.5 1.375 1.375 1.375 1.375h11zm-10.97-1.375a.345.345 0 01-.03-.005c.001-.363.23-1.417 1.045-2.365.759-.89 2.093-1.755 4.455-1.755 2.36 0 3.695.866 4.455 1.755.815.948 1.042 2.003 1.045 2.364l-.011.003a.597.597 0 01-.02.003H9.656zm5.47-8.25a2.75 2.75 0 100-5.5 2.75 2.75 0 000 5.5zm4.125-2.75a4.125 4.125 0 11-8.25 0 4.125 4.125 0 018.25 0zM9.537 10.76a8.08 8.08 0 00-1.691-.34c-.323-.03-.647-.046-.971-.045-5.5 0-6.875 4.125-6.875 5.5 0 .917.458 1.375 1.375 1.375h5.797c-.204-.43-.305-.9-.297-1.375 0-1.389.518-2.808 1.499-3.993a7.273 7.273 0 011.163-1.122zm-2.772.99A7.553 7.553 0 005.5 15.875H1.375c0-.357.226-1.416 1.045-2.37.75-.875 2.051-1.728 4.345-1.754v-.001zM2.063 5.562a4.125 4.125 0 118.25 0 4.125 4.125 0 01-8.25 0zm4.124-2.75a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5z"></path>
    </svg>
);
export const ViewInboxIconFilled: React.FC<IMenuIconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || 22}
        height={props.height || 22}
        fill="none"
        viewBox="0 0 22 22"
    >
        <path
            fill="#669440"
            d="M9.625 19.25s-1.375 0-1.375-1.375 1.375-5.5 6.875-5.5S22 16.5 22 17.875s-1.375 1.375-1.375 1.375h-11zm5.5-8.25a4.125 4.125 0 100-8.25 4.125 4.125 0 000 8.25z"
        ></path>
        <path
            fill="#669440"
            fillRule="evenodd"
            d="M7.172 19.25c-.204-.43-.305-.9-.297-1.375 0-1.863.935-3.781 2.662-5.115a8.696 8.696 0 00-2.662-.385c-5.5 0-6.875 4.125-6.875 5.5s1.375 1.375 1.375 1.375h5.797z"
            clipRule="evenodd"
        ></path>
        <path fill="#669440" d="M6.188 11a3.438 3.438 0 100-6.875 3.438 3.438 0 000 6.875z"></path>
    </svg>
);

export const LoginHistoryIcon: React.FC<IMenuIconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        data-name="Layer 1"
        viewBox="0 0 24 24"
        width={props.width}
        height={props.height}
        fill={props.color}
    >
        <path d="M12 21a5 5 0 0 0-2.52-4.32 3 3 0 1 0-5 0A5 5 0 0 0 2 21v1h10Z" />
        <path d="M12 2A10 10 0 0 0 2 12h2a8 8 0 1 1 9.57 7.85l.39 2A10 10 0 0 0 12 2Z" />
        <path d="M11 6v6.41l3.29 3.3 1.42-1.42-2.71-2.7V6h-2z" />
    </svg>
);
export const ReportProblemIcon: React.FC<IMenuIconProps> = props => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={props.width || "20"}
        height={props.height || "20"}
        fill={"none"}
        viewBox="0 0 12 15"
    >
        <path
            fill={props.color || ColorConstants.grey_600}
            d="M6 1a5 5 0 00-5 5v1h1a1 1 0 011 1v3a1 1 0 01-1 1H1a1 1 0 01-1-1V6a6 6 0 1112 0v6a2.5 2.5 0 01-2.5 2.5H7.366a1 1 0 01-.866.5h-1a1 1 0 010-2h1a1 1 0 01.866.5H9.5A1.5 1.5 0 0011 12h-1a1 1 0 01-1-1V8a1 1 0 011-1h1V6a5 5 0 00-5-5z"
        ></path>
    </svg>
);
