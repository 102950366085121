import * as React from "react";
import Modal from "react-bootstrap/Modal";
import { FileViewer } from "./FileViewer";
import { CustomModal } from "../common/ui/Modals/CustomModal";

interface PreviewUplodedFileModalProps {
    url: string;
    fileName: string | undefined;
    fileType: string | undefined;
    fileGuid: string | undefined;
    handleClose: () => void;
    onClickDownloadUploadedDocument: (fileName: string, fileGuid: string) => void;
    handleShow: () => void;
}

export const PreviewUplodedFileModal: React.FC<PreviewUplodedFileModalProps> = (props: any) => {
    return (
        <div>
            <CustomModal
                className="preview-modal"
                size="xl"
                show={props.handleShow}
                hideFooter={true}
                title={props.fileName}
                isConfirmModal={true}
                onSubmit={props.handleClose}
                onHide={props.handleClose}>

                <Modal.Body>
                    <FileViewer
                        url={props.url}
                        fileType={props.fileType}
                        onClickDownloadUploadedDocument={props.onClickDownloadUploadedDocument}
                        fileGuid={props.fileGuid}
                        fileName={props.fileName}
                    />
                </Modal.Body>
            </CustomModal>
        </div>
    );
};
