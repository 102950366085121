import axios, { AxiosResponse } from "axios";
import {
    CompletedTemplateList,
    ReceivedTemplateList,
    RequestTemplateList,
    RequestTemplateDetail,
    ReceivedTemplateDetail,
    CompletedTemplateDelete,
    CompletedTemplateDetail,
    RequestTemplateDelete,
    RequestAddEditTemplate,
    CompletedAddEditTemplate,
} from "../actions/templateActions";
import { AppThunkAction } from "../store";
import { actionTypes as templateActions } from "../types/templateTypes";
import { actionTypes as notificationAction } from "../types/notificationTypes";
import { StatusType, NotificationAction } from "../reducers/notificationReducer";
import {
    IDocumentRequestTemplate,
    IDocumentRequestTemplateModel,
    ITemplateTableDataRequestModel,
} from "../core/viewModels/template/TemplateModel";
import { ToasterMessages } from "../helper/ToasterMessages";

export type DispatchActions =
    | RequestTemplateList
    | ReceivedTemplateList
    | CompletedTemplateList
    | RequestTemplateDetail
    | ReceivedTemplateDetail
    | CompletedTemplateDelete
    | CompletedTemplateDetail
    | RequestTemplateDelete
    | RequestAddEditTemplate
    | CompletedAddEditTemplate;

export type KnownActions = DispatchActions | NotificationAction;

export const fetchTemplateTableData =
    (payload: ITemplateTableDataRequestModel, callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: templateActions.REQUEST_TEMPLATE_LIST,
        });

        axios
            .post(`${process.env.REACT_APP_API_URL}/api/DocumentRequestTemplate/GeDocumentRequestTemplates`, payload)
            .then((response: AxiosResponse<IDocumentRequestTemplate[]>) => {
                const data = response.data;
                dispatch({
                    type: templateActions.RECEIVED_TEMPLATE_LIST,
                    payload: data,
                });
                if (callback) callback();
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: templateActions.COMPLETED_TEMPLATE_LIST,
                });
            });
    };

export const getTemplateDetail =
    (templateId: number, callback?: (data: IDocumentRequestTemplateModel) => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: templateActions.REQUEST_TEMPLATE_DETAIL,
        });

        axios
            .get(`${process.env.REACT_APP_API_URL}/api/DocumentRequestTemplate/${templateId}`)
            .then((response: AxiosResponse<IDocumentRequestTemplateModel>) => {
                const data = response.data;
                dispatch({
                    type: templateActions.RECEIVED_TEMPLATE_DETAIL,
                    payload: data,
                });
                if (callback) callback(data);
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: templateActions.COMPLETED_TEMPLATE_DETAIL,
                });
            });
    };
export const deleteTemplate =
    (folderIds: number[], callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: templateActions.DELETE_TEMPLATE_REQUEST,
        });

        axios
            .delete(`${process.env.REACT_APP_API_URL}/api/DocumentRequestTemplate`, {
                data: folderIds,
            })
            .then(() => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage:
                        folderIds.length > 1
                            ? ToasterMessages.SUCCESS.DELETE_REQUEST_TEMPLATES
                            : ToasterMessages.SUCCESS.DELETE_REQUEST_TEMPLATE,

                    statusType: StatusType.Success,
                });

                if (callback) callback();
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: templateActions.DELETE_TEMPLATE_COMPLETED,
                });
            });
    };

export const addUpdateTemplate =
    (payload: IDocumentRequestTemplateModel, callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: templateActions.ADD_EDIT_TEMPLATE_REQUEST,
        });
        axios
            .post(`${process.env.REACT_APP_API_URL}/api/DocumentRequestTemplate`, payload)
            .then(() => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: ToasterMessages.SUCCESS.ADD_TEMPLATE,
                    statusType: StatusType.Success,
                });
                if (callback) callback();
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.response.data.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: templateActions.ADD_EDIT_TEMPLATE_REQUEST_COMPLETED,
                });
            });
    };
export const editTemplate =
    (payload: IDocumentRequestTemplateModel, callback?: () => void): AppThunkAction<KnownActions> =>
    dispatch => {
        dispatch({
            type: templateActions.ADD_EDIT_TEMPLATE_REQUEST,
        });
        axios
            .put(`${process.env.REACT_APP_API_URL}/api/DocumentRequestTemplate`, payload)
            .then(() => {
                if (callback) callback();
            })
            .catch(error => {
                dispatch({
                    type: notificationAction.NOTIFICATION,
                    statusMessage: error.response.data.message,
                    statusType: StatusType.Error,
                });
            })
            .finally(() => {
                dispatch({
                    type: templateActions.ADD_EDIT_TEMPLATE_REQUEST_COMPLETED,
                });
            });
    };
