import { ISavedMessageTemplateModel } from "../core/viewModels/settings/SavedMessageTemplate";
import produce from "immer";
import { actionTypes } from "../types/savedDRTemplateTypes";
import { Action, Reducer } from "redux";
import * as SavedRequestMailTemplate from "../actionCreators/SavedRequestActionCreator";

export interface ISavedRequestMailTemplateStore {
  data: ISavedMessageTemplateModel[];
  isLoading: boolean;
}

const initialState: ISavedRequestMailTemplateStore = {
  data: [],
  isLoading: false,
};

export const reducer: Reducer<ISavedRequestMailTemplateStore> = produce(
  (state: ISavedRequestMailTemplateStore = initialState, incomingAction: Action) => {
    const action = incomingAction as SavedRequestMailTemplate.KnownActions;
    switch (action.type) {
      case actionTypes.REQUEST_DR_MAIL_TEMPLATES_LIST:
      case actionTypes.CREATE_DR_MAIL_TEMPLATE:
      case actionTypes.DELETE_DR_MAIL_TEMPLATE:
      case actionTypes.UPDATE_DR_MAIL_TEMPLATE:
        return { ...state, isLoading: true };
      case actionTypes.RECEIVE_DR_MAIL_TEMPLATES_LIST:
        return { ...state, isLoading: false, data: action.data };
      case actionTypes.COMPLETED_DR_MAIL_TEMPLATES:
        return { ...state, isLoading: false };
      default:
        return state;
    }
  }
);