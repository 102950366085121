import React, { useEffect, useState } from "react";
import { DocumentToolbarConstants, MessageToolbarConstants, MyDownloadsModalConstants } from "../../../helper/Constants";
import Checkbox, { CheckBoxSize } from "../../common/ui/Checkbox";
import { ResourceIdLocators } from "../../../helper/ResourceIdLocators";
import { useDispatch, useSelector } from "react-redux";
import { BellIcon, DeleteIcon, MoveToIcon, ResendAccessLinkIcon } from "../../svg/IconCollection";
import { IDocumentRequestListModel } from "../../../core/viewModels/documentRequest/DocumentRequestModel";
import { DetailedStatus, FolderType } from "../../../core/common/enums";
import { enableEditPermission, isDelegatedUser, isFolderOwner } from "../../../helper/HelperFunctions";
import { MoveFolderModal } from "../../common/ui/Modals/FolderModal/MoveFolderModal";
import { requestMoveToFolder } from "../../../actionCreators/documentRequestActionCreators";
import { IFolderModel } from "../../../core/viewModels/folder/FolderModel";
import { fetchFoldersTree, fetchUserOwnedFolders } from "../../../actionCreators/folderActionCreator";
import { ApplicationState } from "../../../store";
import { CustomModal } from "../../common/ui/Modals/CustomModal";

interface IDocumentToolbarProps {
    isAllSelected: boolean;
    isArchive?: boolean;
    isAnySelected: boolean;
    showSelectAllCheckbox: boolean;
    selectedDocumentList: IDocumentRequestListModel[];
    folder?: IFolderModel;
    handleOnSelectAll: (isSelect: boolean) => void;
    resetSelection: () => void;
    handleDeleteDocumentRequest: () => void;
    handleDocumentResendAccessLink: () => void;
    handleDocumentSendReminder: () => void;
    handleDRListRelaod:(drlRefresh?: boolean) => void;
}

export const DocumentToolbar: React.FC<IDocumentToolbarProps> = ({
    isAllSelected,
    isAnySelected,
    isArchive,
    folder,
    selectedDocumentList,
    handleOnSelectAll,
    showSelectAllCheckbox,
    handleDeleteDocumentRequest,
    handleDocumentResendAccessLink,
    handleDocumentSendReminder,
    handleDRListRelaod
}) => {
    const dispatch = useDispatch();
    const [showDelete, toggleShowDelete] = useState(false);
    const isSendReminderEnabled = isAnySelected
        ? selectedDocumentList.length === 1
            ? selectedDocumentList[0].status !== DetailedStatus.COMPLETED
            : true
        : false;

    useEffect(() => {
        dispatch(fetchUserOwnedFolders());
    },[]);
    const user = useSelector((state: ApplicationState) => state.userProfile);
    const userOwnedFolders = useSelector((appState: ApplicationState) => appState.folderState.userOwnedFolders.data);
    const isMoveToFolderEnabled =
            userOwnedFolders &&
            userOwnedFolders.some(folder => {
                return isFolderOwner(folder, user);
            });
    const isMoveEnabled = (folder?: IFolderModel) => {

        if (!folder || isFolderOwner(folder, user)) {
                return true;
        }
            return false;
        };
  
    const isMoveToEnabled = isAnySelected
                ? (selectedDocumentList.some(document => document.status === DetailedStatus.COMPLETED) && isMoveEnabled(folder)) && isMoveToFolderEnabled 
                : false;

    const [moveToFolder, setMoveToFolder] = useState<IFolderModel | undefined>();
    const [showFolder, toggleShowFolder] = useState(false);


    const checkBoxChangeHandler = (e: any) => {
        handleOnSelectAll(e.target.checked);
    };
    const deleteDocumentRequestSubmit = () => {
        handleDeleteDocumentRequest();
        toggleShowDelete(false);    
    };

    const handleOnClickMoveToFolder = () => {
        let folderIds: number[] = [];
        folderIds = isArchive ? [FolderType.INBOX]: [FolderType.DOCUMENTREQUEST, FolderType.INBOX];
        dispatch(fetchFoldersTree([], folderIds));
        toggleShowFolder(true);
    };

    const handleOnSubmitMoveToFolder = () => {
        if (moveToFolder) {
            const documentRequestIds = selectedDocumentList.map(document => document.documentRequestId);

            dispatch(
                requestMoveToFolder(documentRequestIds,moveToFolder.folderId, (result: any)=>{
                    if (result) {
                        toggleShowFolder(false);
                        handleDRListRelaod(false);
                    }
                },
                 isArchive ? ResourceIdLocators.MyFiles.MyFilesBtnRequestMoveTo : ResourceIdLocators.DRL.DRLBtnRequestMoveTo )
            );
        }
    };
    return (
        <div className="message-toolbar">
            <div
                style={{
                    visibility: showSelectAllCheckbox ? "inherit" : "hidden",
                    paddingTop: "2px"
                }}
                className="message-toolbar-action"
            >
                <Checkbox
                    id="mpiCheckbox"
                    text=""
                    size={CheckBoxSize.sm}
                    className="message-toolbar-checkbox"
                    checked={isAllSelected || isAnySelected}
                    disabled={!enableEditPermission(user,folder)}
                    onChange={checkBoxChangeHandler}
                    indeterminate={isAllSelected ? false : isAnySelected}
                    onClick={e => {
                        if (!enableEditPermission(user,folder)) {
                            e.stopPropagation();
                            e.preventDefault();
                        }
                    }}
                />
            </div>
            {!isDelegatedUser() && (
                <div className="message-toolbar-action">
                    <span
                        className={!isAnySelected ? "toolbar-icon-disabled" : ""}
                        data-resource-id={isArchive ? ResourceIdLocators.MyFiles.MyFilesBtnRequestDelete : ResourceIdLocators.DRL.DRLListBtnDeleteRequest}
                        title={DocumentToolbarConstants.TITLE_DELETE}
                        onClick={() => isAnySelected && toggleShowDelete(true)}
                    >
                        <DeleteIcon />
                    </span>
                </div>
            )}

            <div className="message-toolbar-action resend-access-icon-wrapper">
                <span
                    className={!isAnySelected ? "toolbar-icon-disabled" : ""}
                    data-resource-id={isArchive ? ResourceIdLocators.MyFiles.MyFilesBtnRequestResendAccess : ResourceIdLocators.DRL.DRLListBtnResendAccess}
                    title={DocumentToolbarConstants.TITLE_RESEND_ACCESS_LINK}
                    onClick={() => isAnySelected && handleDocumentResendAccessLink()}
                >
                    <ResendAccessLinkIcon />
                </span>
            </div>

           { !isArchive && 
            <div className="message-toolbar-action">
                <span
                    className={!isSendReminderEnabled ? "toolbar-icon-disabled" : ""}
                    title={DocumentToolbarConstants.TITLE_SEND_REMINDER}
                    data-resource-id={ResourceIdLocators.DRL.DRLListBtnSendReminder}
                    onClick={() => isSendReminderEnabled && handleDocumentSendReminder()}
                >
                    <BellIcon width={22} height={17} />
                </span>
            </div>
            }

            <CustomModal
                className="delete-message-modal"
                show={showDelete}
                title={DocumentToolbarConstants.DELETE_REQUEST}
                cancelButtonName={MessageToolbarConstants.TITLE_CANCEL}
                confirmButtonName={MessageToolbarConstants.TITLE_DELETE}
                onSubmit={deleteDocumentRequestSubmit}
                onHide={() => toggleShowDelete(false)}
                isConfirmModal={true}
            >
                <span>{DocumentToolbarConstants.LISTING_DELETE_REQUEST}</span>
                {isArchive && (
                    <div className="delete-warning">
                        <i className="fas fa-exclamation-triangle margin-right-10-px" />
                        <span>{MyDownloadsModalConstants.DELETE_WARNING}</span>
                    </div>
                )}
            </CustomModal>
            <div className="message-toolbar-action">
                <span
                    onClick={() => isMoveToEnabled && handleOnClickMoveToFolder()}
                    title={MessageToolbarConstants.TITLE_MOVE_TO_FOLDER}
                    data-resource-id={isArchive ? ResourceIdLocators.MyFiles.MyFilesBtnRequestMoveTo : ResourceIdLocators.DRL.DRLBtnRequestMoveTo}
                    className={!isMoveToEnabled ? "toolbar-icon-disabled" : ""}
                    >
                <MoveToIcon height={17} width={17} disabled={!isMoveToEnabled}/>
                </span>
            </div>

            {showFolder && (
                <MoveFolderModal
                    selectedFolders={[]}
                    isDocumentRequest = {true}
                    folderType={isArchive ? FolderType.ARCHIVE : FolderType.DOCUMENTREQUEST}
                    selectedMessages={selectedDocumentList}
                    isMovingFolder={false}
                    setMoveToFolder={setMoveToFolder}
                    showState={showFolder}
                    onHide={() => {
                        toggleShowFolder(false);
                    }}
                    onSubmit={handleOnSubmitMoveToFolder}
                    onError={() => {
                        handleDRListRelaod(false);
                    }}
                />
            )}
        </div>
    );
};
