import { useEffect, useRef } from "react";
import {
    ArchiveFolderType,
    AuthenticationMethod,
    DetailedStatus,
    DropOffLinkType,
    FolderColumnName,
    FolderType,
    ListingStatus,
    ShareFolderTypes,
    TemplateColumnName,
} from "../core/common/enums";
import { IDownloadNowPayload, IFolderModel } from "../core/viewModels/folder/FolderModel";
import { IRetentionPeriod } from "../core/viewModels/company/settings/RetentionSettings";
import { Option } from "react-dropdown";
import {
    AuthMethodLabels,
    DropOffLinkTypeLabels,
    LocalStorageConstants,
    MessageTitleConstants,
    ShareFolderTypeLabels,
    SortAndFilterConstants,
    UserGroupConstants,
} from "./Constants";
import { IDelegateeUser } from "../core/viewModels/user/UserViewModel";
import Bowser from "bowser";
import { PasswordOptions } from "../core/viewModels/company/CompanySettingsViewModel";
import { appInsightsData } from "./AppInsightsData";
import { IRecipientModel } from "../core/viewModels/message/RecipientModel";
import { IMessageFileViewModel } from "../core/viewModels/message/MessageFileViewModel";
import { IMenuSection, ISideMenuItem } from "@sssuite-component-ui/ss-suite-layout/dist/SideMenu/SideMenu";
import { IMyAccountUserProfile } from "../components/navigation/parts/Profile";
import { createCookie, getDomain } from "@sssuite-component-ui/session-timeout";
import { invalidFileNameRegex } from "./Validations";
import { IDocumentRequestFile } from "../core/viewModels/documentRequest/DocumentRequestModel";
import { IDocumentRequestFileModel } from "../core/viewModels/documentRequest/DocumentRequestFileModel";

export function phoneNumberDisplay(phoneNumber: string): string {
    return phoneNumber && phoneNumber.length == 10
        ? "(" + phoneNumber.substring(0, 3) + ") " + phoneNumber.substring(3, 6) + "-" + phoneNumber.substring(6, 10)
        : phoneNumber;
}

export function countryCodeDisplay(countryCode: string): string {
    return countryCode ? "(" + countryCode + ") " : "";
}

export function GetCountryCode() {
    const countryCodeOption = [];
    countryCodeOption.push({ value: "+1", label: "USA/Canada (+1)" });
    countryCodeOption.push({ value: "+213", label: "Algeria (+213)" });
    countryCodeOption.push({ value: "+376", label: "Andorra (+376)" });
    countryCodeOption.push({ value: "+244", label: "Angola (+244)" });
    countryCodeOption.push({ value: "+1264", label: "Anguilla (+1264)" });
    countryCodeOption.push({ value: "+1268", label: "Antigua Barbuda (+1268)" });
    countryCodeOption.push({ value: "+54", label: "Argentina (+54)" });
    countryCodeOption.push({ value: "+374", label: "Armenia (+374)" });
    countryCodeOption.push({ value: "+297", label: "Aruba (+297)" });
    countryCodeOption.push({ value: "+61", label: "Australia (+61)" });
    countryCodeOption.push({ value: "+43", label: "Austria (+43)" });
    countryCodeOption.push({ value: "+994", label: "Azerbaijan (+994)" });
    countryCodeOption.push({ value: "+1242", label: "Bahamas (+1242)" });
    countryCodeOption.push({ value: "+973", label: "Bahrain (+973)" });
    countryCodeOption.push({ value: "+880", label: "Bangladesh (+880)" });
    countryCodeOption.push({ value: "+1246", label: "Barbados (+1246)" });
    countryCodeOption.push({ value: "+375", label: "Belarus (+375)" });
    countryCodeOption.push({ value: "+32", label: "Belgium (+32)" });
    countryCodeOption.push({ value: "+501", label: "Belize (+501)" });
    countryCodeOption.push({ value: "+229", label: "Benin (+229)" });
    countryCodeOption.push({ value: "+1441", label: "Bermuda (+1441)" });
    countryCodeOption.push({ value: "+975", label: "Bhutan (+975)" });
    countryCodeOption.push({ value: "+591", label: "Bolivia (+591)" });
    countryCodeOption.push({ value: "+387", label: "Bosnia Herzegovina (+387)" });
    countryCodeOption.push({ value: "+267", label: "Botswana (+267)" });
    countryCodeOption.push({ value: "+55", label: "Brazil (+55)" });
    countryCodeOption.push({ value: "+673", label: "Brunei (+673)" });
    countryCodeOption.push({ value: "+359", label: "Bulgaria (+359)" });
    countryCodeOption.push({ value: "+226", label: "Burkina Faso (+226)" });
    countryCodeOption.push({ value: "+257", label: "Burundi (+257)" });
    countryCodeOption.push({ value: "+855", label: "Cambodia (+855)" });
    countryCodeOption.push({ value: "+237", label: "Cameroon (+237)" });
    countryCodeOption.push({ value: "+238", label: "Cape Verde Islands (+238)" });
    countryCodeOption.push({ value: "+1345", label: "Cayman Islands (+1345)" });
    countryCodeOption.push({
        value: "+236",
        label: "Central African Republic (+236)",
    });
    countryCodeOption.push({ value: "+56", label: "Chile (+56)" });
    countryCodeOption.push({ value: "+86", label: "China (+86)" });
    countryCodeOption.push({ value: "+57", label: "Colombia (+57)" });
    countryCodeOption.push({ value: "+242", label: "Congo (+242)" });
    countryCodeOption.push({ value: "+682", label: "Cook Islands (+682)" });
    countryCodeOption.push({ value: "+506", label: "Costa Rica (+506)" });
    countryCodeOption.push({ value: "+385", label: "Croatia (+385)" });
    countryCodeOption.push({ value: "+53", label: "Cuba (+53)" });
    countryCodeOption.push({ value: "+90392", label: "Cyprus North (+90392)" });
    countryCodeOption.push({ value: "+357", label: "Cyprus South (+357)" });
    countryCodeOption.push({ value: "+42", label: "Czech Republic (+42)" });
    countryCodeOption.push({ value: "+45", label: "Denmark (+45)" });
    countryCodeOption.push({ value: "+253", label: "Djibouti (+253)" });
    countryCodeOption.push({
        value: "+1809",
        label: "Dominican Republic (+1809)",
    });
    countryCodeOption.push({ value: "+593", label: "Ecuador (+593)" });
    countryCodeOption.push({ value: "+20", label: "Egypt (+20)" });
    countryCodeOption.push({ value: "+503", label: "El Salvador (+503)" });
    countryCodeOption.push({ value: "+240", label: "Equatorial Guinea (+240)" });
    countryCodeOption.push({ value: "+291", label: "Eritrea (+291)" });
    countryCodeOption.push({ value: "+372", label: "Estonia (+372)" });
    countryCodeOption.push({ value: "+251", label: "Ethiopia (+251)" });
    countryCodeOption.push({ value: "+500", label: "Falkland Islands (+500)" });
    countryCodeOption.push({ value: "+298", label: "Faroe Islands (+298)" });
    countryCodeOption.push({ value: "+679", label: "Fiji (+679)" });
    countryCodeOption.push({ value: "+358", label: "Finland (+358)" });
    countryCodeOption.push({ value: "+33", label: "France (+33)" });
    countryCodeOption.push({ value: "+594", label: "French Guiana (+594)" });
    countryCodeOption.push({ value: "+689", label: "French Polynesia (+689)" });
    countryCodeOption.push({ value: "+241", label: "Gabon (+241)" });
    countryCodeOption.push({ value: "+220", label: "Gambia (+220)" });
    countryCodeOption.push({ value: "+7880", label: "Georgia (+7880)" });
    countryCodeOption.push({ value: "+49", label: "Germany (+49)" });
    countryCodeOption.push({ value: "+233", label: "Ghana (+233)" });
    countryCodeOption.push({ value: "+350", label: "Gibraltar (+350)" });
    countryCodeOption.push({ value: "+30", label: "Greece (+30)" });
    countryCodeOption.push({ value: "+299", label: "Greenland (+299)" });
    countryCodeOption.push({ value: "+1473", label: "Grenada (+1473)" });
    countryCodeOption.push({ value: "+590", label: "Guadeloupe (+590)" });
    countryCodeOption.push({ value: "+671", label: "Guam (+671)" });
    countryCodeOption.push({ value: "+502", label: "Guatemala (+502)" });
    countryCodeOption.push({ value: "+224", label: "Guinea (+224)" });
    countryCodeOption.push({ value: "+245", label: "Guinea - Bissau (+245)" });
    countryCodeOption.push({ value: "+592", label: "Guyana (+592)" });
    countryCodeOption.push({ value: "+509", label: "Haiti (+509)" });
    countryCodeOption.push({ value: "+504", label: "Honduras (+504)" });
    countryCodeOption.push({ value: "+852", label: "Hong Kong (+852)" });
    countryCodeOption.push({ value: "+36", label: "Hungary (+36)" });
    countryCodeOption.push({ value: "+354", label: "Iceland (+354)" });
    countryCodeOption.push({ value: "+91", label: "India (+91)" });
    countryCodeOption.push({ value: "+62", label: "Indonesia (+62)" });
    countryCodeOption.push({ value: "+98", label: "Iran (+98)" });
    countryCodeOption.push({ value: "+964", label: "Iraq (+964)" });
    countryCodeOption.push({ value: "+353", label: "Ireland (+353)" });
    countryCodeOption.push({ value: "+972", label: "Israel (+972)" });
    countryCodeOption.push({ value: "+39", label: "Italy (+39)" });
    countryCodeOption.push({ value: "+1876", label: "Jamaica (+1876)" });
    countryCodeOption.push({ value: "+81", label: "Japan (+81)" });
    countryCodeOption.push({ value: "+962", label: "Jordan (+962)" });
    countryCodeOption.push({ value: "+254", label: "Kenya (+254)" });
    countryCodeOption.push({ value: "+686", label: "Kiribati (+686)" });
    countryCodeOption.push({ value: "+850", label: "Korea North (+850)" });
    countryCodeOption.push({ value: "+82", label: "Korea South (+82)" });
    countryCodeOption.push({ value: "+965", label: "Kuwait (+965)" });
    countryCodeOption.push({ value: "+996", label: "Kyrgyzstan (+996)" });
    countryCodeOption.push({ value: "+856", label: "Laos (+856)" });
    countryCodeOption.push({ value: "+371", label: "Latvia (+371)" });
    countryCodeOption.push({ value: "+961", label: "Lebanon (+961)" });
    countryCodeOption.push({ value: "+266", label: "Lesotho (+266)" });
    countryCodeOption.push({ value: "+231", label: "Liberia (+231)" });
    countryCodeOption.push({ value: "+218", label: "Libya (+218)" });
    countryCodeOption.push({ value: "+417", label: "Liechtenstein (+417)" });
    countryCodeOption.push({ value: "+370", label: "Lithuania (+370)" });
    countryCodeOption.push({ value: "+352", label: "Luxembourg (+352)" });
    countryCodeOption.push({ value: "+853", label: "Macao (+853)" });
    countryCodeOption.push({ value: "+389", label: "Macedonia (+389)" });
    countryCodeOption.push({ value: "+261", label: "Madagascar (+261)" });
    countryCodeOption.push({ value: "+265", label: "Malawi (+265)" });
    countryCodeOption.push({ value: "+60", label: "Malaysia (+60)" });
    countryCodeOption.push({ value: "+960", label: "Maldives (+960)" });
    countryCodeOption.push({ value: "+223", label: "Mali (+223)" });
    countryCodeOption.push({ value: "+356", label: "Malta (+356)" });
    countryCodeOption.push({ value: "+692", label: "Marshall Islands (+692)" });
    countryCodeOption.push({ value: "+596", label: "Martinique (+596)" });
    countryCodeOption.push({ value: "+222", label: "Mauritania (+222)" });
    countryCodeOption.push({ value: "+269", label: "Mayotte (+269)" });
    countryCodeOption.push({ value: "+52", label: "Mexico (+52)" });
    countryCodeOption.push({ value: "+691", label: "Micronesia (+691)" });
    countryCodeOption.push({ value: "+373", label: "Moldova (+373)" });
    countryCodeOption.push({ value: "+377", label: "Monaco (+377)" });
    countryCodeOption.push({ value: "+976", label: "Mongolia (+976)" });
    countryCodeOption.push({ value: "+1664", label: "Montserrat (+1664)" });
    countryCodeOption.push({ value: "+212", label: "Morocco (+212)" });
    countryCodeOption.push({ value: "+258", label: "Mozambique (+258)" });
    countryCodeOption.push({ value: "+95", label: "Myanmar (+95)" });
    countryCodeOption.push({ value: "+264", label: "Namibia (+264)" });
    countryCodeOption.push({ value: "+674", label: "Nauru (+674)" });
    countryCodeOption.push({ value: "+977", label: "Nepal (+977)" });
    countryCodeOption.push({ value: "+31", label: "Netherlands (+31)" });
    countryCodeOption.push({ value: "+687", label: "New Caledonia (+687)" });
    countryCodeOption.push({ value: "+64", label: "New Zealand (+64)" });
    countryCodeOption.push({ value: "+505", label: "Nicaragua (+505)" });
    countryCodeOption.push({ value: "+227", label: "Niger (+227)" });
    countryCodeOption.push({ value: "+234", label: "Nigeria (+234)" });
    countryCodeOption.push({ value: "+683", label: "Niue (+683)" });
    countryCodeOption.push({ value: "+672", label: "Norfolk Islands (+672)" });
    countryCodeOption.push({ value: "+670", label: "Northern Marianas (+670)" });
    countryCodeOption.push({ value: "+47", label: "Norway (+47)" });
    countryCodeOption.push({ value: "+968", label: "Oman (+968)" });
    countryCodeOption.push({ value: "+680", label: "Palau (+680)" });
    countryCodeOption.push({ value: "+507", label: "Panama (+507)" });
    countryCodeOption.push({ value: "+675", label: "Papua New Guinea (+675)" });
    countryCodeOption.push({ value: "+595", label: "Paraguay (+595)" });
    countryCodeOption.push({ value: "+51", label: "Peru (+51)" });
    countryCodeOption.push({ value: "+63", label: "Philippines (+63)" });
    countryCodeOption.push({ value: "+48", label: "Poland (+48)" });
    countryCodeOption.push({ value: "+351", label: "Portugal (+351)" });
    countryCodeOption.push({ value: "+1787", label: "Puerto Rico (+1787)" });
    countryCodeOption.push({ value: "+974", label: "Qatar (+974)" });
    countryCodeOption.push({ value: "+262", label: "Reunion (+262)" });
    countryCodeOption.push({ value: "+40", label: "Romania (+40)" });
    countryCodeOption.push({ value: "+7", label: "Russia (+7)" });
    countryCodeOption.push({ value: "+250", label: "Rwanda (+250)" });
    countryCodeOption.push({ value: "+378", label: "San Marino (+378)" });
    countryCodeOption.push({ value: "+239", label: "Sao Tome Principe (+239)" });
    countryCodeOption.push({ value: "+966", label: "Saudi Arabia (+966)" });
    countryCodeOption.push({ value: "+221", label: "Senegal (+221)" });
    countryCodeOption.push({ value: "+381", label: "Serbia (+381)" });
    countryCodeOption.push({ value: "+248", label: "Seychelles (+248)" });
    countryCodeOption.push({ value: "+232", label: "Sierra Leone (+232)" });
    countryCodeOption.push({ value: "+65", label: "Singapore (+65)" });
    countryCodeOption.push({ value: "+421", label: "Slovak Republic (+421)" });
    countryCodeOption.push({ value: "+386", label: "Slovenia (+386)" });
    countryCodeOption.push({ value: "+677", label: "Solomon Islands (+677)" });
    countryCodeOption.push({ value: "+252", label: "Somalia (+252)" });
    countryCodeOption.push({ value: "+27", label: "South Africa (+27)" });
    countryCodeOption.push({ value: "+34", label: "Spain (+34)" });
    countryCodeOption.push({ value: "+94", label: "Sri Lanka (+94)" });
    countryCodeOption.push({ value: "+290", label: "St. Helena (+290)" });
    countryCodeOption.push({ value: "+1869", label: "St. Kitts (+1869)" });
    countryCodeOption.push({ value: "+1758", label: "St. Lucia (+1758)" });
    countryCodeOption.push({ value: "+249", label: "Sudan (+249)" });
    countryCodeOption.push({ value: "+597", label: "Suriname (+597)" });
    countryCodeOption.push({ value: "+268", label: "Swaziland (+268)" });
    countryCodeOption.push({ value: "+46", label: "Sweden (+46)" });
    countryCodeOption.push({ value: "+41", label: "Switzerland (+41)" });
    countryCodeOption.push({ value: "+963", label: "Syria (+963)" });
    countryCodeOption.push({ value: "+886", label: "Taiwan (+886)" });
    countryCodeOption.push({ value: "+66", label: "Thailand (+66)" });
    countryCodeOption.push({ value: "+228", label: "Togo (+228)" });
    countryCodeOption.push({ value: "+676", label: "Tonga (+676)" });
    countryCodeOption.push({ value: "+1868", label: "Trinidad Tobago (+1868)" });
    countryCodeOption.push({ value: "+216", label: "Tunisia (+216)" });
    countryCodeOption.push({ value: "+90", label: "Turkey (+90)" });
    countryCodeOption.push({ value: "+993", label: "Turkmenistan (+993)" });
    countryCodeOption.push({
        value: "+1649",
        label: "Turks Caicos Islands (+1649)",
    });
    countryCodeOption.push({ value: "+688", label: "Tuvalu (+688)" });
    countryCodeOption.push({ value: "+256", label: "Uganda (+256)" });
    countryCodeOption.push({ value: "+44", label: "UK (+44)" });
    countryCodeOption.push({ value: "+380", label: "Ukraine (+380)" });
    countryCodeOption.push({
        value: "+971",
        label: "United Arab Emirates (+971)",
    });
    countryCodeOption.push({ value: "+598", label: "Uruguay (+598)" });
    countryCodeOption.push({ value: "+678", label: "Vanuatu (+678)" });
    countryCodeOption.push({ value: "+379", label: "Vatican City (+379)" });
    countryCodeOption.push({ value: "+58", label: "Venezuela (+58)" });
    countryCodeOption.push({ value: "+84", label: "Virgin Islands" });
    countryCodeOption.push({ value: "+681", label: "Wallis Futuna (+681)" });
    countryCodeOption.push({ value: "+969", label: "Yemen North (+969)" });
    countryCodeOption.push({ value: "+967", label: "Yemen South (+967)" });
    countryCodeOption.push({ value: "+260", label: "Zambia (+260)" });
    countryCodeOption.push({ value: "+263", label: "Zimbabwe (+263)" });
    return countryCodeOption;
}

// custom hook for getting previous value
export function usePrevious(value: any): any {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

export function getFormattedSentDate(date: Date): any {
    date = new Date(date);
    const currentDate = new Date();
    const seventhDate = new Date();
    seventhDate.setDate(seventhDate.getDate() + 7);
    if (date > currentDate) {
        return date.toLocaleTimeString();
    } else if (currentDate < seventhDate) {
        return `${date.toLocaleDateString("en-US", {
            weekday: "short",
        })} ${date.getMonth() + 1}-${date.getDate()}`;
    } else {
        return date.toLocaleDateString();
    }
}

export const isSubFolder = (folder: IFolderModel): boolean => {
    return folder.parentId != undefined && folder.parentId != 0;
};

export function getImageInitials(firstName: string, lastName?: string): string {
    const matches = `${firstName.charAt(0)}${lastName?.charAt(0) || ""}`;
    return matches.toUpperCase();
}

export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function convertHtmlStringToText(html: string) {
    const tempDivElement = document.createElement("div");
    tempDivElement.innerHTML = html;
    return tempDivElement.textContent || tempDivElement.innerText || "";
}

export const getRetentionOptions = (retentionPeriods: IRetentionPeriod[]): Option[] => {
    const retentionOptions: Option[] = [];

    if (retentionPeriods?.length > 0)
        retentionPeriods.forEach(x => {
            let label = "";
            if (x.days < 365) {
                label = x.days + (x.days === 1 ? " Day" : " Days");
            } else if (x.days === 365) {
                label = "1 Year";
            } else if (x.days === 1095) {
                label = "3 Years";
            } else if (x.days === 2555) {
                label = "7 Years";
            }

            retentionOptions.push({
                label,
                value: x.days.toString(),
            });
        });

    return retentionOptions;
};

export const getShareFolderOptions = (): Option[] => {
    const options: Option[] = [];
    options.push({
        value: ShareFolderTypes.VIEW.toString(),
        label: ShareFolderTypeLabels.VIEW,
    });
    options.push({
        value: ShareFolderTypes.EDIT.toString(),
        label: ShareFolderTypeLabels.EDIT,
    });
    options.push({
        value: ShareFolderTypes.REMOVE.toString(),
        label: ShareFolderTypeLabels.REMOVE,
    });

    return options;
};

export const getShareFolderOptionValue = (permissionGroupId: ShareFolderTypes): string => {
    switch (permissionGroupId) {
        case ShareFolderTypes.VIEW:
            return ShareFolderTypes.VIEW.toString();
        case ShareFolderTypes.EDIT:
            return ShareFolderTypes.EDIT.toString();
        case ShareFolderTypes.REMOVE:
            return ShareFolderTypes.REMOVE.toString();
        default:
            return "";
    }
};

export const getCompanyAuthMethods = () => {
    const methods = [];
    methods.push({
        value: AuthenticationMethod.ACCESSCODE.toString(),
        label: AuthMethodLabels.ACCESSCODE,
        key: AuthenticationMethod.ACCESSCODE,
    });
    methods.push({
        value: AuthenticationMethod.QUESTIONANSWER.toString(),
        label: AuthMethodLabels.QUESTIONANSWER,
        key: AuthenticationMethod.QUESTIONANSWER,
    });
    // methods.push({
    //   value: AuthenticationMethod.LOGINPASSWORD.toString(),
    //   label: AuthMethodLabels.LOGINPASSWORD,
    //   key: AuthenticationMethod.LOGINPASSWORD
    // });
    return methods;
};

export const getDropOffLinkTypes = () => {
    const linkTypes = [];
    linkTypes.push({
        value: DropOffLinkType.COMPANY.toString(),
        label: DropOffLinkTypeLabels.COMPANY,
        key: DropOffLinkType.COMPANY,
    });
    linkTypes.push({
        value: DropOffLinkType.PERSONAL.toString(),
        label: DropOffLinkTypeLabels.PERSONAL,
        key: DropOffLinkType.PERSONAL,
    });
    linkTypes.push({
        value: DropOffLinkType.NONE.toString(),
        label: DropOffLinkTypeLabels.NONE,
        key: DropOffLinkType.NONE,
    });
    return linkTypes;
};

export const getFolderOptions = (folders: IFolderModel[]): Option[] => {
    return folders.map(f => {
        return { label: f.path, value: f.folderId.toString() };
    });
};

export const getFolderModelColumns = (column?: string): FolderColumnName => {
    let enumVal;
    switch (column) {
        case "folderName":
            enumVal = FolderColumnName.FOLDERNAME;
            break;
        case "expiresDays":
            enumVal = FolderColumnName.EXPIRESDAYS;
            break;
        case "createdDate":
            enumVal = FolderColumnName.CREATEDDATE;
            break;
        case "description":
            enumVal = FolderColumnName.DESCRIPTION;
            break;
        case "sharedWith":
            enumVal = FolderColumnName.SHAREDWITH;
            break;
        case "owner":
            enumVal = FolderColumnName.OWNER;
            break;
        default:
            enumVal = FolderColumnName.FOLDERNAME;
            break;
    }
    return enumVal;
};

export const getFolderTypesToForFetchFoldersTree = (folderType: ArchiveFolderType) => {
    if (folderType === ArchiveFolderType.MESSAGE) {
        return [ArchiveFolderType.MESSAGE, ArchiveFolderType.UNKNOWN, ArchiveFolderType.FOLDER];
    }
    if (folderType === ArchiveFolderType.FOLDER) {
        return [ArchiveFolderType.UNKNOWN, ArchiveFolderType.FOLDER];
    }
    return [ArchiveFolderType.UNKNOWN, ArchiveFolderType.FOLDER];
};

export const copyToClipboard = async (text: string) => {
    await navigator.clipboard.writeText(text);
};

export const getMailboxUserDataFromLocal = () => {
    const data = sessionStorage.getItem(LocalStorageConstants.otherUser);
    return data ? (JSON.parse(data) as IDelegateeUser) : null;
};

export const isDelegatedUser = (): boolean => {
    const data = sessionStorage.getItem(LocalStorageConstants.otherUser);
    const user = data ? (JSON.parse(data) as IDelegateeUser) : null;
    return user?.email == null ? false : true;
};

export const getMailboxUserEmailId = () => {
    const data = getMailboxUserDataFromLocal();
    if (data) {
        return data.metadata;
    } else {
        return "";
    }
};

export const getMailboxUserEmailText = () => {
    const data = getMailboxUserDataFromLocal();
    if (data) {
        return data.email;
    } else {
        return "";
    }
};

export const isFolderOwner = (folder: IFolderModel, user: IMyAccountUserProfile) => {
    const mailboxData = getMailboxUserDataFromLocal();
    if (mailboxData && folder.createdBy === mailboxData.userId) {
        return true;
    } else if (!mailboxData && folder.createdBy === user.userId) {
        return true;
    }
    return false;
};

export const enableEditPermission = ( user: IMyAccountUserProfile, folder?: IFolderModel) => {
    if (!folder || isFolderOwner(folder, user) || folder.permissionGroupId == ShareFolderTypes.EDIT) {
        return true;
    } else if (folder.permissionGroupId == ShareFolderTypes.VIEW) {
        return false;
    }
}; 

export const convertTextToHtml = (str: string) => {
    //This method replaces /n character with <br>
    str = str.replace(/(?:\r\n|\r|\n)/g, "<br>");
    return str;
};

export const getDynamicMaxHeightForAttachments = (attachmentsLength: number, messageLength: number): string => {
    if (attachmentsLength > 0 && messageLength <= 100) {
        return "65vh";
    } else if (messageLength >= 100 && attachmentsLength > 0) {
        return "25vh";
    } else {
        return "none";
    }
};

export const getDynamicMaxHeightForMessage = (
    attachmentsLength: number,
    messageLength: number,
    folderType: FolderType
): string => {
    if (attachmentsLength > 0 && messageLength <= 200) {
        return "none";
    } else if (messageLength >= 200 && attachmentsLength > 0) {
        if (folderType !== FolderType.ARCHIVE) {
            return "calc(100vh - 500px)";
        } else {
            return "calc(100vh - 580px)";
        }
    } else if (messageLength >= 200 && attachmentsLength === 0) {
        if (folderType !== FolderType.ARCHIVE) {
            return "calc(100vh - 218px)";
        } else {
            return "calc(100vh - 308px)";
        }
    } else return "none";
};
export const getMaxHeightForNoteWrapper = (messageLength: number, documentLength: number): string => {
    if (documentLength > 0 && messageLength <= 200) {
        return "none";
    } else if (messageLength >= 200 && documentLength > 0) {
        return "calc(100vh - 705px)";
    }
    return "none";
};
export const getMaxHeightRowListWrapper = (messageLength: number, documentLength: number): string => {
    if (documentLength > 0 && messageLength === 0) {
        return "calc(100vh - 410px)"; //done
    } else if (messageLength >= 100 && documentLength > 0) {
        return "calc(100vh - 725px)";
    } else if (documentLength > 0 && messageLength < 100) {
        return "calc(100vh - 490px)";
    }
    return "none";
};


export const getFileViewWrapperHeight = (mailboxData: IDelegateeUser | null) =>{
    return mailboxData ? "calc(100vh - 258px)" : "calc(100vh - 228px)";
};

export const getBrowserDetails = (userAgent: string) => {
    const browser = Bowser.getParser(userAgent);
    return (
        browser.getBrowserName() +
        "(" +
        browser.getBrowserVersion() +
        ")" +
        " on " +
        browser.getOSName() +
        "(" +
        browser.getOSVersion() +
        ")"
    );
};

export const checkInputIsBlank = (value: string) => {
    if (value.length === 0 || value.trim().length === 0) {
        return false;
    }
    return true;
};

export const getPasswordOptionLabel = (option: number): string => {
    switch (option) {
        case PasswordOptions.None:
            return "";
        case PasswordOptions.UpperCase:
            return "Upper Case Letter";
        case PasswordOptions.LowerCase:
            return "Lower Case Letter";
        case PasswordOptions.Numbers:
            return "Numbers";
        case PasswordOptions.SpecialCharacters:
            return "Special Character";
        default:
            return "";
    }
};
export const getMessageListHeight = (mailboxData: IDelegateeUser | null, folderType: FolderType) => {
    if (mailboxData) {
        if (folderType === FolderType.ARCHIVE) {
            return "calc(100vh - 269px)";
        }
        return "calc(100vh - 185px)";
    } else {
        if (folderType === FolderType.ARCHIVE) {
            return "calc(100vh - 270px)";
        }
        return "calc(100vh - 166px)";
    }
};

export const getHeightForDocumentBody = (headerIsBig: boolean, subject: string): string => {
    if (headerIsBig) {
        if (subject.length > 50) {
            return "calc(100vh - 370px)";
        }
        return "calc(100vh - 345px)";
    }
    return "calc(100vh - 305px)";
};

export const getPageTitleFromFolderType = (folderType: FolderType) => {
    switch (folderType) {
        case FolderType.INBOX:
            return appInsightsData.MailBox.Inbox.PageTitle;
        case FolderType.DRAFT:
            return appInsightsData.MailBox.Draft.PageTitle;
        case FolderType.SENT:
            return appInsightsData.MailBox.Sent.PageTitle;
        case FolderType.SPAM:
            return appInsightsData.MailBox.Spam.PageTitle;
        case FolderType.ARCHIVE:
            return appInsightsData.Folders.MyFiles.PageTitle;
        default:
            return "";
    }
};

export const getMessageTitleFromFolderType = (folderType: FolderType) => {
    switch (folderType) {
        case FolderType.INBOX:
            return MessageTitleConstants.TITLE_INBOX;
        case FolderType.SENT:
            return MessageTitleConstants.TITLE_SENT;
        case FolderType.SPAM:
            return MessageTitleConstants.TITLE_SPAM;
        case FolderType.ARCHIVE:
            return MessageTitleConstants.TITLE_ARCHIVE;
        default:
            return "";
    }
};

export const getUserIdFromEmail = (data: IRecipientModel[], email: string) => {
    let userId = 0;
    for (let i = 0; i < data.length; i++) {
        if (data[i].emailAddress === email && data[i].userId) {
            userId = data[i].userId ?? 0;
            break;
        }
    }
    return userId;
};

export const getWebSocketGroup = (companyId: number) => {
    const group = "00000000" + companyId;
    return "sse" + group.substring(group.length - 8);
};

export const getDocumentRequestPayload = (selectedFiles: IDocumentRequestFileModel[]) =>{
    const grouped = selectedFiles.reduce((acc, model) => {
        const key = `${model.documentRequestFileId}_${model.documentRequestId}`;
        if (!acc[key]) {
          acc[key] = {
            documentRequestFileId: model.documentRequestFileId,
            name: model.documentName,
            description: "", 
            documentRequestId: model.documentRequestId,
            documentCategoryId: undefined,
            documentCategoryName: model.documentCategory,
            createdDate: model.sentDate,
            uploadedFiles: [],
          };
        }
        acc[key].uploadedFiles.push({
          fileName: model.fileName,
          fileType: "", 
          fileSize: model.fileSize,
          fileGuid: model.fileGuid,
          isUploaded: model.isUploaded,
          uploadedDate: model.uploadedDate,
          fileId: model.fileId,
        });
        return acc;
      }, {} as Record<string, IDocumentRequestFile>);
    
      // Convert the grouped object back to an array
      return Object.values(grouped);
    };

export   const getMessagePayload = (selectedFiles: IMessageFileViewModel[]) => {
    const payload: IDownloadNowPayload[] = [];
    selectedFiles.map(files => {
        if (payload?.length) {
            const hasMessageObj = payload.some(item => item.messageGuid === files.receivedMessageGuid);
            if (hasMessageObj) {
                payload.map(item => {
                    if (item.messageGuid === files.receivedMessageGuid) {
                        return item.files.push(files);
                    } else {
                        return item;
                    }
                });
            } else {
                payload.push({
                    messageGuid: files.receivedMessageGuid,
                    files: [files],
                    senderEmail: files.senderEmail,
                    messageSource: files.messageSource,
                    messageId: files.receivedMessageId,
                });
            }
        } else {
            payload.push({
                messageGuid: files.receivedMessageGuid,
                files: [files],
                senderEmail: files.senderEmail,
                messageSource: files.messageSource,
                messageId: files.receivedMessageId,
            });
        }
    });
    return payload;
};

const restrictedMenuItemCaptions = ["Settings", "History Reports", "Drafts"];

export const getAccessibleSideMenuItems = (menuData: IMenuSection[]) => {
    const filteredMenus: IMenuSection[] = []; // This is used since menuData is immutable read-only
    menuData &&
        menuData.length > 0 &&
        menuData.forEach(section => {
            const menuItems: ISideMenuItem[] = [];
            section.items.forEach(menu => {
                if (!restrictedMenuItemCaptions.includes(menu.caption)) {
                    menuItems.push(menu);
                }
            });
            filteredMenus.push({ ...section, items: menuItems });
        });
    return filteredMenus.filter(section => section.items.length > 0);
};

export const getDocumentStatusButtonClass = (documentStatus: ListingStatus) => {
    switch (documentStatus) {
        case ListingStatus.COMPLETED:
            return "document-completed";
        case ListingStatus.FAILED:
            return "document-failed";
        case ListingStatus.OVERDUE:
            return "document-overdue";
        case ListingStatus.PARTIALLY_UPLOADED:
            return "document-partially-uploaded";
        case ListingStatus.PENDING_UPLOAD:
            return "document-pending-upload";
        default:
            return "document-status-button";
    }
};

export const getDocumentStatusButtonCaption = (documentStatus: ListingStatus) => {
    switch (documentStatus) {
        case ListingStatus.COMPLETED:
            return "Completed";
        case ListingStatus.FAILED:
            return "Failed";
        case ListingStatus.OVERDUE:
            return "Overdue";
        case ListingStatus.PARTIALLY_UPLOADED:
            return "Partially Uploaded";
        case ListingStatus.PENDING_UPLOAD:
            return "Pending Upload";
        default:
            return "Default";
    }
};

export const checkIfPermissionIsAllowed = (permissionsList: string[], resourceId: string) => {
    return !permissionsList.find(permission => permission === resourceId);
};
export const getTemplateModelColumns = (column?: string): TemplateColumnName => {
    let enumVal;
    switch (column) {
        case "name":
            enumVal = TemplateColumnName.NAME;
            break;
        case "description":
            enumVal = TemplateColumnName.DESCRIPTION;
            break;
        case "createdDate":
            enumVal = TemplateColumnName.CREATEDDATE;
            break;
        case "modifiedDate":
            enumVal = TemplateColumnName.MODIFIEDDATE;
            break;
        default:
            enumVal = TemplateColumnName.NAME;
            break;
    }
    return enumVal;
};

export function isAdminUser(user: any) {
    return user?.profile?.role?.split(",")?.includes(UserGroupConstants.ADMIN) || false;
}

export function isExchangeUser(user: any) {
    return user?.profile?.role?.split(",")?.includes("Exchange") || false;
}

export const getCookie = (cookieName: string) => {
    const cookieString = document.cookie;
    const cookieArray = cookieString.split(";");
    for (let i = 0; i < cookieArray.length; ++i) {
        if (cookieArray[i].trim().match("^" + cookieName + "=")) {
            return cookieArray[i].replace(`${cookieName}=`, "").trim();
        }
    }
    return null;
};

export const setCookie = (name: string, value: string, days: number) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

export const removeCookie = (name: string) => {
    document.cookie = name + "=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;";
};

export const getDocumentStatusOptions = (currentState: DetailedStatus): Option[] => {
    const dropdownOptions: Option[] = [];
    switch (currentState) {
        case DetailedStatus.COMPLETED:
            dropdownOptions.push({ value: DetailedStatus.OPEN.toString(), label: "Open" });
            break;
        case DetailedStatus.OPEN:
            dropdownOptions.push({ value: DetailedStatus.COMPLETED.toString(), label: "Complete" });
            break;
        default:
            dropdownOptions.push({ value: DetailedStatus.COMPLETED.toString(), label: "Complete" });
            break;
    }

    return dropdownOptions;
};
export const getDocumentStatusDropdownValue = (currentState: DetailedStatus): Option => {
    switch (currentState) {
        case DetailedStatus.COMPLETED:
            return { value: DetailedStatus.COMPLETED.toString(), label: "Complete" };

        case DetailedStatus.OPEN:
            return { value: DetailedStatus.OPEN.toString(), label: "Open" };

        default:
            return { value: DetailedStatus.OPEN.toString(), label: "Open" };
    }
};

export const getFolderMessageSortOptions = (selectedSortOption: string) => {
    switch (selectedSortOption) {
        case SortAndFilterConstants.ALPHABETICAL_ORDER_ASC:
            return { sortColumn: "senderName", sortOrder: SortAndFilterConstants.SORT_ORDER_ASC };

        case SortAndFilterConstants.ALPHABETICAL_ORDER_DESC:
            return { sortColumn: "senderName", sortOrder: SortAndFilterConstants.SORT_ORDER_DESC };

        case SortAndFilterConstants.NEWEST_FIRST_ORDER:
            return { sortColumn: "sentDate", sortOrder: SortAndFilterConstants.SORT_ORDER_DESC };

        case SortAndFilterConstants.OLDEST_FIRST_ORDER:
            return { sortColumn: "sentDate", sortOrder: SortAndFilterConstants.SORT_ORDER_ASC };

        default:
            return { sortColumn: "sentDate", sortOrder: SortAndFilterConstants.SORT_ORDER_DESC };
    }
};

export const getDRSortAndFilterOptions = (text: string, selectedFilterOption: string, selectedSortOption: string) => {
    const filterOption = getSelectedDRFilterOption(selectedFilterOption);
    const sortOption = getSelectedDRSortOption(selectedSortOption);
    switch (text) {
        case SortAndFilterConstants.ALPHABETICAL_ORDER_ASC:
            return {
                sortColumn: "email",
                sortOrder: SortAndFilterConstants.SORT_ORDER_ASC,
                listingStatus: filterOption,
            };

        case SortAndFilterConstants.ALPHABETICAL_ORDER_DESC:
            return {
                sortColumn: "email",
                sortOrder: SortAndFilterConstants.SORT_ORDER_DESC,
                listingStatus: filterOption,
            };

        case SortAndFilterConstants.NEWEST_FIRST_ORDER:
            return {
                sortColumn: "sentDate",
                sortOrder: SortAndFilterConstants.SORT_ORDER_DESC,
                listingStatus: filterOption,
            };

        case SortAndFilterConstants.OLDEST_FIRST_ORDER:
            return {
                sortColumn: "sentDate",
                sortOrder: SortAndFilterConstants.SORT_ORDER_ASC,
                listingStatus: filterOption,
            };
        case SortAndFilterConstants.ALL_STATUSES_ORDER:
            return {
                sortColumn: sortOption.sortColumn,
                sortOrder: sortOption.sortOrder,
                listingStatus: ListingStatus.NONE,
            };
        case SortAndFilterConstants.COMPLETED_ORDER:
            return {
                sortColumn: sortOption.sortColumn,
                sortOrder: sortOption.sortOrder,
                listingStatus: ListingStatus.COMPLETED,
            };
        case SortAndFilterConstants.PENDING_UPLOAD_ORDER:
            return {
                sortColumn: sortOption.sortColumn,
                sortOrder: sortOption.sortOrder,
                listingStatus: ListingStatus.PENDING_UPLOAD,
            };
        case SortAndFilterConstants.PARTIALLY_UPLOAD_ORDER:
            return {
                sortColumn: sortOption.sortColumn,
                sortOrder: sortOption.sortOrder,
                listingStatus: ListingStatus.PARTIALLY_UPLOADED,
            };
        case SortAndFilterConstants.OVERDUE_ORDER:
            return {
                sortColumn: sortOption.sortColumn,
                sortOrder: sortOption.sortOrder,
                listingStatus: ListingStatus.OVERDUE,
            };
        default:
            return { sortColumn: sortOption.sortColumn, sortOrder: sortOption.sortOrder, listingStatus: filterOption };
    }
};

const getSelectedDRFilterOption = (text: string) => {
    switch (text) {
        case SortAndFilterConstants.ALL_STATUSES_ORDER:
            return ListingStatus.NONE;
        case SortAndFilterConstants.COMPLETED_ORDER:
            return ListingStatus.COMPLETED;
        case SortAndFilterConstants.PENDING_UPLOAD_ORDER:
            return ListingStatus.PENDING_UPLOAD;
        case SortAndFilterConstants.PARTIALLY_UPLOAD_ORDER:
            return ListingStatus.PARTIALLY_UPLOADED;
        case SortAndFilterConstants.OVERDUE_ORDER:
            return ListingStatus.OVERDUE;
        default:
            return ListingStatus.NONE;
    }
};

const getSelectedDRSortOption = (text: string) => {
    switch (text) {
        case SortAndFilterConstants.ALPHABETICAL_ORDER_ASC:
            return { sortColumn: "email", sortOrder: SortAndFilterConstants.SORT_ORDER_ASC };

        case SortAndFilterConstants.ALPHABETICAL_ORDER_DESC:
            return { sortColumn: "email", sortOrder: SortAndFilterConstants.SORT_ORDER_DESC };

        case SortAndFilterConstants.NEWEST_FIRST_ORDER:
            return { sortColumn: "sentDate", sortOrder: SortAndFilterConstants.SORT_ORDER_DESC };

        case SortAndFilterConstants.OLDEST_FIRST_ORDER:
            return { sortColumn: "sentDate", sortOrder: SortAndFilterConstants.SORT_ORDER_ASC };

        default:
            return { sortColumn: "sentDate", sortOrder: SortAndFilterConstants.SORT_ORDER_DESC };
    }
};

export function encode(value: string): string {
    let newlyEncodedString = "";
    for (let i = 0; i < value.length; i++) {
        if (value.charCodeAt(i) > 31 && value.charCodeAt(i) < 127) {
            newlyEncodedString += "{STARTASCII}" + value.charCodeAt(i) + "{ENDASCII}";
        } else {
            newlyEncodedString += value[i];
        }
    }
    return newlyEncodedString;
}

export function removeSpecialCharacters(input: string): string {
    return input.replace(/[^a-zA-Z0-9_ ]/g, "");
}

export const createForethoughtCookie = (key: string, value: string, expiryMiliSeconds?: number) => {
    const domain = getDomain(window.location.href);
    createCookie(key, value, expiryMiliSeconds ?? 24 * 60 * 60 * 1000, `.${domain}`);
};

export const getFileName=(name:string):string=>{
    return name.replace(invalidFileNameRegex, "_");
};

export const createOption = (value: string, label: string) => ({
    label,
    value,
  });

// Debounce utility function
export const debounce = (func: (...args: any[]) => void, delay: number) => {
    let timeoutId: NodeJS.Timeout | number;
    return (...args: any[]) => {
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        timeoutId = setTimeout(() => {
            func(...args);
        }, delay);
    };
};

//saved Message Templates 
export const  convertHtmlToText=(htmlString: string): string =>{
    let text = htmlString;
    text = text.replace(/&nbsp;/g, " "); 
    text = text.replace(/<br>/g, "\n");
    return text;
};

export const convertToHtmlString = (input: string): string => {
    const htmlString = input
        .replace(/ {2,}/g, (match) => "&nbsp;".repeat(match.length))
        .replace(/\n/g, "<br>");
    return htmlString;
};

export const addPeriodAtEnd=(text:string):string=>{
    if(text[text.length-1]!=="." || !text.endsWith(".")){
      return text + ".";
    }
    return text;
};